import { LOGOUT } from "Components/query";
import { getLogout } from "Components/__generated__/getLogout";
import { ApolloClient } from "@apollo/client";

export const logout = async (apolloClient: ApolloClient<Object>) => {
  try {
    const { data } = await apolloClient.query<getLogout>({
      query: LOGOUT,
    });
    return data;
  } catch (e) {
    throw e;
  }
};
