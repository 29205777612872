import Media from 'react-media';
import DesktopHeader from './DesktopHeader';
import MobileHeader from './MobileHeader';
import AuthStore from 'Store/AuthStore';
import { storageFactory } from 'Libs/storageFactory';
import BirthdayReminderModal from 'Components/modals/BirthReminderModal';
import {
  dateDiff,
  timestampAndCount,
  createCookieBirtdayReminder,
  BirthdayPopIn,
  checkBirthDateAndDateToday,
} from 'Utils/date';
import Cookie from 'js-cookie';
import BirthdayGiftModal from 'Components/modals/BirthdayGiftModal';
import dayjs from 'dayjs';
import UIStore from 'Store/UiStore';
import Cookies from 'js-cookie';
import { removeCookie } from 'Utils/cookie';
import { createAnonymousSession } from 'Utils/login';
import { useApolloClient } from '@apollo/client';

export default () => {
  const apolloClient = useApolloClient();
  const setAuth = AuthStore.useStoreActions((actions) => actions.setAuth);
  const initAuth = AuthStore.useStoreActions((actions) => actions.initAuth);
  const loggedIn = AuthStore.useStoreState((state) => state.isLoggedIn);
  const localStore = storageFactory(() => localStorage);
  const member = AuthStore.useStoreState((state) => state.member);
  const birthdayReminder = Cookie.get('birthdayReminder');
  const birthdayvoucher = Cookie.get('voucher_birthdate_remain');
  const marketingStore = UIStore.useStoreState(
    (state) => state.cookieMarketing
  );

  if (!Cookie.get('ssession')) {
    createAnonymousSession(apolloClient)
      .then((data) => {
        if (data) {
          const payload = {
            jwt: data.createAnonymousSession.jwt,
            sessionId: data.createAnonymousSession.sessionId,
            member: data.createAnonymousSession.member,
          };
          setAuth(payload);
          initAuth();
        }
      })
      .catch((e) => console.log(e));
  }

  if (member) {
    BirthdayPopIn(member.birthDate);
    checkBirthDateAndDateToday(member.birthDate);
  }

  if (loggedIn) {
    if (sessionStorage.getItem('bugLogin')) {
      sessionStorage.removeItem('bugLogin');
    }
  }

  const birthdayCoupon = () => {
    let voucherBoolean = false;
    if (member && member.vouchers.length !== 0) {
      member.vouchers
        .filter(
          (voucher: any) => voucher.operationName === 'Joyeux Anniversaire'
        )
        .map((birthdayVoucher: any) => {
          const dateFromCoupon = dayjs(birthdayVoucher.dateFrom).unix();
          const dateToCoupon = dayjs(birthdayVoucher.dateTo).unix();
          const today = dayjs().unix();
          if (dateFromCoupon <= today && today <= dateToCoupon) {
            voucherBoolean = true;
          }
        });
      return voucherBoolean;
    }
    return false;
  };

  const getBirthdayReminder = () => {
    if (member) {
      if (!member.birthDate) {
        if (!!birthdayReminder) {
          const [timestamp] = timestampAndCount(birthdayReminder);
          if (dateDiff(timestamp) >= 10) {
            if (BirthdayReminderModal({})) {
              BirthdayReminderModal({});
            }
          }
        } else {
          createCookieBirtdayReminder();
        }
      } else {
        if (
          birthdayCoupon() &&
          BirthdayPopIn(member.birthDate) >= 0 &&
          BirthdayPopIn(member.birthDate) <= 29
        ) {
          if (!birthdayvoucher) {
            const today = String(dayjs().unix());
            Cookie.set('voucher_birthdate_remain', today);
          }
          if (checkBirthDateAndDateToday(member.birthDate)) {
            const today = String(dayjs().unix());
            if (Cookie.get('voucher_birthdate_remain')) {
              const timeStamp = Cookie.get('voucher_birthdate_remain');
              if (timeStamp <= today) {
                if (BirthdayGiftModal({})) {
                  BirthdayGiftModal({});
                }
              }
            }
          }
          // else if (parseInt(numberOfSession) % 3 == 0) {
          else if (Cookie.get('voucher_birthdate_remain')) {
            const today = String(dayjs().unix());
            const timeStamp = Cookie.get('voucher_birthdate_remain');
            if (timeStamp <= today) {
              if (BirthdayGiftModal({})) {
                BirthdayGiftModal({});
              }
            }
          }
          // }
        }
      }
    }
  };

  if (localStore.getItem('isLoggedIn') && !loggedIn) {
    localStore.removeItem('isLoggedIn');
  }

  if (loggedIn && !!localStore.getItem('isLoggedIn') === false) {
    localStore.setItem('isLoggedIn', 'true');
  }

  setTimeout(() => {
    if (loggedIn && !!Cookies.get('redirection_url')) {
      removeCookie('redirection_url');
    }
  }, 100);

  if (loggedIn) {
    if (Cookie.get('RgpdBanner') && marketingStore) {
      getBirthdayReminder();
    }
  }

  // if (!loggedIn) return <AnonymousHeader />;
  // const style = !loggedIn ? { marginBottom: '114px' } : { marginBottom: '154' };
  return (
    <>
      <Media query={{ minWidth: 768 }}>
        <DesktopHeader />
      </Media>
      <Media query={{ maxWidth: 767 }}>
        <MobileHeader />
      </Media>
    </>
  );
};
