import React from 'react';
import { GlobalStyle, AppStyled } from '../GlobalStyle';
import RegistrationForm from 'Components/RegistrationForm';
import styled from 'styled-components';
import { ApolloProvider } from '@apollo/client';
import { init } from 'Libs/apollo';
import Toaster from 'Components/Toaster';
import AuthStore from 'Store/AuthStore';
import UiStore from 'Store/UiStore';
import fonts from '../../Fonts/';

const StyledForm = styled.div`
  padding: 1.5rem;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 20px;
  @font-face {
    font-family: 'granville-bold';
    src: url(${fonts.granvileBold}) format('opentype');
    font-weight: 400;
    font-style: normal;
  }
`;

const Title = styled.h2`
  color: #6c4dff;
  font-size: 6.5rem;
  text-align: center;
  margin: 0 0 1.5rem 0;
  font-family: 'granville-bold';
  font-weight: 700;
`;

export default () => {
  const client = init();
  return (
    <ApolloProvider client={client}>
      <AuthStore.Provider>
        <UiStore.Provider>
          <AppStyled>
            <GlobalStyle />
            <StyledForm>
              <Title>Inscription</Title>
              <RegistrationForm />
              <Toaster />
            </StyledForm>
          </AppStyled>
        </UiStore.Provider>
      </AuthStore.Provider>
    </ApolloProvider>
  );
};
