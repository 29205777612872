import { AppStyled, GlobalStyle } from '../GlobalStyle';
import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { init } from 'Libs/apollo';
import Footer from 'Components/Footer';
import AuthStore from 'Store/AuthStore';
import UiStore from 'Store/UiStore';
import InitiStore from 'Store/InitiStore';
import { BrowserRouter } from 'react-router-dom';
import ModalContainer from 'react-modal-promise';
import { FooterV2 } from 'Components/FooterV2';
import styled from 'styled-components';
import fonts from '../../Fonts/';

const FooterContainer = styled.div`
  @font-face {
    font-family: 'granville-bold';
    src: url(${fonts.granvileBold}) format('opentype');
  }
`;
const App = () => {
  const client = init();
  return (
    <AppStyled>
      <BrowserRouter>
        <AuthStore.Provider initialData={client}>
          <UiStore.Provider>
            <ApolloProvider client={client}>
              <>
                <InitiStore />
                <GlobalStyle />
                <FooterContainer>
                  <FooterV2 external />
                </FooterContainer>
                {/* <Footer external /> */}
                <ModalContainer />
              </>
            </ApolloProvider>
          </UiStore.Provider>
        </AuthStore.Provider>
      </BrowserRouter>
    </AppStyled>
  );
};

export default App;
