import * as React from "react";
const SvgIcoEye = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 25"
    {...props}
  >
    <path
      stroke="#A5A5A5"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M1 12.5s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeOpacity={0.2}
      d="M1 12.5s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8"
    />
    <path
      stroke="#A5A5A5"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12 15.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeOpacity={0.2}
      d="M12 15.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6"
    />
  </svg>
);
export default SvgIcoEye;
