import React from "react";

export default () => {
  /**
   * @Todo
   * updated dataLayer on V4. unify everything when the app will be full react
   */
  console.log("init dataLayer");

  if (window.localStorage.getItem("setSubscriptionType")) {
    const subscriptionTypeJson = window.localStorage.getItem(
      "setSubscriptionType"
    );

    const subscriptionType = JSON.parse(subscriptionTypeJson as any);
    let WindowDatalayer = (window as any)["dataLayer"];
    WindowDatalayer.push(subscriptionType);
    window.localStorage.removeItem("setSubscriptionType");
  }

  return <></>;
};
