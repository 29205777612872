import * as React from "react";
const SvgChat = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 30 24"
    {...props}
  >
    <g clipPath="url(#Chat_svg__a)">
      <path
        fill="currentColor"
        stroke="#fff"
        strokeWidth={0.2}
        d="M18.974 16.326v.454c-.001.693-.273 1.358-.757 1.85-.484.49-1.14.766-1.825.767H8.997L6.31 22.124a1.117 1.117 0 0 1-1.587-.003 1.15 1.15 0 0 1-.33-.804V19.4H3.147c-.684-.001-1.34-.277-1.824-.768a2.64 2.64 0 0 1-.757-1.849V8.226c0-.694.273-1.359.757-1.85a2.57 2.57 0 0 1 1.824-.767h5.98c.354 0 .64.29.64.648a.643.643 0 0 1-.64.648H3.145c-.346 0-.677.14-.921.388a1.33 1.33 0 0 0-.383.933v8.557c.001.35.139.686.383.933.244.248.575.387.92.388H5.03c.17 0 .332.069.452.19.12.122.187.286.187.458v2.193l2.613-2.653a.64.64 0 0 1 .454-.191h7.667c.345 0 .676-.14.92-.388.245-.248.383-.583.383-.933v-.454a.64.64 0 0 1 .634-.568c.323 0 .596.244.635.568ZM29.516 3.183v8.557a2.64 2.64 0 0 1-.757 1.85c-.484.49-1.14.766-1.824.767h-1.246v1.92c0 .302-.12.591-.33.804a1.116 1.116 0 0 1-1.587.003l-2.686-2.727h-7.4c-.684-.001-1.34-.277-1.824-.768a2.64 2.64 0 0 1-.757-1.85V3.184c0-.694.273-1.359.757-1.85a2.57 2.57 0 0 1 1.825-.767h13.245c.684 0 1.342.276 1.826.766a2.64 2.64 0 0 1 .758 1.851Zm-1.277 0c-.001-.35-.14-.686-.383-.933a1.3 1.3 0 0 0-.921-.388H13.687c-.346 0-.677.14-.921.388a1.33 1.33 0 0 0-.383.933v8.557c.001.35.139.685.383.933s.575.387.92.388h7.668c.17 0 .334.069.454.191l2.613 2.653v-2.196c0-.358.286-.648.639-.648h1.885c.345 0 .676-.14.92-.388.245-.248.382-.583.383-.933V3.183z"
      />
      <circle cx={16.415} cy={7.359} r={1.132} fill="url(#Chat_svg__b)" />
      <circle cx={20.377} cy={7.359} r={1.132} fill="url(#Chat_svg__c)" />
      <circle cx={24.34} cy={7.359} r={1.132} fill="url(#Chat_svg__d)" />
    </g>
    <defs>
      <linearGradient
        id="Chat_svg__b"
        x1={16.415}
        x2={16.415}
        y1={6.226}
        y2={8.491}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6C4DFF" />
        <stop offset={1} stopColor="#8268FF" />
      </linearGradient>
      <linearGradient
        id="Chat_svg__c"
        x1={20.377}
        x2={20.377}
        y1={6.226}
        y2={8.491}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6C4DFF" />
        <stop offset={1} stopColor="#8268FF" />
      </linearGradient>
      <linearGradient
        id="Chat_svg__d"
        x1={24.34}
        x2={24.34}
        y1={6.226}
        y2={8.491}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6C4DFF" />
        <stop offset={1} stopColor="#8268FF" />
      </linearGradient>
      <clipPath id="Chat_svg__a">
        <path fill="#fff" d="M0 0h30v22.642H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgChat;
