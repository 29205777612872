import CardImage from './CardImage';
import { Link } from 'Ui';
import { SaleContainer } from 'Ui/SaleContainer';
import { SpySaleVisibility } from 'Libs/Gtm/home';
import UiStore from 'Store/UiStore';

type CardSalesProps = {
  height?: number;
  width?: number;
  image: string | any;
  macaron?: string;
  alt?: string;
  to?: string;
  dataProductId?: string;
  dateEnd?: string;
  dateStart?: string;
  cursor?: any;
  onClick?: any;
  isLastingSale: boolean;
  sale?: any;
  size?: 'Xs' | 'responsiveXs' | 'Small' | 'Medium' | 'Large' | number;
  position?: number;
  dataSrcset?: string;
  universe?: string;
  dataTest?: string;
};

export default ({
  image,
  dateEnd,
  dateStart,
  alt,
  dataProductId,
  to,
  size,
  onClick,
  macaron,
  isLastingSale,
  sale,
  position,
  dataSrcset,
  universe,
  height,
  width,
  dataTest,
}: CardSalesProps) => {
  const cookieMarketingStore = UiStore.useStoreState(
    (state) => state.cookieMarketing
  );

  return (
    <SaleContainer data-test-id={dataProductId} onClick={onClick} size={size}>
      {to ? (
        <Link dataTest={dataTest} to={to} internal={false}>
          <CardImage
            image={image}
            width={width}
            height={height}
            macaron={macaron}
            dateStart={dateStart}
            dateEnd={dateEnd}
            isLastingSale={isLastingSale}
            alt={alt}
            dataSrcset={dataSrcset}
          />
        </Link>
      ) : (
        <CardImage
          image={image}
          width={width}
          height={height}
          macaron={macaron}
          dateStart={dateStart}
          dateEnd={dateEnd}
          isLastingSale={isLastingSale}
          alt={alt}
          data-product-id={dataProductId}
          dataSrcset={dataSrcset}
        />
      )}
    </SaleContainer>
  );
};
