import * as React from "react";
const SvgInfoV2 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M22.6 12c0 5.854-4.746 10.6-10.6 10.6S1.4 17.854 1.4 12 6.146 1.4 12 1.4 22.6 6.146 22.6 12m1.4 0c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0s12 5.373 12 12m-10.593-.306a1 1 0 0 0-.998-.933h-.127a1 1 0 0 0-.998.933l-.363 5.358a1 1 0 0 0 .997 1.068h.855a1 1 0 0 0 .998-1.068zm.05-4.54q-.435-.434-1.111-.434-.693 0-1.111.435-.435.435-.435 1.03 0 .612.435 1.015.42.418 1.11.418.677 0 1.112-.418.435-.403.434-1.015 0-.595-.434-1.03"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgInfoV2;
