import gql from "graphql-tag";

export const PRODUCT_AVAILABILITY = gql`
  mutation ProductAvailability(
    $saleId: ID!
    $productGroupId: ID!
    $productId: ID!
    $phoneNumber: String
    $contactMethod: ContactMethodEnum!
  ) {
    alertProductAvailability(
      saleId: $saleId
      productGroupId: $productGroupId
      productId: $productId
      phoneNumber: $phoneNumber
      contactMethod: $contactMethod
    )
  }
`;

export const REGISTER_RGPD_BEHAVIOUR = gql`
  mutation RegisterRgpdBehaviour(
    $os: OsEnum
    $sdkPerf: Boolean
    $sdkMarket: Boolean
    $btnPersonnalize: Boolean
    $btnAccept: Boolean
    $btnDeny: Boolean
    $linkPvp: Boolean
    $deviceType: DeviceEnum
    $versionPopin: VersionPopinEnum
  ) {
    registerUserRgpdBehaviour(os: $os, sdkPerf: $sdkPerf, sdkMarket: $sdkMarket, btnPersonnalize: $btnPersonnalize,
      btnAccept: $btnAccept,
      btnDeny: $btnDeny,
      linkPvp: $linkPvp,
      deviceType: $deviceType
      versionPopin: $versionPopin
      )
  }
`;

export const SALE_GL = gql`
  query IsCodeAuthorized($code: String!) {
    isCodeAuthorized(code: $code)
  }
`;

export const SHARE_PRODUCT = gql`
  query getShareProduct($productId: ID!,$saleId: ID!, $emails:[String]!, $message:String! ){
    shareProduct(productId: $productId ,saleId: $saleId, emails: $emails, message: $message)
    
  }
`;

export const UPDATE_BIRTHDATE = gql`
  mutation UpdateBirthdate($updateBirthdate: String!) {
    updateBirthdate(birthdate: $updateBirthdate)
  }
`;

