import HtmlHead from 'Components/HtmlHead';
import React, { useEffect } from 'react';
import AuthStore from 'Store/AuthStore';
import styled from 'styled-components/macro';
import { PageContainer, theme } from 'Ui';
import ConceptTab from './concept';
import ConvictionsTab from './Convictions';
import Tabs from './Tabs';
import WhoIsMenu from './WhoIsMenu';
import { getPageView, setGtmUserData } from 'Libs/GA4/common';
import UiStore from 'Store/UiStore';

const PageTitle = styled.h1`
  font-family: 'granville-bold';
  font-size: 4.5rem;
  font-weight: 700;
  color: ${theme.light.colors.purple};
  margin: 3rem 0 3rem 0;
  text-align: center;
  @media screen and (max-width: 480px) {
    font-size: 3.5rem;
  }
  span {
    display: block;
  }
`;

export default () => {
  const isLoggedIn = AuthStore.useStoreState((state) => state.isLoggedIn);
  const member = AuthStore.useStoreState((state) => state.member);
  const sessionMemberType = AuthStore.useStoreState(
    (state) => state.sessionMemberType
  );
  const cookieMarketingStore = UiStore.useStoreState(
    (state) => state.cookieMarketing
  );
  useEffect(() => {
    getPageView(window.location.pathname, window.location.href);
    if (isLoggedIn && cookieMarketingStore) {
      setGtmUserData(member, sessionMemberType);
    }
  }, []);
  let TabsWhoIs = [
    {
      title: 'Le Concept',
      desc: <ConceptTab />,
    },
    {
      title: 'Nos Convictions',
      desc: <ConvictionsTab />,
    },
  ];

  return (
    <PageContainer>
      <HtmlHead
        title="BazarChic - A propos de nous - La référence des ventes privées premium"
        description="Pionnier des ventes évènementielles premium en France depuis 2006, BazarChic propose chaque jour 14 nouvelles ventes sélectionnées avec soin."
        noIndex={false}
      />
      <PageTitle>
        <span>La référence &nbsp;</span>
        <span>des ventes privées &nbsp;</span>
        <span>premium &nbsp;</span>
      </PageTitle>
      <Tabs datas={TabsWhoIs} />
      {isLoggedIn && <WhoIsMenu />}
    </PageContainer>
  );
};
