import React from 'react';
import styled from 'styled-components';
import UiStore from 'Store/UiStore';
import { action } from 'easy-peasy';
import OptinSuccessMessage from './OptinSuccesMessage';
import OptinInput from './OptinInput';

const OptinContainer = styled.div`
  border-radius: 15px;
  background: var(
    --gradient-normal-violet,
    linear-gradient(180deg, #6c4dff 0%, #8268ff 100%)
  );
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 4rem 5.2rem;
  max-width: 112rem;
  margin: 0rem auto 4rem;
  @media screen and (max-width: 48rem) {
    display: flex;
    width: calc(96.5% - 2rem);
    padding: 21px 2px;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    gap: 8px 12px;
    flex-wrap: wrap;
  }
  @media screen and (min-width: 49rem) and (max-width: 768px) {
    width: 100%;
    flex-wrap: wrap;
  }
  @media screen and (min-width: 768px) and (max-width: 967px) {
    width: calc(96.5% - 2rem);
    flex-wrap: wrap;
    gap: 4.8rem;
    padding: 4rem 3.2rem;
    align-items: center;
  }
  @media screen and (min-width: 968px) and (max-width: 1024px) {
    width: calc(96.5% - 2rem);
    flex-wrap: wrap;
    padding: 4rem 5rem;
    gap: 6.2rem;
    align-items: center;
  }
`;
const OptinTextContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 48rem) {
    display: flex;
    width: calc(96.5% - 2rem);
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    gap: 21px 12px;
    flex-wrap: wrap;
  }
  @media screen and (min-width: 49rem) and (max-width: 768px) {
    width: 100%;
    flex-wrap: wrap;
  }
  @media screen and (min-width: 768px) and (max-width: 967px) {
    justify-content: space-between;
    align-items: center;
  }
`;

const OptinSubTest = styled.div`
  font-family: 'MontserratMedium' !important;
  color: #fff;
  font-size: 1.2rem;
  letter-spacing: 0.12px;
  a {
    text-decoration: underline;
  }
  @media screen and (max-width: 48rem) {
    color: #fff;
    font-family: 'Montserrat';
    font-size: 1.1rem;
    font-style: normal;
    line-height: 16.4px; /* 149.091% */
  }
`;

const OptinInputContainer = styled.div`
  margin-top: 1rem;
  @media screen and (max-width: 48rem) {
    width: 100%;
    margin-top: 1.3rem;
  }
  @media screen and (min-width: 968px) and (max-width: 1024px) {
    width: 37.8rem;
  }
  @media screen and (min-width: 768px) and (max-width: 967px) {
    width: 30rem;
  }
`;
const OptinTitle = styled.div`
  color: #fff;
  font-family: 'granville-bold' !important;
  font-size: 3rem;
  font-weight: 700;
  max-width: 18.6rem;
  @media screen and (max-width: 48rem) {
    font-size: 2.4rem;
  }
`;
const OptinText = styled.div`
  color: #fff;
  font-family: 'MontserratMedium' !important;
  font-size: 1.4rem;
  padding-bottom: 0.8rem;
  line-height: 21px; /* 150% */
`;

const OptinResponsive = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4.2rem;
  @media screen and (max-width: 1024px) {
    flex-direction: column;
    gap: 8px;
  }
`;

const OptinMainText = styled.div`
  max-width: 44.6rem;
  @media screen and (min-width: 768px) and (max-width: 967px) {
    max-width: 29.3rem;
  }
`;

const OptinFooter = () => {
  const setOptinResponse = UiStore.useStoreActions(
    (action) => action.setOptinResponse
  );
  const optinResponse = UiStore.useStoreState((state) => state.optinResponse);
  const optinEmail: any =
    optinResponse && optinResponse.member && optinResponse.member.email;

  if (!!optinResponse) {
    setTimeout(() => {
      setOptinResponse(null);
    }, 10000);
  }

  return (
    <OptinContainer>
      <OptinTextContainer>
        <OptinResponsive>
          <OptinTitle>la Newsletter</OptinTitle>
          <OptinMainText>
            <OptinText>
              Inscrivez-vous aux newsletters et découvrez nos ventes, nos offres
              exclusives, et des inspirations mode & maison.
            </OptinText>
            <OptinSubTest>
              En vous inscrivant, vous acceptez notre{' '}
              <a
                href="https://fr.bazarchic.com/contenu/politique-de-vie-privee/  "
                target="_blank"
              >
                politique de vie privée
              </a>
              .
            </OptinSubTest>
          </OptinMainText>
        </OptinResponsive>
        <OptinInputContainer>
          <OptinInput isRadius={true} label="Votre adresse e-mail" />
          {!!optinResponse && <OptinSuccessMessage emailOptin={optinEmail} />}
        </OptinInputContainer>
      </OptinTextContainer>
    </OptinContainer>
  );
};

export default OptinFooter;
