import * as React from "react";
const SvgIconeGuideTaille = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="icone-Guide-taille_svg__Calque_1"
    data-name="Calque 1"
    viewBox="0 0 27.03 57.75"
    width="1em"
    height="1em"
    {...props}
  >
    <defs>
      <style>
        {
          ".icone-Guide-taille_svg__cls-1{fill:none;stroke:#232929;stroke-linecap:round;stroke-linejoin:round;stroke-width:4px}"
        }
      </style>
    </defs>
    <rect
      width={23.03}
      height={53.75}
      x={2}
      y={2}
      className="icone-Guide-taille_svg__cls-1"
      rx={3.57}
    />
    <path
      d="M2 7.87h6.46M2 14.74h11.52M2 22.01h6.46M2 28.88h11.52M2 36.05h6.46M2 49.05h6.46M2 42.92h11.52"
      className="icone-Guide-taille_svg__cls-1"
    />
  </svg>
);
export default SvgIconeGuideTaille;
