import styled from 'styled-components';
import { theme } from 'Ui';
import { Bzc } from 'Ui/Icons/icons';
const ReassuranceContainer = styled.div`
  width: 94rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  @media screen and (max-width: 48rem) {
    flex-wrap: wrap;
    width: calc(96.5% - 2rem);

    /* margin: 0 1.8rem; */
  }
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    width: calc(96.5% - 2rem);
  }
`;
const ReassuranceText = styled.div`
  color: ${theme.light.colors.grayDark};
  text-align: center;
  font-family: 'Montserrat' !important;
  font-size: 1.4rem;
  font-style: normal;
  line-height: 22px; /* 157.143% */
`;

const ReassuranceLogo = styled.div`
  svg {
    width: 16rem;
    height: 1.7rem;
    flex-shrink: 0;
  }
`;

export const Reassurance = () => {
  return (
    <ReassuranceContainer>
      <ReassuranceLogo>
        <Bzc />
      </ReassuranceLogo>
      <ReassuranceText>
        Pionnier des ventes évènementielles premium en France depuis 2006,
        BazarChic propose chaque jour 14 nouvelles ventes sélectionnées avec
        soin. Filiale du Groupe Galeries Lafayette, nous sommes spécialistes des
        univers de la mode, de la maison, de l'épicerie & du vin.
      </ReassuranceText>
    </ReassuranceContainer>
  );
};
