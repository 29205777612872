import logoBzc from 'Assets/images/BzcLogo1.svg';
import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { PageContainer } from 'Ui';
import { theme } from 'Ui/theme';
import AccountMenu from './AccountMenu';
import AnonymousHeader from './AnonymousHeader';
import HeaderBasket from '../HeaderBasket';
import AuthStore from 'Store/AuthStore';
import BannerApp from 'Components/BannerApp';
import { isAndroid, isIOS } from 'react-device-detect';
import UiStore from 'Store/UiStore';
import ModalSearch from 'Components/modals/ModalSearch';
import { storageFactory } from 'Libs/storageFactory';
import BirthdayReminderModal from 'Components/modals/BirthReminderModal';
import {
  dateDiff,
  timestampAndCount,
  createCookieBirtdayReminder,
} from 'Utils/date';
import Cookie from 'js-cookie';
import WishListMenu from './WishListMenu';
import SearchInput from '../MobileHeader/SearchInput';
import { getCookie } from 'Utils/cookie';
import { getUserType } from 'Utils/memberType';
import { createAnonymousSession } from 'Utils/login';
import { useApolloClient } from '@apollo/client';

const LogoStyled = styled.img`
  width: 29rem;
  height: 100%;
`;

const TopBar = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 7.5rem;
  align-items: center !important;
  /* padding-bottom: 12px; */
`;

const FixBar = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  width: 100vw;
  background-color: #fff;
  z-index: 10001;
  .addShadow {
    box-shadow: 0px 1px 9px 0px rgba(0, 0, 0, 0.1);
  }
`;

const SearchInputDiv = styled.div`
  display: flex;
  border-bottom: 1px solid #888;
  box-sizing: border-box;
  align-self: end;
  margin-right: 1.5rem;
  width: 16.8rem;
  &:hover {
    cursor: text;
  }
`;

export default () => {
  const apolloClient = useApolloClient();
  const setAuth = AuthStore.useStoreActions((actions) => actions.setAuth);
  const initAuth = AuthStore.useStoreActions((actions) => actions.initAuth);
  const member = AuthStore.useStoreState((state) => state.member);
  const sessionMemberType = AuthStore.useStoreState(
    (state) => state.sessionMemberType
  );
  const birthdayReminder = Cookie.get('birthdayReminder');
  const marketingStore = UiStore.useStoreState(
    (state) => state.cookieMarketing
  );

  const getBirthdayReminder = () => {
    if (member) {
      if (!member.birthDate) {
        if (!!birthdayReminder) {
          const [timestamp] = timestampAndCount(birthdayReminder);
          if (dateDiff(timestamp) >= 10) {
            BirthdayReminderModal({});
          }
        } else {
          createCookieBirtdayReminder();
        }
      }
    }
  };

  const loggedIn = AuthStore.useStoreState((state) => state.isLoggedIn);

  const cookieMobileAppPopup = UiStore.useStoreState(
    (state) => state.cookieMobileApp
  );
  const localStore = storageFactory(() => localStorage);

  const isLoggedIn =
    loggedIn || !!localStore.getItem('isLoggedIn') ? true : false;

  // if (!!localStore.getItem('isLoggedIn') === false) {
  //   return <AnonymousHeader />;
  // } else if (!!localStore.getItem('isLoggedIn') === false && !loggedIn) {
  //   return <AnonymousHeader />;
  // }

  if (!Cookie.get('ssession')) {
    createAnonymousSession(apolloClient)
      .then((data) => {
        if (data) {
          const payload = {
            jwt: data.createAnonymousSession.jwt,
            sessionId: data.createAnonymousSession.sessionId,
            member: data.createAnonymousSession.member,
          };
          setAuth(payload);
          initAuth();
        }
      })
      .catch((e) => console.log(e));
  }

  if (loggedIn) {
    if (Cookie.get('RgpdBanner') && marketingStore) {
      getBirthdayReminder();
    }
  }

  useEffect(() => {
    const scrollShadow: any = document.getElementById('addShadowJs');
    const detectScroll = (event: any) => {
      const getScroll = event.currentTarget.scrollY;
      if (getScroll > 40) {
        scrollShadow.style.boxShadow = '0px 1px 9px 0px rgba(0, 0, 0, 0.1)';
      } else {
        scrollShadow.style.boxShadow = 'none';
      }
    };

    window.addEventListener('scroll', detectScroll);
    return () => {
      window.removeEventListener('scroll', detectScroll);
    };
  }, []);

  const searchString = UiStore.useStoreState(
    (state) => state.searchStringHeader
  );

  return (
    <>
      {/* {!loggedIn && <AnonymousHeader />} */}

      {/* {isLoggedIn && ( */}
      <>
        {cookieMobileAppPopup && (isAndroid || isIOS) && (
          <BannerApp android={isAndroid} />
        )}
        <FixBar id="addShadowJs">
          <PageContainer>
            <TopBar>
              <a
                style={{
                  width: '25%',
                  display: 'flex',
                  justifyContent: 'space-around',
                  alignItems: 'flex-end',
                }}
                href="/"
              >
                <LogoStyled
                  width={230}
                  height={25}
                  src={logoBzc}
                  alt="BazarChic"
                />
              </a>
              <div
                style={{ width: '50%', margin: '0 6.8rem' }}
                data-tut="headerTuto_position"
                data-test="searchHeader"
                onClick={async () =>
                  (await ModalSearch) && ModalSearch({ searchString })
                }
              >
                <SearchInput placeholder="Rechercher une vente, un produit ..." />
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  width: '15%',
                }}
              >
                <AccountMenu />
                <WishListMenu />
                <HeaderBasket />
              </div>
            </TopBar>
          </PageContainer>
        </FixBar>
      </>
      {/* )} */}
    </>
  );
};
