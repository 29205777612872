import * as React from "react";
const SvgTo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 13 8"
    {...props}
  >
    <path
      fill="url(#to_svg__a)"
      stroke="url(#to_svg__b)"
      strokeMiterlimit={10}
      strokeWidth={0.3}
      d="M8.253 4.5c.136 0 .247-.224.247-.5s-.11-.5-.247-.5H1.747c-.136 0-.247.224-.247.5s.11.5.247.5z"
    />
    <path
      fill="url(#to_svg__c)"
      stroke="url(#to_svg__d)"
      strokeMiterlimit={10}
      strokeWidth={0.3}
      d="m7.59 6.799 2.602-2.748c.103-.102.09-.268 0-.37L7.63 1.228S7.385.934 7.68.64c.282-.281.577-.051.577-.051l3.166 3.04a.26.26 0 0 1 0 .371L8.244 7.386s-.32.256-.59 0c-.294-.293-.076-.587-.076-.587z"
    />
    <defs>
      <linearGradient
        id="to_svg__a"
        x1={8.5}
        x2={1.5}
        y1={4}
        y2={4}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6C4DFF" />
        <stop offset={1} stopColor="#8268FF" />
      </linearGradient>
      <linearGradient
        id="to_svg__b"
        x1={8.5}
        x2={1.5}
        y1={4}
        y2={4}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6C4DFF" />
        <stop offset={1} stopColor="#8268FF" />
      </linearGradient>
      <linearGradient
        id="to_svg__c"
        x1={11.5}
        x2={7.5}
        y1={4}
        y2={4}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6C4DFF" />
        <stop offset={1} stopColor="#8268FF" />
      </linearGradient>
      <linearGradient
        id="to_svg__d"
        x1={11.5}
        x2={7.5}
        y1={4}
        y2={4}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6C4DFF" />
        <stop offset={1} stopColor="#8268FF" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgTo;
