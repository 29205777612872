import React from 'react';
import { createModal } from 'react-modal-promise';
import { ModalBanner, Button, theme } from 'Ui';
import styled from 'styled-components/macro';
import RgpdModal from 'Components/modals/RgpdModal';
import Cookie from 'js-cookie';
import UiStore from 'Store/UiStore';
import { registerUserRgpdBehaviou } from 'Libs/trackingInternal';
import { useApolloClient } from '@apollo/client';
import { DeviceEnum } from '__generated__/graphql-global-types';
import { isMobile } from 'react-device-detect';
import { setCookie } from 'Utils/cookie';
import { getPrivacyChoice } from 'Libs/GA4/common';

type ModalProps = {
  open: boolean;
  close: (shouldGoOn: boolean) => void;
  title?: string;
  external?: boolean;
};

const RgpdBannerBodyModal = styled.div`
  font-family: 'Montserrat';
  line-height: 2.2rem;
  font-size: 1.6rem;
  flex: 1;
  // @media screen and (min-width: 48rem) {
  //   flex-direction: row;
  // }
  a {
    text-decoration: underline !important;
    &:hover {
      color: ${theme.light.colors.purple};
    }
  }
  p {
    margin-bottom: 1rem;
    color: #333;
  }
  span {
    // display: block;
    a {
      margin-left: 0.5rem;
      color: #000;
    }
  }
`;

const Title = styled.p`
  font-weight: 900;
  margin-bottom: 1rem;
  font-family: 'Montserrat';
`;

const GroupButton = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  span {
    width: 100%;
  }
  @media screen and (min-width: 768px) {
    flex-direction: row;
    justify-content: flex-end;
    span {
      width: inherit;
    }
  }
`;

const ButtonAccepted = styled.div`
  display: flex;
  margin-bottom: 2rem;
  order: 1;
  @media screen and (min-width: 768px) {
    margin: 0rem 0rem 0rem 2rem;
    order: unset;
  }
`;
const ButtonRefused = styled.div`
  display: flex;
  order: 2;
  @media screen and (min-width: 768px) {
    order: unset;
  }
`;

const Para = styled.p`
  display: inline;
`;

const ParaLink = styled.a`
  color: #000;
  text-decoration: underline !important;
  &:hover {
    color: ${theme.light.colors.purple};
  }
`;

const RgpdBannerModal = ({ open, close, title, external }: ModalProps) => {
  const setDisclaimer = UiStore.useStoreActions(
    (action) => action.setDisclaimer
  );
  const disclaimer = () => {
    if (!!Cookie.get('majeur') === false) {
      setDisclaimer(true);
    }
  };

  const setAcceptedAndRefusedCookieReload = UiStore.useStoreActions(
    (action) => action.setAcceptedAndRefusedCookieReload
  );

  const setGtmConsentMode = UiStore.useStoreActions(
    (action) => action.setGtmConsentMode
  );

  const deviceType = isMobile ? DeviceEnum.MOBILE : DeviceEnum.DESKTOP;
  const apolloClient = useApolloClient();
  const consultedSales = UiStore.useStoreActions(
    (action) => action.setConsultedSales
  );

  const setCookieMarketingStore = UiStore.useStoreActions(
    (action) => action.setCookieMarketing
  );

  // Object to remove all the other cookie without name of the cookie
  const configCookie = {
    path: '/',
    domain: '.bazarchic.com',
    expires: 180,
    secure: true,
  };

  // Object for RgpdBanner
  const objectCookie = {
    path: '/',
    domain: '.bazarchic.com',
    expires: 180,
    secure: true,
    value: '1',
    name: 'RgpdBanner',
  };

  const closeModal = () => {
    // Cookie.set('RgpdBanner', '1', objectCookie);
    setCookie(objectCookie);
    close(false);
  };
  const rgpdSetting = () => {
    close(false);
    const disabledOutsideClickHandler = false;
    RgpdModal({ disabledOutsideClickHandler, external });
  };

  const refusedCookies = () => {
    disclaimer();
    Cookie.remove('_marketing', configCookie);
    Cookie.remove('_performance', configCookie);
    Cookie.remove('sPagesHistoryV2', configCookie);
    Cookie.remove('sProductsHistoryV2', configCookie);
    Cookie.remove('birthdayReminder', configCookie);
    Cookie.remove('site', configCookie);
    consultedSales([]);
    setAcceptedAndRefusedCookieReload(true);

    const btnPersonnalize = false;
    const sdkPerf = false;
    const sdkMarket = false;
    const btnAccept = false;
    const btnDeny = true;
    const linkPvp = !!localStorage.getItem('linkPvp');
    registerUserRgpdBehaviou(
      apolloClient,
      sdkPerf,
      sdkMarket,
      btnPersonnalize,
      btnAccept,
      btnDeny,
      linkPvp,
      deviceType
    );
    if (!!localStorage.getItem('linkPvp')) {
      localStorage.removeItem('linkPvp');
    }
    closeModal();
    if (external) {
      setTimeout(function () {
        window.location.reload();
      }, 1000);
    }
  };

  const AcceptedCookies = () => {
    disclaimer();
    Cookie.set('_performance', '1', configCookie);
    Cookie.set('_marketing', '1', configCookie);
    setCookieMarketingStore(true);
    setAcceptedAndRefusedCookieReload(true);

    const btnPersonnalize = false;
    const sdkPerf = true;
    const sdkMarket = true;
    const btnAccept = true;
    const btnDeny = false;
    const linkPvp = !!localStorage.getItem('linkPvp');
    registerUserRgpdBehaviou(
      apolloClient,
      sdkPerf,
      sdkMarket,
      btnPersonnalize,
      btnAccept,
      btnDeny,
      linkPvp,
      deviceType
    );

    if (!!localStorage.getItem('linkPvp')) {
      localStorage.removeItem('linkPvp');
    }
    closeModal();

    if (external) {
      setTimeout(function () {
        window.location.reload();
      }, 1000);
    }
  };

  return (
    <ModalBanner
      isOpen={open}
      onClose={() => close(false)}
      disabledOutsideClickHandler={false}
      size="full"
    >
      <RgpdBannerBodyModal>
        <Title>Gérer mes cookies</Title>
        <Para>
          BazarChic utilise des cookies dans le but de vous proposer une
          navigation facile et adaptée.
        </Para>
        <Para>
          {'  '}Ces cookies nous permettent également de personnaliser votre
          expérience et d&apos;analyser la performance de nos outils.
        </Para>
        <Para>{'  '}Votre choix sera conservé pendant 6 mois.</Para>
        <Para>
          {'  '}Vous pouvez changer d’avis à tout moment en cliquant sur:{'  '}
        </Para>
        <ParaLink
          className="pvp"
          href="/home/confidentialite/page/protection-vie-privee/?webview=1"
          target="_blank"
          onClick={() => localStorage.setItem('linkPvp', 'true')}
        >
          Politique de vie privée
        </ParaLink>
        <Para>
          {'  '}|{'  '}
        </Para>
        <ParaLink
          className="settingCookies"
          href="# "
          onClick={(e) => {
            e.preventDefault();
            rgpdSetting();
          }}
        >
          Personnaliser mes choix
        </ParaLink>

        <GroupButton>
          <ButtonRefused>
            <span className="btnRefused">
              <Button
                kind="purple"
                hollow
                onClick={() => {
                  refusedCookies();
                  setGtmConsentMode('update');
                  sessionStorage.setItem('consent', 'oui');
                  setTimeout(getPrivacyChoice, 3000);
                }}
                block
              >
                Refuser
              </Button>
            </span>
          </ButtonRefused>
          <ButtonAccepted>
            <span className="btnAccepted">
              <Button
                dataTest="AcceptedCookies"
                kind="primary"
                onClick={() => {
                  AcceptedCookies();
                  setGtmConsentMode('update');
                  sessionStorage.setItem('consent', 'oui');
                  setTimeout(getPrivacyChoice, 3000);
                }}
                block
              >
                Accepter
              </Button>
            </span>
          </ButtonAccepted>
        </GroupButton>
      </RgpdBannerBodyModal>
    </ModalBanner>
  );
};

export default createModal(RgpdBannerModal);
