import gql from "graphql-tag";
import { GtmInfo } from "Fragments/GtmInfo";

export const SET_AUTOLOG = gql`
  mutation setAutolog($token: String!, $memberId: ID!) {
    autolog(token: $token, memberId: $memberId) {
      jwt
      sessionId
      sessionMemberType
      member {
        id
        isVip
        email
        firstName
        lastName
        phoneNumber
        ...GtmInfo
      }
    }
  }
  ${GtmInfo}
`;
