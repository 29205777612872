import { GtmInfo } from "Fragments/GtmInfo";
import gql from "graphql-tag";

export const REGISTER_SUSPECT = gql`
  mutation optinMember($suspect: SuspectRegistrationInput!, $subscriptionURI: String!, $device: DeviceInput, $environment: EnvironmentInput!) {
    registerSuspect(suspect: $suspect, subscriptionURI: $subscriptionURI, environment: $environment, device: $device) {
      jwt
      isConnected
      sessionId
      member {
        id
        isVip
        email
        firstName
        lastName
        phoneNumber
        ...GtmInfo
      }
    }
  }
  ${GtmInfo}
`;