import * as React from "react";
const SvgArrowBack = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 12 12"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 5.25H2.873l4.192-4.192L6 0 0 6l6 6 1.058-1.058L2.873 6.75H12z"
    />
  </svg>
);
export default SvgArrowBack;
