import { SearchSales_sales_edges_node } from 'Components/Header/SearchHeader/__generated__/SearchSales';
import UiStore from 'Store/UiStore';
import { Link, theme } from 'Ui';
import { createUrlParams } from 'Utils/imageParams';
import Highlighter from 'react-highlight-words';
import styled from 'styled-components/macro';

type SearchCardProps = {
  sale: SearchSales_sales_edges_node;
};

const SearchCardContainer = styled.div`
  display: flex;
  padding: 1px 0px;
  align-items: center;
  gap: 16px;
`;

const SearchCardBrand = styled.div`
  color: ${theme.v2.grayScale[400]};
  font-family: 'Montserrat';
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const SearchCardSaleName = styled.div`
  color: ${theme.v2.black};
  font-family: 'Inter' !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const ImageContainer = styled.div`
  width: 103px;
  height: 50px;
  flex-shrink: 0;
  img {
    height: auto;
    width: 100%;
    border-radius: 4px;
  }
`;

export const SearchCard = ({ sale }: SearchCardProps) => {
  const searchString = UiStore.useStoreState(
    (state) => state.searchStringHeader
  );
  const imageSize = {
    sale: {
      width: 698,
      height: 390,
    },
    saleRetina: {
      width: 1396,
      height: 780,
    },
  };
  const saleImage = `${sale?.coverLogoUrl}?params=${createUrlParams(
    imageSize.sale
  )}`;
  const saleImageRetina = `${sale?.coverLogoUrl}?params=${createUrlParams(
    imageSize.saleRetina
  )}`;
  return (
    <Link to={`/ventes/cata/id/${sale.id}/`}>
      <SearchCardContainer>
        <ImageContainer>
          <img
            src={saleImage!!}
            data-srcSet={`${saleImageRetina!!} x2`}
            alt={sale.name}
          />
        </ImageContainer>
        {/* <SearchCardSaleName className="fontMontserratSemiBold">
          {sale.name}
        </SearchCardSaleName> */}
        <Highlighter
          highlightStyle={{
            fontFamily: 'InterBold',
            fontWeight: 700,
            color: 'inherit',
            backgroundColor: 'transparent',
          }}
          className="fontMontserratMedium"
          searchWords={searchString.split(' ')}
          autoEscape={true}
          textToHighlight={sale?.name || ''}
        />
      </SearchCardContainer>
    </Link>
  );
};
