import React, { useEffect } from 'react';
import { GlobalStyle, AppStyled } from '../GlobalStyle';
import RgpdBannerModal from 'Components/modals/RgdpBannerModal';
import Cookie from 'js-cookie';
import ModalContainer from 'react-modal-promise';
import { BrowserRouter } from 'react-router-dom';
import AuthStore from 'Store/AuthStore';
import UiStore from 'Store/UiStore';
import { ApolloProvider } from '@apollo/react-hooks';
import InitiStore from 'Store/InitiStore';
import { init } from 'Libs/apollo';

export default () => {
  const client = init();
  return (
    <AppStyled>
      <BrowserRouter>
        <AuthStore.Provider initialData={client}>
          <UiStore.Provider>
            <ApolloProvider client={client}>
              <InitiStore />
              <>
                <GlobalStyle />
                <BannerRgpdExternal />
              </>
            </ApolloProvider>
          </UiStore.Provider>
        </AuthStore.Provider>
      </BrowserRouter>
    </AppStyled>
  );
};

export const BannerRgpdExternal = () => {
  const external = true;
  useEffect(() => {
    if (!Cookie.get('RgpdBanner')) {
      RgpdBannerModal({ external });
    }
  }, []);
  return <ModalContainer />;
};
