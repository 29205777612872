import React from 'react';
import styled, { css } from 'styled-components/macro';
import { theme } from 'Ui';

const Menu = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 1.4rem;
  font-weight: 400;
  font-family: 'MontserratMedium';
  color: ${theme.light.colors.grayUltraDark};
  margin: 6rem auto;
  li:hover {
    color: ${theme.light.colors.purple};
  }
`;

export default () => {
  const menuItems = [
    {
      title: 'Espace presse',
      href: '/home/espace-presse/television/',
    },
    {
      title: 'Principe de la vente privée',
      href: 'https://fr.bazarchic.com/contenu/politique-de-vie-privee/  ',
    },
    {
      title: 'CGV',
      href: 'https://fr.bazarchic.com/contenu/conditions-generales-de-ventes/',
    },
    {
      title: 'Aperçu des ventes privées en cours',
      href: '/ventes-privees-encours/',
    },
    {
      title: `Offres d'emplois`,
      href: 'https://carrieres.groupegalerieslafayette.com/bazarchic/',
    },
  ];

  return (
    <>
      <Menu>
        {menuItems.map((menu, i) => (
          <li key={i}>
            <a href={menu.href}>{menu.title}</a>
          </li>
        ))}
      </Menu>
    </>
  );
};
