import * as React from "react";
const SvgIconeLivraison = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="icone-livraison_svg__Calque_1"
    data-name="Calque 1"
    viewBox="0 0 60.67 38"
    width="1em"
    height="1em"
    {...props}
  >
    <defs>
      <style>
        {
          ".icone-livraison_svg__cls-1,.icone-livraison_svg__cls-2{fill:none;stroke:#232929;stroke-miterlimit:10;stroke-width:4px}.icone-livraison_svg__cls-1{stroke-linecap:round}"
        }
      </style>
    </defs>
    <path
      d="M5.49 15.51h13.95M2 10.28h13.95M2 20.75h13.95"
      className="icone-livraison_svg__cls-1"
    />
    <path
      d="M7.21 23.79v4.8a2.18 2.18 0 0 0 2.18 2.18h6.54M7.21 6.79V4.15a2.17 2.17 0 0 1 2.18-2.18h27.92a2.18 2.18 0 0 1 2.18 2.18v24.44a2.19 2.19 0 0 1-2.18 2.18h-10.9"
      className="icone-livraison_svg__cls-2"
    />
    <circle
      cx={21.18}
      cy={30.77}
      r={5.23}
      className="icone-livraison_svg__cls-2"
    />
    <circle
      cx={49.08}
      cy={30.77}
      r={5.23}
      className="icone-livraison_svg__cls-2"
    />
    <path
      d="M36.88 30.77h6.97M54.31 30.77h2.18a2.19 2.19 0 0 0 2.18-2.18v-8.31a2.22 2.22 0 0 0-.57-1.47l-8.37-9.13a2.2 2.2 0 0 0-1.61-.71h-8.63"
      className="icone-livraison_svg__cls-2"
    />
  </svg>
);
export default SvgIconeLivraison;
