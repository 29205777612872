import * as React from "react";
const SvgFaqs = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="#2F2F2F"
      fillRule="evenodd"
      d="M2 12.41C2 6.66 6.66 2 12.41 2c5.748 0 10.408 4.66 10.408 10.41 0 5.748-4.66 10.408-10.409 10.408S2 18.158 2 12.41M12.41 1C6.107 1 1 6.108 1 12.41c0 6.3 5.108 11.408 11.41 11.408 6.3 0 11.408-5.108 11.408-11.409S18.71 1 12.41 1m.685 10.89a.687.687 0 0 0-1.372 0l-.222 3.552a.91.91 0 1 0 1.817 0zm.033-3.176a.93.93 0 0 0-.719-.305q-.447 0-.719.305a1.03 1.03 0 0 0-.28.723q0 .43.28.712.27.294.72.294a.95.95 0 0 0 .718-.294.97.97 0 0 0 .281-.712q0-.418-.281-.723"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgFaqs;
