import React from 'react';
import Helmet from 'react-helmet';

// @ts-ignore
import icon from 'Assets/images/favicon.ico';
import { theme } from 'Ui';

type HelmetProps = {
  title?: string;
  description?: string;
  noIndex?: boolean;
  canonical?: string;
  isTablet?: boolean;
};

export default ({
  title = '',
  description = '',
  noIndex = false,
  canonical = '',
  isTablet = false,
}: HelmetProps) => {
  const colorHead = theme.light.colors.purple;
  return (
    <Helmet>
      <meta charSet="UTF-8" />
      <title>{title}</title>

      <meta name="description" content={description} />
      <meta name="theme-color" content={colorHead} />
      <meta name="msapplication-navbutton-color" content={colorHead} />
      <meta name="apple-mobile-web-app-status-bar-style" content={colorHead} />
      <meta name="keywords" content="" />
      {/* <meta name="apple-itunes-app" content="app-id=575920978" /> */}
      {isTablet && (
        <meta name="viewport" content="width=1024, user-scalable=no" />
      )}
      <meta name="msapplication-config" content="none" />
      <link rel="shortcut icon" href={icon} />
      {noIndex ? (
        <meta name="robots" content="noindex"></meta>
      ) : (
        <meta name="robots" content="index"></meta>
      )}
      {!!canonical && <link rel="canonical" href={canonical} />}
    </Helmet>
  );
};
