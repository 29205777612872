import * as React from "react";
const SvgError = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 19 20"
    {...props}
  >
    <circle cx={9.5} cy={10.455} r={9.5} fill="#C64235" fillOpacity={0.5} />
    <path
      fill="#C64235"
      fillRule="evenodd"
      d="m9.38 9.793-3.017-3.19-.726.686 3.001 3.174L5.66 13.22l.68.734 2.973-2.75 3.046 2.817.679-.734-2.978-2.755 1.327-1.404 1.674-1.77-.726-.688L10.66 8.44z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgError;
