import { action } from 'easy-peasy';
export default {
  brand: {
    violet: '#6C4DFF',
    orange: '#FE7C50',
    Beige: '#E4D7C6',
    Red: '#C64235',
    Yellow: '#fff45C',
    lightViolet: '#6C4DFF',
  },
  white: '#fff',
  black: '#000',
  grayScale: {
    50: 'rgba(222, 220, 220, 0.50)',
    75: '#F3F3F3',
    100: '#DEDCDC',
    200: '#C8C8C8',
    300: '#A5A5A5',
    400: '#4A4A4A',
    500: '#2F2F2F',
  },
  gradient: {
    violet: 'linear-gradient(180deg, #6C4DFF 0%, #8268FF 100%)',
    lightViolet: 'linear-gradient(180deg, #6C4DFF 0%, #8268FF 100%)',
  },
  bg: {
    default: {
      white: '#fff',
      black: '#000',
    },
    cards: {
      gray: '#F5F4F4',
      lightViolet: 'linear-gradient(90deg, #EDE9FF 0.37%, #F7F5FF 99.98%)',
    },
  },
  icon: {
    default: '#fff',
    active: 'linear-gradient(180deg, #6C4DFF, #8268FF 100%)',
  },
  border: {
    default: '#DEDCDC',
    active: 'linear-gradient(180deg, #6C4DFF, #8268FF 100%)',
  },
  button: {
    default: '#DEDCDC',
    active: 'linear-gradient(180deg, #6C4DFF, #8268FF 100%)',
  },
  social: {
    facebook: {
      blue: '#1877F2',
    },
  },
  text: {
    black: '#000',
    white: '#fff',
    darkGray: '#2F2F2F',
    gray: '#4A4A4A',
    lightGray: '#848484',
  },
};
