import AuthStore from './AuthStore';
import UiStore from './UiStore';
import ConfigurationStore from './ConfigurationStore';

//@ts-ignore
export default () => {
  AuthStore.useStoreActions((actions) => actions.initAuth)();
  UiStore.useStoreActions((actions) => actions.initUi)();
  ConfigurationStore.useStoreActions((actions) => actions.initConfiguration)();
  return null;
};
