import * as React from "react";
const SvgIconeFinVentes = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="icone-Fin-ventes_svg__Calque_1"
    data-name="Calque 1"
    viewBox="0 0 48.12 52.8"
    width="1em"
    height="1em"
    {...props}
  >
    <defs>
      <style>
        {
          ".icone-Fin-ventes_svg__cls-1{fill:#232929;stroke:#232929;stroke-miterlimit:10;stroke-width:2px}"
        }
      </style>
    </defs>
    <path
      d="M24.06 51.8a23.07 23.07 0 1 1 23.06-23.06A23.09 23.09 0 0 1 24.06 51.8Zm0-43.73a20.67 20.67 0 1 0 20.67 20.67A20.69 20.69 0 0 0 24.06 8.07Z"
      className="icone-Fin-ventes_svg__cls-1"
    />
    <path
      d="M36.61 12.03a1.2 1.2 0 0 1-.68-.21 1.19 1.19 0 0 1-.31-1.66l1.53-2.26a1.2 1.2 0 0 1 1.67-.35 1.19 1.19 0 0 1 .31 1.66l-1.53 2.34a1.2 1.2 0 0 1-.99.48ZM11.15 12.03a1.2 1.2 0 0 1-1-.53L8.33 8.82a1.206 1.206 0 0 1 2-1.35l1.83 2.69a1.2 1.2 0 0 1-1 1.87Z"
      className="icone-Fin-ventes_svg__cls-1"
    />
    <path
      d="M7.09 10.09a1.2 1.2 0 0 1-.7-2.17l3.6-2.6a1.2 1.2 0 0 1 1.4 1.95l-3.6 2.6a1.2 1.2 0 0 1-.7.22ZM40.75 10.03a1.13 1.13 0 0 1-.68-.22l-3.64-2.55a1.212 1.212 0 1 1 1.37-2l3.64 2.59a1.2 1.2 0 0 1-.69 2.18ZM24.06 8.07a1.2 1.2 0 0 1-1.2-1.2V2.99a1.2 1.2 0 1 1 2.4 0v3.88a1.2 1.2 0 0 1-1.2 1.2Z"
      className="icone-Fin-ventes_svg__cls-1"
    />
    <path
      d="M28.54 3.4h-9a1.2 1.2 0 1 1 0-2.4h9a1.2 1.2 0 0 1 0 2.4ZM22.76 41.12a1.9 1.9 0 0 1-.59-1.38 1.85 1.85 0 0 1 .57-1.38 2 2 0 0 1 1.44-.55 2 2 0 0 1 1.4.55 1.88 1.88 0 0 1 .56 1.38 1.94 1.94 0 0 1-.56 1.38 1.9 1.9 0 0 1-1.4.59 1.93 1.93 0 0 1-1.42-.59Zm-.17-26.07h3.17l-.56 18.5h-2.08Z"
      className="icone-Fin-ventes_svg__cls-1"
    />
  </svg>
);
export default SvgIconeFinVentes;
