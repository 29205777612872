import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
// @ts-ignore
import UiStore from 'Store/UiStore';
import {
  Button,
  Checkbox,
  DateInput,
  FormInfo,
  Input,
  InputGroup,
  RadioGroup,
} from 'Ui';
import InformationsIcone from 'Ui/Icons/icons/InformationsIcone';
import { theme } from 'Ui/theme';
import { CivilityEnum } from '__generated__/graphql-global-types';
import ReactFacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import useForm from 'react-hook-form';
import styled from 'styled-components';
import InfoBox from '../PasswordHelper';
import DataProtectionModal from './DataProtectionModal';

const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export type FormData = {
  firstName: string;
  lastName: string;
  civility: CivilityEnum;
  password: string;
  CGV: boolean;
  email: string;
  birthDate: string;
};

type RegistrationFormProps = {
  onRegister?: (form: FormData) => void;
  onFacebookRegister?: (data: any) => void;
  preFill?: { email?: string; firstName?: string; lastName?: string };
  loading?: boolean;
};

const StyledIconInfo = styled(InformationsIcone)`
  font-size: 1.3rem;
  cursor: pointer;
  margin-left: 0.4rem;
  margin-bottom: -2px;
  /* position: relative; */
  bottom: 1.6rem;
  &:hover,
  &:active {
    fill: ${theme.light.colors.purple};
  }
`;

const Separator = styled.div`
  width: 100%;
  height: 1px;
  /* border: 1px solid #A5A5A5; */
  margin: 1rem 0rem;
`;

const RadioGroupFlex = styled.div`
  .inline-radio-button {
    justify-content: flex-start;
    /* font-family: 'MontserratSemiBold' !important; */
    /* width: 100%; */
    margin: auto;
    display: flex;
    /* flex: 1 100%; */
    margin-bottom: none;
    .radioGroup {
      display: flex;
      align-items: center;
      /* font-family: 'MontserratSemiBold' !important; */
    }
    .radioGroup:nth-child(1n) {
      margin-bottom: 0px;
    }

    .radioGroup:last-child {
      margin-bottom: 0px;
    }
  }
`;

export default ({
  onRegister,
  onFacebookRegister,
  preFill,
  loading = false,
}: RegistrationFormProps) => {
  const {
    register,
    setValue,
    handleSubmit,
    errors,
    getValues,
    setError,
    formState,
    triggerValidation,
    watch,
  } = useForm<FormData>({
    mode: 'onChange',
  });

  useEffect(() => {
    if (formState.submitCount > 0) {
      window.scrollTo(0, 0);
    }
  }, [formState]);

  const cookieMarketing = UiStore.useStoreState(
    (state) => state.cookieMarketing
  );

  const [showDataProtectionModal, setShowDataProtectionModal] = useState(false);
  const [passwordInit, setpasswordInit] = useState(false);
  const [gender, setGender] = useState('');

  const onSubmit = handleSubmit((formData, e) => {
    e.preventDefault();
    onRegister && onRegister(formData);
  });

  const values = getValues();

  useEffect(() => {
    /** Custom fields */
    register({ name: 'civility' }, { required: 'Champ obligatoire' });
    register({ name: 'birthDate' }, { required: 'Champ obligatoire' });
    /** Enter pre-filling values on proper fields */
    if (preFill) {
      // @ts-ignore
      Object.keys(preFill).map((key) => setValue(key, preFill[key]));
    }
  }, []);

  const genderCivility = (value: CivilityEnum) => {
    setValue('civility', value);
    triggerValidation({ name: 'civility' });
  };

  const validating = (field: (typeof formState.touched)[0]) => {
    if (!field) return 'default';
    const typesErors = errors as { [index: string]: any };
    return typesErors[field]
      ? 'error'
      : formState.touched.includes(field)
      ? 'success'
      : 'default';
  };

  const validatingCivility = (field: (typeof formState.touched)[0]) => {
    if (!field) return 'default';
    const typesErors = errors as { [index: string]: any };
    return typesErors[field]
      ? 'error'
      : formState.touched.includes(field)
      ? 'default'
      : 'default';
  };
  const styles = { marginBottom: '1rem' };
  const options = [
    {
      value: CivilityEnum.MME,
      label: 'Madame',
    },
    {
      value: CivilityEnum.MR,
      label: 'Monsieur',
    },
  ];

  return (
    <form onSubmit={onSubmit} noValidate>
      <RadioGroupFlex>
        <RadioGroup
          options={options}
          top={2}
          left={1.9}
          marginLeft={0.2}
          show={false}
          value={gender}
          kind={
            gender === 'MME' || 'MR' ? 'purple' : validatingCivility('civility')
          }
          onChange={(gender) => {
            setGender(gender);
            genderCivility(gender);
          }}
        />
      </RadioGroupFlex>
      <FormInfo kind={'error'} style={styles}>
        {errors.civility && errors.civility.message}
      </FormInfo>
      <InputGroup block gutter={-1} even>
        <div style={{ marginRight: '10px' }}>
          <Input
            type="text"
            className="fontMontserrat v4Input"
            kind={validating('firstName')}
            name="firstName"
            initialValue={preFill && preFill.firstName}
            placeholder="Prénom"
            block
            ref={register({ required: 'Champ obligatoire' })}
            helperText={errors.firstName && errors.firstName.message}
            style={styles}
          />
        </div>
        <div>
          <Input
            type="text"
            block
            name="lastName"
            className="fontMontserrat v4Input"
            initialValue={preFill && preFill.lastName}
            kind={validating('lastName')}
            placeholder="Nom"
            helperText={errors.lastName && errors.lastName.message}
            ref={register({ required: 'Champ obligatoire' })}
            style={styles}
          />
        </div>
      </InputGroup>
      <Input
        block
        type="email"
        name="email"
        className="fontMontserrat v4Input"
        placeholder="Email"
        kind={validating('email')}
        ref={register({
          required: 'Champ obligatoire',
          pattern: {
            value: emailRegex,
            message: "L'adresse e-mail n'est pas valide",
          },
        })}
        helperText={errors.email && errors.email.message}
        style={styles}
        autoComplete="off"
      />
      <Input
        block
        type="password"
        name="password"
        className="fontMontserrat v4Input"
        placeholder="Mot de passe"
        helperText={errors.password && errors.password.message}
        kind={validating('password')}
        style={styles}
        autoComplete="off"
        onFocus={() => {
          setError('password', 'notMatch', '');
          setpasswordInit(true);
        }}
        ref={register({
          required: 'Champ obligatoire',
          pattern: {
            value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,50})/g,
            message: 'Veuillez saisir un format de mot de passe valide',
          },
        })}
      />
      {passwordInit && (
        <InfoBox
          kind={validating('password')}
          password={values.password}
        ></InfoBox>
      )}
      <DateInput
        block
        kind={validating('birthDate')}
        className="fontMontserrat v4Input"
        placeholder="Date d'anniversaire"
        helperText={errors.birthDate && errors.birthDate.message}
        style={styles}
        datePickerProps={{
          initialView: 'year',
          initialDate: getValues().birthDate || dayjs().add(-16, 'y').toDate(),
          minDate: dayjs().add(-90, 'y').toDate(),
          maxDate: dayjs().add(-16, 'y').toDate(),
        }}
        value={watch('birthDate')}
        onChange={(e) =>
          setValue('birthDate', dayjs(e).format('YYYY-MM-DD'), true)
        }
      />
      <div style={{ display: 'flex' }} className="fontMontserrat">
        <Checkbox
          ref={register({
            required:
              'Vous devez accepter les Conditions Générales de Vente pour vous inscrire',
          })}
          name="CGV"
          align
          padding
          label={
            <>
              <div
                style={{
                  fontSize: '1.3rem',
                  lineHeight: '1.5',
                  fontFamily: 'Montserrat',
                }}
              >
                J'accepte les{' '}
                <a
                  href="https://fr.bazarchic.com/contenu/conditions-generales-de-ventes/"
                  target="_blank"
                >
                  CGV et la politique de vie privée
                </a>
                , de recevoir les invitations quotidiennes aux ventes ainsi que
                les offres spéciales
                <StyledIconInfo
                  className=""
                  onClick={() => setShowDataProtectionModal(true)}
                />
              </div>
            </>
          }
        />
      </div>
      <FormInfo kind="error">{errors.CGV && errors.CGV.message} </FormInfo>
      {/* <Checkbox
        ref={register}
        name="partnerOffers"
        label="Je souhaite recevoir les offres partenaires (facultatif)"
        style={styles}
      /> */}
      <InputGroup block>
        <Button
          style={{ marginTop: '1rem' }}
          type="submit"
          kind="primary"
          className="fontMontserratSemiBold"
          block
          loading={loading}
        >
          Acceder aux ventes
        </Button>
      </InputGroup>
      {cookieMarketing && (
        <>
          <Separator />
          <ReactFacebookLogin
            appId={process.env.REACT_APP_FACEBOOK_ID || ''}
            callback={onFacebookRegister}
            isMobile={false}
            render={(props: any) => (
              <Button
                style={{ marginBottom: '1.5rem' }}
                kind="primary"
                hollow={true}
                className="fontMontserratSemiBold"
                color={`${theme.light.colors.purple}`}
                // icon={<IconFacebook />}
                onClick={props.onClick}
                loading={loading}
                block
              >
                S'INSCRIRE AVEC FACEBOOK
              </Button>
            )}
          />
        </>
      )}
      <DataProtectionModal
        open={showDataProtectionModal}
        close={() => setShowDataProtectionModal(false)}
      />
    </form>
  );
};
