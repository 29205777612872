import React from "react";
// @ts-ignore
import ReactFacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { IconFacebook } from "Ui/Icons";

import styled from "styled-components";
import { Button } from "Ui";

const FBconnectSyled = styled.div`
  .fbBG {
    border-left: 1px solid #fff;
    display: block;
    padding: 1.5rem;
  }
`;
type RegistrationFormProps = {
  onFacebookRegister?: (data: any) => void;
  loading?: boolean;
};

export default ({ onFacebookRegister, loading }: RegistrationFormProps) => {
  return (
    <FBconnectSyled>
      <ReactFacebookLogin
        appId={process.env.REACT_APP_FACEBOOK_ID || ""}
        callback={onFacebookRegister}
        isMobile={false}
        autoLoad={false}
        render={(props: any) => (
          <Button
            kind="primary"
            color="#336798"
            onClick={props.onClick}
            icon={<IconFacebook />}
            loading={loading}
            style={{ width: "85%" }}
          >
            <div className="fbBG">Se connecter</div>
          </Button>
        )}
      />
    </FBconnectSyled>
  );
};
