import * as React from "react";
const SvgIconeBack = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Calque 1"
    viewBox="0 0 15.24 26.49"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      d="M13.24 2 2 13.24l11.24 11.25"
      style={{
        fill: "none",
        stroke: "#232929",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: 4,
      }}
    />
  </svg>
);
export default SvgIconeBack;
