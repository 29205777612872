import CardSales from 'Components/CardSales';
import { theme } from 'Ui';
import { createUrlParams } from 'Utils/imageParams';
import styled from 'styled-components';

const MeaContainer = styled.div`
  width: 419px;
  height: 297px;
  flex-shrink: 0;
  img {
    width: 419px;
    height: 297px;
  }
`;

const Title = styled.div`
  color: ${theme.v2.black};
  font-family: 'AlbraSemiBold';
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const Mea = ({ salesOfTheMoment }: any) => {
  const imageSize = {
    meaMobile: {
      width: 108,
      height: 273,
    },
  };
  const sale = salesOfTheMoment && salesOfTheMoment[0];
  console.log({ sale });
  const urlTest = `https://develop-tracking.pp.bazarchic.com/api/homeSale/2924923.jpg?params=${createUrlParams(
    imageSize.meaMobile
  )}`;
  console.log({ urlTest });
  return (
    <MeaContainer>
      <CardSales
        to={`${sale.url}`}
        // to={``}
        alt={`Vente Privée ${sale?.name}` || ''}
        image={`${sale?.image}?params${createUrlParams(imageSize.meaMobile)}`}
        // image={`${urlTest}?params${createUrlParamsWithoutWebP(
        //   imageSize.meaMobile
        // )}`}
        isLastingSale={false}
        onClick={() => {}}
        position={0}
      />
    </MeaContainer>
  );
};
