import React from 'react';
import styled from 'styled-components/macro';

interface PanelProps {
  isActive: boolean;
  children: React.ReactNode;
  paddingTop?: number;
}

const ContentStyled = styled.div<{ isActive: boolean, paddingTop: number }>`
  display: ${(props) => (props.isActive ? 'block' : 'none')};
  padding-top: ${(props) => props.paddingTop && `${props.paddingTop}rem`};
  font-family: 'Montserrat';
  position: relative;
`;

const TabPanelStyled = styled.div<{ isActive: boolean }>`
  font-family: 'Montserrat';
`;

export default ({ isActive, children, paddingTop = 2 }: PanelProps) => {
  return (
    <ContentStyled paddingTop={paddingTop} isActive={isActive}>
      <TabPanelStyled isActive={isActive}>{children}</TabPanelStyled>
    </ContentStyled>
  );
};
