import React from 'react';

import { ApolloProvider } from '@apollo/client';
import { init } from 'Libs/apollo';
import { GlobalStyle, AppStyled } from '../../GlobalStyle';
import { BrowserRouter } from 'react-router-dom';
import AuthStore from 'Store/AuthStore';
import InitiStore from 'Store/InitiStore';
import UiStore from 'Store/UiStore';
import ModalContainer from 'react-modal-promise';
import Login from 'Pages/Login/Desktop';
import Toaster from 'Components/Toaster';

export default () => {
  const client = init();

  return (
    <AppStyled>
      <BrowserRouter>
        <AuthStore.Provider initialData={client}>
          <UiStore.Provider>
            <ApolloProvider client={client}>
              <InitiStore />
              <>
                <GlobalStyle />
                <Login />
                <ModalContainer />
                <Toaster />
              </>
            </ApolloProvider>
          </UiStore.Provider>
        </AuthStore.Provider>
      </BrowserRouter>
    </AppStyled>
  );
};
