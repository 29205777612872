import { BestSales } from './BestSales';
import { Mea } from './Mea';

type Props = {
  saleOfTheMoment: any;
  bestSales: any;
};

export const Sales = ({ saleOfTheMoment, bestSales }: Props) => {
  return (
    <>
      {!!saleOfTheMoment && !!saleOfTheMoment.length ? (
        <Mea saleOfTheMoment={saleOfTheMoment} />
      ) : (
        bestSales && bestSales.length && <BestSales bestSales={bestSales} />
      )}
    </>
  );
};
