import * as React from "react";
const SvgInformationsIcone = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Calque 1"
    viewBox="0 0 44.21 44.21"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      d="M70.23 49.85a20.11 20.11 0 1 1-20.11-20.1 20 20 0 0 1 20.11 20.1"
      style={{
        fill: "none",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: 4,
        stroke: "#232929",
      }}
      transform="translate(-28.02 -27.75)"
    />
    <path
      d="M49.05 41.91a1.65 1.65 0 0 1-.52-1.23 1.7 1.7 0 0 1 .52-1.24 1.76 1.76 0 0 1 1.3-.52 1.82 1.82 0 0 1 1.31.49 1.6 1.6 0 0 1 .51 1.22 1.76 1.76 0 0 1-1.82 1.79 1.8 1.8 0 0 1-1.3-.51Zm0 3.36h2.65v14.84H49Z"
      style={{
        stroke: "#232929",
        fill: "#232929",
        strokeMiterlimit: 10,
        strokeWidth: 2,
      }}
      transform="translate(-28.02 -27.75)"
    />
  </svg>
);
export default SvgInformationsIcone;
