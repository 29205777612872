import * as React from "react";
const SvgMyReturn = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 22 22"
    {...props}
  >
    <g fill="currentColor">
      <path
        stroke="currentColor"
        strokeWidth={0.2}
        d="M20.886 5.75a.43.43 0 0 0-.042-.353l-2.359-3.708C18.227 1.288 17.441 1 17.033 1H4.962c-.409 0-1.2.288-1.453.689L1.15 5.397a.41.41 0 0 0-.042.353A1 1 0 0 0 1 6.193v13.789C1 20.545 1.456 21 2.02 21h17.96c.564 0 1.02-.455 1.02-1.018V6.193a1.1 1.1 0 0 0-.114-.443Zm-3.133-3.6 1.927 3.025h-6.243l-.54-3.318h4.135c.193.006.637.197.72.293ZM9.349 6.043h3.284v1.971H9.349zm.078-.862.54-3.318h2.053l.54 3.318zM4.23 2.156c.09-.108.522-.3.726-.3h4.136l-.54 3.319H2.308zm15.9 17.832a.16.16 0 0 1-.156.155H2.014a.16.16 0 0 1-.156-.155V6.199c0-.084.072-.156.156-.156h6.477v2.403c0 .239.192.43.432.43h4.148a.43.43 0 0 0 .432-.43V6.043h6.477c.084 0 .156.072.156.156v13.789z"
      />
      <path d="m7.753 14.357 1.873-1.928c.518-.542 1.294.271.777.814l-.633.66c-.144.15-.097.272.104.272h3.418c.302 0 .549.257.549.575a.563.563 0 0 1-.549.575H9.875c-.202 0-.248.122-.104.273l.632.66c.518.542-.259 1.355-.776.813l-1.872-1.908a.58.58 0 0 1-.002-.806" />
    </g>
  </svg>
);
export default SvgMyReturn;
