import dayjs from 'dayjs';

export const formatPrice = (price: number | null) => {
  if (!price) return '0,00 €';
  const currency = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
  }).format(price);
  return currency;
  //return price.toFixed(2).toLocaleString();
};

export const getDiscoutPercent = (
  discountedPrice: number | null,
  fullPrice: number | null
) => {
  if (!discountedPrice || !fullPrice) return 100;
  return Math.floor(100 - (100 / fullPrice) * discountedPrice);
};

export const hasMin = (key: string, array: any) => {
  return (
    (array.length &&
      array.reduce(function (prev: any, curr: any) {
        return prev[key] < curr[key] ? prev : curr;
      })) ||
    null
  );
};

export const capitalize = (str: string) => {
  const newStr = '';

  if (str != '') {
    str = str?.toLowerCase();
    return str ? str.charAt(0).toUpperCase() + str.slice(1) : '';
  }

  return newStr;
};
export const timeUntilEndOfSale = (endDateTime: string) => {
  const endDate = dayjs(endDateTime);
  const today = dayjs();
  const daysTo = String(endDate.diff(today, 'day', true));
  const daysRemaining = 'Fin ' + String(Math.floor(parseInt(daysTo))) + 'J';
  const hoursTo = String(endDate.diff(today, 'hours', true));
  const hoursRemaining = 'Fin ' + String(Math.floor(parseInt(hoursTo))) + 'H';
  return {
    daysTo,
    daysRemaining,
    hoursRemaining,
  };
};
