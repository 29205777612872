import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { IconIcoSearch } from 'Ui/Icons';
import logoBzcPath from 'Assets/images/BzcLogo1.svg';
import HeaderBasket from '../HeaderBasket';
import AnonymousHeader from './AnonymousHeader';
import UiStore from 'Store/UiStore';
import AuthStore from 'Store/AuthStore';
import BannerApp from 'Components/BannerApp';
import { isAndroid, isIOS } from 'react-device-detect';
import ModalSearch from 'Components/modals/ModalSearch';
import { storageFactory } from 'Libs/storageFactory';
import BirthdayReminderModal from 'Components/modals/BirthReminderModal';
import {
  dateDiff,
  timestampAndCount,
  createCookieBirtdayReminder,
} from 'Utils/date';
import Cookie from 'js-cookie';
import { theme } from 'Ui';
import IconeMenu from 'Ui/Icons/icons/IconeMenu';
import WishListMenu from '../DesktopHeader/WishListMenu';
import { CheckMark, LogoMobile, Personna } from 'Ui/Icons/icons';
import SearchInput from './SearchInput';

const MobileHeaderHeight = 4.8;

const HeaderMobileContainer = styled.div`
  width: 100%;
  padding: 1.6rem;
`;

const HeaderNavContainer = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderLogoContainer = styled.div`
  flex: 1;
  /* width: 65%; */
  display: flex;
  /* margin-left: 1.8rem; */
`;

const HeaderMenuContainer = styled.div`
  display: flex;
  /* width: 35%; */
  /* justify-content: space-around; */
`;

const FixBar = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 1000;
  .addShadow {
    box-shadow: 0px 1px 9px 0px rgba(0, 0, 0, 0.1);
  }
`;

const TopBar = styled.div`
  display: flex;
  align-items: center;
  /* height: ${MobileHeaderHeight}rem; */
  justify-content: space-between;
  /* padding: 0 1.5rem; */
  /* border-bottom: 1px solid #eee; */
  background-color: #fff;
  font-size: 2.5rem;
  img {
    width: 19rem;
  }
`;

const SuccessIcon = styled.div`
  background-color: ${theme.light.colors.purple};
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: 1rem auto; */
  margin-right: 1rem;
  position: absolute;
  bottom: 12px;
  left: 14px;
  svg path {
    stroke: white !important;
  }
  svg {
    font-size: 0.8rem;
  }
`;

const Basket = styled.div`
  @media screen and (max-width: ${theme.breakpoints.small}px) {
    padding-left: 1rem;
    /* margin-right: 1rem;
    svg {
      height: 2.6rem;
    }
    svg path {
      // stroke: ${theme.light.colors.purple};
      stroke-width: 4px;
    } */
  }
`;

const Search = styled.div`
  @media screen and (max-width: ${theme.breakpoints.small}px) {
    margin-right: 1rem;
    svg path {
      stroke: ${theme.light.colors.purple};
      stroke-width: 4px;
    }
  }
`;

export default () => {
  const setSideMenuOpen = UiStore.useStoreActions(
    (actions) => actions.setSideMenuOpen
  );

  const cookieMobileAppPopup = UiStore.useStoreState(
    (state) => state.cookieMobileApp
  );

  const loggedIn = AuthStore.useStoreState((state) => state.isLoggedIn);
  const localStore = storageFactory(() => localStorage);

  const member = AuthStore.useStoreState((state) => state.member);
  const birthdayReminder = Cookie.get('birthdayReminder');
  const marketingStore = UiStore.useStoreState(
    (state) => state.cookieMarketing
  );

  const scrollShadow = document.getElementById('addShadowJs');
  useEffect(() => {
    const detectScroll = (event: any) => {
      const getScroll = event.currentTarget.scrollY;
      // if (scrollShadow) {
      if (getScroll > 40) {
        scrollShadow.style.boxShadow = '0px 1px 9px 0px rgba(0, 0, 0, 0.1)';
      } else {
        scrollShadow.style.boxShadow = 'none';
      }
      // }
    };

    window.addEventListener('scroll', detectScroll);
    return () => {
      window.removeEventListener('scroll', detectScroll);
    };
  }, [!!scrollShadow]);

  const getBirthdayReminder = () => {
    if (member) {
      if (!member.birthDate) {
        if (!!birthdayReminder) {
          const [timestamp] = timestampAndCount(birthdayReminder);
          if (dateDiff(timestamp) >= 10) {
            BirthdayReminderModal({});
          }
        } else {
          createCookieBirtdayReminder();
        }
      }
    }
  };

  // const isLoggedIn =
  //   loggedIn || !!localStore.getItem('isLoggedIn') ? true : false;

  // if (!loggedIn) return <AnonymousHeader />;
  // if (!!localStore.getItem('isLoggedIn') === false) {
  //   return <AnonymousHeader />;
  // } else if (!!localStore.getItem('isLoggedIn') === false && !loggedIn) {
  //   return <AnonymousHeader />;
  // }

  if (loggedIn) {
    if (Cookie.get('RgpdBanner') && marketingStore) {
      getBirthdayReminder();
    }
  }

  console.log('in there load the header external');

  return (
    <>
      <>
        {cookieMobileAppPopup && (isAndroid || isIOS) && (
          <BannerApp android={isAndroid} />
        )}
        <FixBar id="addShadowJs">
          <TopBar>
            <HeaderMobileContainer>
              <HeaderNavContainer>
                <HeaderLogoContainer>
                  <a href="/">
                    <LogoMobile width="156" height="18" />
                  </a>
                </HeaderLogoContainer>
                <HeaderMenuContainer>
                  <div
                    style={{ position: 'relative' }}
                    onClick={() => {
                      setSideMenuOpen(true);
                    }}
                  >
                    {loggedIn && (
                      <SuccessIcon>
                        <CheckMark />
                      </SuccessIcon>
                    )}
                    <Personna width={30} height={30} />
                  </div>
                  <div style={{ padding: '0 1rem' }}>
                    <WishListMenu />
                  </div>
                  <Basket>
                    <HeaderBasket />
                  </Basket>
                </HeaderMenuContainer>
              </HeaderNavContainer>
              <div
                style={{ width: '100%', marginTop: '1.6rem' }}
                data-tut="headerTuto_position"
                data-test="searchHeader"
                onClick={async () => (await ModalSearch) && ModalSearch({})}
              >
                <SearchInput placeholder="Rechercher une vente, un produit ..." />
              </div>
            </HeaderMobileContainer>
          </TopBar>
        </FixBar>
      </>
    </>
  );
};
