import Footer from 'Components/Footer';
import { FooterV2 } from 'Components/FooterV2';
import Header from 'Components/Header';
import CustomHeader from 'Components/Header/CustomHeader';
import TunnelHeader from 'Components/Header/TunnelHeader.tsx';
import HtmlHead from 'Components/HtmlHead';
import PreferenceCenter from 'Components/PreferenceCenter/Preference';
import PreHeader from 'Components/PreHeader/PreHeader';
import SideMenu from 'Components/SideMenu';
import Toaster from 'Components/Toaster';
import Cookies from 'js-cookie';
import { initGtm } from 'Libs/Gtm/common';
import ClubPrivilege from 'Pages/ClubPrivilege';
import ForgotPassword from 'Pages/ForgotPassword';
import Preference from 'Pages/Preference';
import ResetPassword from 'Pages/ResetPassword';
import WhoIs from 'Pages/WhoIs';
import { lazy, Suspense } from 'react';
import { Helmet } from 'react-helmet';
import ModalContainer from 'react-modal-promise';
import { Route, Routes, useLocation } from 'react-router-dom';
import AuthStore from 'Store/AuthStore';
import UiStore from 'Store/UiStore';
import { PageContainer } from 'Ui';

import { preferenceUrl, tunnelUrl } from 'Utils/RoutesPayment';
import { createCookieWebview } from 'Utils/webview/utilsSession';
const Search = lazy(() => import('./Pages/Search'));
const ReturnOrder = lazy(() => import('./Pages/Help/PageReturnOrder'));
const Home = lazy(() => import('./Pages/Home'));
const HomeNotLoggedIn = lazy(() => import('./Pages/Home/VentesPriveesEnCours'));
const Sale = lazy(() => import('./Pages/Sale'));
const Product = lazy(() => import('./Pages/Product'));
const Landing = lazy(() => import('./Pages/Landing'));
const Tunnel = lazy(() => import('./Pages/Tunnel'));
const Payment = lazy(() => import('./Pages/Tunnel/Payment'));
const Cancellation = lazy(() => import('./Pages/Tunnel/Cancellation'));
const WishList = lazy(() => import('./Pages/WishList'));
const Login = lazy(() => import('./Pages/Login/Mobile'));
// const ForgotPassword = lazy(() => import('./Pages/ForgotPassword'));
const LoginMobileRegister = lazy(
  () => import('./Pages/Login/Mobile/RegisterMobile')
);
const LoginMobile = lazy(() => import('./Pages/Login/Mobile/LoginMobile'));

declare const window: any;

export default () => {
  const loading = AuthStore.useStoreState((state) => state.loading);
  const memberNpai = AuthStore.useStoreState(
    (state) => state.member && state.member.npai
  );

  const member = AuthStore.useStoreState(
    (state) => state.member && state.member
  );

  const webview = UiStore.useStoreState((state) => state.webview);
  const marketing = UiStore.useStoreState((state) => state.cookieMarketing);
  const preheaderEmail = UiStore.useStoreState(
    (state) => state.cookiePreheaderEmail
  );
  const setWebview = UiStore.useStoreActions((action) => action.setWebview);
  const gtmConsentMode = UiStore.useStoreState((state) => state.gtmConsentMode);
  // const [hasLoaded, setHasLoaded] = useState(true)
  const setUtmSource = UiStore.useStoreActions((action) => action.setUtmSource);

  if (!Cookies.get('isWebView')) {
    // gtmConsent();
    initGtm();
  }

  const search = useLocation().search;
  const utm = new URLSearchParams(search).get('utm_source');

  const path = useLocation().pathname;

  const sessionStorageInUrl = sessionStorage.getItem('utmSourceInUrl');
  if (sessionStorageInUrl) {
    setUtmSource(sessionStorageInUrl);
    // sessionStorage.setItem('utmSource', utm);
  }

  const webViewParams = new URLSearchParams(search).get('webview');

  if (!!webViewParams) {
    setWebview(true);
    createCookieWebview();
  }

  if (loading) return null;

  return (
    <div className="BC-react-app">
      <Suspense fallback={<div />}>
        {preheaderEmail && memberNpai == 'hard' && <PreHeader />}
        <HtmlHead />
        <SideMenu />

        {!webview &&
          !tunnelUrl.includes(path) &&
          !preferenceUrl.includes(path) && <Header />}
        {!webview && tunnelUrl.includes(path) && <TunnelHeader />}
        {preferenceUrl.includes(path) && <CustomHeader />}

        <Routes>
          <Route path="/recherche" element={<Search />} />
          {/* Page List */}
          <Route path="/ventes/cata/id/:id/" element={<Sale />}>
            <Route
              path="/ventes/cata/id/:id/idmembre/:memberId/key/:key/"
              element={<Sale />}
            />
            <Route
              path="/ventes/cata/id/:id/idmembre/:memberId/key/:key"
              element={<Sale />}
            />
          </Route>
          <Route path="/ventes/cata/id/:id/pub/:pub/" element={<Sale />} />
          {/* Product */}
          <Route
            path="/ventes/produit/id/:id/idcat/:saleId"
            element={<Product />}
          >
            <Route
              path="/ventes/produit/id/:id/idcat/:saleId/"
              element={<Product />}
            />
            <Route
              path="/ventes/produit/id/:id/idcat/:saleId/pub/:pub/"
              element={<Product />}
            />
            <Route
              path="/ventes/produit/id/:id/idcat/:saleId/idmembre/:memberId/key/:key"
              element={<Product />}
            />
            <Route
              path="/ventes/produit/id/:id/idcat/:saleId/idmembre/:memberId/key/:key/"
              element={<Product />}
            />
          </Route>
          {/* end Product */}
          {/* Landing */}
          <Route path="/ventes-privees/:action" element={<Landing />} />
          <Route
            path="/ventes-privees/idparrain/:idParrain/"
            element={<Landing />}
          />
          <Route path="/ventes-privees/" element={<Landing />} />
          {/* Return order spec */}
          <Route path="/retours/" element={<ReturnOrder />}>
            <Route
              path="/retours/idmembre/:memberId/key/:key/"
              element={<ReturnOrder />}
            />
            <Route
              path="/retours/idmembre/:memberId/key/:key/"
              element={<ReturnOrder />}
            />
          </Route>
          {/* Club Privilege */}
          <Route path="/accueil/privilege" element={<ClubPrivilege />} />
          <Route path="/accueil/privilege/" element={<ClubPrivilege />} />
          {/* Who is Page */}
          <Route path="/home/whois/" element={<WhoIs />} />
          <Route path="/home/whois" element={<WhoIs />} />

          {/* WishList */}
          <Route path="/mes-favoris" element={<WishList />} />
          <Route path="/mes-favoris/" element={<WishList />} />

          {/* Reset Password */}
          <Route
            path="/login/resetPassword/token/:token/idmembre/:memberId"
            element={<ResetPassword />}
          />
          <Route
            path="/login/resetPassword/token/:token/idmembre/:memberId/"
            element={<ResetPassword />}
          />

          {/* Forgot Password */}
          <Route
            path="/login/emailForResetPassword/"
            element={<ForgotPassword />}
          />

          {/* Home + Univers */}
          <Route
            path="/ventes-privees-encours/"
            element={<HomeNotLoggedIn />}
          />
          <Route
            path="/ventes-privees-encours/"
            element={<HomeNotLoggedIn />}
          />
          <Route
            path="/ventes-privees-encours/pub/:pub?"
            element={<HomeNotLoggedIn />}
          />

          {/* Landing preference */}
          <Route
            path="/centre-preferences/idmembre/:memberId/key/:key"
            element={<Preference />}
          />
          <Route path="/centre-preferences/" element={<Preference />} />
          <Route
            path="/centre-preferences/idmembre/:memberId/key/:key"
            element={<Preference />}
          />

          <Route path="/v2/tunnel/basket" element={<Tunnel />} />
          <Route path="/v3/tunnel/recap" element={<Payment />} />
          <Route path="/v3/tunnel/recap/" element={<Payment />} />
          <Route path="/v3/tunnel/cancel" element={<Cancellation />} />
          <Route path="/v3/tunnel/cancel/" element={<Cancellation />} />
        </Routes>
        <ModalContainer />
        <Toaster autoClose={6000} />
        {!webview && !preferenceUrl.includes(path) && <FooterV2 />}
      </Suspense>
    </div>
  );
};
