import React, { useEffect } from 'react';
import { getHeaderNavigation } from '../__generated__/getHeaderNavigation';
import { useQuery } from '@apollo/client';
import headerNavigationQuery from '../headerNavigationQuery';
import { MenuBar, MenuItem, MobileScroller } from '../menuComponents';

import AuthStore from 'Store/AuthStore';
import { storageFactory } from 'Libs/storageFactory';
import { DeviceEnum, OsEnum } from '__generated__/graphql-global-types';
import { isMobile } from 'react-device-detect';
import { theme } from 'Ui/theme';

export default () => {
  const urlSplit = (url: any) => {
    const splitUrl = url.split('/');
    return splitUrl[2];
  };
  const loggedIn = AuthStore.useStoreState((state) => state.isLoggedIn);
  const localStore = storageFactory(() => localStorage);
  const menuDevice: DeviceEnum = isMobile
    ? DeviceEnum.MOBILE
    : DeviceEnum.DESKTOP;
  const { data } = useQuery<getHeaderNavigation>(headerNavigationQuery, {
    variables: { device: menuDevice, os: OsEnum.SITE },
  });

  const links = (data && data.headerNavigation && data.headerNavigation) || [];
  // const home = '/accueil/index/' === window.location.pathname;
  const deco = '/deco/index/' === window.location.pathname;
  const wine = '/wines/index/' === window.location.pathname;
  // const homeType = 'ACCEUIL';
  const linksMenu = links.map((link, index) => {
    return {
      label: link.label,
      url: link.url,
      color: link.color,
      alt: link.alt,
      type: link.type,
      target: link.target,
      selected: link.url === window.location.pathname,
      nameUrl: urlSplit(link.url),
    };
  });

  const selectedScroll = (el: any) => {
    const nameUrl = urlSplit(window.location.pathname);
    if (nameUrl) {
      const category = el && el.dataset && el.dataset.url;
      if (nameUrl === category) {
        const scrolllElement = document.querySelector('.mobileScroll');
        if (category !== 'index' || deco || wine) {
          if (scrolllElement) {
            scrolllElement.scrollLeft = el.offsetLeft - 150;
          }
        }
      }
    }
  };

  const setColor = (color: any, link: string) => {
    return color
      ? color
      : link === window.location.pathname
      ? `${theme.light.colors.purple}`
      : '#000';
  };

  // const isLoggedIn =
  //   loggedIn || !!localStore.getItem('isLoggedIn') ? true : false;
  return (
    <>
      {loggedIn && (
        <>
          <MobileScroller>
            <MenuBar className="mobileScroll reactHomeMenuV4">
              {linksMenu
                .filter((link) => !!link.label)
                .map((link, index) => (
                  <MenuItem
                    className="itemScroll"
                    style={{
                      color: setColor(link.color, link.url),
                      height: '25px',
                    }}
                    href={link.url}
                    target={link.target || ''}
                    key={index}
                    selected={link.selected}
                    type={link.type || ''}
                    data-url={link.nameUrl}
                    ref={(node) => selectedScroll(node)}
                  >
                    {link.label}
                  </MenuItem>
                ))}
            </MenuBar>
          </MobileScroller>
        </>
      )}
    </>
  );
};
