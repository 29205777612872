import { Social } from '../Social';
import styled from 'styled-components';
import { theme } from 'Ui';
import { Fbook, Linkedin } from 'Ui/Icons/icons';
import IgGray from 'Ui/Icons/svg/igGray1.svg';
import IgColor from 'Ui/Icons/svg//igColor.svg';

const SocialContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.3rem;
  padding-top: 1rem;
  svg {
    width: 2.5rem;
    height: 2.5rem;
  }
  .fboook {
    svg path:hover {
      fill: #3b5998;
    }
  }
  a.ig {
    background-image: url(${IgGray});
    width: 3rem;
    height: 3rem;
    background-size: contain;
    background-repeat: no-repeat;
    box-sizing: border-box;
    :hover {
      background-image: url(${IgColor});
      width: 3rem;
      height: 3rem;
      background-size: contain;
      background-repeat: no-repeat;
      box-sizing: border-box;
    }
  }
  .lk {
    svg path:hover {
      fill: #007bb5;
    }
  }
`;

export const SocialNetworks = () => {
  const SocialGroup = () => (
    <SocialContainer>
      <a
        href="https://www.facebook.com/BazarChicFR"
        className="footer_social_ico fboook"
        target="__blank"
        rel="noopener noreferrer"
      >
        <Fbook />
      </a>
      <a
        href="https://www.instagram.com/bazarchic/"
        className="footer_social_ico ig"
        target="__blank"
        rel="noopener noreferrer"
      />
      <a
        href="https://www.linkedin.com/company/bazarchic/"
        className="footer_social_ico lk"
        target="__blank"
        rel="noopener noreferrer"
      >
        <Linkedin />
      </a>
    </SocialContainer>
  );
  return <Social title="Suivez-nous" content={<SocialGroup />} />;
};
