import styled from 'styled-components';
import { theme } from 'Ui';

const AdvantageContainer = styled.div`
  display: flex;
  width: 15.6rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  @media screen and (max-width: 48rem) {
    /* width: 100%; */
    display: flex;
    width: 15.5rem;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
  }
  @media screen and (min-width: 49rem) and (max-width: 768px) {
    /* width: 100%; */
    flex-wrap: wrap;
  }
  @media screen and (min-width: 769px) and (max-width: 967px) {
    /* width: 90rem; */
    flex-wrap: wrap;
    width: 13rem;
  }
  @media screen and (min-width: 968px) and (max-width: 1024px) {
    /* width: 90rem; */
    flex-wrap: wrap;
    width: 16.2rem;
  }
`;

const AdvantageLogo = styled.div`
  svg {
    width: 6.6rem;
    height: 6.6rem;
  }
`;

const AdvantageTitle = styled.div`
  color: #000;
  text-align: center;
  font-family: 'granville-bold' !important;
  font-size: 1.6rem;
  line-height: normal;
`;

const AdvantageSecondaryText = styled.div`
  color: ${theme.light.colors.grayUltraDark};
  text-align: center;
  font-family: 'Montserrat' !important;
  font-size: 1.4rem;
  line-height: 1.8rem; /* 128.571% */
`;

type AdvantageProps = {
  icon: any;
  title: string;
  secondaryText: string;
};

export const Advantage = ({ icon, title, secondaryText }: AdvantageProps) => {
  return (
    <AdvantageContainer>
      <AdvantageLogo>{icon}</AdvantageLogo>
      <AdvantageTitle>{title}</AdvantageTitle>
      <AdvantageSecondaryText>{secondaryText}</AdvantageSecondaryText>
    </AdvantageContainer>
  );
};
