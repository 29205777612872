import * as React from "react";
const SvgBasket = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 30 30"
    {...props}
  >
    <path
      fill="currentColor"
      d="M21.8 26.7h-14c-1.5 0-2.8-1.3-2.8-2.8v-.1s1.7-10.9 2-12.4 1.3-2.7 2.8-2.7h10c2 0 2.6 2 2.8 2.7l2 12.5c0 1.5-1.2 2.8-2.8 2.8M6.5 23.9c0 .7.6 1.3 1.3 1.3h14c.7 0 1.3-.6 1.3-1.3l-2-12.5c-.1-.3-.3-1.4-1.3-1.3h-10c-.7 0-1.2.4-1.3 1.3M14.8 4.5c1.7 0 3.1 1.3 3.3 2.9 0 .3.3.5.6.5h.4c.4 0 .6-.3.6-.7-.3-2.3-2.3-4.2-4.8-4.2S10.4 4.8 10 7.1c0 .3.2.7.6.7h.4c.3 0 .5-.2.6-.5.2-1.6 1.5-2.8 3.2-2.8"
    />
  </svg>
);
export default SvgBasket;
