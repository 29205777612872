import * as React from "react";
const SvgIcoInstagram = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 60 60"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      d="M47.8 60H12.6C5.9 60 .5 54.6.5 47.9V12.7C.5 6 5.9.6 12.6.6h35.2C54.5.6 59.9 6 59.9 12.7v35.2C59.8 54.6 54.4 60 47.8 60"
      style={{
        fill: "#2f2f2f",
      }}
    />
    <circle
      cx={30.2}
      cy={30.3}
      r={13.9}
      style={{
        fill: "none",
        stroke: "#fff",
        strokeWidth: 2,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <circle
      cx={47.3}
      cy={13.2}
      r={3.2}
      style={{
        fill: "#fff",
      }}
    />
  </svg>
);
export default SvgIcoInstagram;
