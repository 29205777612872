import { useQuery, useMutation } from '@apollo/client';
import PreferenceCenterModal from 'Components/modals/PreferenceCenterModal';
import React, { useEffect, useState } from 'react';
import UiStore from 'Store/UiStore';
import styled from 'styled-components';
import { Button, Spinner, theme } from 'Ui';
import { GET_MEMBER_NEWSLETTER, MEMBER_NEWSLETTER_PREFERENCE } from './query';
import {
  getMemberNewsletter,
  getMemberNewsletter_memberNewsletters,
} from './query/__generated__/getMemberNewsletter';
import {
  updateNewsletterPreference,
  updateNewsletterPreferenceVariables,
} from './query/__generated__/updateNewsletterPreference';
import { RadioGroup } from './RadioPeriodic';
import ResponseToast from './ResponseToast';
import Switch from './SwitchBtn';
import AuthStore from 'Store/AuthStore';
import { isMobile } from 'react-device-detect';

type PreferenceScrennProps = {
  backgroundColor?: boolean;
  legalText?: boolean;
  toHomepage?: boolean;
  preferencePageTitle?: boolean;
  preferenceEmail?: boolean;
};

interface MemberNewsletter {
  newsletterId: string;
  isActive: boolean;
  name: string;
}

const PreferenceCenterConatiner = styled.div<{ backgroundColor?: boolean }>`
  padding: ${(props) => (props.backgroundColor ? `3rem 4rem` : '4rem 8rem ')};
  background-color: ${(props) => (props.backgroundColor ? `#f1f2f3` : 'white')};
  border-radius: 5px;
  @media screen and (max-width: 768px) {
    padding: ${(props) =>
      props.backgroundColor ? `3rem 2rem` : '0rem 0rem 0rem'};
  }
`;

const OptinTitle = styled.div`
  font-family: 'MontserratSemiBold' !important;
  font-size: 1.8rem;
  margin-bottom: 1.6rem;

  @media screen and (max-width: 48rem) {
    margin-bottom: 0.8rem;
    align-self: stretch;
    font-size: 1.6rem;
  }
`;
const OptinMainSecondaryTitle = styled.div`
  font-family: 'MontserratMedium' !important;
  font-size: 1.8rem;
  margin-bottom: 1.6rem;
  color: ${theme.light.colors.black};
  .unsubscribe {
    margin-bottom: 0;
  }
  @media screen and (max-width: 48rem) {
    margin-bottom: 0.8rem;
    align-self: stretch;
    font-size: 1.6rem;
  }
`;

const OptinText = styled.div`
  font-family: 'Montserrat' !important;
  font-size: 1.4rem;
  color: ${theme.light.colors.grayUltraDark};
  @media screen and (max-width: 48rem) {
    font-size: 1.4rem;
  }
`;

const OptinSecondaryTitle = styled.div`
  font-family: 'MontserratMedium' !important;
  margin-bottom: 7px;
  font-size: 1.4rem;
  color: ${theme.light.colors.black};
  @media screen and (max-width: 48rem) {
    width: 80%;
    font-size: 1.4rem;
    margin-bottom: 4px;
    line-height: 17px; /* 121.429% */
    letter-spacing: 0.28px;
  }
`;

const OptinSecondaryText = styled.div`
  font-family: 'Montserrat' !important;
  font-size: 1.4rem;
  line-height: 17px;
  color: ${theme.light.colors.grayUltraDark};
  @media screen and (max-width: 48rem) {
    font-size: 1.4rem;
    color: ${theme.light.colors.grayUltraDark};

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
    letter-spacing: 0.28px;
  }
`;

const PreferenceOption = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  margin-bottom: 7px;
  gap: 0;
  @media screen and (max-width: 48rem) {
    gap: 3rem;
    margin-bottom: 11px;
  }
`;

const PreferenceSecondaryContainer = styled.div`
  padding-top: 1.6rem;
  @media screen and (max-width: 48rem) {
    padding-top: 2.4rem;
  }
`;

const RadioGroupFlex = styled.div`
  width: 100%;
  .inline-radio-button {
    justify-content: flex-start;
    flex-direction: column;
    font-family: 'Montserrat' !important;
    display: flex;
    margin-bottom: none;
    .radioGroup {
      display: flex;
      justify-content: space-between;
    }
  }
`;

const ToHomepage = styled.div`
  font-family: 'MontserratMedium';
  font-size: 1.4rem;
  text-align: center;
  text-decoration: underline;
  padding: 3rem 0;
  :hover {
    color: ${theme.light.colors.purple};
  }
  @media screen and (max-width: 48rem) {
    padding: 13px 0 14px;
  }
`;

const LegalText = styled.div`
  color: ${theme.light.colors.grayUltraLight};
  font-size: 1rem;
  font-family: 'MontserratMedium';
  margin-top: 1.5rem;
`;

const PageTitle = styled.div`
  font-family: 'MontserratMedium';
  font-size: 2.4rem;
  color: #000;
  margin-bottom: 3.2rem;
  @media screen and (max-width: 49rem) {
    font-size: 2rem;
  }
`;

const Email = styled.div`
  font-family: 'Montserrat';
  font-size: 1.4rem;
  margin-bottom: 3.2rem;
  color: ${theme.light.colors.purple};
`;

const SpinnerStyled = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 5rem;
  margin-bottom: 5rem;
  .spinner {
    margin: 100px auto 0;
    width: 70px;
    text-align: center;
  }

  .spinner > div {
    width: 18px;
    height: 18px;
    background-color: #6c4dff;

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }

  .spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  .spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }

  @-webkit-keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
    }
  }

  @keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
`;

const Error = styled.div`
  font-family: 'Montserrat';
  font-size: 1.5rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 6rem;
  @media screen and (max-width: 48rem) {
    margin-top: 2.4rem;
  }
`;

const PreferenceCenter = ({
  backgroundColor = true,
  toHomepage = false,
  legalText = false,
  preferencePageTitle = false,
  preferenceEmail = false,
}: PreferenceScrennProps) => {
  const member = AuthStore.useStoreState((state) => state.member);
  const preferenceJwt = AuthStore.useStoreState((state) => state.preferenceJwt);
  const emailSuspect = AuthStore.useStoreState((state) => state.emailSuspect);
  const accessToken = sessionStorage.getItem('access_token');
  const path = window.location.pathname;

  const { data, loading: loadingChoices } = useQuery<getMemberNewsletter>(
    GET_MEMBER_NEWSLETTER,
    {
      context: {
        headers: {
          authorization: !!preferenceJwt
            ? `Bearer ${preferenceJwt}`
            : `Bearer ${accessToken}`,
        },
      },
      skip: !accessToken && !preferenceJwt,
    }
  );

  const unsubscribeReasonMessage = UiStore.useStoreState(
    (state) => state.unsubscribeReasonMessage
  );
  const unsubscribeReason = UiStore.useStoreState(
    (state) => state.unsubscribeReason
  );

  const msgPreference = UiStore.useStoreState((state) => state.msgPreference);
  const isErrorModalPref = UiStore.useStoreState(
    (state) => state.isErrorModalPref
  );

  const setUnsubscribeReason = UiStore.useStoreActions(
    (actions) => actions.setUnsubscribeReason
  );
  const setUnsubscribeReasonMessage = UiStore.useStoreActions(
    (actions) => actions.setUnsubscribeReasonMessage
  );

  const getPeriodic: MemberNewsletter[] | null =
    data?.memberNewsletters?.filter(
      (news: MemberNewsletter) => news.newsletterId !== '9'
    ) || null;
  const getSpecial: MemberNewsletter[] | null =
    data?.memberNewsletters?.filter(
      (news: MemberNewsletter) => news.newsletterId === '9'
    ) || null;
  const periodicityArray =
    getPeriodic &&
    getPeriodic.map((e: any) => {
      return {
        isActive: e.isActive,
        newsletterId: e.newsletterId,
        name: e.name,
      };
    });

  const checkIfHebdoIstrue = periodicityArray?.find(
    (news: MemberNewsletter) => news.isActive === true
  );
  const checkIfHebdoIs = periodicityArray?.filter(
    (news: MemberNewsletter) => news.isActive === true
  );
  const check =
    checkIfHebdoIs &&
    checkIfHebdoIs.map((e) => {
      if (e === e.isActive) {
        return true;
      }
      return false;
    });

  const getOps = getSpecial && getSpecial[0].isActive;
  const [checkedDaily, setCheckedDaily] = useState(false);
  const [checkedActu, setCheckedActu] = useState(false);
  const [periodic, setPeriodic] = useState('');
  const [isError, setIsError] = useState(null);
  const [msg, setMsg] = useState(false);
  const [checkedUnsuscribe, setCheckedUnsuscribe] = useState(false);
  useEffect(() => {
    setCheckedActu(getSpecial && getSpecial[0].isActive);
    if (!!checkIfHebdoIstrue) {
      setCheckedDaily(true);
      setPeriodic(checkIfHebdoIstrue.newsletterId);
    }

    if (check) {
      if (!getOps) {
        if (!check.length) {
          setCheckedUnsuscribe(true);
        }
      }
    }
  }, [data]);

  const responseText = isError
    ? 'Merci de bien vouloir renseigner au moins une des options.'
    : 'Vos préférences ont bien été enregistrées !';

  const responseTextFromModal = isErrorModalPref
    ? 'Merci de bien vouloir renseigner au moins une des options.'
    : 'Vos préférences ont bien été enregistrées !';

  const [updatePreferenceNewsletter, { loading }] = useMutation<
    updateNewsletterPreference,
    updateNewsletterPreferenceVariables
  >(MEMBER_NEWSLETTER_PREFERENCE, {
    context: {
      headers: {
        authorization: !!preferenceJwt
          ? `Bearer ${preferenceJwt}`
          : `Bearer ${accessToken}`,
      },
    },

    variables: {
      unsubscribe: {
        unsubscribeId: unsubscribeReason,
        unsubscribeReason: unsubscribeReasonMessage,
      },
      preferences: [
        // periodic daily
        {
          newsletterId: '3',
          status: periodic === '3' ? true : false,
        },
        // periodic Hebdo
        { newsletterId: '6', status: periodic === '6' ? true : false },
        // OPC OPS
        {
          newsletterId: '9',
          status: checkedActu ? true : false,
        },
      ],
    },
  });

  const emailFronMail = emailSuspect
    ? emailSuspect
    : member && member.email
    ? member.email
    : '';

  if (loadingChoices) {
    return (
      <SpinnerStyled>
        <div className="txtCenter">
          <div className="spinner">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div>
        </div>
      </SpinnerStyled>
    );
  }

  const error = 'Une erreur est survenue';

  // if (path === '/account/newsletters/' && !accessToken) {
  //   return <Error>{error}</Error>;
  // }

  // if (path === '/centre-preferences/' && !preferenceJwt) {
  //   return <Error>{error}</Error>;
  // }

  return (
    <>
      {msg && <ResponseToast isError={isError} text={responseText} />}
      {msgPreference && (
        <ResponseToast
          isError={isErrorModalPref}
          text={responseTextFromModal}
        />
      )}
      <PreferenceCenterConatiner backgroundColor={backgroundColor}>
        {preferencePageTitle && (
          <PageTitle>Mes préférences de communication</PageTitle>
        )}
        {preferenceEmail && <Email>{emailFronMail}</Email>}
        <div style={{ marginBottom: '3.2rem' }}>
          <OptinTitle className="fontMontserratSemiBold">
            Pourquoi m'abonner aux newsletters ?
          </OptinTitle>
          <OptinText className="fontMontserrat">
            Nos ventes privées sont limitées dans le temps, en vous abonnant ne
            passez plus à côté de vos marques & pièces favorites !
          </OptinText>
        </div>

        <div>
          <OptinMainSecondaryTitle className="fontMontserratMedium">
            Gérez vos préférences e-mail sur mesure
          </OptinMainSecondaryTitle>
          <PreferenceSecondaryContainer>
            <OptinSecondaryTitle className="fontMontserratMedium">
              Invitations aux ventes
            </OptinSecondaryTitle>
            <PreferenceOption>
              <OptinSecondaryText className="fontMontserrat">
                Pour ne manquer aucune des nouveautés en recevant les nouvelles
                ventes :
              </OptinSecondaryText>
              <Switch
                isOn={checkedDaily}
                handleToggle={(e: any) => {
                  setCheckedDaily(e.target.checked);
                  if (!checkedDaily) {
                    setCheckedUnsuscribe(false);
                    setPeriodic('3');
                  } else {
                    setPeriodic('');
                  }
                }}
              />
            </PreferenceOption>
            <PreferenceOption>
              <RadioGroupFlex>
                {getPeriodic && (
                  <RadioGroup
                    options={getPeriodic}
                    top={2}
                    left={1.9}
                    marginLeft={0.2}
                    value={checkedDaily && periodic}
                    kind={periodic === '3' || '6' ? 'purple' : 'default'}
                    onChange={(periodic) => {
                      if (periodic !== '') {
                        setCheckedDaily(true);
                        setCheckedUnsuscribe(false);
                      }
                      setPeriodic(periodic);
                    }}
                  />
                )}
              </RadioGroupFlex>
            </PreferenceOption>
          </PreferenceSecondaryContainer>

          <PreferenceSecondaryContainer>
            <OptinSecondaryTitle className="fontMontserratSemiBold">
              Actualités BazarChic
            </OptinSecondaryTitle>
            <PreferenceOption>
              <OptinSecondaryText className="fontMontserrat">
                Une sélection des meilleures offres et tendances du moment.
              </OptinSecondaryText>

              <Switch
                isOn={checkedActu}
                handleToggle={(e: any) => {
                  setCheckedActu(e.target.checked);
                  if (!checkedActu) {
                    setCheckedUnsuscribe(false);
                    setCheckedActu(!checkedActu);
                  }
                }}
              />
            </PreferenceOption>
          </PreferenceSecondaryContainer>
          <PreferenceSecondaryContainer>
            <PreferenceOption>
              <OptinMainSecondaryTitle className="fontMontserratMedium unsuscribe">
                Se désabonner des communications e-mail
              </OptinMainSecondaryTitle>
              <Switch
                isOn={checkedUnsuscribe}
                handleToggle={(e: any) => {
                  setCheckedUnsuscribe(e.target.checked);
                  if (!checkedUnsuscribe) {
                    setPeriodic('');
                    PreferenceCenterModal({});
                    setCheckedDaily(false);
                    setCheckedActu(false);
                  }
                }}
              />
            </PreferenceOption>
          </PreferenceSecondaryContainer>
        </div>
        <ButtonContainer>
          <Button
            loading={loading}
            className="fontMontserratSemiBold"
            style={{ width: isMobile ? '100%' : '16.3rem' }}
            onClick={async () => {
              if (!checkedActu && !checkedUnsuscribe && !checkedDaily) {
                setIsError(true);
                setMsg(true);
              } else {
                await updatePreferenceNewsletter()
                  .then((res) => {
                    setMsg(true);
                    setIsError(false);
                    setUnsubscribeReason('');
                    setUnsubscribeReasonMessage('');
                  })
                  .catch((err) => {
                    setMsg(true);
                    setIsError(true);
                    setUnsubscribeReason('');
                    setUnsubscribeReasonMessage('');
                  });
              }
            }}
          >
            Valider
          </Button>
        </ButtonContainer>

        {toHomepage && (
          <ToHomepage>
            <a href="/">Découvrir nos ventes</a>
          </ToHomepage>
        )}
        {legalText && (
          <LegalText>
            Vos données sont collectées par BazarChic – 4 rue Mozart, 92110,
            Clichy, responsable du traitement, afin de vous adresser des
            newsletters. Vos données sont exclusivement destinées à la société
            BazarChic et au Groupe Galeries Lafayette. Vous bénéficiez d'un
            droit d'accès de rectification et d'effacement de vos données que
            vous pouvez exercer à tout moment en adressant un e-mail à l'adresse
            : dataprotectionofficer@bazarchic.com. Pour parcourir dans le détail
            notre Politique de vie privée, cliquez ici.
          </LegalText>
        )}
      </PreferenceCenterConatiner>
    </>
  );
};

export default PreferenceCenter;
