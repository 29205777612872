import React from 'react';
import styled, { css } from 'styled-components';
import posed from 'react-pose';
import { theme } from 'Ui';

interface StyleProps {
  kind?: 'default' | 'purple' | 'error';
  selected: boolean;
  hollow?: boolean;
  top?: number;
  left?: number;
  marginLeft?: number;
  reverse?: boolean;
}

interface StylePropsRouned extends Omit<StyleProps, 'selected'> {
  kind?: 'default' | 'purple' | 'error';
  hollow?: boolean;
}

interface RadioGroup extends Omit<StyleProps, 'selected'> {
  options: { newsletterId: string; name: string; isActive: boolean }[];
  onChange: (value: any) => void;
  placeholder?: string;
  value: any;
  selected?: boolean;
  show?: boolean;
}

const SelectStyled = styled.div`
  position: relative;
  font-family: 'Montserrat';
`;

const CheckRadio = styled.div<StyleProps>`
  width: 1.8rem !important;
  height: 1.8rem !important;
  position: relative;
  /* display: flex; */
  border: 1px solid ${theme.light.colors.grayUltraLight};
  background-color: white;
  border-radius: 100%;
  margin-right: 9px;
  box-sizing: border-box;
  cursor: pointer;
  @media screen and (max-width: 48rem) {
    width: 2rem !important;
    height: 2rem !important;
    margin-right: 8px;
  }
`;

const Content = styled.div<{ show?: boolean; reverse?: boolean }>`
  .radioGroup {
    display: flex;
    /* flex: 1 0; */
    align-items: center;
  }
  .radioGroup:nth-child(1n) {
    margin-bottom: 7px;
  }
  .radioGroup:last-child {
    margin-bottom: 0px;
  }
  ${(props) =>
    props.reverse &&
    css`
      .radioGroup {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        /* flex: 1 0; */
        align-items: center;
      }
      .radioGroup:nth-child(1n) {
        margin-bottom: 8px;
      }
      .radioGroup:last-child {
        margin-bottom: 0px;
      }
    `}
`;

const Option = styled.div`
  /* padding: 0.5rem 2rem; */
  font-size: 14px;
  color: ${theme.light.colors.grayUltraDark};
  font-family: 'Montserrat' !important;
  @media screen and (max-width: 48rem) {
    color: #000;
    font-family: 'Montserrat';
    font-size: 14px;
    line-height: 17px; /* 121.429% */
    letter-spacing: 0.63px;
  }
`;

const SelectedRounded = styled.div<StylePropsRouned>`
  position: absolute;
  transform-origin: center;
  top: ${(props) => props.top && `${props.top}px`};
  left: ${(props) => props.left && `${props.left}px`};
  margin-left: ${(props) => props.left && `${props.marginLeft}px`};
  border-radius: 100%;
  width: 12px !important;
  height: 12px !important;
  background: ${(props) => theme.light.colors[props.kind || 'default']};
  @media screen and (max-width: 48rem) {
    /* top: ${(props) => props.top && `${props.top - 0.5}px`}; */
    margin-left: 0px;
    margin-left: ${(props) => props.marginLeft && `0px`};
    width: 14px !important;
    height: 14px !important;
  }
`;
const SelectedRoundedAnimated = posed.div({
  selected: {
    alignSelf: 'center !important',
    scale: 1,
    transition: { ease: 'easeInOut', duration: 200 },
  },
  unselected: {
    scale: 0,
    transition: { ease: 'easeInOut', duration: 200 },
  },
});

export const RadioGroup = ({
  options,
  onChange,
  top = 2,
  left = 2,
  marginLeft = 0,
  value,
  selected = true,
  reverse = false,
  kind,
  show,
  hollow,
}: RadioGroup) => {
  const onSelect = (value: any) => {
    onChange(value);
  };

  return (
    <SelectStyled>
      <Content className="inline-radio-button" reverse={reverse}>
        {options.map((option, index) => {
          const selected = option.newsletterId === value;
          return (
            <div
              className="radioGroup"
              key={option.newsletterId}
              onClick={() => onSelect(option.newsletterId)}
            >
              <Option
                className="fontMontserrat"
                key={
                  option.name === 'quotienne' ? 'Chaque jour' : 'Chaque semaine'
                }
              >
                {option.name === 'quotidienne'
                  ? 'Chaque jour'
                  : 'Chaque semaine'}
              </Option>
              <CheckRadio selected={selected} kind={kind} hollow={hollow}>
                <SelectedRoundedAnimated
                  pose={selected ? 'selected' : 'unselected'}
                >
                  <SelectedRounded
                    kind={kind}
                    top={top}
                    left={left}
                    marginLeft={marginLeft}
                  />
                </SelectedRoundedAnimated>
              </CheckRadio>
            </div>
          );
        })}
      </Content>
    </SelectStyled>
  );
};
