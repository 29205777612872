import * as React from "react";
const SvgAlmaLogo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 130 40"
    {...props}
  >
    <path
      fill="#FA5022"
      d="M120.337 10.402v3.707c-2.072-2.695-5.079-4.38-8.65-4.38-6.89 0-12.228 6.253-12.228 13.969s5.338 13.967 12.228 13.967c3.822 0 6.999-1.925 9.074-4.959v4.291h6.591V10.402zm-6.938 20.603c-3.831 0-6.933-3.271-6.933-7.307s3.102-7.308 6.933-7.308 6.933 3.271 6.933 7.308c0 4.036-3.102 7.307-6.933 7.307M39.542 3.02h7.01v33.973h-7.01zm15.075 18.901c0-7.311 4.425-12.187 10.552-12.187 3.805 0 6.812 1.715 8.623 4.638 1.816-2.923 4.819-4.638 8.623-4.638 6.128 0 10.552 4.876 10.552 12.187v15.076h-7.011V21.152c0-2.854-1.864-4.713-4.33-4.713-2.465 0-4.328 1.864-4.328 4.713v15.845h-7.011V21.152c0-2.854-1.864-4.713-4.33-4.713-2.465 0-4.328 1.864-4.328 4.713v15.845h-7.012zM26.845 10.68c-1.66-6.309-5.17-8.327-8.51-8.327-3.342 0-6.852 2.018-8.511 8.327L2.617 37.002H9.72c1.156-4.397 4.723-6.846 8.614-6.846s7.458 2.453 8.615 6.846h7.107zm-8.51 13.344a8.5 8.5 0 0 0-5.825 2.282l4.074-15.318c.39-1.464.966-1.93 1.755-1.93s1.364.466 1.755 1.93l4.069 15.318a8.52 8.52 0 0 0-5.829-2.282"
    />
  </svg>
);
export default SvgAlmaLogo;
