import { useQuery } from '@apollo/client';
import logoBzc from 'Assets/images/BzcLogo1.svg';
import { PageContainer } from 'Ui';
import styled from 'styled-components/macro';
import HeaderBasket from '../HeaderBasket';
import {
  getHeaderNavigation,
  getHeaderNavigationVariables,
} from '../__generated__/getHeaderNavigation';
import query from '../headerNavigationQuery';
import { MenuBar } from '../menuComponents';
import AccountMenu from './AccountMenu';
import AnonymousHeader from './AnonymousHeader';

import BannerApp from 'Components/BannerApp';
import ModalSearch from 'Components/modals/ModalSearch';
import { storageFactory } from 'Libs/storageFactory';
import AuthStore from 'Store/AuthStore';
import { theme } from 'Ui';
import { isAndroid, isIOS } from 'react-device-detect';

import { DeviceEnum, OsEnum } from '__generated__/graphql-global-types';
import { isMobile } from 'react-device-detect';

import { HeaderLinks, Link } from 'Ui/Link';

import { purgeVisibility } from 'Libs/Gtm/home';
import UiStore from 'Store/UiStore';
import WishListMenu from './WishListMenu';
import SearchInput from '../MobileHeader/SearchInput';
import { useEffect } from 'react';
import { getCookie } from 'Utils/cookie';
import { getUserType } from 'Utils/memberType';

// const DesktopHeaderHeight = 6.5;

// const HeaderStyled = styled.div`
//   background-color: white;
//   z-index: 10001;
//   position: relative;
//   padding-top: ${DesktopHeaderHeight}rem;
//   margin-bottom: 1.2rem;
// `;

const LogoStyled = styled.img`
  width: 100%;
  height: 100%;
`;

export const TopBar = styled.div`
  position: relative;
  display: flex;

  justify-content: space-between;
  height: 7.5rem;
  align-items: center !important;
  /* padding-bottom: 12px; */
`;

const FixBar = styled.header`
  position: sticky;
  top: 0;
  left: 0;
  width: 100vw;
  background-color: #fff;
  z-index: 10001;
  .addShadow {
    box-shadow: 0px 1px 9px 0px rgba(0, 0, 0, 0.1);
  }
`;

export default () => {
  useEffect(() => {
    const scrollShadow: any = document.getElementById('addShadowJs');
    const detectScroll = (event: any) => {
      const getScroll = event.currentTarget.scrollY;
      if (getScroll > 40) {
        scrollShadow.style.boxShadow = '0px 1px 9px 0px rgba(0, 0, 0, 0.1)';
      } else {
        scrollShadow.style.boxShadow = 'none';
      }
    };

    window.addEventListener('scroll', detectScroll);
    return () => {
      window.removeEventListener('scroll', detectScroll);
    };
  });
  const menuDevice: DeviceEnum = isMobile
    ? DeviceEnum.MOBILE
    : DeviceEnum.DESKTOP;
  const loggedIn = AuthStore.useStoreState((state) => state.isLoggedIn);
  const sessionMemberType = AuthStore.useStoreState(
    (state) => state.sessionMemberType
  );
  const cookieMobileAppPopup = UiStore.useStoreState(
    (state) => state.cookieMobileApp
  );
  const localStore = storageFactory(() => localStorage);

  const { data } = useQuery<getHeaderNavigation, getHeaderNavigationVariables>(
    query,
    {
      variables: { device: menuDevice, os: OsEnum.SITE },
    }
  );
  const links = (data && data.headerNavigation && data.headerNavigation) || [];
  // const home = '/accueil/index/' === window.location.pathname;
  // const homeType = 'ACCEUIL';

  const setPathHeaderMenu = UiStore.useStoreActions(
    (action) => action.setPathHeaderMenu
  );
  const setLinkType = UiStore.useStoreActions((action) => action.setLinkType);
  setPathHeaderMenu(links);

  const linksMenu = links.map((link, index) => {
    const urlLink = link.url;
    const linkUrlWithoutSlash = urlLink.slice(0, -1);
    const urlMatch =
      window.location.pathname === linkUrlWithoutSlash
        ? linkUrlWithoutSlash
        : link.url;
    return {
      label: link.label,
      url: urlMatch,
      color: link.color,
      alt: link.alt,
      type: link.type,
      target: link.target,
    };
  });

  const isLoggedIn =
    loggedIn || !!localStore.getItem('isLoggedIn') ? true : false;

  // if (!!localStore.getItem('isLoggedIn') === false) {
  //   return <AnonymousHeader />;
  // } else if (!!localStore.getItem('isLoggedIn') === false && !loggedIn) {
  //   return <AnonymousHeader />;
  // }

  return (
    <>
      {/* {!loggedIn && <AnonymousHeader />} */}

      {/* {isLoggedIn && ( */}
      <>
        <FixBar id="addShadowJs">
          {cookieMobileAppPopup && (isAndroid || isIOS) && (
            <BannerApp android={isAndroid} />
          )}

          <PageContainer>
            <TopBar>
              <a
                style={{
                  width: '25%',
                  display: 'flex',
                  justifyContent: 'space-around',
                  alignItems: 'flex-end',
                }}
                href="/"
              >
                <LogoStyled
                  width={230}
                  height={25}
                  src={logoBzc}
                  alt="BazarChic"
                />
              </a>
              <div
                style={{ width: '50%', margin: '0 6.8rem' }}
                data-tut="headerTuto_position"
                data-test="searchHeader"
                onClick={async () => (await ModalSearch) && ModalSearch({})}
              >
                <SearchInput
                  isLogin={true}
                  placeholder="Rechercher une vente, un produit ..."
                />
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  width: '15%',
                }}
              >
                <AccountMenu />
                <WishListMenu />
                <HeaderBasket />
              </div>
            </TopBar>
          </PageContainer>
        </FixBar>
        <div
          style={{
            boxShadow: '0px 1px 9px 0px rgba(0, 0, 0, 0.10)',
            marginBottom: '2rem',
          }}
        >
          <PageContainer>
            <MenuBar data-test="header-links" className="reactHomeMenuV4">
              {linksMenu
                .filter((link) => !!link.label)
                .map((link, index) => (
                  <div data-test={link.label} key={index}>
                    <HeaderLinks
                      to={link.url}
                      dataTest="header-link"
                      style={{ color: link.color }}
                      internal={false}
                      // activeClassName="active"
                      onClick={() => {
                        setLinkType(link.type.toUpperCase());
                        purgeVisibility();
                      }}
                    >
                      {link.label}
                    </HeaderLinks>
                  </div>
                ))}
            </MenuBar>
          </PageContainer>
        </div>
      </>
      {/* )} */}
    </>
  );
};
