import React from 'react';
import styled from 'styled-components/macro';
import { IconClose } from 'Ui/Icons';
import { theme } from 'Ui/theme';

interface Tag {
  value: string;
  label: string;
}

interface TagProps {
  /** the value that is displayed */
  label: string;
  /** function callback */
  onDelete?: () => void;
}

interface TagListProps {
  tags: Tag[];
  deleteAllButton?: boolean;
  onChange?: (tags: Tag[]) => void;
  onDeleteAll?: () => void;
  onDelete?: (tagValue: string) => void;
}

const TagStyled = styled.div`
  display: inline-flex;
  font-family: 'Montserrat', sans-serif;
  height: 3.5rem;
  background-color: ${theme.light.colors.white};
  font-size: 1.4rem;
  border: 1px solid ${theme.light.colors.gray};
`;

const DeleteAllButton = styled.div`
  font-family: 'MontserratSemiBold', sans-serif;
  display: inline-flex;
  padding: 0 1rem;
  margin-left: 0.5rem;
  height: 3.5rem;
  align-items: center;
  text-decoration: underline;
  font-size: 1.4rem;
  font-weight: 600;
  cursor: pointer;
  &:hover {
    color: ${theme.light.colors.purple};
  }
`;

const TagLabel = styled.div`
  padding: 1rem;
`;

const IconCloseStyled = styled.div`
  padding: 1rem 1rem;
  cursor: pointer;
  &:hover {
    color: ${theme.light.colors.purple};
  }
`;

export const Tag = ({ label, onDelete, ...rest }: TagProps) => {
  return (
    <TagStyled {...rest}>
      <TagLabel>{label.toUpperCase()}</TagLabel>
      {onDelete && (
        <IconCloseStyled onClick={onDelete}>
          <IconClose />
        </IconCloseStyled>
      )}
    </TagStyled>
  );
};

const TagListStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 1rem;
`;

const TagInList = styled(Tag)`
  margin: 0 1rem 1rem 0;
  align-items: center;
`;

export const TagList = ({
  tags,
  onChange,
  onDelete,
  onDeleteAll,
  deleteAllButton = false,
}: TagListProps) => {
  const onDeleteTag = (tagValue: string) => {
    const newTags = tags.filter((tag) => tag.value !== tagValue);
    onChange && onChange(newTags);
    onDelete && onDelete(tagValue);
  };

  return (
    <TagListStyled>
      {tags.map((tag) => (
        <TagInList
          key={tag.value}
          label={tag.label}
          onDelete={() => onDeleteTag(tag.value)}
        />
      ))}
      {deleteAllButton && (
        <DeleteAllButton onClick={onDeleteAll}>Tout supprimer</DeleteAllButton>
      )}
    </TagListStyled>
  );
};
