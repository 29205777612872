import * as React from "react";
const SvgCloseModal = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 21 20"
    {...props}
  >
    <path
      fill="#4A4A4A"
      fillRule="evenodd"
      d="M10.568 9.805 3.73 2.513l-.729.684 6.79 7.243-6.766 6.315.682.731 6.714-6.266 6.87 6.411.681-.73-6.766-6.316 3.224-3.439 3.566-3.804-.73-.684-3.566 3.804z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCloseModal;
