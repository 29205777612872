import { useApolloClient, useQuery } from '@apollo/client';
import HtmlHeader from 'Components/HtmlHead';
import { toast } from 'Components/Toaster/';
import { pageInit } from 'Libs/GA4/common';
import { getPageView, setGtmUserData } from 'Libs/GA4/common';
import { backgroundLogin } from 'Pages/Login/__generated__/backgroundLogin';
import { BACKGROUND_LOGIN } from 'Pages/Login/query';
import AuthStore from 'Store/AuthStore';
import { Button, Input, PageContainer, Spinner, theme } from 'Ui';
import { emailRegex } from 'Utils/login';
import { useEffect, useState } from 'react';
import useForm from 'react-hook-form';
import styled, { css } from 'styled-components/macro';
import {
  sendResetPasswordLink,
  sendResetPasswordLinkVariables,
} from './__generated__/sendResetPasswordLink';
import { FORGOT_PASSWORD } from './query';
import UiStore from 'Store/UiStore';

export type ForgotFormData = {
  email: string;
};

const Page = styled.div<{
  backgroundDesktop: string;
  backgroundMobile: string;
  webview: boolean;
}>`
  min-height: 80vh;
  padding: 2rem 0;
  display: flex;
  align-items: center;
  position: relative;
  background-color: rgba(0, 1, 17, 0.2);
  &:before {
    /* background-color: ; */
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    ${(props) =>
      props.webview &&
      css`
        height: 100vh;
      `}
    background-image: url(${(props) => props.backgroundDesktop});
    background-size: cover;
    background-position: center;
    @media screen and (max-width: 48rem) {
      background-image: url(${(props) => props.backgroundMobile});
    }
  }
  h1 {
    font-size: 2.5rem;
    text-align: center;
    margin: 2.5rem 0;
    position: relative;
    font-family: 'Montserrat';
    text-transform: uppercase;
    color: white;
  }
  .main-icon {
    margin: 0 auto;
    font-size: 6rem;
    color: white;
    display: block;
    position: relative;
  }
`;

const ForgotPasswordForm = styled.div`
  h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    font-family: 'Montserrat';
    /* padding-bottom: 0 0 5px 0; */
  }
  padding: 2rem;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 20px;
  position: relative;
`;

const Reset = styled.div`
  /* color: #6c6; */
  font-family: 'Montserrat';
  font-size: 1.2rem;
  margin-top: 2rem;
  p {
    margin-bottom: 1rem;
  }
  span {
    font-family: 'Montserrat';
    font-weight: 600;
  }
`;

const SpinnerStyled = styled.div`
  display: flex;
  justify-content: center;

  margin-top: 5rem;
  margin-bottom: 5rem;
`;

export default () => {
  const { register, handleSubmit, errors, formState } = useForm<ForgotFormData>(
    {
      mode: 'onChange',
    }
  );
  const client = useApolloClient();
  const webview = UiStore.useStoreState((state) => state.webview);

  const { data } = useQuery<backgroundLogin>(BACKGROUND_LOGIN);
  const member = AuthStore.useStoreState((state) => state.member);
  const sessionMemberType = AuthStore.useStoreState(
    (state) => state.sessionMemberType
  );
  const emailFromBuglogin = sessionStorage.getItem('bugLogin');
  const emailParsed = JSON.parse(emailFromBuglogin);

  const [loading, setLoading] = useState(false);
  const [resetInstruction, setResetInstruction] = useState(false);
  const [error, setError] = useState(null);
  const [emailFromStorage, setEmailFromStorageState] = useState(
    emailParsed ? emailParsed.email : ''
  );
  const [emailSend, setEmailSend] = useState('');
  const isLoggedIn = AuthStore.useStoreState((state) => state.isLoggedIn);

  const bgImage = data && data;
  const image: any = bgImage && bgImage.pageDatas;
  const urlBackgroundDesktop1x =
    image && image.loginBackgroundImages[0].urlBackgroundDesktop1x;
  const urlBackgroundMobile1x =
    image && image.loginBackgroundImages[0].urlBackgroundMobile1x;

  useEffect(() => {
    setGtmUserData(member, sessionMemberType);
    getPageView(window.location.pathname, window.location.href);
    pageInit();
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      window.location.href = '/';
    }
  }, [isLoggedIn]);

  const validating = (field: (typeof formState.touched)[0]) => {
    if (!field) return 'default';
    const typesErors = errors as { [index: string]: any };
    return typesErors[field]
      ? 'error'
      : formState.touched.includes(field)
      ? 'success'
      : 'default';
  };

  const onSubmit = handleSubmit((formData, e) => {
    e.preventDefault();
    setLoading(true);
    client
      .query<sendResetPasswordLink, sendResetPasswordLinkVariables>({
        query: FORGOT_PASSWORD,
        variables: formData,
      })
      .then((response) => {
        setResetInstruction(response.data?.sendResetPasswordLink);
        setEmailSend(formData.email);
        setLoading(false);
      })
      .catch((err) => {
        console.log({ err });
        const graphQLErrors =
          err &&
          err.graphQLErrors &&
          err.graphQLErrors[0].extensions.bzcMessage;

        const graphQLErrorCode =
          err && err.graphQLErrors && err.graphQLErrors[0].extensions.bzcCode;
        console.log({ graphQLErrorCode });
        if (graphQLErrorCode !== 'ERROR_SERVER_GENERIC') {
          setError(graphQLErrors);
        }

        if (graphQLErrorCode === 'ERROR_UNAUTHORIZED') {
          console.log('Error');
          setError(graphQLErrors);
          setLoading(false);
          toast.error('Requête non autorisée');
        }
      });
  });

  const ResetPasswordIntruction = (emailSend: string) => (
    <Reset>
      <p>
        Un lien de réinitialisation de votre mot de passe a été envoyé à
        l'adresse <span>{emailSend}</span>, il n’est valable que{' '}
        <span>30 minutes</span>.
      </p>
      <p>
        Vous n'avez pas reçu le mail ? Vérifiez que l'adresse email saisie est
        bien celle liée à votre compte
      </p>
    </Reset>
  );
  const initialValues = {
    email: emailParsed,
  };

  return (
    <Page
      backgroundDesktop={urlBackgroundDesktop1x}
      backgroundMobile={urlBackgroundMobile1x}
      webview={webview}
    >
      <HtmlHeader title={'Mot de passe oublié'} />
      <PageContainer style={{ maxWidth: '44rem' }}>
        {isLoggedIn ? (
          <SpinnerStyled>
            <Spinner size={50} color={theme.light.colors.purple} />
          </SpinnerStyled>
        ) : (
          <>
            <h1>Vous avez oublié votre mot de passe ?</h1>
            <ForgotPasswordForm>
              <h2>
                Saisissez votre adresse de connexion pour recevoir un mail de
                réinitialisation de mot de passe :
              </h2>
              <form onSubmit={onSubmit} noValidate>
                <Input
                  block
                  autoComplete="off"
                  type="email"
                  placeholder="E-mail"
                  name="email"
                  style={{ marginBottom: 10 }}
                  value={emailFromStorage ? emailFromStorage : ''}
                  initialValue={initialValues.email}
                  defaultValue={emailFromStorage}
                  kind={error ? 'error' : validating('email')}
                  ref={register({
                    required: 'Champ obligatoire',
                    pattern: {
                      value: emailRegex,
                      message: "L'adresse e-mail n'est pas valide",
                    },
                  })}
                  helperText={errors.email && errors.email.message}
                />
                <Button block type="submit" loading={loading}>
                  Valider
                </Button>
              </form>
              {resetInstruction &&
                ResetPasswordIntruction(emailSend ? emailSend : '')}
            </ForgotPasswordForm>
          </>
        )}
      </PageContainer>
    </Page>
  );
};
