/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ApplicationEnum {
  DESKTOP = "DESKTOP",
  MOBILE = "MOBILE",
}

export enum AppliedPriceEnum {
  GROUP = "GROUP",
  SIZE = "SIZE",
}

export enum BlocReferrerEnum {
  CROSS_SELL = "CROSS_SELL",
}

export enum CivilityEnum {
  MLLE = "MLLE",
  MME = "MME",
  MR = "MR",
  NONE = "NONE",
}

export enum ContactMethodEnum {
  MAIL = "MAIL",
  PHONE = "PHONE",
}

export enum DeviceEnum {
  DESKTOP = "DESKTOP",
  MOBILE = "MOBILE",
  PHONE = "PHONE",
  TABLET = "TABLET",
}

export enum ModuleEnum {
  APP = "APP",
  SITE = "SITE",
}

export enum OsEnum {
  ANDROID = "ANDROID",
  IOS = "IOS",
  SITE = "SITE",
}

export enum PageInformationKeyEnum {
  AGE = "AGE",
  APP_VERSION = "APP_VERSION",
  BEAUTY = "BEAUTY",
  CHILD = "CHILD",
  DATE = "DATE",
  DECO = "DECO",
  ENCODED_KEY = "ENCODED_KEY",
  EXECUTION_TIME = "EXECUTION_TIME",
  FLUX = "FLUX",
  GENDER = "GENDER",
  MAN = "MAN",
  MEMBER_ID = "MEMBER_ID",
  NORMAL = "NORMAL",
  OPERATING_SYSTEM = "OPERATING_SYSTEM",
  OS_VERSION = "OS_VERSION",
  OUTLET = "OUTLET",
  PATH = "PATH",
  PRODUCT_GROUP_ID = "PRODUCT_GROUP_ID",
  ROOT_ID = "ROOT_ID",
  SALE_ID = "SALE_ID",
  SCREEN_HEIGHT = "SCREEN_HEIGHT",
  SCREEN_WIDTH = "SCREEN_WIDTH",
  SEARCH = "SEARCH",
  SESSION_MEMBER_TYPE = "SESSION_MEMBER_TYPE",
  SHOES = "SHOES",
  SPECIAL = "SPECIAL",
  WINE = "WINE",
  WOMAN = "WOMAN",
}

export enum PageViewKeyEnum {
  MEMBER_ID = "MEMBER_ID",
  NORMAL = "NORMAL",
}

export enum PaymentsMethod {
  ACCORD_3X = "ACCORD_3X",
  ACCORD_4X = "ACCORD_4X",
  ALMA = "ALMA",
  AMEX = "AMEX",
  BANCONTACT = "BANCONTACT",
  BUYSTER = "BUYSTER",
  CB = "CB",
  CHEQUE = "CHEQUE",
  MASTERCARD = "MASTERCARD",
  PAYPAL = "PAYPAL",
  VISA = "VISA",
}

export enum ServiceEnum {
  SHIPPING_FEES = "SHIPPING_FEES",
  SMS = "SMS",
  VIP = "VIP",
}

export enum SortEnum {
  PRICE_ASC = "PRICE_ASC",
  PRICE_DESC = "PRICE_DESC",
  RELEVANCE = "RELEVANCE",
  SALES_QUANTITY_DESC = "SALES_QUANTITY_DESC",
}

export enum VersionPopinEnum {
  V1 = "V1",
  V2 = "V2",
}

export interface DeviceInput {
  deviceId?: string | null;
  screenType?: string | null;
  screenWidth?: string | null;
  screenHeight?: string | null;
  os?: OsEnum | null;
  versionApp?: string | null;
  versionOs?: string | null;
  deviceModel?: string | null;
  locale?: string | null;
}

export interface EnvironmentInput {
  application?: ApplicationEnum | null;
  module: ModuleEnum;
}

export interface FacetInput {
  name: string;
  options?: string[] | null;
}

export interface FiltersInput {
  withVipProducts?: boolean | null;
}

export interface NewsletterPreferencesInput {
  newsletterId: string;
  status: boolean;
}

export interface PageInformationInput {
  key: PageInformationKeyEnum;
  value: string;
}

export interface PageViewInput {
  key: PageViewKeyEnum;
  value: string;
}

export interface PaginationInput {
  after?: string | null;
  first?: number | null;
}

export interface RegistrationInput {
  civility?: CivilityEnum | null;
  lastName: string;
  firstName: string;
  email: string;
  password: string;
  birthDate?: any | null;
  countryId?: number | null;
  partnerOffers?: boolean | null;
}

export interface SuspectRegistrationInput {
  email: string;
}

export interface UnsubscribeInput {
  unsubscribeId: string;
  unsubscribeReason?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
