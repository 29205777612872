import CardSales from 'Components/CardSales';
import { theme } from 'Ui';
import { createUrlParams } from 'Utils/imageParams';
import Media from 'react-media';
import styled from 'styled-components';
import { BestSalesCarousel } from './BestSalesCarousel';

const BestSalesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`;

const Title = styled.div`
  color: ${theme.v2.black};
  font-family: 'AlbraSemiBold' !important;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const BestSalesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
`;

const SaleImgContainer = styled.div`
  border-radius: 3px;
  width: 123px;
  height: 123px;
  img {
    width: 123px;
    height: 123px;
  }
`;

export const BestSales = ({ bestSales }: any) => {
  const imageSize = {
    carousel: {
      width: 321,
      height: 323,
    },
  };
  return (
    <BestSalesContainer>
      <Title className="fontGranville">Nos meilleures ventes</Title>
      <Media query={{ maxWidth: 480 }}>
        <BestSalesCarousel
          isSearch={true}
          sales={bestSales && bestSales.slice(0, 6)}
        />
      </Media>
      <Media query={{ minWidth: 481 }}>
        <BestSalesGrid>
          {bestSales &&
            bestSales.slice(0, 6).map((sale: any) => {
              const saleImage = `${sale.coverLogoUrl}?params=${createUrlParams(
                imageSize.carousel
              )}`;
              return (
                <SaleImgContainer>
                  <CardSales
                    image={saleImage}
                    isLastingSale={false}
                    alt={sale.name}
                    to={`/ventes/cata/id/${sale.id}/` || ''}
                  />
                </SaleImgContainer>
              );
            })}
        </BestSalesGrid>
      </Media>
    </BestSalesContainer>
  );
};
