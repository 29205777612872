import { useEffect, useRef, useState } from 'react';
import UiStore from 'Store/UiStore';
import styled from 'styled-components/macro';
import { Button, Input, theme } from 'Ui';
import { IconFacebook } from 'Ui/Icons';
// @ts-ignore
import { useApolloClient } from '@apollo/client';
import {
  registerMemberFacebook,
  registerMemberFacebookVariables,
} from 'Components/RegistrationForm/__generated__/registerMemberFacebook';
import { REGISTER_MEMBER_FACEBOOK } from 'Components/RegistrationForm/query';
import { logScreenName, signInApp, trackSignInAdjust } from 'Libs/Gtm/GtmApp';
import ReactFacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import useForm from 'react-hook-form';
import AuthStore from 'Store/AuthStore';
import { toast } from 'Components/Toaster';
import { numberOfSession } from 'Utils/cookie';
import { bugLogin, emailRegex, login, redirect } from 'Utils/login';
import { addEmailAndPasswordForConnectionApp } from 'Utils/webview/utilsSession';

type FormData = {
  email: string;
  password: string;
};

const LoginConnectTitleGroup = styled.div`
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  h1 {
    margin-top: 0 !important;
  }
`;

const BackToLogin = styled.div`
  text-align: left;
  position: relative;
  top: 2.5rem;
  left: 1.5rem;
  svg {
    font-size: 2.7rem;
  }
  svg path {
    stroke-width: 3px;
  }
`;

const LoginConnectPage = styled.div`
  padding: 0rem 2rem 2rem;
  margin-top: -2.5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  .separation {
    border-left: 1px solid #ffffff;
    height: 2.5rem;
    margin: 1rem 1rem 1rem -0.5rem;
  }
`;

const LoginConnectTitle = styled.h1`
  font-family: 'granville-bold';
  font-size: 3.9rem;
  text-align: center;
  padding: 0 2rem;
  color: ${theme.light.colors.purple};
  font-weight: 700;
`;

const LoginConnectWelcome = styled.div`
  font-family: 'MontserratSemiBold';
  font-size: 1.6rem;
  text-align: center;
  margin-top: 1rem;
  color: ${theme.light.colors.grayDark};
  font-weight: 600;
`;

const LoginConnectForgotPassWord = styled.div`
  font-family: 'Montserrat';
  font-size: 1.4rem;
  text-decoration: underline;
  text-align: center;
  font-weight: 400;
  padding: 5px 0;
  margin: 0rem 0 2rem;
`;

const LoginConnectHelp = styled.div`
  font-family: 'MontserratSemiBold';
  font-size: 1.2rem;
  color: ${theme.light.colors.grayDark};
  font-weight: 600;
  margin-bottom: 1.5rem;
  a {
    color: ${theme.light.colors.purple};
    text-decoration: underline;
  }
`;

const LoginConnectEndPara = styled.div`
  font-family: 'Montserrat';
  font-size: 1.2rem;
  margin-top: 2rem;
`;

const LoginConnectImg = styled.div`
  margin: 20px auto 0;
  max-width: 16rem;
  img {
    max-width: 100%;
    height: auto;
  }
`;

const StyledForm = styled.div`
  padding: 1.5rem;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9);
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Error = styled.div`
  margin-top: 8px;
  margin-left: 7px;
  color: ${theme.light.colors.error};
  font-size: 14px;
`;

export default () => {
  // Get the prefill Values to connect
  const inputRef: any = useRef();
  const passwordRef: any = useRef();
  const apolloClient = useApolloClient();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const cookieMarketingStore = UiStore.useStoreState(
    (state) => state.cookieMarketing
  );
  const webview = UiStore.useStoreState((state) => state.webview);
  const setAuth = AuthStore.useStoreActions((actions) => actions.setAuth);

  const { register, handleSubmit, errors, formState } = useForm<FormData>({
    mode: 'onChange',
  });

  const validating = (field: (typeof formState.touched)[0]) => {
    if (!field) return 'default';
    const typesErors = errors as { [index: string]: any };
    return typesErors[field]
      ? 'error'
      : formState.touched.includes(field)
      ? 'success'
      : 'default';
  };

  const connectMemberWithFacebook = async (data: any) => {
    const accessToken: string = data.accessToken;
    setLoading(true);
    try {
      const response = await apolloClient.mutate<
        registerMemberFacebook,
        registerMemberFacebookVariables
      >({
        mutation: REGISTER_MEMBER_FACEBOOK,
        variables: {
          accessToken,
        },
      });

      if (response.data && response.data.registerMemberFacebook) {
        const data = response.data.registerMemberFacebook;
        if (data.member) setAuth(data);
      }

      if (webview && data.member && cookieMarketingStore) {
        // login_type email
        signInApp('Facebook', window);
        trackSignInAdjust(data.member.id, window);
        logScreenName('Login', window);
      }

      numberOfSession();
      redirect();
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const onSubmit = handleSubmit((formData, e) => {
    e.preventDefault();

    login(formData, apolloClient)
      .then((data) => {
        if (data) {
          const loginData = data;
          if (loginData.member) {
            setAuth(loginData);
            const member = loginData && loginData.member;
            if (webview && member && cookieMarketingStore) {
              // login_type email
              signInApp('email', window);
              trackSignInAdjust(member.id, window);
              logScreenName('Login', window);
            }

            if (webview && member) {
              addEmailAndPasswordForConnectionApp(
                formData.email,
                formData.password
              );
            }
            // window.localStorage.setItem('loginType', 'BZC');
            redirect();
          }
          if (sessionStorage.getItem('bugLogin')) {
            sessionStorage.removeItem('bugLogin');
          }
          numberOfSession();
        }
      })
      .catch((err) => {
        const graphQLErrors =
          err &&
          err.graphQLErrors &&
          err.graphQLErrors[0].extensions.bzcMessage;
        const graphQLErrorCode =
          err && err.graphQLErrors && err.graphQLErrors[0].extensions.bzcCode;
        if (
          graphQLErrorCode !==
          ('ERROR_SERVER_GENERIC' || 'ERROR_TOO_MANY_REQUEST')
        ) {
          setError(graphQLErrors);
        }
        if (graphQLErrorCode === 'ERROR_TOO_MANY_REQUEST') {
          // setError(graphQLErrors);
          toast.error('Requête non autorisée');
        }
        bugLogin(formData);
        setLoading(false);
      });
  });

  useEffect(() => {}, [error]);

  return (
    <>
      <LoginConnectPage>
        <form onSubmit={onSubmit}>
          <StyledForm>
            <Input
              style={{ margin: '0 auto 0', maxWidth: '33.3rem ' }}
              block
              autoComplete="off"
              type="email"
              placeholder="E-mail"
              className="fontMontserrat"
              name="email"
              initialValue={inputRef}
              kind={error ? 'error' : validating('email')}
              ref={register({
                required: 'Champ obligatoire',
                pattern: {
                  value: emailRegex,
                  message: "L'adresse e-mail n'est pas valide",
                },
              })}
              helperText={errors.email && errors.email.message}
            />

            <Input
              style={{ margin: '1.5rem auto 0', maxWidth: '33.3rem ' }}
              block
              autoComplete="off"
              placeholder="Mot de passe"
              type="password"
              name="password"
              className="fontMontserrat"
              initialValue={passwordRef}
              ref={register({
                required: 'Champ obligatoire',
              })}
              kind={
                error
                  ? 'error'
                  : errors.password && errors.password.message
                  ? 'error'
                  : 'default'
              }
              helperText={errors.password && errors.password.message}
            />
            {error && <Error>{error}</Error>}
          </StyledForm>

          <LoginConnectForgotPassWord>
            <a href="/login/emailForResetPassword">Mot de passe oublié ?</a>
          </LoginConnectForgotPassWord>
          <ButtonGroup>
            <Button
              style={{
                marginBottom: '2rem',
                maxWidth: '85%',
                width: '30.7rem',
                fontSize: '1.6rem',
              }}
              block
              className="fontMontserratSemiBold"
              type="submit"
            >
              Accéder aux ventes
            </Button>
            {cookieMarketingStore && (
              <ReactFacebookLogin
                appId={process.env.REACT_APP_FACEBOOK_ID || ''}
                callback={connectMemberWithFacebook}
                isMobile={false}
                render={(props: any) => (
                  <Button
                    style={{
                      marginBottom: '2rem',
                      maxWidth: '85%',
                      width: '30.7rem',
                      fontSize: '1.6rem',
                      backgroundColor: 'rgb(51, 103, 152)',
                      border: '1px solid rgb(51, 103, 152)',
                    }}
                    onClick={props.onClick}
                    hollow
                    className="fontMontserratSemiBold"
                    loading={loading}
                    color="#FFFFFF"
                    icon={<IconFacebook />}
                    block
                  >
                    <div className="separation"></div>
                    Se connecter
                  </Button>
                )}
              />
            )}
          </ButtonGroup>
        </form>
        {/* <LoginConnectHelp>
          Pas encore membre ? <a href="/?anchor=inscription">Créer un compte</a>
        </LoginConnectHelp>
        <LoginConnectHelp>
          Vous avez besoin d'aide ?{' '}
          <a href="/contact/question/">Contactez-nous</a>
        </LoginConnectHelp>
        <LoginConnectEndPara>
          Simple, rapide, découvrez notre application :
        </LoginConnectEndPara> */}
        {/* <LoginConnectImg>
          {isAndroid && (
            <a href="https://app.adjust.com/yopby0g?engagement_type=fallback_click">
              <img
                src="https://cdn.bazarchic.com/front/images/appLogo/icon-GooglePlay.png"
                alt="LogoGooglePlay"
              />
            </a>
          )}
          {isIOS && (
            <a href="https://itunes.apple.com/fr/app/bazarchic/id575920978?mt=8">
              <img
                src="https://cdn.bazarchic.com/front/images/appLogo/icon-AppStore.png"
                alt="logoAppleStore"
              />
            </a>
          )}
        </LoginConnectImg> */}
      </LoginConnectPage>
    </>
  );
};
