import React, { useState } from 'react';
import { createModal } from 'react-modal-promise';
import { Modal, Button, Checkbox } from 'Ui';
import styled from 'styled-components/macro';
import Cookies from 'js-cookie';
import UiStore from 'Store/UiStore';
import { registerUserRgpdBehaviou } from 'Libs/trackingInternal';
import { useApolloClient } from '@apollo/client';
import RgpBannerModal from './RgdpBannerModal';

import { DeviceEnum } from '__generated__/graphql-global-types';
import { isMobile } from 'react-device-detect';
import { setCookie } from 'Utils/cookie';
import { getPrivacyChoice } from 'Libs/GA4/common';

type ModalProps = {
  open: boolean;
  close: (shouldGoToCheckout: boolean) => void;
  disabledOutsideClickHandler?: boolean;
  external?: boolean;
};

const RgpdModalBody = styled.div`
  flex: 1;
  display: flex;
  font-family: 'Montserrat';
  flex-direction: column;
  font-size: 1.4rem;
`;

const RgpdModalDesc = styled.p`
  margin-bottom: 30px;
  line-height: 1.9rem;
`;

const RgpdBodyTitle = styled.div`
  margin-bottom: 10px;
  font-size: 16px;
  font-family: 'MontserratBold';
`;

const RgpdModalLabel = styled.div`
  p {
    padding: 0.2rem 0 0 4rem;
    line-height: 1.9rem;
  }

  .marginCheckbox {
    margin-top: 2rem;
  }
  span {
    text-transform: uppercase;
    align-self: center;
  }
`;

const RgpdModal = ({
  open,
  close,
  disabledOutsideClickHandler,
  external,
}: ModalProps) => {
  const cookiePerformance = Cookies.get('_performance') ? true : false;
  const cookieMarketing = Cookies.get('_marketing') ? true : false;
  const setDisclaimer = UiStore.useStoreActions(
    (action) => action.setDisclaimer
  );

  const consultedSales = UiStore.useStoreActions(
    (action) => action.setConsultedSales
  );

  const setCookieMarketingStore = UiStore.useStoreActions(
    (action) => action.setCookieMarketing
  );
  const setGtmConsentMode = UiStore.useStoreActions(
    (action) => action.setGtmConsentMode
  );

  const setAcceptedAndRefusedCookieReload = UiStore.useStoreActions(
    (action) => action.setAcceptedAndRefusedCookieReload
  );

  const disclaimer = () => {
    if (!!Cookies.get('majeur') === false) {
      setDisclaimer(true);
    }
  };
  const [performance, setPerformance] = useState(cookiePerformance);
  const [marketing, setMarketing] = useState(cookieMarketing);
  const configCookie = { domain: '.bazarchic.com', expires: 180, secure: true };
  const apolloClient = useApolloClient();
  const deviceType = isMobile ? DeviceEnum.MOBILE : DeviceEnum.DESKTOP;

  const registerRGPD = (sdkPerf: boolean, sdkMarket: boolean) => {
    const btnPersonnalize = true;
    const btnAccept = false;
    const btnDeny = false;
    const linkPvp = !!localStorage.getItem('linkPvp');
    registerUserRgpdBehaviou(
      apolloClient,
      sdkPerf,
      sdkMarket,
      btnPersonnalize,
      btnAccept,
      btnDeny,
      linkPvp,
      deviceType
    );
  };

  const RGPDValidate = () => {
    disclaimer();
    if (performance) {
      const objectCookie = {
        path: '/',
        value: '1',
        name: '_performance',
        domain: '.bazarchic.com',
        expires: 180,
        secure: true,
      };
      setCookie(objectCookie);
      // Cookies.set('_performance', '1', configCookie);
    } else {
      Cookies.remove('_performance', configCookie);
    }
    if (marketing) {
      const objectCookie = {
        path: '/',
        value: '1',
        name: '_marketing',
        domain: '.bazarchic.com',
        expires: 180,
        secure: true,
      };
      setCookie(objectCookie);
      // Cookies.set('_marketing', '1', configCookie);
    } else {
      Cookies.remove('_marketing', configCookie);
      Cookies.remove('sPagesHistoryV2', configCookie);
      Cookies.remove('sProductsHistoryV2', configCookie);
      Cookies.remove('birthdayReminder', configCookie);
      Cookies.remove('site', configCookie);
      consultedSales([]);
    }
    Cookies.set('RgpdBanner', '1', configCookie);
    registerRGPD(performance, marketing);
    setCookieMarketingStore(marketing);
    setAcceptedAndRefusedCookieReload(true);
    if (!!localStorage.getItem('linkPvp')) {
      localStorage.removeItem('linkPvp');
    }
    if (external) {
      setTimeout(function () {
        window.location.reload();
      }, 1000);
    }
  };

  const onClose = () => {
    if (!Cookies.get('RgpdBanner')) {
      {
        if (!disabledOutsideClickHandler) {
          RgpBannerModal({});
        }
      }
    }
    close(false);
  };

  return (
    <Modal
      disabledOutsideClickHandler={disabledOutsideClickHandler}
      isOpen={open}
      onClose={() => onClose()}
      size="large"
      title="PARAMÉTRAGE DES COOKIES"
      actions={
        <>
          <Button
            kind="primary"
            onClick={() => {
              RGPDValidate();
              setGtmConsentMode('update');
              sessionStorage.setItem('consent', 'oui');
              setTimeout(getPrivacyChoice, 3000);
              close(true);
            }}
          >
            Valider
          </Button>
        </>
      }
    >
      <RgpdModalBody>
        <RgpdBodyTitle>Vos cookies de navigation sur BazarChic</RgpdBodyTitle>
        <RgpdModalDesc>
          BazarChic utilise des cookies afin de vous proposer une expérience
          shopping enrichie et personnalisée. Certains cookies sont nécessaires
          pour assurer le fonctionnement optimal du site et sont activés en
          permanence. Ils vous permettent de naviguer en toute sécurité et
          d’accéder aux différentes catégories, produits et services du site.
        </RgpdModalDesc>
        <RgpdBodyTitle>Vos paramètre de cookies</RgpdBodyTitle>
        <div>
          <RgpdModalLabel onClick={() => setPerformance(!performance)}>
            <Checkbox
              size={2}
              sizeCheck={1}
              width={1.6}
              height={1.6}
              checked={performance}
              onChange={() => {}}
              label={'les cookies performance'}
            />
            <p>
              Ces cookies servent à mesurer et analyser l’utilisation faite du
              site afin d’en améliorer l’ergonomie. En acceptant ces cookies,
              vous contribuez à l’amélioration de l’expérience client sur
              BazarChic.
            </p>
          </RgpdModalLabel>

          <RgpdModalLabel onClick={() => setMarketing(!marketing)}>
            <Checkbox
              className="marginCheckbox"
              size={2}
              sizeCheck={3}
              width={1.6}
              height={1.6}
              checked={marketing}
              onChange={() => {
                setMarketing(!marketing);
              }}
              label={'les cookies Marketing'}
            />
            <p>
              Ces cookies nous permettent de vous recommander des offres qui
              répondent au mieux à vos attentes et préférences.
            </p>
            <p>
              Si vous désactivez ces cookies, des publicités apparaîtront mais
              ne tiendront néanmoins pas compte de vos centres d’intérêts et
              seront donc moins pertinentes.
            </p>
          </RgpdModalLabel>
        </div>
      </RgpdModalBody>
    </Modal>
  );
};

export default createModal(RgpdModal);
