import styled, { createGlobalStyle } from "styled-components/macro";

export const GlobalStyle = createGlobalStyle`
    html {
        font-size: 62.5%;
    }
    body {
      width : 100vw;
      overflow-x : hidden;
      overflow-y : auto;
      margin: 0;
    }
`;

export const AppStyled = styled.div`
  color: #000;
  font-size: 1rem;
  ol,
  ul {
    list-style: none;
  }
  @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap");
  font-family: "Open Sans", sans-serif;
  a {
    color: inherit;
    text-decoration: inherit;
  }
  button {
    outline: none;
  }
  * {
    box-sizing: border-box;
    font-family: inherit;
  }
`;
