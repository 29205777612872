import gql from "graphql-tag";
import { GtmInfo } from "Fragments/GtmInfo";

export const TOKEN_FROM_SESSIONID = gql`
  query login($sessionId: String!) {
    createTokenFromSessionId(sessionId: $sessionId) {
      jwt
      sessionMemberType
      isCompletedProfile
      member {
        id
        isVip
        email
        firstName
        lastName
        phoneNumber
        ...GtmInfo
      }
    }
  }
  ${GtmInfo}
`;


export const IS_INTERNAL = gql`
  query isInternal{
    isInternal
  }
`;

export const DEVICE_CONFIGURATION = gql`
query Configuration($device: DeviceEnum!, $site: OsEnum!) {
  deviceConfiguration(device:$device, os:$site){
    showOutletBloc
  }
}
`;

export const SET_ANONYMOUS_SESSION = gql`
  mutation setAnonymousSession {
    createAnonymousSession {
      jwt
      sessionId
      isConnected
      member {
        id
        isVip
        email
        firstName
        lastName
        phoneNumber
        ...GtmInfo
      }
    }
  }
  ${GtmInfo}
`;
