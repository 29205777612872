import React from 'react';
import styled, { css } from 'styled-components/macro';
import { theme } from 'Ui/theme';

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div<{ size: number }>`
  width: ${(props) => props.size && `${props.size}rem`};
  min-width: ${(props) => props.size && `${props.size}rem`};
  height: ${(props) => props.size && `${props.size}rem`};
  border-radius: 3px;
  margin-right: 1rem;
  box-sizing: border-box;
  border: 1px solid ${theme.light.colors.grayDark};
  color: #999;
  margin-bottom: 0.5rem;
`;

const CheckStyled = styled.div<{ padding?: boolean }>`
  position: relative;
  ${(props) =>
    props.padding &&
    css`
      padding-top: 0.5rem;
    `}
`;

const StyledCheck = styled.div<{
  width?: any;
  height?: any;
  padding?: boolean;
}>`
  background: ${theme.light.colors.purpleLight};
  width: ${(props) => props.width && `${props.width}rem`};
  height: ${(props) => props.height && `${props.height}rem`};
  /* height: 0.8rem; */
  display: none;
  position: absolute;
  border-radius: 3px;
  top: ${(props) => (props.padding ? '7px' : '2px')};
  left: 2px;
`;

const CheckboxContainer = styled.div<{ align?: string; alignLeft?: boolean }>`
  cursor: pointer;
  display: flex;
  margin-bottom: 0.5rem;
  align-items: ${(props) => (props.align ? 'normal' : 'center')};
  /* align-items: center; */
  position: relative;
  &:hover {
    ${StyledCheckbox} {
      color: #222;
    }
  }
  ${HiddenCheckbox}:checked {
    & ~ ${StyledCheck} {
      display: block;
      border-color: ${theme.light.colors.purpleLight};
      color: #66cc66;
    }
    & ~ ${StyledCheckbox} {
      border-color: ${theme.light.colors.purpleLight};
    }
  }

  ${(props) =>
    props.alignLeft &&
    css`
      justify-content: space-between;
    `}
`;

const StyledLabel = styled.span<{ fontSize?: number }>`
  color: ${theme.light.colors.grayUltraDark};
  font-size: ${(props) => props.fontSize}rem;
  font-family: 'MontserratMedium', sans-serif;
  font-weight: 500;
  margin-bottom: 0.5rem;
  a {
    border-bottom: 1px solid;
  }
`;

type CheckboxProps = Omit<React.HTMLProps<HTMLInputElement>, 'label'> & {
  className?: any;
  checked?: boolean;
  label: string | React.ReactNode;
  onChange?: () => void;
  size?: number;
  sizeCheck?: number;
  align?: any;
  padding?: boolean;
  alignLeft?: boolean;
  fontSize?: number;
};

export const Checkbox = React.forwardRef(
  (
    {
      className,
      checked,
      label,
      type,
      onChange,
      style,
      size = 1.2,
      sizeCheck = 0.3,
      align,
      padding,
      width = 0.8,
      height = 0.8,
      alignLeft,
      fontSize = 1.4,
      ...rest
    }: CheckboxProps,
    parentRef
  ) => {
    const onCheckBoxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange && onChange();
    };
    let checkBoxElement: any;
    return (
      <CheckboxContainer
        align={align}
        className={className}
        onClick={() => {
          checkBoxElement && checkBoxElement.click();
        }}
        style={style}
        alignLeft={alignLeft}
      >
        {alignLeft && <StyledLabel fontSize={fontSize}>{label}</StyledLabel>}
        <CheckStyled padding={padding}>
          <HiddenCheckbox
            checked={checked}
            {...rest}
            onChange={onCheckBoxChange}
            as="input"
            ref={(ref: any) => {
              checkBoxElement = ref;
              /** We want to access to the ref but also to have parent components access it too */
              // @ts-ignore
              parentRef && parentRef(ref);
            }}
            className="checkedInput"
          />
          <StyledCheck padding={padding} width={width} height={height} />
          <StyledCheckbox size={size} />
        </CheckStyled>
        {!alignLeft && <StyledLabel fontSize={fontSize}>{label}</StyledLabel>}
      </CheckboxContainer>
    );
  }
);
