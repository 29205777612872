import * as React from "react";
const SvgMyInfos = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.04 3.869a2.63 2.63 0 1 0 0 5.26 2.63 2.63 0 0 0 0-5.26m-3.63 2.63a3.63 3.63 0 1 1 7.261 0 3.63 3.63 0 0 1-7.26 0M1.15 20.033c0-4.6 3.814-8.296 8.469-8.296h2.844c4.655 0 8.468 3.695 8.468 8.295v.69c0 1.142-.954 2.032-2.065 2.032H3.216c-1.12 0-2.066-.88-2.066-2.031zm8.469-7.296c-4.125 0-7.469 3.27-7.469 7.295v.69c0 .57.468 1.032 1.065 1.032h15.651c.588 0 1.065-.471 1.065-1.031v-.69c0-4.026-3.343-7.296-7.468-7.296zM17.3 5.873a.626.626 0 1 0 0 1.252h5.222a.626.626 0 0 0 0-1.252zm-1.773-1.878c0-.346.28-.626.626-.626h6.26a.626.626 0 0 1 0 1.252h-6.26a.626.626 0 0 1-.626-.626m.626 4.382a.626.626 0 0 0 0 1.252h3.756a.626.626 0 1 0 0-1.252z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgMyInfos;
