import * as React from "react";
const SvgTwoCol = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path fill="#FFF" fillRule="nonzero" d="M0 0h32v32H0z" />
      <g fill="currentColor" fillRule="nonzero">
        <path d="M6 6h9v9H6zM17 6h9v9h-9zM17 17h9v9h-9zM6 17h9v9H6z" />
      </g>
    </g>
  </svg>
);
export default SvgTwoCol;
