import * as React from "react";
const SvgRetoursretour = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 66 66"
    {...props}
  >
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.5}
      d="M53.596 22.062a.9.9 0 0 0-.088-.736l-4.914-7.724c-.538-.836-2.176-1.435-3.026-1.435H20.42c-.85 0-2.5.599-3.026 1.435l-4.914 7.724a.86.86 0 0 0-.088.736 2.14 2.14 0 0 0-.225.924v28.726c0 1.173.95 2.121 2.126 2.121h37.414a2.12 2.12 0 0 0 2.126-2.12V22.985a2.4 2.4 0 0 0-.237-.924Zm-6.528-7.5 4.015 6.303H38.077l-1.125-6.914h8.616c.4.013 1.325.412 1.5.612Zm-17.507 8.112h6.84v4.105h-6.84zm.163-1.797 1.125-6.913h4.277l1.126 6.913zm-10.83-6.302c.188-.224 1.088-.624 1.513-.624h8.616l-1.125 6.914H14.892zm33.126 37.15a.33.33 0 0 1-.325.324H14.28a.33.33 0 0 1-.325-.325V23a.33.33 0 0 1 .325-.325h13.493v5.004c0 .5.4.898.9.898h8.641c.5 0 .9-.399.9-.898v-5.004h13.494a.33.33 0 0 1 .325.325v28.726z"
    />
    <path
      fill="url(#retoursretour_svg__a)"
      d="m26.235 39.994 3.903-4.017c1.078-1.13 2.696.565 1.617 1.695l-1.317 1.375c-.3.314-.203.567.216.567h7.121c.63 0 1.144.537 1.144 1.199 0 .661-.512 1.198-1.144 1.198h-7.12c-.42 0-.516.254-.216.567l1.318 1.375c1.078 1.13-.54 2.825-1.618 1.696l-3.899-3.976a1.207 1.207 0 0 1-.005-1.68"
    />
    <defs>
      <linearGradient
        id="retoursretour_svg__a"
        x1={25.898}
        x2={38.919}
        y1={40.813}
        y2={40.813}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6C4DFF" />
        <stop offset={1} stopColor="#8268FF" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgRetoursretour;
