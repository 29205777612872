import styled from 'styled-components';
import { theme } from 'Ui';
import { CheckMark } from 'Ui/Icons/icons';

const SuccesParagraph = styled.div`
  text-align: center;
`;

const SuccessMessage = styled.div`
  color: #fff;
  font-size: 1.2rem;
  font-family: 'MontserratMedium';
  line-height: 1.9rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
`;

const SuccessInfos = styled.div`
  color: black;
  font-size: 1.2rem;
  font-family: 'MontserratLight';
  line-height: 1.8rem;
`;

const SuccessIcon = styled.div`
  background-color: ${theme.light.colors.white};
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: 1rem auto; */
  margin-right: 1rem;
  svg path {
    stroke: ${theme.light.colors.purple};
  }
  svg {
    font-size: 1rem;
  }
`;

type OptinMessagProps = {
  emailOptin?: string;
};
const OptinSuccessMessage = ({ emailOptin }: OptinMessagProps) => (
  <SuccesParagraph>
    <SuccessMessage>
      <SuccessIcon>
        <CheckMark />
      </SuccessIcon>
      <div>Merci !</div>
      <div
        style={{ paddingLeft: '0.2rem', fontFamily: 'Montserrat !important' }}
      >
        Vous êtes bien inscrit.
      </div>
    </SuccessMessage>
    {/* <SuccessInfos>
      <div>Vous recevrez votre code de bienvenue</div>
      <div>sur votre boîte mail :</div>
      <div>{emailOptin}</div>
    </SuccessInfos> */}
  </SuccesParagraph>
);

export default OptinSuccessMessage;
