import * as React from "react";
const SvgCoeur = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 90.26 81.93"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      d="M88.24 25.27c.01-.3.02-.59.02-.89C88.26 12.39 78.15 2 65.67 2 56.55 2 48.7 7.87 45.13 15.35h0C41.56 7.87 33.71 2 24.59 2 12.12 2 2 12.39 2 24.38c0 .3.01.6.02.89h0S.74 39.51 16.59 55.06c7.21 7.22 15.27 14.08 28.53 24.86 13.27-10.78 21.32-17.64 28.53-24.86C89.5 39.51 88.22 25.27 88.22 25.27Z"
      style={{
        fill: "none",
        stroke: "#232929",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: 4,
      }}
    />
  </svg>
);
export default SvgCoeur;
