import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { Button } from 'Ui';
import { IconClose } from 'Ui/Icons';
import { setCookie, getCookie } from 'Utils/cookie';

const PageContainerPreHeaderStyled = styled.div<{ isDisplay: boolean }>`
  display: ${(props) => (props.isDisplay ? 'flex;' : 'none;')};
  background: #f2f2f3;
  min-height: 10rem;
  padding: 2rem 2rem;
  justify-content: center;
  font-family: 'Montserrat';
  line-height: 1.2;
`;

const PreHeaderStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TitleStyled = styled.div`
  text-transform: uppercase;
  font-size: 1.3rem;
  font-family: 'MontserratSemiBold';
  text-align: center;
  @media screen and (min-width: 768px) {
    font-size: 1.5rem;
  }
`;

const DescriptionStyled = styled.div`
  font-size: 1.3rem;
  font-weight: 500;
  margin: 1rem 0rem;
  text-align: center;
`;

const IconCloseStyled = styled.div`
  font-size: 1.5rem;
  position: absolute;
  right: 4px;

  @media screen and (min-width: 768px) {
    font-size: 2rem;
    cursor: pointer;
    right: 4rem;
  }

  @media screen and (min-width: 965px) {
    font-size: 2rem;
    cursor: pointer;
    right: 10rem;
  }
`;

export default () => {
  const [isPreheader, setIsPreheader] = useState(
    !getCookie('preheader_wremail')
  );
  const redirecTO = () => {
    window.location.href = '/account/index/';
    addCookie();
  };

  const addCookie = () => {
    const objectCookie = {
      path: '/',
      expires: 1,
      secure: true,
      value: 'oui',
      name: 'preheader_wremail',
    };
    setCookie(objectCookie);
  };

  return (
    <PageContainerPreHeaderStyled isDisplay={isPreheader}>
      <PreHeaderStyled>
        <TitleStyled>Votre adresse e-mail n'est pas valide</TitleStyled>
        <DescriptionStyled>
          Vous ne recevez pas vos suivis de commandes, les nouvelles ventes ou
          nos offres spéciales!
        </DescriptionStyled>
        <Button kind="purple" onClick={redirecTO}>
          Modifiez votre adresse mail
        </Button>
      </PreHeaderStyled>
      <IconCloseStyled
        onClick={() => {
          setIsPreheader(false);
          addCookie();
        }}
      >
        <IconClose />
      </IconCloseStyled>
    </PageContainerPreHeaderStyled>
  );
};
