import gql from "graphql-tag";

export const GET_MEMBER_NEWSLETTER = gql`
  query getMemberNewsletter {
    memberNewsletters {
      name
      newsletterId
      isActive
    }
  }
`;

export const MEMBER_NEWSLETTER_PREFERENCE = gql`
  mutation updateNewsletterPreference(
    $preferences: [NewsletterPreferencesInput]
    $unsubscribe: UnsubscribeInput
  ) {
    manageNewsletterPreferences(
      preferences: $preferences
      unsubscribe: $unsubscribe
    ) 
  }
`;

export const UNSUBSCRIBE_REASON = gql`
  query unsubscribeReasons {
    unsubscribingReasons {
      description
      id
    }
  }
`;