import React, { useEffect, useState } from 'react';
import { createModal } from 'react-modal-promise';
import { Modal, Button } from 'Ui';
import styled from 'styled-components/macro';
import cadeauwAnniv from 'Assets/images/cadeauAnniv.png';
import { Select } from 'Ui';
import { useApolloClient } from '@apollo/client';
import dayjs from 'dayjs';
import Cookie from 'js-cookie';
import { UPDATE_BIRTHDATE } from './query';
import {
  UpdateBirthdate,
  UpdateBirthdateVariables,
} from './__generated__/UpdateBirthdate';

import { timestampAndCount, createCookieBirtdayReminder } from 'Utils/date';
import UiStore from 'Store/UiStore';
import { getModalEventName } from 'Libs/GA4/common';

type ModalProps = {
  open: boolean;
  close: (shouldGoToCheckout: boolean) => void;
};

export type FormData = {
  birthDate: string;
};

const ErrorMessage = styled.div`
  text-align: center;
  color: red;
  padding-top: 1rem;
`;
const BirthReminderodalBody = styled.div`
  flex: 1;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.6rem;

  p {
    line-height: 2rem;
  }

  @media screen and (max-width: 480px) {
    margin-bottom: 10rem;
  }
`;

const BrithContentStyled = styled.div`
  display: flex;
`;

const BirthdayReminderModal = ({ open, close }: ModalProps) => {
  const cookieMarketingStore = UiStore.useStoreState(
    (state) => state.cookieMarketing
  );
  const apolloClient = useApolloClient();
  const [errorMessage, SetErrorMessage] = useState(false);
  const birthdayReminder = Cookie.get('birthdayReminder');
  const configCookie = {
    path: '/',
    domain: '.bazarchic.com',
    expires: 360,
  };

  if (birthdayReminder) {
    const [timestamp, count] = timestampAndCount(birthdayReminder);
    createCookieBirtdayReminder(count);
  }

  const [day, setDay] = useState('');
  const [month, setMonth] = useState('');
  const [years, setYears] = useState('');

  const PostUpdateBirthday = (date: string) => {
    try {
      apolloClient.mutate<UpdateBirthdate, UpdateBirthdateVariables>({
        mutation: UPDATE_BIRTHDATE,
        variables: {
          updateBirthdate: date,
        },
      });
      if (cookieMarketingStore) getModalEventName('birthday_success');

      close(true);
      Cookie.remove('birthdayReminder', configCookie);
    } catch (error) {
      console.log(error);
    }
  };

  const validBirthday = () => {
    if (day && month && years) {
      SetErrorMessage(false);
      const date = `${years}-${month}-${day}`;
      PostUpdateBirthday(date);
    } else {
      SetErrorMessage(true);
    }
  };

  const optionMonths = [
    {
      label: 'Janv',
      value: '01',
      disabled: false,
    },
    {
      label: 'Fev',
      value: '02',
      disabled: false,
    },
    {
      label: 'Mars',
      value: '03',
      disabled: false,
    },
    {
      label: 'Avr',
      value: '04',
      disabled: false,
    },
    {
      label: 'Mai',
      value: '05',
      disabled: false,
    },
    {
      label: 'Juin',
      value: '06',
      disabled: false,
    },
    {
      label: 'Juill',
      value: '07',
      disabled: false,
    },
    {
      label: 'Aout',
      value: '08',
      disabled: false,
    },
    {
      label: 'Sept',
      value: '09',
      disabled: false,
    },
    {
      label: 'Oct',
      value: '10',
      disabled: false,
    },
    {
      label: 'Nov',
      value: '11',
      disabled: false,
    },
    {
      label: 'Dec',
      value: '12',
      disabled: false,
    },
  ];

  const optionDays: { value: string; label: string; disabled?: boolean }[] = [];

  for (var i = 1; i <= 31; i++) {
    const jour = i < 10 ? `0${i}` : `${i}`;
    optionDays.push({
      label: jour,
      value: jour,
      disabled: false,
    });
  }

  const minDate = dayjs().add(-90, 'y').format('YYYY');
  const maxDate = dayjs().add(-16, 'y').format('YYYY');

  let arrayYears = [];
  for (var i = parseInt(minDate, 10); i <= parseInt(maxDate, 10); i++) {
    arrayYears.push(`${i}`);
  }

  const optionYears = arrayYears
    .sort((a: any, b: any) => b - a)
    .map((v) => {
      return {
        label: v,
        value: v,
        disabled: false,
      };
    });

  const TextStyled = styled.p`
    width: 100%;
    margin: 3rem 0rem;
  `;

  useEffect(() => {
    if (cookieMarketingStore) getModalEventName('birthday');
  }, []);

  return (
    <Modal isOpen={open} onClose={() => close(false)} size={650}>
      <BirthReminderodalBody>
        <img src={cadeauwAnniv} alt="Cadeaux" />
        <TextStyled>
          Indiquez votre date de naissance et recevez un cadeaux le jour de
          votre anniversaire.
        </TextStyled>
        <BrithContentStyled>
          <Select
            titleMargin
            options={optionDays}
            placeholder={'Jours'}
            onChange={(value) => setDay(value)}
            value={day}
            kind="default"
            hollow
            bodyMaxHeight={135}
            initOpen={false}
            style={{ marginBottom: 10 }}
          />
          <Select
            titleMargin
            options={optionMonths}
            placeholder={'Mois'}
            onChange={(value) => setMonth(value)}
            value={month}
            kind="default"
            hollow
            bodyMaxHeight={135}
            initOpen={false}
            style={{ marginBottom: 10 }}
          />

          <Select
            titleMargin
            options={optionYears}
            placeholder={'Année'}
            onChange={(value) => setYears(value)}
            value={years}
            kind="default"
            hollow
            bodyMaxHeight={135}
            initOpen={false}
            style={{ marginBottom: 10 }}
          />
        </BrithContentStyled>
        {errorMessage && (
          <ErrorMessage>Veuillez rentrer une date valide</ErrorMessage>
        )}
        <Button
          kind="primary"
          style={{ width: '20rem', marginTop: 20 }}
          block
          onClick={() => {
            validBirthday();
          }}
        >
          Valider
        </Button>
      </BirthReminderodalBody>
    </Modal>
  );
};

export default createModal(BirthdayReminderModal);
