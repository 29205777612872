import * as React from "react";
const SvgPaiement = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 30 24"
    {...props}
  >
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.1}
      d="M26.488 2H4.544C3.43 2 2.5 3 2.5 4.2V18.09c0 1.231.93 2.231 2.044 2.231h21.912c1.145 0 2.043-1 2.043-2.196V4.2C28.532 3 27.605 2 26.488 2ZM3.43 4.2c0-.668.496-1.2 1.118-1.2h21.911c.622 0 1.118.532 1.118 1.2v1.131H3.43zm24.175 13.92c0 .668-.495 1.2-1.117 1.2H4.544c-.622 0-1.118-.532-1.118-1.2V9.325h24.175v8.793zm0-9.79H3.43V6.332h24.175z"
    />
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.1}
      d="M21.25 17.065H9.473c-.217 0-.413-.21-.413-.448 0-.242.196-.448.413-.448h11.779c.217 0 .413.206.413.448s-.196.448-.413.448Z"
    />
    <path
      fill="currentColor"
      d="M6.372 17.447c.429 0 .776-.374.776-.834s-.347-.834-.776-.834-.776.373-.776.834.347.834.776.834"
    />
  </svg>
);
export default SvgPaiement;
