import gql from "graphql-tag";
import { GtmInfo } from "Fragments/GtmInfo";

export const REGISTER_MEMBER = gql`
  mutation registerMember(
    $member: RegistrationInput!
    $subscriptionURI: String!
    $sponsorId: ID
    $environment: EnvironmentInput!
    $newslettersPreferences: [NewsletterPreferencesInput]
  ) {
    registerMember(
      member: $member
      subscriptionURI: $subscriptionURI
      sponsorId: $sponsorId
      environment: $environment
      newslettersPreferences: $newslettersPreferences
    ) {
      jwt
      sessionId
      member {
        id
        isVip
        email
        firstName
        lastName
        phoneNumber
        ...GtmInfo
      }
    }
  }
  ${GtmInfo}
`;

export const REGISTER_MEMBER_FACEBOOK = gql`
  mutation registerMemberFacebook($accessToken: String!) {
    registerMemberFacebook(accessToken: $accessToken) {
      jwt
      sessionId
      member {
        id
        isVip
        email
        firstName
        lastName
        phoneNumber
        ...GtmInfo
      }
    }
  }
  ${GtmInfo}
`;
