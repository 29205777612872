import ModalSearch from 'Components/modals/ModalSearch';
import UiStore from 'Store/UiStore';
import { IconSearch } from 'Ui/Icons';
import GenericType from 'Ui/types/GenericType';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
interface Props
  extends Pick<GenericType, 'block' | 'kind'>,
    Omit<React.HTMLProps<HTMLInputElement>, 'kind'> {
  placeholder?: any;
  className?: string;
  style?: React.CSSProperties;
  onPressEnter?: () => void;
  dataTest?: string;
}

const Wrapper = styled.div<Props>`
  width: '100%';
`;

const InputStyled = styled.div<Props>`
  width: 100%;
  height: 4.4rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  svg {
    width: 1.7rem;
    height: 1.7rem;
    margin-right: 1rem;
  }
  border-radius: 3rem;
  border: 1px solid #d8d8d8;
  background: #f9f9f9;
  padding: 0 4px 0 15px;
  height: 4.4rem;
  position: relative;
  box-sizing: border-box;

  /* Tricking browser auto fill color */
  input:-webkit-autofill {
    -webkit-border-radius: 10px 10px 10px 10px !important;
    -webkit-box-shadow: 0 0 0 50px white inset;
    -webkit-text-fill-color: #333;
    border-radius: 10px !important;
  }
  input:-webkit-autofill:focus {
    -webkit-border-radius: 10px 10px 10px 10px !important;
    -webkit-box-shadow: 0 0 0 50px white inset;
    -webkit-text-fill-color: #333;
    border-radius: 10px !important;
  }
`;

const InputContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
  height: 100%;
`;

const InputElement = styled.input<{ testString?: any }>`
  border: none;
  font-family: 'Montserrat';
  transition: 0.2s;
  background: #f9f9f9;
  outline: none;
  width: 100%;
  :placeholder {
    width: 100%;
    font-family: 'Montserrat';
    overflow: hidden;
    font-size: 1.4rem;
    color: #4f4f4f;
    line-height: 1;
  }
  @media screen and (max-width: 48rem) {
    font-size: 1.4rem;
  }
`;

const RightIcon = styled.div<{ kindError?: boolean }>`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 10%;
`;

export const InputSearch = React.forwardRef(
  (
    {
      type = 'text',
      placeholder = '',
      onChange,
      ref: oldRef,
      style,
      dataTest,
      onPressEnter,
      onKeyPress,
      ...rest
    }: Props,
    ref
  ) => {
    const setSearchStringHeader = UiStore.useStoreActions(
      (actions) => actions.setSearchStringHeader
    );
    const searchStringHeader = UiStore.useStoreState(
      (state) => state.searchStringHeader
    );

    const [value, setValue] = useState('');
    const [first, setFirst] = useState(false);

    useEffect(() => {
      if (searchStringHeader === '') {
        setValue('');
      }
    }, [searchStringHeader === '']);
    console.log('in there');
    return (
      <Wrapper {...{ style }}>
        <InputStyled>
          <InputContainer>
            <InputElement
              placeholder={placeholder}
              data-test={dataTest}
              style={{ fontSize: '14px', fontFamily: 'Montserrat' }}
              value={value}
              onChange={async (e: any) => {
                setValue(e.target.value);
                setSearchStringHeader(e.target.value);
              }}
              onKeyPress={(e: any) => {
                if (e.key === 'Enter') {
                  onPressEnter && onPressEnter();
                  // @ts-ignore
                  e.target.blur();
                }
                if (onKeyPress) onKeyPress(e);
              }}
              ref={(thisRef: any) => {
                // @ts-ignore
                ref && ref(thisRef);
              }}
              {...rest}
              className="fontMontserrat"
              as="input"
            />
            <RightIcon
              style={{
                fontSize: '2rem',
                cursor: 'pointer',
              }}
            >
              <span
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '24px',
                }}
              >
                <IconSearch width="20" height="21" />
              </span>
            </RightIcon>
          </InputContainer>
        </InputStyled>
      </Wrapper>
    );
  }
);
