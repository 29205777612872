import * as React from "react";
const SvgBonAchat = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 22 22"
    {...props}
  >
    <path
      fill="currentColor"
      d="M10.889 7.345a1.032 1.032 0 1 0 0-2.064 1.032 1.032 0 0 0 0 2.064M13.86 10.317a1.032 1.032 0 1 0 0-2.064 1.032 1.032 0 0 0 0 2.064M10.799 9.89a.515.515 0 0 1-.364-.88l3.151-3.152a.515.515 0 1 1 .73.73L11.163 9.74c-.1.1-.232.15-.364.15M3.265 3.781a.516.516 0 0 1-.499-.388l-.603-2.362H.516A.516.516 0 0 1 .516 0h2.048c.235 0 .441.16.5.388l.701 2.75a.515.515 0 0 1-.5.643M20.11 19.25H4.382a1.59 1.59 0 0 1-1.545-1.17 1.59 1.59 0 0 1 .708-1.802l2.2-1.344a.515.515 0 1 1 .537.88l-2.2 1.343a.55.55 0 0 0-.252.644.55.55 0 0 0 .552.418h15.726a.516.516 0 0 1 0 1.031"
    />
    <path
      fill="currentColor"
      d="M15.257 22a2.61 2.61 0 0 1-2.475-3.429.52.52 0 0 1 .49-.353h3.97c.222 0 .42.142.49.353A2.61 2.61 0 0 1 15.256 22m-1.57-2.75a1.578 1.578 0 0 0 1.57 1.72 1.577 1.577 0 0 0 1.569-1.72zM7.527 22a2.61 2.61 0 0 1-2.474-3.429.52.52 0 0 1 .49-.353h3.97c.222 0 .419.142.489.353A2.61 2.61 0 0 1 7.527 22m-1.569-2.75a1.577 1.577 0 0 0 1.569 1.72 1.58 1.58 0 0 0 1.57-1.72zM18.736 15.889H6.014a.516.516 0 0 1-.503-.401l-.69-3.042a.516.516 0 0 1 .503-.63h14.102a.515.515 0 0 1 .503.63l-.69 3.042a.515.515 0 0 1-.503.401m-12.31-1.031h11.898l.456-2.01H5.97z"
    />
    <path
      fill="currentColor"
      d="M19.426 12.848H5.324a.516.516 0 0 1-.503-.402L2.763 3.38a.515.515 0 0 1 .503-.63h18.218a.515.515 0 0 1 .503.63l-2.058 9.066a.516.516 0 0 1-.503.402m-13.69-1.032h13.279l1.823-8.035H3.912z"
    />
  </svg>
);
export default SvgBonAchat;
