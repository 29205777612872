import { useQuery } from '@apollo/client';
import React from 'react';
import styled from 'styled-components/macro';
import { BACKGROUND_LOGIN } from '../query';
import { backgroundLogin } from 'Pages/Login/__generated__/backgroundLogin';
import { Button, theme } from 'Ui';

const LoginMobileBackground = styled.div<{ backgroundImage: string }>`
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  &:before {
    content: '';
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 1, 17, 0.2);
    /* background-image: url(${(props) => props.backgroundImage}); */
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: center;
    background-position-y: 0;
  }
  &::after {
    background-image: url(${(props) => props.backgroundImage});
    content: '';
    position: absolute;
    z-index: 0;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: center;
    background-position-y: 0;
  }
`;

const LoginMobileContent = styled.div`
  z-index: 100;
  margin: 0 auto;
`;

const LoginMobileLogo = styled.div`
  text-align: center;
  padding-top: 3rem;
  svg path {
    fill: #ffffff;
  }
`;

const LoginMobileAction = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 85vh;
  width: 100%;
  padding: 0 0 4rem;
`;

const LoginMobileText = styled.div`
  font-family: 'MontserratSemiBold';
  color: #ffffff;
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0 0 5rem;
  text-align: center;
  span {
    text-transform: uppercase;
  }
`;

const LoginMobileSales = styled.div`
  color: ${theme.light.colors.purple};
  text-decoration: underline;
  font-family: 'MontserratSemiBold';
  font-size: 1.2rem;
  font-weight: 600;
`;

export default () => {
  const { data } = useQuery<backgroundLogin>(BACKGROUND_LOGIN);
  const bgImage = data && data;
  const image: any = bgImage && bgImage.pageDatas;

  const urlBackgroundMobile1x =
    image && image.loginBackgroundImages[0].urlBackgroundMobile1x;

  return (
    <LoginMobileBackground backgroundImage={urlBackgroundMobile1x}>
      <LoginMobileContent>
        <LoginMobileLogo>
          <svg
            width="280"
            height="39"
            viewBox="0 0 280 39"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="BC-Logo">
              <path
                id="Fill 1"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M18.3382 24.0103C19.4897 23.2426 20.0441 22.0912 20.0441 20.4706C20.0441 17.3573 17.7412 15.7794 13.1779 15.7794H4.00882V25.1191H13.2206C15.4809 25.1191 17.1868 24.7353 18.3382 24.0103ZM4.00882 3.5397V12.5382H12.2397C14.2868 12.5382 15.8647 12.1544 16.9735 11.3868C18.0824 10.6191 18.6368 9.51029 18.6368 8.06029C18.6368 6.61029 18.0824 5.45882 16.9735 4.73382C15.8647 3.96617 14.2868 3.58235 12.2397 3.58235H4.00882V3.5397ZM22.7309 16.2912C23.6691 17.4853 24.1382 18.9779 24.1382 20.8118C24.1382 23.2426 23.2426 25.1191 21.4088 26.4412C19.5324 27.7206 16.8882 28.3603 13.3912 28.3603H0V0.298523H12.5809C15.7794 0.298523 18.2529 0.938229 20.0015 2.21764C21.75 3.49705 22.6456 5.28823 22.6456 7.54852C22.6456 8.99852 22.3044 10.2779 21.6221 11.3441C20.9397 12.4103 20.0441 13.2206 18.8926 13.775C20.5132 14.2441 21.7926 15.0971 22.7309 16.2912Z"
                fill="black"
              />
              <path
                id="Fill 4"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M40.0455 0.298584L27.3367 28.3603H31.4734L34.5867 21.3662H49.5131L52.5837 28.3603H56.8058L44.0121 0.298584H40.0455ZM42.0503 4.3927L48.1062 18.1255H35.9944L42.0503 4.3927Z"
                fill="black"
              />
              <path
                id="Fill 5"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M61.2839 0.298584V3.79564H78.6413L60.9854 25.6309V28.3603H84.4413V24.8633H66.3589L83.9295 3.028V0.298584H61.2839Z"
                fill="black"
              />
              <path
                id="Fill 6"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M100.86 0.298584L88.1516 28.3603H92.2879L95.3585 21.3662H110.285L113.356 28.3603H117.578L104.827 0.298584H100.86ZM102.822 4.3927L108.878 18.1255H96.7663L102.822 4.3927Z"
                fill="black"
              />
              <path
                id="Fill 7"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M139.669 14.7558C140.991 13.647 141.673 12.1117 141.673 10.0646C141.673 8.01758 140.991 6.48229 139.669 5.37346C138.347 4.30729 136.385 3.75287 133.869 3.75287H127.045V16.4191H133.869C136.385 16.4191 138.347 15.8651 139.669 14.7558ZM141.802 28.3602L135.746 19.7455C134.978 19.7882 134.423 19.8308 133.997 19.8308H127.045V28.3602H123.037V0.298462H133.997C137.622 0.298462 140.479 1.1514 142.569 2.89993C144.659 4.64846 145.683 7.0367 145.683 10.0646C145.683 12.2396 145.128 14.0735 144.062 15.6088C142.996 17.1445 141.46 18.2529 139.499 18.9352L146.151 28.3602H141.802Z"
                fill="black"
              />
              <path
                id="Fill 11"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M176.431 19.8308C175.493 21.4941 174.17 22.7735 172.55 23.7117C170.887 24.6499 169.053 25.1191 167.006 25.1191C164.959 25.1191 163.082 24.6499 161.419 23.7117C159.756 22.7735 158.434 21.4941 157.496 19.8308C156.558 18.1676 156.088 16.3338 156.088 14.3294C156.088 12.2823 156.558 10.4485 157.496 8.82788C158.434 7.16464 159.756 5.88523 161.419 4.947C163.082 4.00876 164.959 3.53964 167.006 3.53964C169.053 3.53964 170.93 4.00876 172.55 4.947C174.213 5.88523 175.493 7.16464 176.431 8.82788C176.516 8.95582 176.559 9.04112 176.602 9.16906H180.952C180.653 8.40141 180.312 7.67641 179.928 6.99406C178.648 4.81906 176.857 3.11317 174.597 1.87641C172.337 0.639645 169.778 -6.10352e-05 166.963 -6.10352e-05C164.148 -6.10352e-05 161.589 0.639645 159.287 1.87641C157.026 3.11317 155.235 4.81906 153.956 7.0367C152.677 9.2117 151.994 11.6852 151.994 14.372C151.994 17.0588 152.634 19.5323 153.956 21.7073C155.235 23.8823 157.026 25.6308 159.287 26.8676C161.547 28.1044 164.106 28.7441 166.963 28.7441C169.778 28.7441 172.294 28.1044 174.597 26.8676C176.857 25.6308 178.648 23.9249 179.928 21.7499C180.567 20.6411 181.08 19.4901 181.378 18.2107H177.199C176.985 18.8077 176.729 19.3191 176.431 19.8308Z"
                fill="black"
              />
              <path
                id="Fill 9"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M208.971 0.298584V12.3681H192.85V0.298584H188.841V28.3603H192.85V15.8648H208.971V28.3603H212.98V0.298584H208.971Z"
                fill="black"
              />
              <path
                id="Fill 10"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M223.087 28.3603H227.096V0.298523H223.087V28.3603Z"
                fill="black"
              />
              <path
                id="Fill 14"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M259.763 19.8308C258.825 21.4941 257.503 22.7735 255.883 23.7117C254.219 24.6499 252.385 25.1191 250.339 25.1191C248.291 25.1191 246.415 24.6499 244.751 23.7117C243.089 22.7735 241.766 21.4941 240.828 19.8308C239.89 18.1676 239.421 16.3338 239.421 14.3294C239.421 12.2823 239.89 10.4485 240.828 8.82788C241.766 7.16464 243.089 5.88523 244.751 4.947C246.415 4.00876 248.291 3.53964 250.339 3.53964C252.385 3.53964 254.262 4.00876 255.883 4.947C257.546 5.88523 258.825 7.16464 259.763 8.82788C259.848 8.95582 259.891 9.04112 259.934 9.16906H264.284C263.986 8.40141 263.644 7.67641 263.26 6.99406C261.981 4.81906 260.19 3.11317 257.929 1.87641C255.669 0.639645 253.111 -6.10352e-05 250.296 -6.10352e-05C247.481 -6.10352e-05 244.922 0.639645 242.619 1.87641C240.359 3.11317 238.568 4.81906 237.288 7.0367C236.009 9.2117 235.326 11.6852 235.326 14.372C235.326 17.0588 235.966 19.5323 237.288 21.7073C238.568 23.8823 240.359 25.6308 242.619 26.8676C244.879 28.1044 247.438 28.7441 250.296 28.7441C253.111 28.7441 255.626 28.1044 257.929 26.8676C260.19 25.6308 261.981 23.9249 263.26 21.7499C263.9 20.6411 264.412 19.4901 264.71 18.2107H260.531C260.318 18.8077 260.062 19.3191 259.763 19.8308Z"
                fill="black"
              />
            </g>
          </svg>
        </LoginMobileLogo>
        <LoginMobileAction>
          <LoginMobileText>
            <p style={{ paddingBottom: '5px' }}>
              Le meilleur de la mode premium
            </p>
            <p>
              en ventes privées<span> jusqu'à -70%</span>
            </p>
          </LoginMobileText>
          <Button
            onClick={() => (window.location.href = '/?anchor=inscription')}
            block
            style={{ margin: '1rem 0', fontSize: '1.6rem' }}
          >
            S'INSCRIRE
          </Button>
          <Button
            onClick={() => (window.location.href = '/?anchor=connexion')}
            block
            style={{ margin: '1rem 0 3rem', fontSize: '1.6rem' }}
          >
            SE CONNECTER
          </Button>
          <LoginMobileSales>
            <a href="/ventes-privees-encours/">Voir les ventes privées</a>
          </LoginMobileSales>
        </LoginMobileAction>
      </LoginMobileContent>
    </LoginMobileBackground>
  );
};
