import * as React from "react";
const SvgEyeClosed = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 25"
    {...props}
  >
    <g
      strokeLinecap="round"
      strokeLinejoin="round"
      clipPath="url(#eyeClosed_svg__a)"
    >
      <path
        stroke="#A5A5A5"
        d="M17.94 18.395a10.07 10.07 0 0 1-5.94 2.06c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94m3.84-1.82a9 9 0 0 1 2.1-.24c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a2.998 2.998 0 0 1-5.194-2.098 3 3 0 0 1 .954-2.142"
      />
      <path
        stroke="currentColor"
        strokeOpacity={0.2}
        d="M17.94 18.395a10.07 10.07 0 0 1-5.94 2.06c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94m3.84-1.82a9 9 0 0 1 2.1-.24c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a2.998 2.998 0 0 1-5.194-2.098 3 3 0 0 1 .954-2.142"
      />
      <path stroke="#A5A5A5" d="m1 1.455 22 22" />
      <path stroke="currentColor" strokeOpacity={0.2} d="m1 1.455 22 22" />
    </g>
    <defs>
      <clipPath id="eyeClosed_svg__a">
        <path fill="#fff" d="M0 .455h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgEyeClosed;
