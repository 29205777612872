import * as React from "react";
const SvgGoogle = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 21 20"
    {...props}
  >
    <path
      fill="#4285F4"
      d="M10.96 8.364v3.485h4.844a4.15 4.15 0 0 1-1.808 2.708l2.92 2.267c1.702-1.571 2.684-3.879 2.684-6.62q-.002-.957-.164-1.84z"
    />
    <path
      fill="#34A853"
      d="m5.916 11.713-.659.504-2.331 1.817C4.406 16.97 7.442 19 10.96 19c2.43 0 4.467-.802 5.956-2.176l-2.92-2.267c-.803.54-1.825.868-3.036.868-2.34 0-4.328-1.58-5.04-3.707z"
    />
    <path
      fill="#FBBC05"
      d="M2.926 5.966A8.9 8.9 0 0 0 1.96 10c0 1.456.352 2.823.966 4.034 0 .008 2.994-2.324 2.994-2.324A5.4 5.4 0 0 1 5.634 10c0-.597.106-1.17.286-1.71z"
    />
    <path
      fill="#EA4335"
      d="M10.96 4.584c1.326 0 2.504.458 3.445 1.341l2.577-2.577C15.419 1.892 13.39 1 10.96 1 7.442 1 4.406 3.02 2.926 5.966L5.92 8.29c.712-2.127 2.7-3.706 5.04-3.706"
    />
  </svg>
);
export default SvgGoogle;
