import * as React from "react";
const SvgPersonna = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 30 30"
    {...props}
  >
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.2}
      d="M14.575 13.8c2.7 0 4.9-2.2 4.9-4.9s-2.2-4.9-4.9-4.9-4.9 2.2-4.9 4.9 2.2 4.9 4.9 4.9Zm0-1.3c-2 0-3.6-1.6-3.6-3.6s1.6-3.6 3.6-3.6 3.6 1.6 3.6 3.6-1.6 3.6-3.6 3.6Z"
    />
    <path
      fill="currentColor"
      d="M24.675 22c-2-3.4-5.4-7.3-10.2-7.3s-8.1 4-10.1 7.4c-.5.8-.5 1.8 0 2.6.5.7 1.5 1.3 2.5 1.3h15.3c1.1 0 2-.5 2.5-1.4s.5-1.8 0-2.6m-1.2 1.8c-.2.4-.7.6-1.2.6h-15.4c-.5 0-1-.2-1.2-.6-.1-.2-.3-.6 0-1 1.4-2.5 4.5-6.6 8.8-6.6s7.4 4.1 8.9 6.6c.3.4.2.8.1 1"
    />
  </svg>
);
export default SvgPersonna;
