import * as React from "react";
const SvgPictoMobile = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="picto-mobile_svg__Calque_1"
    x={0}
    y={0}
    viewBox="0 0 60 60"
    width="1em"
    height="1em"
    {...props}
  >
    <style>
      {
        ".picto-mobile_svg__st0{fill:none;stroke:#232929;stroke-width:2;stroke-miterlimit:10}"
      }
    </style>
    <switch>
      <foreignObject
        width={1}
        height={1}
        x={0}
        y={0}
        requiredExtensions="http://ns.adobe.com/AdobeIllustrator/10.0/"
      />
      <g>
        <path
          d="M45.2 43.8c0 3.9-2.9 7-6.5 7H21.3c-3.6 0-6.5-3.1-6.5-7"
          className="picto-mobile_svg__st0"
        />
        <path
          d="M40 2.9h-4.2l-2 3.7h-8.1l-1.5-3.7H20c-2.9 0-5.2 2.3-5.2 5.2v43.8c0 2.9 2.3 5.2 5.2 5.2h20c2.9 0 5.2-2.3 5.2-5.2V8.1c0-2.9-2.4-5.2-5.2-5.2z"
          className="picto-mobile_svg__st0"
        />
        <path
          d="m33.8 6.5 2-3.6H24.2l1.5 3.6z"
          className="picto-mobile_svg__st0"
        />
      </g>
    </switch>
  </svg>
);
export default SvgPictoMobile;
