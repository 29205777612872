import * as React from "react";
const SvgChevronRight = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 10 16"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m1.7.3-.9.9L7.401 8 .8 14.799l.9.901 7.5-7.699v-.002z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgChevronRight;
