import React from 'react';

type Props = {
  width: number;
  height: number;
  url: string;
  alt: string;
  className?: string;
  crop?: boolean;
};

export const ImageRetina = ({
  width = 467.5,
  height = 228,
  url,
  alt,
  crop,
  className,
}: Props) => {
  const params = {
    dimensions: {
      width: width,
      height: height,
      crop: crop ? true : false,
    },
  };

  const paramsRetina = {
    dimensions: {
      width: width * 2,
      height: height * 2,
      crop: crop ? true : false,
    },
  };

  const paramsUrl = JSON.stringify(params);
  const paramsRetinaUrl = JSON.stringify(paramsRetina);

  return (
    <div className={className}>
      <img
        src={url + `?params=${paramsUrl}`}
        data-srcset={url + `?params=${paramsRetinaUrl} x2`}
        alt={alt}
      />
    </div>
  );
};
