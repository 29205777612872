import styled from 'styled-components';
import { Contact } from './Contact';
import { ContactColumn } from './ContactColum';
import {
  ClientLinks,
  ServicesLinks,
  FaqLinks,
  aProposLinks,
} from 'Utils/footerLinks';

const ContactsContainer = styled.div`
  display: flex;
  padding-bottom: 2.4rem;
  justify-content: center;
  @media screen and (max-width: 48rem) {
    flex-direction: column;
  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    flex-direction: row;
  }
`;

const ContactContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media screen and (max-width: 48rem) {
    flex-direction: column;
    gap: 24px;
  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    /* flex-wrap: wrap;
    flex-direction: row;
    justify-content: center; */
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 5rem;
    column-gap: 2.4rem;
    margin-bottom: 5rem;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  padding-bottom: 2.4rem;
  justify-content: flex-start;
  svg {
    width: 3.9rem;
    height: 3.8rem;
  }
`;

export const SiteMap = () => {
  return (
    <div style={{ maxWidth: '112rem', margin: 'auto' }}>
      <ContactsContainer>
        <Contact />
        <ContactContainer>
          <ContactColumn title="À propos de BazarChic" links={aProposLinks} />
          <ContactColumn title="Aide & contact" links={FaqLinks} />
          <ContactColumn title="Offres & services" links={ServicesLinks} />
          <ContactColumn title="Espace Client" links={ClientLinks} />
        </ContactContainer>
      </ContactsContainer>
    </div>
  );
};
