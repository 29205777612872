export const errorMessages: { [index: string]: string } = {
  ERROR_INVALID_OLD_PASSWORD_GLOBAL: 'Le mot de passe actuel est invalide',
  ERROR_INVALID: 'Une erreur est survenue',
  ERROR_INVALID_OLD_PASSWORD_GENERIC: 'Le mot de passe actuel est invalide',
  ERROR_FIELD_MISSING_GENERIC: 'Une erreur est survenu vous allez être redirgé',
  ERROR_FIELD_INVALID_GENERIC: 'Une erreur est survenu vous allez être redirgé',
  ERROR_TOKEN_EXPIRED_GENERIC:
    'Votre lien est expiré. Vous allez être redirigé dans quelques secondes afin de réitérer votre demande.',
  ERROR_EMAIL_ALREADY_EXIST_GENERIC:
    "Vous disposez déjà d'un compte avec cet e-mail",
  ERROR_FORM_GENERIC: 'Une erreur est survenue',
  ERROR_FACEBOOK_LOGIN_GENERIC: 'Erreur Login Facebook',
  ERROR_SERVER_GENERIC: 'Une erreur serveur est survenue',
  ERROR_EXCEPTION_GENERIC: 'Une erreur est survenue',
  ERROR_UNKNOWN_GENERIC:
    'Votre nom ou prénom doit comporter au moins 2 caractères',
  ERROR_EMAIL_INVALID_GENERIC: 'Cette adresse e-mail est impossible',
  ERROR_UNAUTHORIZED: 'Merci de vous connecter pour accéder à cette page',
};
