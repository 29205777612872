import * as React from "react";
const SvgGroup = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 55 36"
    {...props}
  >
    <g fill="currentColor">
      <path d="M50.026 0H4.262C1.94 0 0 1.94 0 4.27v26.954c0 2.39 1.94 4.33 4.262 4.33h45.696a4.26 4.26 0 0 0 4.262-4.262V4.27C54.288 1.94 52.356 0 50.026 0M1.94 4.27a2.32 2.32 0 0 1 2.33-2.33h45.697a2.32 2.32 0 0 1 2.33 2.33v2.195H1.94zm50.416 27.013a2.32 2.32 0 0 1-2.33 2.33H4.262a2.32 2.32 0 0 1-2.33-2.33V14.218h50.415v17.065zm0-18.997H1.94v-3.88h50.416z" />
      <path d="M14.54 29.333h24.564c.517 0 .966-.449.966-.966a.99.99 0 0 0-.966-.966H14.54a.99.99 0 0 0-.966.966c0 .509.45.966.966.966M8.075 29.977a1.618 1.618 0 1 0 0-3.236 1.618 1.618 0 0 0 0 3.236" />
    </g>
  </svg>
);
export default SvgGroup;
