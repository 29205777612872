import { IconMyPref, IconFaqs, IconPrivilege } from 'Ui/Icons';
import {
  ConnectSubscribe,
  HeaderActions,
  HeaderBackgroundMobile,
  HeaderConnect,
  HeaderLogoContainer,
  LinkLine,
} from './style';
import { isAndroid } from 'react-device-detect';
import { Separator } from 'Components/Header/DesktopHeader/DropDown/styles';
import { LogoMobile } from 'Ui/Icons/icons';
import showLoginModal from 'Components/modals/LoginRegister';

export const SideMenuAnonymous = ({
  onClickConnect,
}: {
  onClickConnect?: () => void;
}) => {
  const linkDownload = isAndroid
    ? '/home/app/p/android/source/interstitiel/'
    : '/home/app/p/ios/source/interstitiel/';

  return (
    <>
      <HeaderConnect>
        <HeaderBackgroundMobile>
          <div style={{ padding: '0 0 2.4rem', textAlign: 'center' }}>
            <a href="/">
              <LogoMobile width="156" height="18" />
            </a>
          </div>
          <HeaderActions>
            <ConnectSubscribe
              onClick={() => {
                onClickConnect && onClickConnect();
                showLoginModal({ isRegister: false });
              }}
            >
              S'inscrire{' '}
            </ConnectSubscribe>
            <Separator />
            <ConnectSubscribe
              onClick={() => {
                onClickConnect && onClickConnect();
                showLoginModal({ isRegister: true });
              }}
            >
              Se connecter
            </ConnectSubscribe>
          </HeaderActions>
        </HeaderBackgroundMobile>
      </HeaderConnect>
      <div style={{ marginTop: '1.5rem' }}>
        <LinkLine
          text="Aide & contact"
          src="/contact/"
          icon={<IconFaqs />}
          hasBorder={false}
          withIcon={false}
          hasMarginTop={true}
        />
        <LinkLine
          src={linkDownload}
          text="Les applis mobile BazarChic"
          icon={<IconMyPref />}
          hasBorder={false}
          withIcon={false}
        />
        {/* <LinkLine
          text="Club privilège"
          src="/accueil/privilege/"
          icon={<IconPrivilege />}
          hasBorder={false}
          withIcon={false}
        /> */}
      </div>
    </>
  );
};
