import React from 'react';
import styled, { css } from 'styled-components/macro';
import { theme } from './theme';
import { Spinner } from './Spinner';
import GenericType from './types/GenericType';

interface StyleProps
  extends Pick<GenericType, 'kind' | 'hollow' | 'block' | 'disabled'> {
  /** disabled or activated the loading*/
  loading?: boolean;
  /** add icon */
  icon?: React.ReactNode;
  hasChildren?: boolean;
  /** add color background */
  color?: string;
  iconRight?: boolean | false;
  styleButton?: 'cropRight';
}
interface ButtonProps
  extends Omit<StyleProps, 'hasChildren'>,
    React.PropsWithoutRef<JSX.IntrinsicElements['button']> {
  children?: React.ReactNode;
  /** function callback */
  onClick?: (event: any) => void;
  iconRight?: boolean | false;
  styleButton?: 'cropRight';
  className?: any;
  dataTest?: string;
}

const ButtonStyled = styled.button<StyleProps>`
  font-family: 'MontserratSemiBold', sans-serif !important;
  outline: none;
  position: relative;
  height: 4.6rem;
  font-size: 1.6rem;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  transition: 0.2s;
  align-items: center;
  justify-content: center;
  /* font-weight: 600; */
  text-transform: uppercase;
  line-height: 0;
  min-width: 4.6rem;
  white-space: nowrap;
  border-radius: 2.3rem;
  border: 1px solid
    ${(props) =>
      props.color || theme.light.colors.button.color[props.kind || 'default']};

  background-color: ${(props) =>
    props.color || theme.light.colors.button.color[props.kind || 'default']};
  background: ${(props) =>
    !props.color &&
    !props.disabled &&
    (!props.kind || props.kind === 'primary' || props.kind === 'default')
      ? theme.v2.button.active
      : ''};
  color: white;

  ${(props) => props.hasChildren && 'padding : 0 2.3rem;'}
  /* ${(props) =>
    props.icon &&
    props.hasChildren &&
    !props.iconRight &&
    'padding-left : 4.6rem;'} */

${(props) =>
    props.icon &&
    props.hasChildren &&
    props.iconRight &&
    'padding: 0rem  0rem 0rem 1.2rem;'}

${(props) =>
    props.styleButton == 'cropRight' &&
    css`
      position: absolute;
      margin-top: 15px;

      top: 248px;
      border-radius: unset;
      border-top-left-radius: 25px;
      border-bottom-left-radius: 25px;
      width: 50px;
      right: 0;
    `}

  ${(props) =>
    props.hollow &&
    css`
      background-color: transparent;
      color: ${props.color ||
      theme.light.colors.button.color[props.kind || 'default']};
      //
    `}

  ${(props) =>
    props.block &&
    css`
      width: 100%;
      //
    `}

  ${(props) =>
    props.disabled &&
    css`
      border-color: #f1f2f3;
      background-color: #f1f2f3;
      color: #999;
      //
    `}

  ${(props) =>
    !props.disabled &&
    !props.loading &&
    css`
      &:hover {
        background: ${theme.v2.button.active};
        border-color: ${theme.light.colors.purpleLight};
        color: white;
        cursor: pointer;
      }
      //
    `}
    @media screen and (max-width: 480px) {
    font-size: 1.4rem;
  }
`;

const ButtonIcon = styled.div<StyleProps>`
  font-size: 2.7rem;
  /* ${(props) =>
    props.icon && props.hasChildren && props.iconRight
      ? 'position : relative;'
      : 'position : absolute;'} */

  width: 4.4rem;
  height: 4.4rem;
  display: flex;
  align-items: center !important;
  justify-content: center;
  left: 0;
  top: 0;
`;

export const Button = ({
  children,
  icon,
  style,
  onClick,
  color,
  type = 'button',
  block = false,
  kind = 'default',
  hollow = false,
  disabled = false,
  loading = false,
  iconRight = false,
  dataTest,
  styleButton,
  className,
}: ButtonProps) => {
  const onButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    !loading && !disabled && onClick && onClick(e);
  };
  return (
    <ButtonStyled
      hasChildren={!!children}
      className={className}
      data-test={dataTest}
      onClick={onButtonClick}
      {...{
        style,
        kind,
        block,
        hollow,
        disabled,
        loading,
        icon,
        color,
        iconRight,
        type,
        styleButton,
      }}
    >
      {!loading && icon && !iconRight && <ButtonIcon>{icon}</ButtonIcon>}
      {!loading && children}
      {!loading && icon && iconRight && <ButtonIcon>{icon}</ButtonIcon>}
      {loading && <Spinner size={20} />}
    </ButtonStyled>
  );
};
