import { Spinner, theme } from 'Ui';
import styled from 'styled-components';
import UiStore from 'Store/UiStore';

const ConnectWithContainer = styled.div<{ $isDisabled: boolean }>`
  display: inline-flex;
  flex-direction: row;
  border: 1px solid transparent;
  border-radius: 2.5rem;
  align-items: center;
  padding: 1rem;
  cursor: pointer;
  margin: 2rem 0;
  justify-content: center;
  width: 15.9rem;
  background-color: ${(props) => {
    if (props.$isDisabled) return theme.v2.grayScale[50];
    return theme.v2.white;
  }};
  box-shadow: 0px 0px 4px 0px #00000040;
  @media screen and (max-width: 48rem) {
    padding: 0.8rem 0.8rem;
    max-width: 11.8rem;
    gap: 0.4rem;
    margin: 1.5rem 0;
    width: 100%;
  }
`;

const ConnectWithLogo = styled.div<{ $isDisabled: boolean }>`
  .facebook {
    path:nth-child(2) {
      fill: ${(props) => {
        if (props.$isDisabled) return `${theme.v2.white}`;
        return '';
      }};
    }
  }
  svg {
    font-size: 2rem;
    path {
      fill: ${(props) => {
        if (props.$isDisabled) return `${theme.v2.grayScale[300]}`;
        return '';
      }};
    }
  }
  @media screen and (max-width: 48rem) {
    svg {
      font-size: 2rem;
    }
  }
`;

const ConnectWithText = styled.div<{ $isDisabled: boolean }>`
  display: flex;
  flex-direction: column;
  font-family: 'MontserratSemiBold' !important;
  justify-content: flex-start;
  font-size: 1.2rem;
  margin-left: 1rem;
  text-transform: uppercase;
  color: ${(props) => {
    if (props.$isDisabled) return theme.v2.text.lightGray;
    return theme.v2.text.darkGray;
  }};
  @media screen and (max-width: 48rem) {
    margin-left: 0rem;
    font-size: 1rem;
  }
`;

type ConnectWithProps = {
  connectWithMethod: string;
  connectWithIcon: any;
  onClickConnectWith?: () => void;
  loading: boolean;
};

export const ConnectWith = ({
  connectWithIcon,
  connectWithMethod,
  onClickConnectWith,
  loading,
}: ConnectWithProps) => {
  const isDisabled = !UiStore.useStoreState((state) => state.cookieMarketing);

  return (
    <ConnectWithContainer
      $isDisabled={isDisabled}
      onClick={() => {
        if (!isDisabled) {
          onClickConnectWith && onClickConnectWith();
        }
      }}
    >
      {loading ? (
        <div>
          <Spinner size={20} color={theme.light.colors.purple} />
        </div>
      ) : (
        <>
          <ConnectWithLogo $isDisabled={isDisabled}>
            {connectWithIcon}
          </ConnectWithLogo>

          <ConnectWithText $isDisabled={isDisabled}>
            <div>{connectWithMethod}</div>
          </ConnectWithText>
        </>
      )}
    </ConnectWithContainer>
  );
};
