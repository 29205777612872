import { action, Action, createContextStore } from "easy-peasy";

type ErrorMessageModel = {
  messageOldPassword: string;
  setMessageOldPassword: Action<ErrorMessageModel, string>;
};

const errorMessageModel: ErrorMessageModel = {
  /* Values */
  messageOldPassword: "",

  /* Actions */
  setMessageOldPassword: action((state, payload) => {
    state.messageOldPassword = payload;
  })
};

export default createContextStore(errorMessageModel, {
  name: "messageOldPasswordStore"
});
