import colors from './colors';
import colorsLight from './colorsLight';
import breakpoints from './breakpoints';
import colorsV2 from './colorsV2';

export const theme = {
  colors,
  breakpoints,
  light: {
    colors: {
      ...colorsLight,
    },
  },
  dark: {
    colors: {
      ...colorsLight,
    },
  },
  v2: {
    ...colorsV2,
  },
};
