import React from 'react';
import styled from 'styled-components/macro';
import Concept2_2 from 'Assets/images/Concept2_2.png';
import qui1 from 'Assets/images/qui1.png';
import Caroll from 'Assets/images/Caroll.png';
import Bobochic from 'Assets/images/Bobochic.png';
import Jonak from 'Assets/images/Jonak.png';
import MaxMoi from 'Assets/images/MaxEtMoi.png';

import { Button, Link, theme } from 'Ui';
import AuthStore from 'Store/AuthStore';
import LoginRegister from 'Components/modals/LoginRegister';

type Props = {
  top?: number;
  left?: number;
  topResponsive?: number;
  leftResponsive?: number;
};

const ImageContainer = styled.div<{ url?: string }>`
  position: relative;
  /* background-image: ${(props) =>
    props.url && `url("Assets/images/Concept2.png")`}; */

  width: 100%;
`;

const Image = styled.div`
  img {
    width: 100%;
  }
  @media screen and (max-width: 480px) {
    img {
      width: 100%;
      height: 33rem;
    }
  }
`;

const ImageText = styled.div<{
  top?: number;
  left?: number;
  topResponsive?: number;
  leftResponsive?: number;
  height?: any;
}>`
  width: 42rem;
  font-family: 'Montserrat';
  font-size: 1.7rem;
  font-weight: 400;
  line-height: 2.9rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-content: center;
  align-items: center;
  height: ${(props) => props.height && `${props.height}%`};
  color: ${theme.light.colors.grayUltraDark};
  position: absolute;
  left: ${(props) => props.left && `${props.left}rem`};
  top: ${(props) => props.top && `${props.top}rem`};
  z-index: 10000;
  .purple {
    color: ${theme.light.colors.purple} !important;
  }
  @media screen and (max-width: 480px) {
    width: 30rem;
    font-family: 'Montserrat';
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2rem;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-around;
    color: ${theme.light.colors.grayUltraDark};
    position: absolute;
    z-index: 10000;
    left: ${(props) => props.leftResponsive && `${props.leftResponsive}rem`};
    top: ${(props) => props.topResponsive && `${props.topResponsive}rem`};
    padding: 0 2rem;
  }
  @media screen and (min-width: 480px) and (max-width: 768px) {
    left: 6rem;
    top: 4rem;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: ${theme.light.colors.purple};
  border-radius: 8rem 0 8rem 0;
  margin: 3rem 0;
  padding: 5rem 5rem 5.5rem 5rem;
  @media screen and (max-width: 480px) {
    padding: 2rem 2rem;
  }
`;

const LogoTitle = styled.h2`
  font-family: 'granville-bold';
  text-align: center;
  font-weight: 700;
  font-size: 3.5rem;
  color: #ffffff;
  @media screen and (max-width: 480px) {
    font-size: 3.5rem;
  }
`;

const LogoParagraph = styled.div`
  font-family: 'Montserrat';
  text-align: center;
  font-weight: 500;
  font-size: 2rem;
  color: #ffffff;
  padding-top: 0.5rem;
  @media screen and (max-width: 480px) {
    font-size: 1.5rem;
  }
`;

const Logos = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 5rem 0 0 0;
  img {
    /* width: 15.6rem;
        height: 14.6rem;
        object-fit: contain;
        padding: 0 2rem;
        background-color: white;
        border-radius: 2rem; */
    width: 100%;
    height: auto;
  }
  @media screen and (max-width: 480px) {
    img {
      width: 6.6rem;
      height: 6.6rem;
    }
  }
  @media screen and (min-width: 480px) and (max-width: 768px) {
    img {
      width: 13.6rem;
      height: 13.6rem;
    }
  }
`;

const LogoContaine = styled.div`
  width: 15.6rem;
  height: 14.6rem;
  padding: 0 2rem;
  background-color: white;
  border-radius: 2rem;
  img {
    width: 11.6rem;
    height: 14.6rem;
    object-fit: contain;
  }
`;

export default () => {
  const isLoggedIn = AuthStore.useStoreState((state) => state.isLoggedIn);

  return (
    <>
      <ImageContainer url="src/Assets/images/Concept2.png">
        <Image>
          <img className="" src={qui1} alt="Le concept" />
        </Image>
        <ImageText
          height={70}
          top={7}
          left={8}
          leftResponsive={0}
          topResponsive={0}
        >
          <p>
            Pionnier des ventes évènementielles premium en France depuis 2006,
            <span className="purple">
              &nbsp;BazarChic propose chaque jour 14 nouvelles ventes
              sélectionnées avec soin à ses 2 millions de membres actifs.
            </span>
          </p>
          <p>
            Nous sommes sans cesse à la recherche de
            <span className="purple">
              &nbsp;nouveaux créateurs pour répondre au mieux aux envies de
              notre communauté
            </span>
          </p>
          <p>
            Filiale du Groupe Galeries Lafayette, nous sommes spécialistes
            <span className="purple">
              &nbsp;des univers de la mode, de la maison, de l’épicerie & du
              vin.
            </span>
          </p>
        </ImageText>
      </ImageContainer>
      <LogoContainer>
        <LogoTitle>De nouvelles ventes par jour</LogoTitle>
        <LogoParagraph>avec des remises allant jusqu'a 70%</LogoParagraph>
        <Logos>
          <LogoContaine>
            <img src={Caroll} alt="Caroll" />
          </LogoContaine>
          <LogoContaine>
            <img src={MaxMoi} alt="Max&Moi" />
          </LogoContaine>
          <LogoContaine>
            <img src={Jonak} alt="Jonak" />
          </LogoContaine>
          <LogoContaine>
            <img src={Bobochic} alt="Bobochic" />
          </LogoContaine>
        </Logos>
      </LogoContainer>
      <ImageContainer>
        <Image>
          <img src={Concept2_2} alt="Les offres BazarChic" />
        </Image>
        <ImageText
          height={30}
          top={32}
          left={50}
          leftResponsive={0}
          topResponsive={0}
        >
          <p
            style={{
              fontWeight: 500,
              textAlign: 'center',
              marginBottom: '3rem',
            }}
          >
            {isLoggedIn
              ? 'Découvrez dès maintenant les plus belles offres du moment !'
              : 'Inscrivez-vous pour rejoindre notre communauté exclusive et découvrir les ventes du moment !'}
          </p>
          {isLoggedIn ? (
            <div style={{ width: '28rem', marginBottom: '10rem' }}>
              <Button block>
                <Link to="/">DÉCOUVRIR LES OFFRES</Link>
              </Button>
            </div>
          ) : (
            <div style={{ width: '20rem', marginBottom: '10rem' }}>
              <Button
                block
                onClick={() => {
                  LoginRegister({
                    isRegister: false,
                  });
                }}
              >
                S'INCRIRE
              </Button>
            </div>
          )}
        </ImageText>
      </ImageContainer>
      {!isLoggedIn && <div style={{ marginBottom: '6rem' }} />}
    </>
  );
};
