import { registerMemberBySocialNetwork, registerMemberBySocialNetworkVariables } from "Libs/__generated__/registerMemberBySocialNetwork";
import { REGISTER_MEMBER_SOCIAL_LOGIN } from "Libs/query";
import { EnvironmentInput } from "__generated__/graphql-global-types";
import { ApolloClient } from '@apollo/client';

// export type LoginBySocialProps = {
//   accessToken?: string;
//   name: string;
//   url: string;
//   environment: EnvironmentInput;
//   graphQLClient: ApolloClient<Object>
// };

export const setRegisterSocial = async (
  graphQLClient: ApolloClient<Object>,
  name: string,
  url: string,
  environment: EnvironmentInput,
  accessToken?: string,
) => {
  return graphQLClient.mutate({
    mutation: REGISTER_MEMBER_SOCIAL_LOGIN,
    variables: {
      name: name,
      url: url,
      environment: environment,
      accessToken: accessToken,
    },
  });
};