import React, { useEffect } from 'react';
import styled, { css } from 'styled-components/macro';
import { theme } from 'Ui/theme';
import AuthStore from 'Store/AuthStore';
import dayjs from 'dayjs';
import { PageContainer } from 'Ui';
import IsClubPrivilege from 'Assets/images/isClubPrivilege.jpg';
import InscriptionClubPrivilege from 'Assets/images/inscriptionClubPrivilege.jpg';
import { PictoMobile, NewCloche } from 'Ui/Icons/icons';
import { setGtmUserData } from 'Libs/Gtm/common';
import UiStore from 'Store/UiStore';
import { getPageView } from 'Libs/GA4/common';

const PageTitle = styled.div`
  font-family: 'granville-bold';
  font-weight: 700;
  font-size: 4.5rem;
  color: ${theme.light.colors.purple};
  text-align: center;
  padding: 5rem 0;
`;

const PrivilegeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media screen and (max-width: 48rem) {
    img {
      display: none;
    }
    flex-direction: column;
  }
`;

const PrivilegeInformation = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-between;
`;

const Image = styled.div`
  text-align: center;
  font-size: 4.6rem;
  img {
    width: 72px;
    height: 86px;
  }
  svg path {
    stroke-width: 3px;
  }
`;

const MainTitle = styled.div`
  font-family: 'MontserratBold';
  font-size: 2rem;
  color: #2f2f2f;
  margin: 0 auto;
  text-align: center;
  line-height: 2.9rem;
  margin-bottom: 4.5rem;
`;

const SecondaryParagraph = styled.div`
  font-family: 'MontserratMedium';
  font-size: 1.7rem;
  color: ${theme.light.colors.purple};
  // margin: 0rem auto 1.5rem auto;
  &::nth-child(4n) {
    margin: 0rem auto 6.5rem auto;
  }
  text-align: center;
  width: 100%;
  line-height: 2.9rem;
`;

const ThirdParagraph = styled.div`
  font-family: 'MontserratMedium';
  font-size: 1.7rem;
  color: #2f2f2f;
  margin: 4.1rem auto 0rem auto;
  text-align: center;
  width: 100%;
  line-height: 2.9rem;
`;
const InformationContainer = styled.div`
  // display: flex;
  // flex-direction: column;
`;

export default () => {
  const member = AuthStore.useStoreState((state) => state.member);
  const sessionMemberType = AuthStore.useStoreState(
    (state) => state.sessionMemberType
  );
  const cookieMarketingStore = UiStore.useStoreState(
    (state) => state.cookieMarketing
  );

  const isVipSince = member && member.isVipSince;
  const isVipSinceDateFormat = dayjs(isVipSince)
    .add(1, 'year')
    .format('DD/MM/YYYY');

  // const clubPrivilegeImg =
  //   member && member.isVip ? IsClubPrivilege : InscriptionClubPrivilege;
  const clubPrivilegeImg = false;

  useEffect(() => {
    getPageView(window.location.pathname, window.location.href);
    if (cookieMarketingStore) {
      setGtmUserData(member, sessionMemberType);
    }
  }, [sessionMemberType]);
  const isNotVipTitle = (
    <MainTitle>
      <p>Pour 16€, devenez</p>
      <p>membre du Club Privilège</p>
      <p>Bazarchic :</p>
    </MainTitle>
  );

  const isVipTitle = (
    <MainTitle>
      <p>
        <span>FÉLICITATIONS</span>
      </p>
      <p>Vous êtes actuellement</p>
      <p>membre du Club Privilège :</p>
    </MainTitle>
  );

  const SecondaryParagraph1 =
    member && member.isVip ? (
      <div>
        <p>Votre invitation aux ventes quotidiennes</p>
        <p>en avant première</p>
      </div>
    ) : (
      <div>
        <p>Recevez votre invitation aux ventes quotidiennes</p>
        <p>en avant première</p>
      </div>
    );

  const SecondaryParagraph2 =
    member && member.isVip ? (
      <div>
        <p>L'accès aux ventes à 19h</p>
        <p>la veille de leur ouverture</p>
      </div>
    ) : (
      <div>
        <p>Accédez aux ventes à 19h</p>
        <p>la veille de leur ouverture</p>
      </div>
    );

  const ThirdParagraph1 =
    member && member.isVip ? (
      <div>
        <p>Durée de l'adhésion :</p>
        <p>jusqu'au {member && isVipSince && isVipSinceDateFormat}</p>
      </div>
    ) : (
      <div>
        <p>Durée de l'adhésion :</p>
        <p>1 an à compter du jour de la souscription</p>
      </div>
    );

  return (
    <PageContainer>
      <div style={{ paddingBottom: '5rem' }}>
        <PageTitle>Le club privilège</PageTitle>
        <PrivilegeContainer>
          <div>
            <img src={clubPrivilegeImg} alt="" />
          </div>
          <PrivilegeInformation>
            {member && member.isVip ? isVipTitle : isNotVipTitle}
            <InformationContainer>
              <Image>
                <PictoMobile />
              </Image>
              <SecondaryParagraph>{SecondaryParagraph1}</SecondaryParagraph>
            </InformationContainer>

            <InformationContainer>
              <Image>
                <NewCloche />
              </Image>
              <SecondaryParagraph>{SecondaryParagraph2}</SecondaryParagraph>
            </InformationContainer>
            <ThirdParagraph>{ThirdParagraph1}</ThirdParagraph>
          </PrivilegeInformation>
        </PrivilegeContainer>
      </div>
    </PageContainer>
  );
};
