import * as React from "react";
const SvgLock = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 32 32"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.293 29.638h18.755V13.187H6.293zm4.353-22.04a5.03 5.03 0 0 1 5.024-5.025 5.03 5.03 0 0 1 5.025 5.025v4.297H10.646zm11.364 4.226V7.34A6.347 6.347 0 0 0 15.67 1a6.347 6.347 0 0 0-6.34 6.34v4.484H5V31h21.34V11.824z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16.757 20.556a1.46 1.46 0 0 0-.461 1.059v2.789a.626.626 0 0 1-1.25 0v-2.79c0-.396-.168-.782-.461-1.058a1.585 1.585 0 0 1 1.086-2.735 1.585 1.585 0 0 1 1.086 2.735m-1.086-4.011a2.946 2.946 0 0 0-2.943 2.943c0 .804.335 1.582.919 2.135v2.658c0 1.115.907 2.023 2.024 2.023a2.025 2.025 0 0 0 2.023-2.023v-2.658a2.95 2.95 0 0 0 .919-2.135 2.946 2.946 0 0 0-2.942-2.943"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgLock;
