import React, { useState } from 'react';
import styled from 'styled-components/macro';

const Figure = styled.figure`
  width: 100%;
  height: 49.1rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-repeat: no-repeat;
  &:hover img {
    opacity: 0;
  }
  img {
    display: block;
    width: 100%;
    pointer-events: none;
  }
`;

export const ImageZoom = ({ src }: { src: string }) => {
  const [backgroundPosition, setBackgroundPosition] = useState('0% 161%');
  const [backgroundDisplay, setBackgroundDisplay] = useState(false);
  const onMouseMove = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setBackgroundDisplay(true);
    // @ts-ignore
    const { left, top, width, height } = e.target.getBoundingClientRect();
    const viewPortTop = top + window.scrollY;
    const viewPortLeft = left + window.scrollX;
    const x = ((e.pageX - viewPortLeft) / width) * 100;
    const y = ((e.pageY - viewPortTop) / height) * 100;
    setBackgroundPosition(`${x}% ${y}%`);
  };

  const onMouseOutMove = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setBackgroundPosition(`0% 161%`);
    setBackgroundDisplay(false);
  };

  return (
    <Figure
      onMouseOut={onMouseOutMove}
      onMouseMove={onMouseMove}
      style={
        backgroundDisplay
          ? { backgroundPosition, backgroundImage: `url("${src}")` }
          : {}
      }
    >
      <img alt="" src={src} />
    </Figure>
  );
};
