import * as React from "react";
const SvgMyPref = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="currentColor" clipPath="url(#myPref_svg__a)">
      <path
        stroke="currentColor"
        strokeWidth={0.2}
        d="M12.952 1.615 5.928 3.287a1.86 1.86 0 0 0-1.167.867c-.26.439-.338.969-.219 1.473l3.726 15.652c.12.504.43.942.86 1.216.429.275.944.364 1.431.248l7.025-1.672a1.86 1.86 0 0 0 1.166-.867c.26-.439.339-.968.22-1.473L15.242 3.08a2 2 0 0 0-.859-1.217 1.86 1.86 0 0 0-1.432-.248ZM5.635 6.666l9.1-2.167 2.72 11.42-9.102 2.166zm.49-2.55 7.024-1.673a1.05 1.05 0 0 1 .81.14c.242.155.416.402.484.687l.096.402-9.101 2.166-.096-.401a1.12 1.12 0 0 1 .123-.832 1.05 1.05 0 0 1 .66-.49Zm11.262 16.127-7.025 1.673a1.05 1.05 0 0 1-.809-.14 1.12 1.12 0 0 1-.485-.687l-.517-2.176 9.1-2.166.518 2.175c.068.285.024.584-.123.832a1.05 1.05 0 0 1-.66.49Z"
      />
      <path d="M13.572 20.277a.64.64 0 0 0 .467-.779.64.64 0 0 0-.768-.484.64.64 0 0 0-.467.778.64.64 0 0 0 .768.485" />
    </g>
    <defs>
      <clipPath id="myPref_svg__a">
        <path
          fill="#fff"
          d="m.476 2.501 18.272-4.35L24.307 21.5l-18.273 4.35z"
        />
      </clipPath>
    </defs>
  </svg>
);
export default SvgMyPref;
