import * as React from "react";
const SvgChatbox = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 36"
    {...props}
  >
    <path
      fill="url(#chatbox_svg__a)"
      stroke="#fff"
      strokeWidth={0.2}
      d="M30.499 25.848v.739a4.3 4.3 0 0 1-1.234 3.014 4.18 4.18 0 0 1-2.973 1.25H14.239l-4.378 4.445c-.344.346-.81.54-1.294.54-.485-.001-.95-.197-1.293-.544a1.87 1.87 0 0 1-.537-1.31v-3.125h-2.03a4.18 4.18 0 0 1-2.973-1.25A4.3 4.3 0 0 1 .5 26.591V12.648a4.3 4.3 0 0 1 1.234-3.015 4.18 4.18 0 0 1 2.973-1.25h9.746c.575 0 1.041.472 1.041 1.056 0 .582-.466 1.055-1.041 1.055H4.7a2.12 2.12 0 0 0-1.5.632c-.399.404-.623.951-.624 1.522v13.944c.001.571.225 1.118.623 1.521.398.404.938.631 1.501.633h3.072c.276 0 .54.111.736.31.195.197.305.466.305.745v3.574l4.259-4.323c.195-.2.461-.312.739-.312h12.495a2.11 2.11 0 0 0 1.5-.632c.399-.403.623-.95.625-1.521v-.739c.064-.53.507-.926 1.033-.926s.969.397 1.033.926Zm17.18-21.419v13.945a4.3 4.3 0 0 1-1.233 3.014 4.18 4.18 0 0 1-2.973 1.25h-2.03v3.13a1.87 1.87 0 0 1-.538 1.31 1.82 1.82 0 0 1-2.586.005l-4.379-4.445H21.882a4.18 4.18 0 0 1-2.973-1.25 4.3 4.3 0 0 1-1.234-3.014V4.429a4.3 4.3 0 0 1 1.234-3.014 4.18 4.18 0 0 1 2.973-1.25h21.586c1.115 0 2.186.448 2.976 1.248s1.234 1.884 1.235 3.016Zm-2.082 0a2.17 2.17 0 0 0-.624-1.521 2.12 2.12 0 0 0-1.5-.632h-21.59a2.12 2.12 0 0 0-1.501.632c-.398.403-.622.95-.624 1.521v13.945c.002.57.226 1.117.624 1.52.398.404.938.632 1.5.633h12.495c.278 0 .544.112.74.311l4.259 4.323v-3.578c0-.583.466-1.056 1.04-1.056h3.073a2.12 2.12 0 0 0 1.5-.632c.398-.404.623-.95.624-1.521V4.429zm-18.18 5.004c-.427 0-.836.171-1.137.477s-.472.72-.472 1.153.17.848.472 1.154.71.477 1.137.477.836-.171 1.137-.477a1.643 1.643 0 0 0 0-2.307 1.6 1.6 0 0 0-1.137-.477Zm5.618 0c-.427 0-.836.171-1.138.477s-.47.72-.47 1.153.168.848.47 1.154a1.6 1.6 0 0 0 1.138.477c.426 0 .836-.171 1.137-.477s.471-.72.471-1.154c0-.432-.169-.847-.47-1.153a1.6 1.6 0 0 0-1.138-.477Zm5.618 0c-.428 0-.836.171-1.138.477a1.64 1.64 0 0 0 0 2.307c.302.306.71.477 1.137.477s.836-.171 1.138-.477a1.643 1.643 0 0 0 .004-2.303 1.6 1.6 0 0 0-1.131-.481z"
    />
    <defs>
      <linearGradient
        id="chatbox_svg__a"
        x1={24.09}
        x2={24.09}
        y1={0.164}
        y2={35.836}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6C4DFF" />
        <stop offset={1} stopColor="#8268FF" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgChatbox;
