import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import Preference from 'Components/PreferenceCenter/Preference';
import AutologPreference from 'Components/Autolog/AutologPreference';
import BcBackgroundPage from 'Ui/Icons/svg/BCBackgroundPage.svg';
import { theme } from 'Ui';
import { getPageView } from 'Libs/GA4/common';

const Background = styled.div`
  /* background: linear-gradient(
    180deg,
    rgba(108, 77, 255, 0.06) 0%,
    rgba(252, 252, 252, 0.06) 100%
  ); */
  background-image: url(${BcBackgroundPage}),
    linear-gradient(
      180deg,
      rgba(108, 77, 255, 0.06) 0%,
      rgba(252, 252, 252, 0.06) 100%
    );
  background-repeat: no-repeat;
  height: 100vh;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 768px) {
    background-image: none;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

export const PageContainer = styled.div`
  max-width: 85rem;
  margin: 0 auto;
  padding: 2.4rem 1.6rem;
  @media screen and (min-width: ${theme.breakpoints.medium}px) {
    padding: 0rem 3rem;
  }
  @media screen and (min-width: ${theme.breakpoints.large}px) {
    padding: 0;
  }
`;
export default () => {
  let params: string = '';
  if (window.location.search.length > 0) {
    params = `${window.location.search}`;
  }
  let redirectTO = `/centre-preferences/${params}`;

  useEffect(() => {
    getPageView(window.location.pathname, window.location.href);
  }, []);
  const PreferenceCenter = () => (
    <Background>
      <PageContainer>
        <Preference
          backgroundColor={false}
          toHomepage={true}
          legalText={true}
          preferencePageTitle={true}
          preferenceEmail={true}
        />
      </PageContainer>
    </Background>
  );
  return (
    <AutologPreference component={PreferenceCenter} redirectTo={redirectTO} />
  );
};
