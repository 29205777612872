// import { InMemoryCache } from "apollo-cache-inmemory";
import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client';
// import ApolloClient from "apollo-client";
// import { ApolloLink, from } from "apollo-link";
// import { setContext } from "apollo-link-context";
import { setContext } from '@apollo/client/link/context';
// import { onError } from "apollo-link-error";
import { onError } from '@apollo/client/link/error';
// import { createHttpLink } from "apollo-link-http";
// import { RetryLink } from "apollo-link-retry";
import { RetryLink } from '@apollo/client/link/retry';
import { toast } from 'Components/Toaster';
import { getCookie } from 'Utils/cookie';
import { errorMessages } from 'Utils/errorMessages';
import { DeviceEnum, ModuleEnum } from '__generated__/graphql-global-types';
import Cookies from 'js-cookie';
import { isMobile } from 'react-device-detect';
import 'unfetch/polyfill';


const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URL,
  credentials: 'include',
  fetchOptions: {
    fetch,
    credentials: 'include',
  },
});

let asExternal: any = {};
if (getCookie('asExternal')) {
  asExternal = { 'x-as-external': getCookie('asExternal') };
}

// const authLink = setContext((_, { headers }) => {
//   const token = sessionStorage.getItem('access_token');

//   return {
//     credentials: 'include',
//     headers: {
//       ...headers,
//       authorization: token ? `Bearer ${token}` : '',
//       'x-device': isMobile ? DeviceEnum.MOBILE : DeviceEnum.DESKTOP,
//       'x-os': ModuleEnum.SITE,
//       ...asExternal,
//     },
//   };
// });

const authLink = new ApolloLink((operation, forward) => {
  const token = sessionStorage.getItem('access_token');
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : '',
      'x-device': isMobile ? DeviceEnum.MOBILE : DeviceEnum.DESKTOP,
      'x-os': ModuleEnum.SITE,
      ...asExternal,
      ...operation.getContext().headers,
    },
  });

  return forward(operation);
});

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: 1000,
    jitter: true,
  },
  attempts: {
    /** @note only one retry here. If retry is needed, increase this value */
    max: 1,
    retryIf: (error, _operation) => !!error,
  },
});

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (networkError) console.log(`[Network error]: ${networkError}`);
    else if (graphQLErrors)
      graphQLErrors.map(({ extensions }) => {
        if (!extensions || !extensions.bzcCode) return null;
        if (extensions.bzcCode === 'ERROR_INVALID_SESSION_GENERIC') {
          sessionStorage.removeItem('access_token');
          Cookies.remove('ssession');
          const headers = operation.getContext().headers;
          delete headers.Authorization;
          operation.setContext({
            headers,
          });
          return forward(operation);
          //
        } else {
          if (extensions.bzcCode && !!errorMessages[extensions.bzcCode]) {
            toast.error(errorMessages[extensions.bzcCode]);
          } else if (
            extensions.bzcMessage &&
            extensions.bzcCode !== 'ERROR_UNAUTHORIZED'
          ) {
            if (extensions.bzcCode === 'DATA_NOT_FOUND') {
              toast.error(extensions.bzcMessage);
              setTimeout(() => {
                // window.location.href = "/";
              }, 6000);
            } else {
              if (extensions.bzcCode === errorMessages[extensions.bzcCode]) {
                toast.error(extensions.bzcMessage);
              }
              if (extensions.bzcCode === 'STOCK_QUANTITY_ERROR') {
                toast.error(extensions.bzcMessage);
              }
              if (
                extensions.bzcCode === 'ERROR_PRODUCT_QUANTITY_OUT_OF_STOCK'
              ) {
                toast.error(extensions.bzcMessage);
              }
              if (extensions.bzcCode === 'ERROR_TECHNICAL_GENERIC') {
                toast.error(extensions.bzcMessage);
              }
            }
          }
        }
        return null;
      });
  }
);

export const init = () => {
  return new ApolloClient({
    link: ApolloLink.from([errorLink, authLink, retryLink, httpLink]),
    cache: new InMemoryCache(),
  });
};
