import * as React from "react";
const SvgSearch = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 20 21"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.3}
      d="M11.231 2.457A6.2 6.2 0 0 0 6.838.65a6.2 6.2 0 0 0-4.38 1.838A6.25 6.25 0 0 0 .65 6.898a6.25 6.25 0 0 0 1.824 4.405 6.2 6.2 0 0 0 4.385 1.822 6.23 6.23 0 0 0 4.394-1.846 6.28 6.28 0 0 0 1.817-4.426 6.25 6.25 0 0 0-1.839-4.396Zm0 0 .459-.461z"
    />
    <path
      fill="currentColor"
      d="M19.032 20.276a.815.815 0 0 0-.003-1.15l-5.302-5.305a.815.815 0 0 0-1.153 1.153l5.302 5.304c.32.32.837.319 1.156-.002"
    />
  </svg>
);
export default SvgSearch;
