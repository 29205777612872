import React from "react";
import styled from "styled-components/macro";

const LinkMobileStyled = styled.div`
  display: block;
`;

interface LinkMobileProps {
  img: string;
  url: string | undefined;
  target: string;
  onClick: any;
}

export const LinkAppMobile = ({ img, url, target, onClick }: LinkMobileProps) => {
  return (
    <LinkMobileStyled>
      <a href={url} className="footer_apps_ico" target={target} onClick={onClick}>
        <img src={img} alt="Logo App mobile" />
      </a>
    </LinkMobileStyled>
  );
};
