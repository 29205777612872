import * as React from "react";
const SvgIconpreference = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 30 25"
    {...props}
  >
    <path
      fill="#2F2F2F"
      d="M22.244 9.13C21.24 4.975 19.274.5 17.331.5q-.145 0-.284.034c-.423.102-.76.424-1.008.948-.026.02-.055.036-.076.064-1.087 1.39-4.98 5.98-9.422 7.054l-3.394.876-.04.011A4.21 4.21 0 0 0 .626 11.34a4.15 4.15 0 0 0-.506 3.181 4.15 4.15 0 0 0 1.903 2.6c.958.577 2.09.749 3.188.484l.704-.17.847-.205L9.569 23a2.116 2.116 0 0 0 2.826.976 2.116 2.116 0 0 0 .974-2.826l-2.27-4.665c4.054-.034 8.194 1.85 9.14 2.309.338.252.681.392 1.024.392q.145 0 .284-.034c.897-.216 1.412-1.408 1.49-3.447.071-1.83-.21-4.165-.793-6.574M4.998 16.733a3.32 3.32 0 0 1-2.513-.381 3.26 3.26 0 0 1-1.493-2.04 3.26 3.26 0 0 1 .397-2.499 3.32 3.32 0 0 1 2.029-1.479l.299-.072 1.549 6.406zm7.003 6.436a1.216 1.216 0 0 1-1.624-.56l-1.02-2.095 2.215-1 .99 2.031c.293.602.04 1.33-.561 1.624m-.822-4.464-2.215 1-1.31-2.69 1.041-.252a10 10 0 0 1 1.427-.23zM8.484 15.89l-2.345.567-1.548-6.407 2.161-.523c3.735-.903 7.056-4.102 8.842-6.118q-.025.275-.037.573c-.037.953.024 2.045.171 3.21.002.017-.002.033.002.05q.003.008.007.015a31.485 31.485 0 0 0 1.605 6.605c.003.02-.002.041.003.062a.4.4 0 0 0 .08.16c.443 1.19.948 2.279 1.488 3.146-2.416-.959-6.671-2.248-10.43-1.34m8.205-8.3c.677.185 1.48 1.11 1.82 2.516.338 1.398.052 2.58-.46 3.062a30 30 0 0 1-.825-2.823 31 31 0 0 1-.535-2.756m5.45 8.081c-.064 1.684-.45 2.523-.803 2.609a.3.3 0 0 1-.073.008.6.6 0 0 1-.264-.072.44.44 0 0 0-.142-.118q-.03-.016-.14-.07c-.65-.537-1.527-1.893-2.342-3.978 1.012-.622 1.454-2.311 1.008-4.156-.448-1.853-1.618-3.16-2.813-3.238a18 18 0 0 1-.115-2.64c.065-1.683.45-2.523.803-2.608a.3.3 0 0 1 .073-.008c.867 0 2.804 2.828 4.04 7.942.563 2.331.836 4.579.769 6.329M25.675 8.854a.45.45 0 0 1-.097-.886l2.935-.72a.45.45 0 0 1 .214.873l-2.935.72a.4.4 0 0 1-.117.013M23.452 3.945a.449.449 0 0 1-.272-.792l2.647-2.25a.45.45 0 0 1 .582.684l-2.647 2.251a.44.44 0 0 1-.31.107M29.627 15.034a.44.44 0 0 1-.184-.007l-3.254-.804a.449.449 0 1 1 .215-.872l3.254.804a.449.449 0 0 1-.03.879"
    />
  </svg>
);
export default SvgIconpreference;
