import * as React from "react";
const SvgChevronPaiement = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      d="m4.795 8.506 6.505 6.005a1 1 0 0 0 1.385-.028l6.078-6.078"
    />
  </svg>
);
export default SvgChevronPaiement;
