import React from 'react';
import styled from 'styled-components/macro';
import { theme } from './theme';
import { Link } from 'Ui';
import IconeRight from './Icons/icons/IconeRight';

type Props = {
  path: { url: string; label: string; internal?: boolean }[];
  viewHome?: boolean;
};

const BreadCrumbs = styled.div`
  display: flex;
  height: 3.5rem;
  align-items: center;
`;

const BreadCrumb = styled.div`
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  font-family: 'Montserrat', sans-serif;
  font-weight: 'regular';

  .home a {
    color: ${theme.light.colors.purple};
    font-family: 'MontserratBold';
  }

  a {
    color: ${theme.light.colors.black};
  }
  .separator {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  svg path {
    stroke-width: 3 !important;
  }
`;

export const Breadcrumb = ({ path, viewHome }: Props) => {
  return (
    <BreadCrumbs>
      <BreadCrumb>
        {viewHome && (
          <>
            <div className="home">
              <Link to={'/'} style={{ color: `${theme.light.colors.purple}` }}>
                Accueil
              </Link>
            </div>
            {!!path.length && <IconeRight className="separator" />}
          </>
        )}
      </BreadCrumb>
      {path.map((step, index) => {
        const lastOne = index >= path.length - 1;
        return (
          <BreadCrumb key={index}>
            <div>
              {!lastOne ? (
                <Link to={step.url} internal={step.internal}>
                  {step.label}
                </Link>
              ) : (
                step.label
              )}
            </div>
            {!lastOne && <IconeRight className="separator" />}
          </BreadCrumb>
        );
      })}
    </BreadCrumbs>
  );
};
