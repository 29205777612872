import React from 'react';
import styled, { createGlobalStyle } from 'styled-components/macro';
import posed, { PoseGroup } from 'react-pose';
import OutsideClickHandler from 'react-outside-click-handler';
import { IconClose } from './Icons';
import { CloseModal } from './Icons/icons';

const theme = {
  padding: '1rem',
  modalWidths: {
    small: 300,
    medium: 600,
    mediumLarge: 650,
    large: 900,
  } as { [index: string]: number },
};

const GlobalStyle = createGlobalStyle`
  body{
    overflow-y : hidden;
  }
`;

const StyledModal = styled.div`
  z-index: 1000001;
  position: fixed;
  top: 0;
  left: 0;
`;

type OverlayProps = {
  isOpen: boolean;
  children?: React.ReactNode;
  onClose?: (e: React.SyntheticEvent) => void;
};

const AnimatedContent = styled(
  posed.div({
    enter: { y: 0, opacity: 1, transition: { duration: 200, delay: 100 } },
    exit: { y: 20, opacity: 0, transition: { duration: 200 } },
  })
)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;

  z-index: 200000;

  display: flex;
  align-items: flex-end;
  @media only screen and (min-width: 48rem) {
    align-items: center;
    // top: 75px;
  }

  .scroller {
    width: 100vw;
    max-height: 100vh;

    @media only screen and (min-width: 48rem) {
      padding: 0 0 3rem 0;
    }
    // text-align: center;
    display: flex;
    justify-content: center;
    overflow-y: auto;
    overflow-x: hidden;
    .content {
      height: auto;
      text-align: initial;
      display: inline-block;
      box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
    }
  }
  overflow: hidden;
`;

const Shade = styled(
  posed.div({
    enter: { opacity: 1, transition: { duration: 300 } },
    exit: { opacity: 0, transition: { duration: 300 } },
  })
)`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1800;
  box-shadow: 0px 1px 9px 0px rgba(0, 0, 0, 0.1);

  background-color: rgba(0, 0, 0, 0.5);
  @media screen and (min-width: 48rem) {
    top: 75px;
  }
`;

export const OverlayTop = ({ isOpen, children, onClose }: OverlayProps) => {
  return (
    <StyledModal>
      <GlobalStyle />
      <PoseGroup>
        {isOpen && [
          <Shade key="shade" />,
          <AnimatedContent key="modal">
            <div className="scroller">
              <div className="content">
                <OutsideClickHandler
                  onOutsideClick={(e) => onClose && onClose(e)}
                >
                  {children}
                </OutsideClickHandler>
              </div>
            </div>
          </AnimatedContent>,
        ]}
      </PoseGroup>
    </StyledModal>
  );
};

type ModalProps = {
  isOpen: boolean;
  title?: string;
  onClose?: (e: React.SyntheticEvent) => void;
  children?: React.ReactNode;
  actions?: React.ReactNode;
  size?: 'small' | 'medium' | 'large' | number | 'mediumLarge' | 'full';
  noPadding?: boolean;
};

const ModalStyled = styled.div<{ size: string | number }>`
  display: flex;
  /* box-shadow: 0px 1px 9px 0px rgba(0, 0, 0, 0.1); */
  position: relative;
  flex-direction: column;
  background-color: white;
  min-width: 30rem;

  max-width: 100vw;
  width: 100vw;
  @media only screen and (min-width: 48rem) {
    border-radius: 0 0 3px 3px;
    width: ${(props) => {
      if (typeof props.size === 'number') return props.size + 'px';
      else return theme.modalWidths[props.size] + 'px';
    }};
  }
`;

const ModalTitle = styled.div`
  display: flex;
  height: 6rem;
  font-size: 1.6rem;
  box-sizing: border-box;
  justify-content: space-between;
  align-content: center;
  padding: ${theme.padding};
  border-bottom: 1px solid #ccc;
`;

const ModalClose = styled.div`
  z-index: 1;
  right: 3.5rem;
  top: 5px;
  z-index: 1000;
  position: absolute;
  font-size: 1.6rem;
  box-sizing: border-box;
  justify-content: flex-end;
  align-content: center;
  padding: ${theme.padding};
  .close {
    cursor: pointer;
  }

  @media screen and (max-width: 480px) {
    padding: 1rem 1rem 0 0;
    right: 1.5rem;
  }
`;

const ModalBody = styled.div<{ noPadding: boolean }>`
  display: flex;
  justify-content: space-between;
  padding: ${(props) => (props.noPadding ? '0' : theme.padding)};
  flex: 1 1 auto;
`;

const ModalActions = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: ${theme.padding};
`;
export const ModalTop = ({
  isOpen,
  title,
  onClose,
  children,
  actions,
  noPadding,
  size = 'medium',
}: ModalProps) => {
  return (
    <OverlayTop isOpen={isOpen} onClose={onClose}>
      <ModalStyled key="modal" size={size}>
        {title && (
          <ModalTitle>{title && <div>{title.toUpperCase()}</div>}</ModalTitle>
        )}
        <ModalClose>
          <CloseModal onClick={onClose} className="close" />
        </ModalClose>
        <ModalBody noPadding={noPadding || false}>{children}</ModalBody>
        {actions && <ModalActions>{actions}</ModalActions>}
      </ModalStyled>
    </OverlayTop>
  );
};
