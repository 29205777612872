import React from 'react';

import { ApolloProvider } from '@apollo/client';
import { init } from 'Libs/apollo';
import { GlobalStyle, AppStyled } from '../GlobalStyle';
import AuthStore from 'Store/AuthStore';
import InitiStore from 'Store/InitiStore';
import UiStore from 'Store/UiStore';

import PreferenceCenter from 'Components/PreferenceCenter/Preference';
import ModalContainer from 'react-modal-promise';

export default () => {
  const client = init();

  return (
    <AppStyled>
      <AuthStore.Provider initialData={client}>
        <UiStore.Provider>
          <ApolloProvider client={client}>
            <InitiStore />
            <GlobalStyle />
            <PreferenceCenter />
            <ModalContainer />
          </ApolloProvider>
        </UiStore.Provider>
      </AuthStore.Provider>
    </AppStyled>
  );
};
