import gql from 'graphql-tag';

export const SET_SESSION_SITE = gql`
  mutation setSessionSite($tag: String) {
    sessionSite(tag: $tag) {
      outputUrl
    }
  }
`;

export const SESSION_SITE = gql`
  mutation sessionSite($tag: String) {
    sessionSite(tag: $tag) {
      outputUrl
    }
  }
`;

export const REGISTER_MEMBER_SOCIAL_LOGIN = gql`
  mutation registerMemberBySocialNetwork(
    $accessToken: String
    $name: String!
    $url: String!
    $environment: EnvironmentInput!
  ) {
    registerMemberBySocialNetwork(
      environment: $environment
      subscriptionURI: $url
      socialNetwork: { name: $name, accessToken: $accessToken }
    ) {
      jwt
      sessionId
      isCompletedProfile
      redirectUri
      member {
        firstName
        lastName
        email
        birthDate
        civility
        gender
        id
      }
      code
      message
    }
  }
`;
