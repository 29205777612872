import * as React from "react";
const SvgMenu = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 32 32"
    {...props}
  >
    <g stroke="currentColor" strokeLinecap="square" strokeWidth={2}>
      <path d="M2 8.25h28M2 15.75h28M2 23.25h28" />
    </g>
  </svg>
);
export default SvgMenu;
