import gql from "graphql-tag";

export const InfoProduct = gql`
  fragment InfoProduct on Product {
    id
    rangeId
    groupId
    ecoResponsibleLabel {
      hasEcoResponsibleLabel
      facetEcoResponsibleLabel
    }
    sizeDatas {
      productId
      stock
      size
      maxQuantity
      sellPrice
      publicPrice
    }

    imagesDatas {
      urlListPage
    }
    title
    sellPrice
    publicPrice
    appliedPrice
    discountPercentage
    brandDatas{
      id
      name
    }
    unsuitableForUnderaged
    saleId
    isTissue
    availableQuantity {
      quantity
      sizeId
    }
    ecoParticipation
  }
`;

export const ProductNav = gql`
  fragment ProductNav on Product {
    id
    saleId
    title
    brandDatas {
      name
    }
    imagesDatas{urlListPage}
  }
`;

export const EcoResponsability = gql`
  fragment EcoResponsability on Product {
    ecoResponsibleLabel {
      hasEcoResponsibleLabel
    }
  }
`;

