import { ApolloClient } from "@apollo/client";
import {
  setTrackViewIformation,
  setTrackViewIformationVariables,
} from "Pages/Sale/Product/__generated__/setTrackViewIformation";
import { SET_TRACKVIEW_INFORMATION } from "Pages/Sale/Product/query";

import {
  setTrackPageView,
  setTrackPageViewVariables,
} from "Pages/Sale/Product/__generated__/setTrackPageView";
import { SET_TRACK_PAGE_VIEW } from "Pages/Sale/Product/query";
import { PageViewKeyEnum } from "__generated__/graphql-global-types";
import { OsEnum, VersionPopinEnum, DeviceEnum } from '__generated__/graphql-global-types';
import { REGISTER_RGPD_BEHAVIOUR } from 'Components/modals/query';
import {
  RegisterRgpdBehaviour,
  RegisterRgpdBehaviourVariables,
} from 'Components/modals/__generated__/RegisterRgpdBehaviour';

//Affiliation
import {
  setSessionSite,
  setSessionSiteVariables,
} from './__generated__/setSessionSite';
import { SET_SESSION_SITE } from "./query";

export const trackViewInforamtion = async (
  infos: any,
  apolloClient: ApolloClient<Object>
) => {
  try {
    await apolloClient.mutate<
      setTrackViewIformation,
      setTrackViewIformationVariables
    >({
      mutation: SET_TRACKVIEW_INFORMATION,
      variables: {
        infos,
      },
    });
  } catch (e) {
    console.log({ e });
  }
};

export const trackPageView = async (
  infos: any,
  apolloClient: ApolloClient<Object>
) => {
  try {
    await apolloClient.mutate<setTrackPageView, setTrackPageViewVariables>({
      mutation: SET_TRACK_PAGE_VIEW,
      variables: {
        infos,
      },
    });
  } catch (e) {
    console.log({ e });
  }
};

export const dectectBlockerPub = (
  apolloClient: ApolloClient<Object>,
  pageViewKeyEnumMember: PageViewKeyEnum,
  pageViewKeyEnumNormal: PageViewKeyEnum,
  memberId: any
) => {
  const wp = document.getElementById("wrapperBLock");

  if (wp && wp.clientHeight > 0) {
    const infos = [
      {
        key: pageViewKeyEnumMember,
        value: `${memberId}`,
      },
      {
        key: PageViewKeyEnum.NORMAL,
        value: "1",
      },
    ];
    trackPageView(infos, apolloClient);
  }
};

export const registerUserRgpdBehaviou = (
  apolloClient: ApolloClient<Object>,
  sdkPerf: boolean,
  sdkMarket: boolean,
  btnPersonnalize: boolean,
  btnAccept: boolean,
  btnDeny: boolean,
  linkPvp: boolean,
  deviceType: DeviceEnum
) => {
  try {
    const versionPopin = VersionPopinEnum.V2
    apolloClient.mutate<
      RegisterRgpdBehaviour,
      RegisterRgpdBehaviourVariables
    >({
      mutation: REGISTER_RGPD_BEHAVIOUR,
      variables: {
        os: OsEnum.SITE,
        sdkPerf,
        sdkMarket,
        btnPersonnalize,
        btnAccept,
        btnDeny,
        linkPvp,
        versionPopin,
        deviceType
      },
    });
  } catch (error) {
    console.log(error);
  }
}


export const affiliation = async (
  location: any,
  history: any,
  pub: string,
  apolloClient: ApolloClient<Object>
) => {
  const url = location.pathname.split("/pub/")
  try {
    const { data } = await apolloClient.mutate<
      setSessionSite,
      setSessionSiteVariables
    >({
      mutation: SET_SESSION_SITE,
      variables: {
        tag: pub,
      },
    });
    const outputUrl = data && data.sessionSite && data.sessionSite.outputUrl || undefined
    if (outputUrl) {
      history.push(outputUrl)
    } else {
      history.push(url[0])
    }
  } catch (e) {
    console.log({ e });
  }
};
