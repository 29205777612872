import { theme } from 'Ui';
import styled from 'styled-components';

const LinkToContainer = styled.div`
  border-radius: 0.3rem;
  border: 1px solid ${theme.v2.border.default};
  background: ${theme.v2.bg.cards.gray};
  display: flex;
  font-size: 1.4rem;
  /* width: 31.0625rem; */
  padding: 1.2rem 1.2rem;
  align-items: center;
  justify-content: center;
  margin: 2.4rem 0 2.4rem 0;
  /* gap: 0.625rem; */
`;

const TextTo = styled.div`
  color: ${theme.v2.text.darkGray};
  text-align: center;
  font-family: 'Montserrat' !important;
  font-size: 1.4rem;
  font-style: normal;
  line-height: normal;
`;

const To = styled.div`
  color: ${theme.v2.brand.violet};
  font-family: 'MontserratMedium' !important;
  cursor: pointer;
  font-size: 1.4rem;
  text-decoration: underline;
  margin-left: 5px;
`;

type LinkToProps = {
  textTo: string;
  linkTo: string;
  switchTo: () => void;
};

export const LinkToLoginOrRegister = ({
  textTo,
  linkTo,
  switchTo,
}: LinkToProps) => {
  return (
    <LinkToContainer>
      <TextTo>{textTo}</TextTo>
      <To onClick={switchTo}>{linkTo}</To>
    </LinkToContainer>
  );
};
