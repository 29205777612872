import {
  IconMyInfos,
  IconMyOrders,
  IconMyReturn,
  IconMyPref,
  IconMyFilleuls,
  IconFaqs,
  IconPrivilege,
  IconMailMenu,
} from 'Ui/Icons';
import { Personna, HomeNew, InfoV2, Logout, BonAchat } from 'Ui/Icons/icons';
import { logout } from 'Utils/isLogout';
import {
  Header,
  HeaderMaincontainer,
  HeaderLogoContainer,
  WelcomeMember,
  LinkLine,
  NeedHelp,
  HeaderBackgroundMobileConnected,
} from './style';
import AuthStore from 'Store/AuthStore';
import UiStore from 'Store/UiStore';
import { useApolloClient, useQuery } from '@apollo/client';
import { isAndroid } from 'react-device-detect';
import styled from 'styled-components';
import { getBasket } from 'Components/Header/HeaderBasket/__generated__/getBasket';
import query from 'Components/Header/HeaderBasket/query';

const LinksContainer = styled.div`
  display: flex;
  height: calc(100% - 10.6rem);
  justify-content: space-between;
  flex-direction: column;
  padding: 0 0 1.5rem;
  overflow-y: scroll;
`;

export const SideMenuMember = () => {
  const setAuth = AuthStore.useStoreActions((actions) => actions.setAuth);
  const sideMenuOpen = UiStore.useStoreState((state) => state.sideMenuOpen);
  const setSideMenuOpen = UiStore.useStoreActions(
    (actions) => actions.setSideMenuOpen
  );
  const member = AuthStore.useStoreState((state) => state.member);
  const apolloClient = useApolloClient();
  const linkDownload = isAndroid
    ? '/home/app/p/android/source/interstitiel/'
    : '/home/app/p/ios/source/interstitiel/';
  const { refetch } = useQuery<getBasket>(query);
  return (
    <>
      <Header>
        <HeaderBackgroundMobileConnected>
          <HeaderMaincontainer>
            <WelcomeMember>Bonjour {member && member.firstName}</WelcomeMember>
          </HeaderMaincontainer>
        </HeaderBackgroundMobileConnected>
      </Header>
      <LinksContainer>
        <div>
          <LinkLine
            text="Mes commandes"
            src="/commandes/index/"
            icon={<IconMyOrders />}
            hasBorder={false}
            withIcon={false}
            hasMarginTop={true}
          />
          <LinkLine
            text="Mon compte"
            src="/account/index/"
            icon={<IconMyInfos />}
            hasBorder={false}
            withIcon={false}
          />
          <LinkLine
            text="Mes retours"
            src="/account/retours/"
            icon={<IconMyReturn />}
            hasBorder={false}
            withIcon={false}
          />
          <LinkLine
            text="Aide & contact"
            src="/contact/"
            icon={<IconFaqs />}
            hasBorder={false}
            withIcon={false}
          />
          <LinkLine
            text="Mes préférences de communication"
            src="/account/newsletters/"
            icon={<IconMailMenu />}
            hasBorder={false}
            withIcon={false}
          />
          <LinkLine
            text="Les applis mobile BazarChic"
            src={linkDownload}
            icon={<IconMyPref />}
            hasBorder={false}
            withIcon={false}
          />
          {/* <LinkLine
            text="Club privilège"
            src="/accueil/privilege/"
            icon={<IconPrivilege />}
            hasBorder={false}
            withIcon={false}
          /> */}
          <LinkLine
            text="Parrainage"
            src="/accueil/parrainage/"
            icon={<IconMyFilleuls />}
            hasBorder={false}
            withIcon={false}
          />
        </div>
        <div>
          <LinkLine
            withIcon={false}
            hasBorderTop={true}
            hasBorder={false}
            text="Déconnexion"
            icon={<Logout />}
            onClick={() => {
              logout(apolloClient)
                .then((data) => {
                  if (data) {
                    const payload = {
                      jwt: data.logout.jwt,
                      sessionId: data.logout.sessionId,
                      member: data.logout.member,
                    };
                    if (!!data) {
                      setAuth(payload);
                      refetch();
                      window.location.href = '/';
                    }
                  }
                })
                .catch((err) => {
                  console.log(err);
                  window.location.href = '/';
                });
            }}
          />
        </div>
      </LinksContainer>
    </>
  );
};
