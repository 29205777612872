import * as React from "react";
const SvgLogout = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 20 18"
    {...props}
  >
    <path
      fill="currentColor"
      d="M11.444 8.04H3.436c-.283 0-.349-.293-.141-.5l.883-.955c.731-.731-.01-1.47-.741-.738L2.229 7.04l-1 1c-.305.295-.305.706 0 1l2.207 2.125c.731.73 1.473-.03.742-.75l-.883-.884c-.208-.207-.142-.49.141-.49h8.008c.426 0 .785-.075.785-.5 0-.426-.36-.5-.785-.5"
    />
    <path
      fill="currentColor"
      d="M16.604.545H7.5C6.07.545 5 1.072 5 2.428v1.115c0 .38 0 .767.5.767s.5-.376.5-.767V2.428c0-.585.71-1.01 1.33-1.01h9.48c.623 0 1.19.483 1.19 1.078v11.808c0 .585-.378 1.3-1 1.3H7c-.622 0-1-.345-1-.94v-1.09c0-.38-.096-.793-.5-.793s-.5.404-.5.794v1.088c0 1.355.57 1.882 2 1.882h10c1.43 0 2-.886 2-2.241V2.496c0-1.355-.57-1.95-2-1.95z"
    />
  </svg>
);
export default SvgLogout;
