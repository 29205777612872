import React from 'react';
import styled, { css } from 'styled-components/macro';
import { IconMinus, IconPlus } from './Icons';
import { theme } from 'Ui/theme';

const CounterStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${theme.light.colors.grayLight};
  height: 4.6rem;
  font-size: 1.6rem;
`;

const ButtonDefaultStyled = styled.div<{ disabled: boolean }>`
  user-select: none;
  font-size: 3rem;
  margin: 0 1rem;
  height: 4.6rem;
  justify-content: center;
  display: flex;
  align-items: center;
  line-height: 0;
  ${(props) =>
    props.disabled
      ? css`
          color: #ccc;
          //
        `
      : css`
          cursor: pointer;
          &:hover {
            color: ${theme.light.colors.purple};
          }
          //
        `}
`;

const ValueCounter = styled.div`
  width: 45px;
  color: ${theme.light.colors.black};
  font-size: 2rem;
  font-family: 'MontserratSemiBold', sans-serif;
  font-size: 1.4rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 600;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  disabled: boolean;
`;

interface CounterProps {
  max?: number;
  onChange: (value: number) => void;
  value: number;
  [index: string]: any;
}

export const Counter = ({
  max = 10,
  value,
  onChange,
  ...rest
}: CounterProps) => {
  const increment = () => {
    if (value < max) {
      onChange(value + 1);
    }
  };

  const decrement = () => {
    if (value > 1) {
      onChange(value - 1);
    }
  };

  return (
    <CounterStyled {...rest}>
      <ButtonDefaultStyled disabled={value <= 1} onClick={() => decrement()}>
        <IconMinus />
      </ButtonDefaultStyled>
      <ValueCounter>{value}</ValueCounter>
      <ButtonDefaultStyled disabled={value >= max} onClick={() => increment()}>
        <IconPlus />
      </ButtonDefaultStyled>
    </CounterStyled>
  );
};
