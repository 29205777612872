import * as React from "react";
const SvgLoopSearch = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 25 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M13.3 6.674a4.4 4.4 0 0 1 1.286 3.095 4.47 4.47 0 0 1-1.28 3.12 4.33 4.33 0 0 1-3.067 1.296 4.3 4.3 0 0 1-3.061-1.274A4.42 4.42 0 0 1 5.9 9.804c0-1.17.457-2.285 1.272-3.116A4.3 4.3 0 0 1 10.224 5.4c1.151 0 2.257.457 3.076 1.273m-3.06 8.911c1.53 0 2.99-.627 4.064-1.713a5.87 5.87 0 0 0 1.682-4.106 5.8 5.8 0 0 0-1.697-4.084A5.76 5.76 0 0 0 10.224 4c-1.522 0-2.976.62-4.05 1.705A5.85 5.85 0 0 0 4.5 9.804a5.82 5.82 0 0 0 1.682 4.091 5.7 5.7 0 0 0 4.057 1.69m10.037 3.095a.783.783 0 0 1 0 1.093.767.767 0 0 1-1.088 0L15.3 15.937a.783.783 0 0 1 0-1.094.767.767 0 0 1 1.089 0z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgLoopSearch;
