import React from 'react';
import logoBzc from 'Assets/images/BzcLogo1.svg';
import { PageContainer } from 'Ui';
import styled from 'styled-components/macro';

const LogoStyled = styled.img`
  width: 25rem;
  height: auto;
  @media screen and (max-width: 48rem) {
    width: 19rem;
  }
`;

const AnonymousHeader = styled.div`
  box-shadow: 0 3px 3px rgba(51, 51, 51, 0.1);
  background-color: #fff;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000000;
`;

const HeaderContent = styled.div`
  display: flex;
  justify-content: center;
  height: 9.6rem; //9.3rem
  align-items: center;
  @media screen and (max-width: 48rem) {
    height: 4.6rem;
  }
`;
export default () => {
  return (
    <AnonymousHeader>
      <PageContainer style={{ padding: '0rem 1rem' }}>
        <HeaderContent>
          <a href="/">
            <LogoStyled src={logoBzc} />
          </a>
        </HeaderContent>
      </PageContainer>
    </AnonymousHeader>
  );
};
