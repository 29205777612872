import styled from 'styled-components';
import { theme } from 'Ui';
import AlmaSearch from 'Assets/images/AlmaSearch1.png';
import AuthStore from 'Store/AuthStore';

const Title = styled.div`
  color: ${theme.v2.black};
  font-family: 'AlbraSemiBold' !important;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  @media screen and (max-width: 48rem) {
    font-weight: 500;
  }
`;

const Help = styled.div`
  color: ${theme.v2.black};
  font-family: var(--font-primary);
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  flex-direction: row;
  align-items: center;
  :hover {
    color: ${theme.v2.brand.violet};
  }
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`;

export const Alma = () => {
  const isLoggedIn = AuthStore.useStoreState((state) => state.isLoggedIn);
  const Arrow = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.1548 9.45183L6.65132 14.3303L6.10023 13.8216L10.6037 8.94311C10.7402 8.79521 10.7356 8.56591 10.5933 8.42358L6.03483 3.86513L6.56516 3.3348L11.1236 7.89325C11.5506 8.32023 11.5643 9.00814 11.1548 9.45183Z"
        fill="url(#paint0_linear_9202_2845)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_9202_2845"
          x1="8.74398"
          y1="14.3303"
          x2="8.74398"
          y2="3.3348"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#6C4DFF" />
          <stop offset="1" stop-color="#8268FF" />
        </linearGradient>
      </defs>
    </svg>
  );
  return (
    <MainContainer>
      <Container>
        <Title className="fontGranville">Payer en plusieurs fois</Title>
        <img src={AlmaSearch} alt="Alma - Paiement 3x 4x" />
      </Container>
      <Container>
        <Title className="fontGranville">Besoin d’aide ?</Title>
        <Help>
          <Arrow />
          <a href="/faq/index/">Questions fréquentes</a>
        </Help>
        {isLoggedIn && (
          <Help>
            <Arrow />
            <a href="/commandes/index/">Suivre mes commandes</a>
          </Help>
        )}
      </Container>
    </MainContainer>
  );
};
