import * as React from "react";
const SvgCheckCircle = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 32 32"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      stroke="currentColor"
      d="m13.585 20.526-5.502-5.795-.874.921 6.376 6.714 11.205-11.81-.867-.921z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M.102 16C.102 7.239 7.228.102 16 .102 24.761.102 31.898 7.239 31.898 16S24.772 31.898 16 31.898.102 24.761.102 16m2.193 0c0 7.564 6.141 13.705 13.705 13.705 7.553 0 13.705-6.141 13.705-13.705S23.564 2.295 16 2.295 2.295 8.436 2.295 16"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCheckCircle;
