import { useQuery, useMutation } from '@apollo/client';
import {
  MEMBER_NEWSLETTER_PREFERENCE,
  UNSUBSCRIBE_REASON,
} from 'Components/PreferenceCenter/query';
import { unsubscribeReasons } from 'Components/PreferenceCenter/query/__generated__/unsubscribeReasons';
import {
  updateNewsletterPreference,
  updateNewsletterPreferenceVariables,
} from 'Components/PreferenceCenter/query/__generated__/updateNewsletterPreference';
import { RadioGroup } from 'Components/PreferenceCenter/Radio';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { createModal } from 'react-modal-promise';
import UiStore from 'Store/UiStore';
import styled from 'styled-components';
import { Button, Modal } from 'Ui';

type ModalProps = {
  open: boolean;
  close: (shouldGoToCheckout: boolean) => void;
};

const ResponsiveModal = styled.div`
  @media screen and (max-width: 768px) {
    width: 100%;
    padding-bottom: 3rem;
    .btnVip {
      line-height: 2.5rem;
      width: 50%;
      margin: 2rem auto 0 auto;
    }
  }
`;

const PreferenceContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5rem;
  justify-content: space-between;
  width: 100%;
  .btnVip {
    line-height: 2.5rem;
    width: 80%;
    margin: 2rem auto 2rem auto;
  }
  textarea:focus {
    outline: none !important;
  }
  @media screen and (max-width: 48rem) {
    padding: 1.6rem 1.6rem;
  }
`;

const ModalTitle = styled.div`
  font-family: 'MontserratMedium' !important;
  padding: 7px 0rem 3rem;
  width: 44.1rem;
  font-size: 2.4rem;
  text-align: center;
  margin: auto;
  color: #000;
  @media screen and (max-width: 48rem) {
    width: 100%;
    color: #000;
    text-align: center;
    font-size: 20px;
  }
`;

const PreferenceCenterModal = ({ open, close }: ModalProps) => {
  const { data } = useQuery<unsubscribeReasons>(UNSUBSCRIBE_REASON);
  const reasons = data && data.unsubscribingReasons.map((reason) => reason);
  const [reason, setReason] = useState<any>('');
  const [textareMessage, setTextareaMessage] = useState('');
  const setUnsubscribeReason = UiStore.useStoreActions(
    (actions) => actions.setUnsubscribeReason
  );
  const setUnsubscribeReasonMessage = UiStore.useStoreActions(
    (actions) => actions.setUnsubscribeReasonMessage
  );

  const setIsErrorModalPref = UiStore.useStoreActions(
    (actions) => actions.setIsErrorModalPref
  );
  const setMsgPreference = UiStore.useStoreActions(
    (actions) => actions.setMsgPreference
  );

  const [updatePreferenceNewsletter, { loading }] = useMutation<
    updateNewsletterPreference,
    updateNewsletterPreferenceVariables
  >(MEMBER_NEWSLETTER_PREFERENCE, {
    variables: {
      unsubscribe: {
        unsubscribeId: reason.toString(),
        unsubscribeReason: textareMessage,
      },
      preferences: [
        // periodic daily
        {
          newsletterId: '3',
          status: false,
        },
        // periodic Hebdo
        { newsletterId: '6', status: false },
        // OPC OPS
        {
          newsletterId: '9',
          status: false,
        },
      ],
    },
  });
  useEffect(() => {
    console.log(reason === 18);
    console.log(typeof reason);
  }, [reason]);
  return (
    <Modal
      // style={{ width: '100%' }}
      isOpen={open}
      onClose={() => {
        close(false);
      }}
      size={630}
      noPadding
      className="optinModal"
    >
      <ResponsiveModal>
        <PreferenceContainer>
          <ModalTitle className="fontMontserratMedium">
            Pour quelle raison souhaitez-vous vous désabonner ?
          </ModalTitle>
          {reasons && (
            <RadioGroup
              reverse={true}
              options={reasons}
              value={reason}
              kind={
                reason === '3' || '6' || '9' || '12' || '15' || '18'
                  ? 'purple'
                  : 'default'
              }
              onChange={(reason) => setReason(reason)}
            />
          )}
          {reason === 18 && (
            <textarea
              className="preferenceCenterTextarea"
              style={{
                backgroundColor: `#F5F5F5`,
                borderRadius: '5px',
                width: '100%',
                height: '10rem',
                color: 'black',
                border: 'none',
                padding: '1rem',
                margin: '2rem 0 0',
                fontFamily: 'Montserrat',
                resize: 'none',
              }}
              placeholder="Pourriez-vous précisez les raisons ?"
              onChange={(e) => setTextareaMessage(e.target.value)}
            ></textarea>
          )}
          <Button
            loading={loading}
            onClick={() => {
              updatePreferenceNewsletter()
                .then((res) => {
                  setUnsubscribeReason(reason);
                  setUnsubscribeReasonMessage(textareMessage);
                  setMsgPreference(true);
                  setIsErrorModalPref(false);
                  close(true);
                })
                .catch((e) => {
                  setMsgPreference(true);
                  setIsErrorModalPref(true);
                  close(true);
                  console.log(e);
                });
            }}
            style={{
              width: isMobile ? '100%' : '17rem',
              margin: '2.4rem auto 0 auto',
            }}
          >
            Envoyer
          </Button>
        </PreferenceContainer>
      </ResponsiveModal>
    </Modal>
  );
};

export default createModal(PreferenceCenterModal);
