import { useApolloClient } from '@apollo/client';
import logoBzc from 'Assets/images/BzcLogo1.svg';
import AuthStore from 'Store/AuthStore';
import UiStore from 'Store/UiStore';
import { Button, Input, Modal } from 'Ui';
import { IconCheck } from 'Ui/Icons';
import { theme } from 'Ui/theme';
import { numberOfSession, setCookie } from 'Utils/cookie';
import { bugLogin, emailRegex, login, redirect } from 'Utils/login';
import { toast } from 'Components/Toaster';
import { addEmailAndPasswordForConnectionApp } from 'Utils/webview/utilsSession';
import React, { useEffect, useRef, useState } from 'react';
import useForm from 'react-hook-form';
import Media from 'react-media';
import { createModal } from 'react-modal-promise';
import styled, { css } from 'styled-components/macro';
import RegistrationModal from './RegistrationModal';

type ModalProps = {
  open: boolean;
  close: (shouldGoOn: boolean) => void;
  footerRedirect?: string;
};

export type FormData = {
  email: string;
  password: string;
};

const Body = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 3rem;
  img {
    height: 4.3rem;
    max-width: 80%;
  }
`;

const Sides = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Montserrat';
  .title {
    font-family: 'Montserrat' !important;
  }
  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
`;
const Column = styled.div`
  flex: 1;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  font-family: 'Montserrat';
  span {
    font-family: 'Montserrat';
  }
  .registerForm {
    font-family: 'Montserrat' !important;
  }
`;
const Separator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1px;
  background-color: #ccc;
  margin: 0 3rem;
  span {
    background-color: #fff;
    padding: 1rem;
    font-family: 'Montserrat';
  }
`;

const SeparatorMobile = styled.div`
  display: flex;
  height: 3rem;
  justify-content: center;
  width: 100%;
  margin: 2rem 0rem;
  span {
    background-color: #fff;
    padding: 1rem;
    position: absolute;
  }
  .line {
    border-top: 1px #ccc solid;
    height: 1px;
    width: 100%;
    align-self: center;
  }
`;
const Title = styled.div`
  font-size: 2rem;
  margin-bottom: 3rem;
  .title {
    font-family: 'Montserrat' !important;
  }
`;

const Link = styled.a`
  display: block;
  font-size: 1.2rem;
  padding: 1rem 0;
`;

const Expand = styled.a`
  flex: 1;
`;

const Line = styled.div`
  margin-bottom: 2rem;
  font-size: 1.4rem;
  svg {
    margin-right: 1rem;
  }
  .register {
    font-family: 'Montserrat' !important;
  }
`;

const ColumnBody = styled.div`
  margin: auto;
  @media screen and (min-width: 768px) {
    margin: inherit;
  }
`;

const Error = styled.div`
  margin-top: 8px;
  color: ${theme.light.colors.error};
  font-size: 14px;
`;

const Placeholder = styled.div<{ open?: boolean; focus?: boolean }>`
  position: absolute;
  width: 100%;
  padding: 0 0rem 0 2rem;
  overflow: hidden;
  font-size: 1.4rem;
  font-weight: 600;
  font-family: 'MontserratSemiBold';
  color: ${theme.light.colors.gray};
  text-overflow: ellipsis;
  transition: 0.2s;
  top: 1.45rem;
  white-space: nowrap;
  line-height: 1;
  ${(props) =>
    props.open &&
    css`
      top: 0.4rem;
      font-size: 1.2rem;
      z-index: 1;
      font-weight: normal;
      //
    `}

  ${(props) =>
    props.focus &&
    css`
      color: ${theme.light.colors.gray};
      //
    `}
`;
const InputStyled = styled.div`
  display: inline-flex;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  max-width: 100vw;
  align-items: center;
  border: 1px solid ${theme.light.colors.gray};

  border-radius: 10px;
  height: 4.6rem;
  position: relative;
  box-sizing: border-box;
  background-color: #fff;
`;

const InputElement = styled.input<{ open?: boolean }>`
  box-sizing: border-box;
  border: none;
  transition: 0.2s;
  border-radius: 10px !important;
  background: transparent;
  outline: none;
  padding-top: 1rem;
  width: 100%;
  opacity: 0;
  position: absolute;

  padding-top: 1.6rem;
  padding-bottom: 0.6rem;
  padding-left: 2rem;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  ${(props) =>
    props.open &&
    css`
      opacity: 1;
      //
    `}
`;

const LoginModal = ({ open, close, footerRedirect }: ModalProps) => {
  const { register, handleSubmit, errors, formState } = useForm<FormData>({
    mode: 'onChange',
  });

  const apolloClient = useApolloClient();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const setAuth = AuthStore.useStoreActions((actions) => actions.setAuth);
  const webview = UiStore.useStoreState((state) => state.webview);

  useEffect(() => {
    const cookieObj = {
      path: '/',
      expires: 1,
      secure: true,
      value: footerRedirect ? footerRedirect : document.location.href,
      name: 'redirection_url',
    };
    setCookie(cookieObj);
  }, []);

  const goToRegister = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    close(false);
    RegistrationModal({});
  };

  const validating = (field: (typeof formState.touched)[0]) => {
    if (!field) return 'default';
    const typesErors = errors as { [index: string]: any };
    return typesErors[field]
      ? 'error'
      : formState.touched.includes(field)
      ? 'success'
      : 'default';
  };

  // Get the prefill Values to connect
  const inputRef: any = useRef();
  const passwordRef: any = useRef();

  const onSubmit = handleSubmit((formData, e) => {
    e.preventDefault();
    setLoading(true);
    login(formData, apolloClient)
      .then((data) => {
        if (data) {
          const loginData = data;
          if (loginData.member) {
            setAuth(loginData);
            if (webview) {
              addEmailAndPasswordForConnectionApp(
                formData.email,
                formData.email
              );
            }
            // window.localStorage.setItem('loginType', 'BZC');
          }
          close(true);
          redirect();
          numberOfSession();
        }
      })
      .catch((err) => {
        const graphQLErrors =
          err &&
          err.graphQLErrors &&
          err.graphQLErrors[0].extensions.bzcMessage;
        const graphQLErrorCode =
          err && err.graphQLErrors && err.graphQLErrors[0].extensions.bzcCode;
        if (
          graphQLErrorCode !==
          ('ERROR_SERVER_GENERIC' || 'ERROR_TOO_MANY_REQUEST')
        ) {
          setError(graphQLErrors);
        }
        if (graphQLErrorCode === 'ERROR_TOO_MANY_REQUEST') {
          // setError(graphQLErrors);
          toast.error('Requête non autorisée');
        }
        bugLogin(formData);
        setLoading(false);
      });
  });

  useEffect(() => {}, [error]);
  const isOpen = inputRef ? true : false;

  return (
    <Modal isOpen={open} onClose={() => close(false)} size="large">
      <Body>
        <Header>
          <img src={logoBzc} alt="BazarChic" />
        </Header>
        <Sides>
          <Column className="registerForm">
            <form
              autoComplete="on"
              onSubmit={onSubmit}
              id="formUserInscription"
              noValidate
            >
              <Sides className="title">
                <Title className="title">Déjà membre ?</Title>
              </Sides>
              <div>
                <Input
                  className="v4Input"
                  dataTest="email"
                  block
                  autoComplete="email"
                  type="email"
                  placeholder="E-mail"
                  name="email"
                  id="email"
                  style={{ marginBottom: 10 }}
                  initialValue={inputRef}
                  kind={error ? 'error' : validating('email')}
                  ref={register({
                    required: 'Champ obligatoire',
                    pattern: {
                      value: emailRegex,
                      message: "L'adresse e-mail n'est pas valide",
                    },
                  })}
                  helperText={errors.email && errors.email.message}
                />
              </div>

              <Input
                dataTest="password"
                className="v4Input"
                block
                autoComplete="password"
                placeholder="Mot de passe"
                id="password"
                type="password"
                name="password"
                initialValue={passwordRef}
                kind={
                  error
                    ? 'error'
                    : errors.password && errors.password.message
                    ? 'error'
                    : 'default'
                }
                ref={register({
                  required: 'Champ obligatoire',
                })}
                helperText={errors.password && errors.password.message}
              />
              {error && <Error>{error}</Error>}
              <Link href="/login/emailForResetPassword/">
                <span>Mot de passe oublié</span>
              </Link>
              <Button
                loading={loading}
                kind="primary"
                block
                type="submit"
                dataTest="btnConnect"
              >
                Je me connecte
              </Button>
            </form>
          </Column>

          <Media
            query={{ minWidth: 768 }}
            render={() => (
              <>
                <Separator>
                  <span>OU</span>
                </Separator>
              </>
            )}
          />

          <Media
            query={{ maxWidth: 767 }}
            render={() => (
              <>
                <SeparatorMobile>
                  <div className="line"></div>
                  <span>OU</span>
                </SeparatorMobile>
              </>
            )}
          />

          <Column>
            <ColumnBody className="registerForm">
              <Title>Devenir membre BazarChic ?</Title>
              <Line className="register">
                <IconCheck />
                Profitez de grandes marques jusqu'à -70%
              </Line>
              <Line className="register">
                <IconCheck />
                10€ offerts avec le code "BIENVENUE"
              </Line>
              <Line className="register">
                <IconCheck />
                Réglez vos achats en 3 ou 4 fois
              </Line>
            </ColumnBody>
            <Expand />
            <Button kind="primary" type="submit" block onClick={goToRegister}>
              Je m'inscris gratuitement
            </Button>
          </Column>
        </Sides>
      </Body>
    </Modal>
  );
};

export default createModal(LoginModal);
