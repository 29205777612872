import React from 'react';
import styled from 'styled-components/macro';

import eco from 'Assets/images/eco.png';

type ImgEcoProps = {
  width: number;
  height: number;
  position: number;
  right: number;
  rightMobile: number;
};

const ImgEco = styled.div<{
  width: number;
  height: number;
  position: number;
  right: number;
  rightMobile: number;
}>`
  position: absolute;
  z-index: 100;
  height: ${(props) => props.height}rem;
  width: ${(props) => props.height}rem;
  bottom: ${(props) => props.position}px;
  right: ${(props) => props.rightMobile}px;
  @media screen and (min-width: 768px) {
    right: ${(props) => props.right}px;
    height: ${(props) => props.height}rem;
    width: ${(props) => props.height}rem;
  }

  img {
    max-width: 100%;
  }
`;

export const ImgEcoResponsability = ({
  width,
  height,
  position,
  right,
  rightMobile,
}: ImgEcoProps) => {
  return (
    <ImgEco
      width={width}
      height={height}
      position={position}
      right={right}
      rightMobile={rightMobile}
    >
      <img src={eco} />
    </ImgEco>
  );
};
