import gql from "graphql-tag";

export const GtmInfo = gql`
  fragment GtmInfo on Member {
    age
    status
    registeredAtTimestamp
    dayCountFromRegister
    dayCountFromLastOrder
    lastOrderAtTimestamp
    isOptIn
    orderCount
    birthDate
    emailHash
    civility
    emailMd5
    gender
    vouchers {
      dateFrom
      dateTo
      maxOrderPrice
      minOrderPrice
      value
      commercialName
      operationName
    }
    country {
      name
      id
      alpha2
    }
    isVipSince
    validMobilesCount
    npai
  }
`;
