import { useApolloClient, useQuery } from '@apollo/client';
import PasswordHelper from 'Components/PasswordHelper';
import { toast } from 'Components/Toaster';
import Cookies from 'js-cookie';
import React, { useState } from 'react';
import useForm from 'react-hook-form';
import AuthStore from 'Store/AuthStore';
import styled from 'styled-components';
import { Button, Input, PageContainer } from 'Ui';
import { IconLock } from 'Ui/Icons';
import HtmlHeader from 'Components/HtmlHead';

import {
  CREATE_PASSWORD_FROM_RESET_TOKEN,
  CHECK_RESET_PASSWORD_DATA,
  BACKGROUND_IMAGES,
} from './query';
import {
  createPasswordFromResetToken,
  createPasswordFromResetTokenVariables,
} from './__generated__/createPasswordFromResetToken';
import {
  checkResetPasswordDatas,
  checkResetPasswordDatasVariables,
} from './__generated__/checkResetPasswordDatas';

import { pageDatas } from './__generated__/pageDatas';
import { useParams } from 'react-router-dom';
import UiStore from 'Store/UiStore';
import { onResetPassword } from 'Libs/RedirectApp';

type FormData = {
  newPassword: string;
  newPasswordConfirmation: string;
};

const Page = styled.div<{ urlImage1: string; urlImage2: string }>`
  min-height: 80vh;
  padding: 2rem 0;
  display: flex;
  align-items: center;
  position: relative;
  background-color: #000;
  &:before {
    opacity: 0.3;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(${(props) => props.urlImage1});
    background-size: cover;
    background-position: center;
    @media screen and (max-width: 48rem) {
      background-image: url(${(props) => props.urlImage2});
    }
  }
  h1 {
    font-size: 2.5rem;
    text-align: center;
    margin: 2.5rem 0;
    position: relative;
    font-family: 'Montserrat';
    text-transform: uppercase;
    color: white;
  }
  .main-icon {
    margin: 0 auto;
    font-size: 6rem;
    color: white;
    display: block;
    position: relative;
  }
`;
const ResetPasswordForm = styled.form.attrs((props) => ({
  noValidate: true,
  onSubmit: (e: React.FormEvent) => e.preventDefault(),
}))`
  h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    font-family: 'Montserrat';
    /* padding-bottom: 0 0 5px 0; */
  }
  padding: 2rem;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 20px;
  position: relative;
`;

export default () => {
  const { token, memberId } = useParams();
  const [loading, setLoading] = useState(false);
  const setAuth = AuthStore.useStoreActions((actions) => actions.setAuth);
  const apolloClient = useApolloClient();
  const [passwordInit, setpasswordInit] = useState(false);
  const [checkPasswordData, setCheckPasswordData] = useState(true);

  const webview = UiStore.useStoreState((state) => state.webview);

  //start image background
  const { data } = useQuery<pageDatas>(BACKGROUND_IMAGES);
  const bgImage = data && data;
  const image: any = bgImage && bgImage.pageDatas;
  const urlBackgroundDesktop1x =
    image && image.loginBackgroundImages[0].urlBackgroundDesktop1x;
  const urlBackgroundMobile1x =
    image && image.loginBackgroundImages[0].urlBackgroundMobile1x;
  //end image background

  if (checkPasswordData) {
    apolloClient
      .query<checkResetPasswordDatas, checkResetPasswordDatasVariables>({
        query: CHECK_RESET_PASSWORD_DATA,
        variables: { memberId, token },
      })
      .then((result) =>
        setCheckPasswordData(result.data.checkResetPasswordDatas || false)
      )
      .catch((e) => {
        const error = e.graphQLErrors[0];
        if (e.graphQLErrors[0]) {
          switch (error.extensions.bzcCode) {
            case 'ERROR_FIELD_MISSING_GENERIC':
            case 'ERROR_FIELD_INVALID_GENERIC':
              setTimeout(() => {
                window.document.location.href = '/';
              }, 6000);

              break;
            case 'ERROR_TOKEN_EXPIRED_GENERIC':
              const closeToast = document.querySelector('.Toastify');
              if (closeToast) {
                closeToast.addEventListener('click', function () {
                  window.location.href = '/login/emailForResetPassword';
                });
              }
              setTimeout(() => {
                window.document.location.href = '/login/emailForResetPassword/';
              }, 6000);
              break;
            default:
              break;
          }
        }
      });
  }

  const {
    getValues,
    register,
    errors,
    triggerValidation,
    formState,
    setError,
  } = useForm<FormData>({
    mode: 'onChange',
  });

  const validating = (field: (typeof formState.touched)[0]) => {
    if (!field) return 'default';
    const typesErors = errors as { [index: string]: any };
    return typesErors[field]
      ? 'error'
      : formState.touched.includes(field)
      ? 'success'
      : 'default';
  };

  const values = getValues();

  const OnValidate = async () => {
    await triggerValidation();
    if (!formState.isValid) return;
    setLoading(true);
    try {
      const response = await apolloClient.mutate<
        createPasswordFromResetToken,
        createPasswordFromResetTokenVariables
      >({
        mutation: CREATE_PASSWORD_FROM_RESET_TOKEN,
        variables: {
          memberId,
          token,
          newPassword: values.newPassword,
        },
      });
      toast.success(
        "Votre mot de passe vient d'être modifié avec succès. Vous allez être redirigé dans quelques instants sur la page d'accueil."
      );
      if (response.data && response.data.createPasswordFromResetToken) {
        const data = response.data.createPasswordFromResetToken;
        if (data.member) setAuth({ ...data, member: data.member });
      }
      const url = Cookies.get('redirection_url');
      Cookies.remove('redirection_url');
      if (
        webview &&
        response.data &&
        response.data.createPasswordFromResetToken
      ) {
        const data = response.data.createPasswordFromResetToken;
        onResetPassword(JSON.stringify(data), window);
      } else {
        window.location.href = url || '/';
      }
    } catch (e) {
      setTimeout(() => {
        window.location.href = '/login/emailForResetPassword';
      }, 3000);
    }
    setLoading(false);
  };

  return (
    <Page urlImage1={urlBackgroundDesktop1x} urlImage2={urlBackgroundMobile1x}>
      <HtmlHeader title={'Réinitilisation de votre mot de passe'} />
      <PageContainer style={{ maxWidth: '44rem' }}>
        <IconLock className="main-icon" />
        <h1>Réinitialisation du mot de passe</h1>
        <ResetPasswordForm>
          <h2>Saisissez ci-dessous votre nouveau mot de passe</h2>
          <Input
            block
            ref={register({
              required: 'Champ obligatoire',
              pattern: {
                value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,50})/g,
                message: 'Veuillez saisir un format de mot de passe valide',
              },
            })}
            name="newPassword"
            placeholder="Nouveau mot de passe"
            type="password"
            kind={validating('newPassword')}
            helperText={errors.newPassword && errors.newPassword.message}
            style={{ marginBottom: '1rem' }}
            onFocus={() => {
              setError('newPassword', 'notMatch', '');
              setpasswordInit(true);
            }}
          />
          {passwordInit && <PasswordHelper password={values.newPassword} />}

          <Input
            block
            type="password"
            kind={validating('newPasswordConfirmation')}
            name="newPasswordConfirmation"
            placeholder="Confirmation de mot de passe"
            helperText={
              errors.newPasswordConfirmation &&
              errors.newPasswordConfirmation.message
            }
            ref={register({
              required: 'Champ obligatoire',
              validate: (value: string) => {
                if (value !== getValues().newPassword) {
                  return 'Les mots de passe de correspondent pas';
                }
              },
            })}
            style={{ marginBottom: '1rem' }}
          />
          <Button
            type="submit"
            kind="primary"
            block
            onClick={OnValidate}
            loading={loading}
          >
            Valider
          </Button>
        </ResetPasswordForm>
      </PageContainer>
    </Page>
  );
};
