import React from 'react';
import styled from 'styled-components/macro';
import ProductAvailableModal from 'Components/modals/productAvailable';
import login from 'Components/modals/Login';
import { Link } from 'Ui';
import UiStore from 'Store/UiStore';

type ProductStatus = {
  hasStock: boolean;
  saleId: string;
  isLoggedIn: boolean;
  product: any;
};

type Props = {
  children: React.ReactNode;
  childrenFooter?: React.ReactNode;
  image?: React.ReactNode;
  link?: string;
  noMargin?: boolean;
  status?: ProductStatus;
  onClick?: () => void;
  onClickData?: () => void;
  className?: string;
};

const CardStyled = styled.div<{ noMargin: boolean }>`
  overflow: hidden;
  margin-bottom: ${(props) => (props.noMargin ? 'none' : '3rem')};
  position: relative;
`;

const CardBody = styled.div``;
const CardFooter = styled.div``;

export const Card = ({
  children,
  childrenFooter,
  image,
  link,
  noMargin,
  className,
  onClickData,
  status,
}: Props) => {
  const setProductIdStore = UiStore.useStoreActions(
    (action) => action.setProductIdStore
  );

  const setProductRangeIdStore = UiStore.useStoreActions(
    (action) => action.setProductRangeIdStore
  );
  const product = status && status.product;
  const saleId = status && status.saleId;
  const isLoggedIn = status && status.isLoggedIn;
  const hasStock = status && status.hasStock;
  // const utmSource = UiStore.useStoreState((state) => state.utmSource);
  // const getUtm = utmSource ? localStorage.setItem("utm", utmSource) : localStorage.setItem("utm", "naturel")

  return (
    <CardStyled noMargin={noMargin || false} className={className}>
      <>
        {!status && (
          <div>
            {image || null}
            <CardBody>{children}</CardBody>
          </div>
        )}

        {/* if prodduct */}

        {hasStock && (
          <>
            {isLoggedIn && (
              <>
                <div
                  onClick={() => {
                    // setProductIdStore(product.groupId);
                    setProductRangeIdStore(product.rangeId);
                    return false;
                  }}
                >
                  {image || null}
                  <CardBody>{children}</CardBody>
                </div>
              </>
            )}

            {!isLoggedIn && (
              <div onClick={async () => login({})}>
                {image || null}
                <CardBody>{children}</CardBody>
              </div>
            )}
          </>
        )}

        {/* if prodduct */}
        {hasStock === false && (
          <div
            onClick={async () => {
              !isLoggedIn && login({});
            }}
            style={{ cursor: 'auto' }}
          >
            {image || null}
            <CardBody>{children}</CardBody>
          </div>
        )}

        <CardFooter>{childrenFooter}</CardFooter>
      </>
    </CardStyled>
  );
};
