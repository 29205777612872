import styled from 'styled-components';
import { theme } from 'Ui';
import { Faqs, Headphones, MailFooter, ServiceClient } from 'Ui/Icons/icons';

const MainContainer = styled.div`
  position: relative;
  max-width: 22rem;
  margin-right: 2.4rem;
  @media screen and (max-width: 48rem) {
    margin-bottom: 4rem;
  }
`;

const IconWithTextContainer = styled.div`
  display: flex;
  align-items: center;
  padding-top: 15px;
  svg {
    width: 24px;
    height: 24px;
    /* margin-right: 2rem; */
  }
  a {
    display: flex;
  }
`;

const TextOfIcon = styled.div`
  color: ${theme.light.colors.grayUltraDark};
  font-family: 'MontserratSemiBold' !important;
  font-size: 1.4rem;
  font-style: normal;
  line-height: 22px;
  margin-left: 1rem;
`;

const Title = styled.div`
  color: ${theme.light.colors.black};
  font-family: 'granville-bold' !important;
  font-size: 2.4rem;
  font-style: normal;
  line-height: 24px; /* 100% */
`;

const Paragraph = styled.div`
  color: ${theme.light.colors.black};
  font-family: 'Montserrat' !important;
  font-size: 1.4rem;
  font-style: normal;
  line-height: 21px; /* 150% */
  letter-spacing: -0.047px;
  padding-top: 1.4rem;
  span {
    font-family: 'MontserratMedium' !important;
  }
`;

const LogoContainer = styled.div`
  /* display: flex;
  padding-bottom: 2.4rem;
  justify-content: flex-start; */
  position: absolute;
  top: -4.7rem;
  svg {
    width: 3.9rem;
    height: 3.8rem;
  }
`;

export const Contact = () => {
  return (
    <MainContainer>
      <LogoContainer>
        <Headphones />
      </LogoContainer>
      <Title>Besoin d'aide ?</Title>
      <Paragraph>
        Nos équipes sont à votre écoute du{' '}
        <span>lundi au vendredi de 8h à 20h.</span>{' '}
        <span>Samedi de 9h à 18h.</span>
      </Paragraph>
      <IconWithTextContainer>
        <ServiceClient />
        <TextOfIcon>+33 1 76 27 81 66</TextOfIcon>
      </IconWithTextContainer>
      <IconWithTextContainer>
        <a href="/contact/question/">
          <MailFooter />
          <TextOfIcon>Nous contacter</TextOfIcon>
        </a>
      </IconWithTextContainer>
      <IconWithTextContainer>
        <a href="/faq/index/">
          <Faqs />
          <TextOfIcon>F.A.Q</TextOfIcon>
        </a>
      </IconWithTextContainer>
    </MainContainer>
  );
};
