import dayjs from 'dayjs';
import { setCookie } from 'Utils/cookie';

// type ConfigurationCookie = {
//   path: string;
//   domain: string;
//   expires: number | Date;
//   sameSite?: 'strict' | 'Strict' | 'lax' | 'Lax' | 'none' | 'None';
//   secure: boolean;
// };

// const configCookie: ConfigurationCookie = {
//   path: '/',
//   domain: '.bazarchic.com',
//   expires: 180,
//   sameSite: 'Strict',
//   secure: true,
// };

export const dateDiff = (timestamp: any) => {
  const convertTimpestampToDate = dayjs.unix(timestamp).format('YYYY-MM-DD');
  const dateNow = dayjs().format('YYYY-MM-DD');
  const dateTimpestamp = dayjs(convertTimpestampToDate);
  const date = dayjs(dateNow);

  const diffDate = dateTimpestamp.diff(date, 'day').toString();
  return parseInt(diffDate.substr(1), 10);
};

export const timestampAndCount = (birthdayReminder: string) => {
  const splitBridate = birthdayReminder.split('_');
  const timeStamp = parseInt(splitBridate[0], 10);
  const count = parseInt(splitBridate[1], 10);
  return [timeStamp, count];
};

export const createCookieBirtdayReminder = (count?: number) => {
  const newTimeStamp = dayjs().add(10, 'day').unix();
  const incrementCount = count ? count + 1 : 0;
  const newbirthdayReminder = `${newTimeStamp}_${incrementCount}`;
  const objectCookie = {
    path: '/',
    expires: 180,
    secure: true,
    name: 'birthdayReminder',
    value: newbirthdayReminder,
  };
  setCookie(objectCookie);
};

export const BirthdayPopIn = (birthDate: any) => {
  // Take Member
  const year = dayjs().year();
  const today = dayjs().format('YYYY-MM-DD');
  const birthday = dayjs.unix(birthDate).format(`${year}-MM-DD`);
  const todayDiffBirthday = dayjs(today)
    .diff(dayjs(birthday), 'day')
    .toString();
  return parseInt(todayDiffBirthday);
};

export const checkBirthDateAndDateToday = (birthDate: any) => {
  const today = dayjs().format('MM-DD');
  const birthday = dayjs.unix(birthDate).format('MM-DD');
  return birthday == today;
};

export const dateWallPaperCheck = (
  dateStarting: string,
  dateEnding: string,
  dateStartingVip?: string,
  isVip?: boolean
): boolean => {
  const dateNow = new Date();
  //DateExample : '2023-01-18 07:00:00'
  const dateStart = isVip
    ? new Date(dateStartingVip.replace(/-/g, '/'))
    : new Date(dateStarting.replace(/-/g, '/'));
  const dateEnd = new Date(dateEnding.replace(/-/g, '/'));
  const checkDate = dateNow > dateStart && dateNow < dateEnd;
  return checkDate;
};
