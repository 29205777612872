import * as React from "react";
const SvgIcoBack = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    viewBox="0 0 60 60"
    width="1em"
    height="1em"
    {...props}
  >
    <defs>
      <path id="ico-back_svg__a" d="M-909.4-1040.9h1920v2150h-1920z" />
    </defs>
    <clipPath id="ico-back_svg__b">
      <use
        xlinkHref="#ico-back_svg__a"
        style={{
          overflow: "visible",
        }}
      />
    </clipPath>
    <g
      style={{
        clipPath: "url(#ico-back_svg__b)",
      }}
    >
      <defs>
        <path id="ico-back_svg__c" d="M-180.4 142.1h472v592h-472z" />
      </defs>
      <clipPath id="ico-back_svg__d">
        <use
          xlinkHref="#ico-back_svg__c"
          style={{
            overflow: "visible",
          }}
        />
      </clipPath>
      <g
        style={{
          clipPath: "url(#ico-back_svg__d)",
        }}
      >
        <image
          width={472}
          height={592}
          style={{
            overflow: "visible",
          }}
          transform="translate(-180.355 142.133)"
        />
      </g>
    </g>
    <defs>
      <path id="ico-back_svg__e" d="M-909.4-1040.9h1920v2150h-1920z" />
    </defs>
    <clipPath id="ico-back_svg__f">
      <use
        xlinkHref="#ico-back_svg__e"
        style={{
          overflow: "visible",
        }}
      />
    </clipPath>
    <g
      style={{
        clipPath: "url(#ico-back_svg__f)",
      }}
    >
      <defs>
        <path id="ico-back_svg__g" d="M-180.4 142.1h472v592h-472z" />
      </defs>
      <clipPath id="ico-back_svg__h">
        <use
          xlinkHref="#ico-back_svg__g"
          style={{
            overflow: "visible",
          }}
        />
      </clipPath>
      <g
        style={{
          clipPath: "url(#ico-back_svg__h)",
        }}
      >
        <image
          width={472}
          height={592}
          style={{
            overflow: "visible",
          }}
          transform="translate(-180.355 142.133)"
        />
      </g>
    </g>
    <path
      d="m41.1 3-27 27 27 27"
      style={{
        fill: "none",
        stroke: "#2f2f2f",
        strokeWidth: 2,
        strokeLinecap: "round",
      }}
    />
  </svg>
);
export default SvgIcoBack;
