import * as React from "react";
const SvgMyOrders = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.2}
      d="M21.886 6.75a.43.43 0 0 0-.042-.353l-2.359-3.708C19.227 2.288 18.441 2 18.033 2H5.962c-.409 0-1.2.288-1.453.689L2.15 6.397a.41.41 0 0 0-.042.353A1 1 0 0 0 2 7.193v13.789C2 21.545 2.456 22 3.02 22h17.96c.564 0 1.02-.455 1.02-1.018V7.193a1.1 1.1 0 0 0-.114-.443Zm-3.133-3.6 1.927 3.025h-6.243l-.54-3.318h4.135c.193.006.637.197.72.293Zm-8.404 3.893h3.284v1.971h-3.284zm.078-.862.54-3.318h2.053l.54 3.318zM5.23 3.156c.09-.108.522-.3.726-.3h4.136l-.54 3.319H3.308zm15.9 17.832a.16.16 0 0 1-.156.155H3.014a.16.16 0 0 1-.156-.155V7.199c0-.084.072-.156.156-.156h6.477v2.403c0 .239.192.43.432.43h4.148a.43.43 0 0 0 .432-.43V7.043h6.477c.084 0 .156.072.156.156v13.789z"
    />
  </svg>
);
export default SvgMyOrders;
