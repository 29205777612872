import { theme } from 'Ui';
import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';

type LinkCustom = {
  to?: string;
  internal?: boolean;
  children?: React.ReactNode;
  onClick?: any;
  style?: any;
  activeStyle?: any;
  activeClassName?: string;
  target?: string;
  dataTest?: string;
};

export const Link = ({
  to,
  internal,
  children,
  onClick,
  activeStyle,
  target,
  dataTest,
}: LinkCustom) => {
  if (internal)
    return (
      <NavLink
        to={to || ''}
        onClick={onClick}
        target={target}
        data-test={dataTest}
        // style={({ isActive }) => (isActive ? activeStyle : '')}
      >
        {children}
      </NavLink>
    );
  else
    return (
      <a target={target} href={to}>
        {children}
      </a>
    );
};

const MenuLink = styled(NavLink)`
  font-size: 1.3rem;
  display: flex;
  white-space: nowrap;
  align-items: center;
  font-weight: 600;
  text-transform: uppercase;
  padding-right: 1.5rem;
  padding: 0.5rem 0.8rem 0rem;
  cursor: pointer;
  margin: 0 0.5rem;
  flex-direction: column;
  font-family: 'MontserratMedium';
  :hover {
    color: ${theme.light.colors.purple};
  }
  :after {
    content: '';
    display: flex;
    width: 0;
    height: 3px;
    /* background: #0099cc; */
    transition: 0.3s;
    margin: 0;
    margin-top: 10px;
  }
  /* &:hover::after {
    width: 100%;
    margin-left: 0;
    transition: width 0.3s;
  } */
  &.no-underline {
    :after {
      content: none;
    }
  }
  &.active {
    color: ${theme.light.colors.purple};
  }
  &.active:after {
    content: '';
    display: flex;
    width: 100%;
    height: 3px;
    background: ${theme.light.colors.purple};
    transition: 0.3s;
    margin: 0;
    margin-top: 10px;
  }
  @media screen and (min-width: ${theme.breakpoints.small}px) {
    margin-top: 1.5rem;
    padding-right: 0;
    font-family: 'MontserratMedium';
  }
`;

const MenuHref = styled.a`
  font-size: 1.3rem;
  display: flex;
  white-space: nowrap;
  align-items: center;
  /* font-weight: 600; */
  text-transform: uppercase;
  padding-right: 1.5rem;
  padding: 0.5rem 0.8rem 0.5rem;
  cursor: pointer;
  margin: 0 0.5rem;
  flex-direction: column;
  font-family: 'MontserratMedium';
  :hover {
    color: ${theme.light.colors.purple};
  }
  :after {
    content: '';
    display: flex;
    width: 0;
    height: 3px;
    /* background: #0099cc; */
    transition: 0.3s;
    margin: 0;
    margin-top: 5px;
  }
  &.no-underline {
    :after {
      content: none;
    }
  }
  &.active {
    color: ${theme.light.colors.purple};
  }
  &.active:after {
    content: '';
    display: flex;
    width: 100%;
    height: 3px;
    background: ${theme.light.colors.purple};
    transition: 0.3s;
    margin: 0;
    margin-top: 5px;
  }
  @media screen and (min-width: ${theme.breakpoints.small}px) {
    margin-top: 0.5rem;
    padding-right: 0;
    font-family: 'MontserratMedium';
  }
`;

export const HeaderLinks = ({
  to,
  internal,
  children,
  onClick,
  style,
  dataTest,
}: LinkCustom) => {
  if (internal)
    return (
      <MenuLink
        data-test={dataTest}
        to={to || ''}
        onClick={onClick}
        style={style}
      >
        {children}
      </MenuLink>
    );
  else
    return (
      <MenuHref style={style} href={to}>
        {children}
      </MenuHref>
    );
};
