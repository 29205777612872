import React from 'react';
import styled, { createGlobalStyle, css } from 'styled-components/macro';
import posed, { PoseGroup } from 'react-pose';
import OutsideClickHandler from 'react-outside-click-handler';
import IconeClose from './Icons/icons/IconeClose';
import { theme } from './theme';

const themeCustom = {
  padding: ' 2rem',
  paddingClose: {
    padding: '1rem',
  },
  modalWidths: {
    small: 300,
    medium: 600,
    mediumLarge: 650,
    large: 900,
  } as { [index: string]: number },
};

const GlobalStyle = createGlobalStyle<{ isOpen: boolean }>`
${(props) =>
  props.isOpen &&
  css`
    body {
      overflow-y: hidden;
    }
  `};
  
`;

const StyledModal = styled.div`
  z-index: 1000000;
  position: fixed;
  top: 0;
  left: 0;
`;

type OverlayProps = {
  isOpen: boolean;
  children?: React.ReactNode;
  onClose?: (e: React.SyntheticEvent) => void;
  fullHeight?: boolean;
  disabledOutsideClickHandler?: boolean;
};

const AnimatedContent = styled(
  posed.div({
    enter: { y: 0, opacity: 1, transition: { duration: 200, delay: 100 } },
    exit: { y: 20, opacity: 0, transition: { duration: 200 } },
  })
)<{ fullHeight: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100vw;

  z-index: 200000;

  display: flex;
  align-items: ${(props) => (props.fullHeight ? 'flex-start' : 'flex-end')};

  @media only screen and (min-width: 1025px) {
    align-items: center;
  }

  .scroller {
    width: 100vw;
    max-height: 100%;

    @media only screen and (min-width: 1025px) {
      padding: 3rem 0;
    }
    // text-align: center;
    display: flex;
    justify-content: center;
    overflow-y: auto;
    overflow-x: hidden;
    .content {
      height: auto;
      border-radius: 25px;
      text-align: initial;
      display: inline-block;
      box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
    }
  }
  overflow: hidden;
`;

const Shade = styled(
  posed.div({
    enter: { opacity: 1, transition: { duration: 300 } },
    exit: { opacity: 0, transition: { duration: 300 } },
  })
)<{ fullHeight: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100vw;
  z-index: 1800;

  background-color: ${(props) =>
    props.fullHeight ? '#fff' : 'rgba(0, 0, 0, 0.7)'};
`;

export const OverlayV2 = ({
  isOpen,
  children,
  onClose,
  fullHeight,
  disabledOutsideClickHandler = true,
}: OverlayProps) => {
  return (
    <StyledModal>
      <GlobalStyle isOpen={isOpen} />
      <PoseGroup>
        {isOpen && [
          <Shade key="shade" fullHeight={fullHeight} />,
          <AnimatedContent key="modal" fullHeight={fullHeight}>
            <div className="scroller">
              <div className="content">
                <OutsideClickHandler
                  onOutsideClick={(e) => {
                    if (disabledOutsideClickHandler) {
                      onClose && onClose(e);
                    }
                  }}
                >
                  {children}
                </OutsideClickHandler>
              </div>
            </div>
          </AnimatedContent>,
        ]}
      </PoseGroup>
    </StyledModal>
  );
};

type ModalProps = {
  isOpen: boolean;
  title?: string;
  headerImage?: React.ReactNode;
  onClose?: (e: React.SyntheticEvent) => void;
  children?: React.ReactNode;
  actions?: React.ReactNode;
  isClose?: boolean;
  size?: 'small' | 'medium' | 'large' | number | 'mediumLarge' | 'full';
  noPadding?: boolean;
  fullHeight?: boolean;
  style?: any;
  closeIconPosition?: number;
  disabledOutsideClickHandler?: boolean;
  className?: string;
  hasRadius?: boolean;
};

const ModalStyled = styled.div<{ size: string | number; hasRadius?: boolean }>`
  display: flex;
  position: relative;
  flex-direction: column;
  background-color: white;
  min-width: 30rem;
  ${(props) =>
    props.hasRadius &&
    css`
      border-radius: 11px 11px 0px 0px;
    `}
  max-width: 100vw;
  width: 100vw;
  @media only screen and (min-width: 1025px) {
    border-radius: 25px;
    width: ${(props) => {
      if (typeof props.size === 'number') return props.size + 'px';
      else return themeCustom.modalWidths[props.size] + 'px';
    }};
  }
`;

const ModalTitle = styled.div`
  display: flex;
  font-family: 'Granville-bold';
  font-weight: 700;
  height: 9rem;
  font-size: 3rem;
  color: ${theme.light.colors.purple};
  text-align: center;
  box-sizing: border-box;
  align-items: center;
  padding: 1.4rem 2rem;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.3);

  .w-85 {
    width: 100%;
  }
`;

const ModalHeaderImage = styled.div`
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  align-content: center;
  padding: ${themeCustom.padding};
  border-bottom: 1px solid #ccc;
`;

const ModalClose = styled.div<{ closeIconPosition?: number }>`
  z-index: 1;
  // height: 6rem;
  right: 2rem;
  top: ${(props) => props.closeIconPosition && `${props.closeIconPosition}rem`};
  position: absolute;
  font-size: 2.2rem;
  box-sizing: border-box;
  justify-content: flex-end;
  align-content: center;
  padding: ${themeCustom.paddingClose.padding};
  .close {
    cursor: pointer;
  }
  .close path {
    stroke: #2f2f2f;
    stroke-width: 4px;
  }
`;

const ModalBody = styled.div<{ noPadding: boolean }>`
  // display: flex;
  // justify-content: center;
  padding: ${(props) => (props.noPadding ? '0' : themeCustom.padding)};
  // flex: 1 1 auto;
  /* @media screen and (min-width: 768px) and (max-width: 1024px) {
    width: 80%;
  } */
`;

const ModalActions = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: ${themeCustom.padding};
`;
export const ModalV2 = ({
  isOpen,
  title,
  headerImage,
  onClose,
  children,
  className,
  isClose = true,
  actions,
  noPadding,
  fullHeight,
  style,
  closeIconPosition = 3.8,
  size = 'medium',
  disabledOutsideClickHandler = true,
  hasRadius = false,
}: ModalProps) => {
  return (
    <OverlayV2
      isOpen={isOpen}
      onClose={onClose}
      fullHeight={fullHeight}
      disabledOutsideClickHandler={disabledOutsideClickHandler}
    >
      <ModalStyled
        hasRadius={hasRadius}
        key="modal"
        size={size}
        style={style}
        className={className}
      >
        {title && (
          <ModalTitle>
            {title && <div className="w-85">{title}</div>}
          </ModalTitle>
        )}

        {headerImage && <ModalHeaderImage>{headerImage}</ModalHeaderImage>}
        {isClose && (
          <ModalClose closeIconPosition={closeIconPosition}>
            <IconeClose onClick={onClose} className="close" />
          </ModalClose>
        )}

        <ModalBody noPadding={noPadding || false}>{children}</ModalBody>
        {actions && <ModalActions>{actions}</ModalActions>}
      </ModalStyled>
    </OverlayV2>
  );
};
