import React, { useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { theme } from './theme';
import { IconClose, IconEyeClosed, IconIcoEye } from './Icons';
import { FormInfo } from './FormInfo';

import GenericType from './types/GenericType';
import { IcoEye } from './Icons/icons';
import UiStore from 'Store/UiStore';

interface Props
  extends Pick<GenericType, 'block' | 'kind'>,
    Omit<React.HTMLProps<HTMLInputElement>, 'kind'> {
  placeholder?: any;
  className?: string;
  style?: React.CSSProperties;
  helperText?: string | boolean;
  alignHelperText?: string;
  initialValue?: string;
  readOnly?: boolean;
  isLogin?: boolean;
  dataTest?: string;
}

type InsideProps = {
  focus?: boolean;
  open?: boolean;
  isLogin?: boolean;
};

const Wrapper = styled.div<Props>`
  width: ${(props) => (props.block ? '100%' : '50rem')};
`;

const InputStyled = styled.div<Props & InsideProps>`
  display: inline-flex;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  max-width: 100vw;
  align-items: center;
  border: 1px solid ${theme.light.colors.gray};

  border-radius: 10px;
  height: 4.6rem;
  position: relative;
  box-sizing: border-box;
  background-color: #fff;
  /* cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
      background-color: grey;
      //
    `} */
  ${(props) =>
    props.isLogin &&
    css`
      box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 50%);
    `}

  /* Tricking browser auto fill color */
  input:-webkit-autofill {
    -webkit-border-radius: 10px 10px 10px 10px !important;
    -webkit-box-shadow: 0 0 0 50px white inset;
    -webkit-text-fill-color: #333;
    border-radius: 10px !important;
  }
  input:-webkit-autofill:focus {
    -webkit-border-radius: 10px 10px 10px 10px !important;
    -webkit-box-shadow: 0 0 0 50px white inset;
    -webkit-text-fill-color: #333;
    border-radius: 10px !important;
  }

  &:hover {
    border-color: #222;
  }

  ${(props) =>
    props.focus &&
    css`
      border-radius: 10px !important;
      &,
      &:hover {
        border-color: ${theme.light.colors.gray};
      }
      //
    `}

  ${(props) => {
    if (!props.kind || props.kind === 'primary') return null;
    // @ts-expect-error
    const color = theme.light.colors[props.kind || ''];
    return css`
      &,
      &:hover {
        border-color: ${color};
      }
    `;
  }}
`;

const InputContainer = styled.div`
  flex: 1;
  position: relative;
  height: 100%;
`;

const InputElement = styled.input<InsideProps>`
  box-sizing: border-box;
  border: none;
  transition: 0.2s;
  border-radius: 10px !important;
  background: transparent;
  outline: none;
  padding-top: 1rem;
  width: 100%;
  opacity: 0;
  position: absolute;
  padding-top: 1.6rem;
  padding-bottom: 0.6rem;
  padding-left: 2rem;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  ${(props) =>
    props.open &&
    css`
      opacity: 1;
      //
    `}
`;

const Placeholder = styled.div<Props & InsideProps>`
  position: absolute;
  width: 100%;
  padding: 0 0rem 0 2rem;
  overflow: hidden;
  font-size: ${(props) => (props.isLogin ? '1.9rem' : '1.4rem')};
  font-family: 'Montserrat' !important;
  color: ${theme.v2.text.darkGray};
  text-overflow: ellipsis;
  transition: 0.2s;
  top: ${(props) => (props.isLogin ? '1.15rem' : '1.45rem')};
  white-space: nowrap;
  line-height: 1;
  input:-webkit-autofill {
    top: -20px;
  }
  :-webkit-autofill {
    top: 0.4rem;
  }
  ${(props) =>
    props.open &&
    css`
      top: 0.4rem;
      font-size: 1.2rem;
      z-index: 1;
      font-family: 'Montserrat' !important;
      //
    `}

  ${(props) =>
    props.focus &&
    css`
      color: ${theme.light.colors.gray};
      //
    `}
`;

const RightIcon = styled.div<{ kindError?: boolean }>`
  /* font-size: 3rem; */
  width: ${(props) => (props.kindError ? '4rem' : '5rem')};
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: ${(props) => (props.kindError ? '2rem' : '4rem')};
    height: 100%;
    font-size: 5px;
    padding-bottom: 1px;
  }
`;

export const Input = React.forwardRef(
  (
    {
      type = 'text',
      block = false,
      kind = 'default',
      placeholder = '',
      isLogin = false,
      onFocus,
      initialValue,
      defaultValue,
      onChange,
      ref: oldRef,
      value,
      helperText,
      style,
      readOnly,
      alignHelperText,
      dataTest,
      ...rest
    }: Props,
    ref
  ) => {
    const isControlled = !!onChange;
    const [focus, setFocus] = useState(false);
    const [localValue, setLocalValue] = useState(
      isControlled ? value : initialValue ? initialValue : false
    );

    const [RevealPassword, setRevealPassword] = useState(false);
    const isOpen = focus || (isControlled ? !!value : !!localValue);

    let currentType = type;
    if (type === 'password')
      if (RevealPassword) currentType = 'text';
      else currentType = 'password';

    return (
      <Wrapper {...{ style }} block={block}>
        <div>
          <InputStyled isLogin={isLogin} focus={focus} kind={kind}>
            <InputContainer>
              {placeholder && (
                <Placeholder isLogin={isLogin} open={isOpen} focus={focus}>
                  {placeholder}
                </Placeholder>
              )}

              <InputElement
                data-test={dataTest}
                readOnly={readOnly}
                value={isControlled ? value : undefined}
                onChange={(e: any) => {
                  if (isControlled && onChange) {
                    onChange(e);
                  }
                }}
                onFocus={(e: any) => {
                  setFocus(true);
                  onFocus && onFocus(e);
                }}
                onBlur={(e: any) => {
                  setFocus(false);
                  setLocalValue(e.target.value);
                }}
                open={isOpen}
                type={currentType}
                ref={(thisRef: any) => {
                  // @ts-ignore
                  ref && ref(thisRef);
                }}
                {...rest}
                as="input"
              />
            </InputContainer>
            {type === 'password' && (
              <RightIcon
                onClick={() => setRevealPassword(!RevealPassword)}
                style={{
                  opacity: RevealPassword ? 1 : 0.3,
                  fontSize: '2rem',
                  cursor: 'pointer',
                }}
              >
                {RevealPassword ? (
                  <span
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '24px',
                    }}
                  >
                    <IconIcoEye />
                  </span>
                ) : (
                  <span
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '24px',
                    }}
                  >
                    <IconEyeClosed />
                  </span>
                )}
              </RightIcon>
            )}
            {kind === 'success' && (
              <div style={{ color: theme.colors.success }}></div>
              // <RightIcon style={{ color: theme.colors.success }}>
              // <IconCheck />
              // </RightIcon>
            )}
          </InputStyled>
          {helperText && (
            <FormInfo alignHelperText={alignHelperText} kind={kind}>
              {helperText}
            </FormInfo>
          )}
        </div>
      </Wrapper>
    );
  }
);
