import { setCookie } from 'Utils/cookie';

export const createSession = (idSessionParams: any) => {
  const expirationDate = new Date();
  expirationDate.setMonth(expirationDate.getMonth() + 6);

  if (!!idSessionParams) {
    const configCookieIdSession = {
      path: '/',
      domain: '.bazarchic.com',
      expires: expirationDate,
      secure: true,
      value: idSessionParams,
      name: 'ssession',
    };
    setCookie(configCookieIdSession);
  }
};

export const createCookieWebview = () => {
  const configCookie = {
    path: '/',
    domain: '.bazarchic.com',
    expires: 1,
    secure: true,
    value: 'oui',
    name: 'isWebView',
  };
  setCookie(configCookie);
};

export const addEmailAndPasswordForConnectionApp = (
  email: string,
  password: string
) => {
  const objectCookieEmail = {
    path: '/',
    domian: '.bazarchic.com',
    expires: 2,
    secure: true,
    value: email,
    name: 'email',
  };
  const objectCookiePassword = {
    path: '/',
    domian: '.bazarchic.com',
    expires: 2,
    secure: true,
    value: password,
    name: 'password',
  };
  setCookie(objectCookiePassword);
  setCookie(objectCookieEmail);
};
