import React, { useEffect } from 'react';
import SearchInput from 'Components/SearchInput';
import { useState } from 'react';
import styled from 'styled-components/macro';
import posed from 'react-pose';
import Highlighter from 'react-highlight-words';
import { theme } from 'Ui';
import OutsideClickHandler from 'react-outside-click-handler';
import { useQuery } from '@apollo/client';
import useLocalStorage from 'react-use/lib/useLocalStorage';
import query from './query';
import {
  getAutosuggest,
  getAutosuggestVariables,
} from './__generated__/getAutosuggest';
import { IconCloseCircle } from 'Ui/Icons';
import { useDebounce } from 'Utils/hooks';
import { Link, useNavigate } from 'react-router-dom';

import { isMobile } from 'react-device-detect';
import { useApolloClient } from '@apollo/react-hooks';
import SearchInputMobile from 'Components/Header/SearchHeader/SearchInputMobile';
import { SearchProduct } from 'Components/Header/SearchHeader/SearchProduct';
import { SearchSale } from 'Components/Header/SearchHeader/SearchSales';
import {
  addSearchHistoy_addSearchHistory,
  addSearchHistoyVariables,
} from 'Components/Header/SearchHeader/__generated__/addSearchHistoy';
import {
  deleteSearchHistory,
  deleteSearchHistoryVariables,
} from 'Components/Header/SearchHeader/__generated__/deleteSearchHistory';
import {
  SEARCH_HISTORY,
  ADD_SEARCH_HISTORY,
  DELETE_SEARCH_HISTORY,
  SEARCH_SALES,
} from 'Components/Header/SearchHeader/query';
import AuthStore from 'Store/AuthStore';
import UiStore from 'Store/UiStore';
import LoopSearch from 'Ui/Icons/icons/LoopSearch';
import Media from 'react-media';
import { searchHistory } from 'Components/Header/SearchHeader/__generated__/searchHistory';
import getProductsQuery from 'Pages/Search/Products/query';

const searchUrl = (search: string) => `/recherche?search=${search}`;

const AutoSuggest = styled.div`
  position: relative;
  z-index: 600;
  max-width: 110rem;
  margin: 0 11rem;
  padding: 1rem 0;
  @media screen and (max-width: 480px) {
    z-index: 0;
    margin: 0 auto;
    padding: 0rem 0;
  }
`;

const OptionTitle = styled.div`
  /* margin-top: 1.8rem; */
  font-family: 'granville';
  margin-bottom: 1.6rem;
  color: #000;

  font-family: Granville;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const Option = styled.a`
  display: block;
  align-items: center;
  padding: 0.5rem 0;
  cursor: pointer;
  &:hover,
  &.selected {
    color: ${theme.light.colors.purple};
  }
`;

const OptionWithIcon = styled.div`
  display: flex;
  align-items: center;
  svg {
    font-size: 2.7rem;
    cursor: pointer;
    margin-left: 1rem;
    &:hover {
      color: ${theme.light.colors.purple};
    }
  }
  @media screen and (max-width: 48rem) {
    svg {
      margin-left: 0rem;
    }
  }
`;

const OptionsFlex = styled.div`
  display: flex;
  flex-direction: column;
  & > div {
    flex: 1;
  }
  @media screen and (min-width: ${theme.breakpoints.small}px) {
    flex-direction: row;
  }
`;

const Options = posed(styled.div`
  display: flex !important;
  justify-content: flex-start;
  top: 0;
  left: 0;
  right: 0;
  padding: 2rem 0;
  background-color: #fff;
  overflow: hidden;
  transform-origin: top;
  font-size: 1.5rem;
  border-radius: 5px;
  @media screen and (max-width: 768px) {
    justify-content: flex-start;
    padding: 2rem 0rem;
    flex-direction: column;
  }
`)({
  open: {
    applyAtStart: { display: 'block' },
    opacity: 1,
    transition: { duration: 200 },
  },
  close: {
    applyAtEnd: { display: 'none' },
    opacity: 0,
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0)',
    transition: { duration: 200 },
  },
});

const ProductAvailableSearch = styled.div`
  color: var(--Text-light_grey, #848484);
  font-family: 'Montserrat';
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 1.6rem;
  margin-left: 5px;
`;

const SeeAll = styled.div`
  color: var(--Text-light_grey, #848484);
  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
  text-align: center;
  margin-top: 8px;
`;

type Props = {
  onChange?: (value: string) => void;
  cleanHistory: (option?: string) => void;
  trendingSearch: string[];
  searchHistory: string[];
  internalRouting: boolean;
  setSearchHistory?: any;
  placeHolderCustom: boolean;
};

/** Main Autosuggest component */

const AutoSuggestComponent = ({
  trendingSearch,
  searchHistory,
  setSearchHistory,
  cleanHistory,
  internalRouting,
}: Props) => {
  let member: any;
  const sessionMemberType = AuthStore.useStoreState(
    (state) => state.sessionMemberType
  );

  const searchString = UiStore.useStoreState(
    (state) => state.searchStringHeader
  );

  const apolloClient = useApolloClient();

  const history = useNavigate();
  const [search, setSearch] = useState('');
  const [open, setOpen] = useState(true);
  const [preSelected, setPreSelected] = useState(0);
  const debouncedSearch = useDebounce(searchString, 200);
  const [historic, setHistoric] = useState<any>([]);

  // Get the history of search
  const { data: dataHistory, refetch } = useQuery<searchHistory>(
    SEARCH_HISTORY,
    {
      skip: sessionMemberType === 'anonymous',
    }
  );

  const timeOut = () => {
    setTimeout(function () {
      refetch();
    }, 1000);
  };

  useEffect(() => {
    if (sessionMemberType !== 'anonymous') {
      refetch();
      if (
        dataHistory &&
        dataHistory.searchHistory.searchHistory &&
        !!dataHistory.searchHistory.searchHistory.length
      ) {
        setHistoric(dataHistory && dataHistory.searchHistory.searchHistory);
      }
    }
  }, [dataHistory]);
  const addSearch = async (item?: any) => {
    try {
      const response = await apolloClient.mutate<
        addSearchHistoy_addSearchHistory,
        addSearchHistoyVariables
      >({
        mutation: ADD_SEARCH_HISTORY,
        variables: {
          search: item,
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  // Delete search
  const deleteSearch = async (search: string, searchArray?: string[]) => {
    try {
      const response = await apolloClient.mutate<
        deleteSearchHistory,
        deleteSearchHistoryVariables
      >({
        mutation: DELETE_SEARCH_HISTORY,
        variables: {
          delete: search,
          deleteMulti: searchArray,
        },
      });
      if (response) {
        const deleteData =
          (response.data && response.data.deleteSearchHistory.searchHistory) ||
          [];
        if (!!deleteData.length) {
          setHistoric([...response.data.deleteSearchHistory.searchHistory]);
        } else {
          setHistoric([]);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const { data, loading } = useQuery<getAutosuggest, getAutosuggestVariables>(
    query,
    {
      skip: !debouncedSearch,
      variables: { search: debouncedSearch, first: 5 },
    }
  );

  const { data: searchSaleData } = useQuery(SEARCH_SALES, {
    skip: !debouncedSearch,
    variables: { search: debouncedSearch, pagination: { first: 5 } },
  });

  const { data: searchProductData } = useQuery(getProductsQuery, {
    skip: !debouncedSearch,
    variables: { search: debouncedSearch, pagination: { first: 5 } },
  });

  const options = !!data && !!data.autoSuggest ? data.autoSuggest : [];

  //** Arrow Selection */
  const onKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'ArrowUp') {
      e.preventDefault();
      if (preSelected > 0) {
        setPreSelected(preSelected - 1);
        if (sessionMemberType !== 'anonymous') {
          addSearch(preSelected - 1);
        }
      }
    }

    if (e.key === 'ArrowDown') {
      e.preventDefault();
      if (preSelected < options.length) {
        setPreSelected(preSelected + 1);
        if (sessionMemberType !== 'anonymous') {
          addSearch(preSelected + 1);
        }
      }
    }
  };

  /** Reset arrow selection */
  useEffect(() => {
    setPreSelected(0);
  }, [search]);

  /** Navigate, internally or externally */
  const onNavigate = (search: string) => {
    // const historyTmp = [search, ...searchHistory];
    // setSearchHistory([...new Set(historyTmp)].slice(0, 4));
    const url = searchUrl(search);
    if (sessionMemberType !== 'anonymous') {
      addSearch(search);
    }
    if (internalRouting) history(url);
    else document.location.href = url;
  };

  /** Navigate on enter */
  const onPressEnter = () => {
    const selected = preSelected > 0 ? options[preSelected - 1] : search;
    onNavigate(selected);
    if (sessionMemberType !== 'anonymous') {
      addSearch(selected);
    }
  };

  const capitalize = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const placeHolderPersonalMember = member
    ? `${capitalize(member.firstName)}, que recherchez-vous ?`
    : 'Que recherchez-vous ?';

  const Separator = () => (
    <>
      <Media
        query={{ minWidth: 481 }}
        render={() => (
          <div style={{ maxHeight: '40rem', margin: '0 2.4rem' }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1"
              height="318"
              viewBox="0 0 1 318"
              fill="none"
            >
              <path d="M0.499986 318L0.5 0" stroke="#DEDCDC" />
            </svg>
          </div>
        )}
      />

      <Media
        query={{ maxWidth: 480 }}
        render={() => <div style={{ margin: '1.2rem 0' }}></div>}
      />
    </>
  );

  return (
    <AutoSuggest onClick={() => setOpen(true)}>
      <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
        <Media
          query={{ maxWidth: 480 }}
          render={() => (
            <SearchInputMobile
              placeholder={'Rechercher une vente, un produit ...'}
            />
          )}
        />

        <Options pose={!!open ? 'open' : 'close'}>
          <>
            {!debouncedSearch && (
              <OptionsFlex>
                {historic && !!historic.length && (
                  <div>
                    <OptionTitle className="searchTitleV4 fontMontserratSemiBold">
                      Recherches récentes
                    </OptionTitle>
                    {historic.map((option: any, index: any) => (
                      <OptionWithIcon key={index}>
                        <Option
                          className="searchoptionV4 fontMontserrat"
                          onClick={() => onNavigate(option)}
                        >
                          {option}
                        </Option>
                        <IconCloseCircle
                          onClick={(e: any) => {
                            e.preventDefault();
                            deleteSearch(option);
                            timeOut();
                          }}
                        />
                      </OptionWithIcon>
                    ))}
                    <Option
                      className="searchoptionV4 fontMontserrat"
                      onClick={(e) => {
                        e.preventDefault();
                        deleteSearch('', historic);
                        timeOut();
                      }}
                    >
                      Tout supprimer
                    </Option>
                  </div>
                )}
                <div>
                  <OptionTitle className="searchTitleV4 fontMontserratSemiBold">
                    Recherches populaires
                  </OptionTitle>
                  {trendingSearch &&
                    trendingSearch.map((option, index) => (
                      <Option
                        className="searchoptionV4 fontMontserrat"
                        key={index}
                        onClick={() => {
                          onNavigate(option);
                        }}
                      >
                        {option}
                      </Option>
                    ))}
                </div>
              </OptionsFlex>
            )}

            {debouncedSearch && (
              <>
                <div style={{ width: '22rem' }}>
                  {!!options && !!options.length ? (
                    <>
                      {!!options.length &&
                        options.map((option: any, index: any) => (
                          <OptionWithIcon
                            key={index}
                            onClick={() => onNavigate(option)}
                            style={{
                              gap: '8px',
                              marginBottom: '8px',
                              cursor: 'pointer',
                            }}
                            className={
                              index === preSelected - 1 ? 'selected' : ''
                            }
                          >
                            <LoopSearch width="24" height="24" />
                            <Highlighter
                              highlightStyle={{
                                fontWeight: 'bold',
                                color: 'inherit',
                                backgroundColor: 'transparent',
                              }}
                              searchWords={searchString.split(' ')}
                              autoEscape={true}
                              textToHighlight={option || option}
                            />
                          </OptionWithIcon>
                        ))}
                    </>
                  ) : (
                    <i>Aucune suggestion</i>
                  )}
                </div>

                <Separator />

                <div style={{ width: '30rem' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <OptionTitle className="searchTitleV4">
                      Produits
                    </OptionTitle>
                    <ProductAvailableSearch>
                      ({searchProductData?.products?.edges.length} sur{' '}
                      {searchProductData?.products?.totalCount})
                    </ProductAvailableSearch>
                  </div>
                  {!!searchProductData &&
                  searchProductData?.products?.totalCount !== 0 ? (
                    <>
                      <SearchProduct products={searchProductData.products} />
                      <Link to={`/recherche/?search=${searchString}`}>
                        <SeeAll>Voir tout les produits</SeeAll>
                      </Link>
                    </>
                  ) : (
                    <i>Aucune suggestion</i>
                  )}
                </div>

                <Separator />

                <div style={{ width: '35rem' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <OptionTitle className="searchTitleV4">Ventes</OptionTitle>
                    <ProductAvailableSearch>
                      (
                      {searchSaleData?.sales?.edges?.length!! >= 4
                        ? 4
                        : searchSaleData?.sales?.edges?.length}{' '}
                      sur {searchSaleData?.sales?.totalCount})
                    </ProductAvailableSearch>
                  </div>
                  {!!searchSaleData &&
                  searchSaleData?.sales?.totalCount !== 0 ? (
                    <SearchSale sales={searchSaleData?.sales} />
                  ) : (
                    <i>Aucune suggestion</i>
                  )}
                </div>
              </>
            )}
          </>
        </Options>
      </OutsideClickHandler>
    </AutoSuggest>
  );
};

/** Overarching component - get history and trending just once */
export default ({ internalRouting = false, placeholderCustom = true }) => {
  const { data } = useQuery<getAutosuggest, getAutosuggestVariables>(query, {
    variables: { search: '', first: 5 },
  });
  const [searchHistory, setSearchHistory] = useLocalStorage<string[]>(
    'searchHistory',
    []
  );
  const trendingSearch = !!data && !!data.autoSuggest ? data.autoSuggest : [];

  /** Clean search history, completely or of one search */
  const cleanHistory = (item?: string) => {
    if (item) {
      setSearchHistory(searchHistory.filter((search) => search !== item));
    } else {
      setSearchHistory([]);
    }
  };

  return (
    <AutoSuggestComponent
      placeHolderCustom={placeholderCustom}
      searchHistory={searchHistory}
      trendingSearch={trendingSearch}
      cleanHistory={cleanHistory}
      internalRouting={internalRouting}
    />
  );
};
