import React from 'react';
import styled, { css } from 'styled-components/macro';
import AuthStore from 'Store/AuthStore';
import { ImageRetina } from 'Ui/ImageRetina';

type ImageProps = {
  image: string;
  dateStart: string;
  dateEnd: string;
  alt?: string;
  macaron: any;
  isLastingSale: boolean;
  dataSrcset?: string;
  width?: number;
  height?: number;
};

const Macaron = styled.div<{ macaronLength?: any }>`
  ${(props) =>
    props.macaronLength === 2 &&
    css`
      &:nth-child(1) {
        margin-right: 0.4rem;
      }
    `}
  img {
    display: block;
    height: 3em;
    max-height: 3em;
  }
  @media screen and (max-width: 768px) {
    img {
      display: block;
      height: 3em;
      max-height: 3em;
    }
  }
`;

const ContentInformation = styled.div`
  display: flex;
  position: absolute;
  bottom: -3px;
  right: 0px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 3em;
  animation: showMacarons 0s 0.2s forwards;
  opacity: 0;
  @keyframes showMacarons {
    to {
      opacity: 1;
    }
  }
  @media screen and (max-width: 768px) {
    height: 3em;
    bottom: 0px;
  }
  @media screen and (min-width: 769px) and (max-width: 968px) {
    bottom: 0px;
  }
  @media screen and (min-width: 969px) and (max-width: 1023px) {
    /* height: 2.5em; */
    bottom: 11px;
  }
`;

const SaleBlock = styled.div`
  .cardSale img {
    display: block;
    width: 100%;
    position: relative;
    overflow: hidden;
    /* margin-bottom: 20px; */
    border-radius: 20px 20px 20px 20px;
    img {
      width: 100%;
    }
    @media screen and (max-width: 768px) {
      /* width: 100%; */
    }
  }
`;

const MacaronDate = styled.div<{ isLastingSale: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.8em;
  height: 3rem;
  border-radius: 0px 0px 20px 0px;
  background-color: #000;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  font-size: 1em;
  color: #fff;
  display: ${(props) => (props.isLastingSale ? 'none' : 'flex')};
`;

export default ({
  image,
  alt,
  dateStart,
  dateEnd,
  macaron,
  isLastingSale,
  dataSrcset,
  width,
  height,
}: ImageProps) => {
  const isLoggedIn = AuthStore.useStoreState((state) => state.isLoggedIn);
  const macaronSort =
    macaron &&
    macaron.sort((a: any, b: any) => (a.position < b.position ? 1 : -1));

  return (
    <SaleBlock>
      <img
        src={image}
        width={width}
        height={height}
        className="cardSale"
        alt={alt}
        // data-srcset={dataSrcset}
      ></img>
      {/* {isLoggedIn && (
        <ContentInformation>
          {!!macaron &&
            macaronSort.map((macaron: any, index: number) => (
              <Macaron
                key={index}
                macaronLength={!!macaron && macaronSort.length}
              >
                <img src={macaron.url} alt=" Avantage BazarChic" />
              </Macaron>
            ))}
          <MacaronDate isLastingSale={isLastingSale}>{dateEnd}</MacaronDate>
        </ContentInformation>
      )} */}
    </SaleBlock>
  );
};
