import * as React from "react";
const SvgCheck = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 31 31"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      stroke="currentColor"
      d="m11.672 23.413-9.076-9.559-1.442 1.519 10.518 11.076L30.154 6.968l-1.431-1.519z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCheck;
