import React, { useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import styled from 'styled-components';
import { Modal } from 'Ui';
import { createModal } from 'react-modal-promise';
import CryptoJS from 'crypto-js';
import { LoginFormV2 } from 'Components/Login';
import { LoginForm } from 'Components/Login/Form/LoginForm';
import { isMobile } from 'react-device-detect';
import RgpdModal from 'Components/modals/RgpdModal';

import { omit } from 'lodash';
import { toast } from 'react-toastify';
import { redirect } from 'Utils/login';
import {
  ApplicationEnum,
  CivilityEnum,
  EnvironmentInput,
  ModuleEnum,
} from '__generated__/graphql-global-types';
import { numberOfSession, setCookie } from 'Utils/cookie';
import useForm from 'react-hook-form';
import AuthStore from 'Store/AuthStore';
import UiStore from 'Store/UiStore';
import RegistrationFormV2 from 'Components/RegistrationForm/RegistrationFormV2';
import {
  registerMember,
  registerMemberVariables,
} from 'Components/RegistrationForm/__generated__/registerMember';
import { REGISTER_MEMBER } from 'Components/RegistrationForm/query';
import {
  loginMember,
  loginMemberVariables,
} from 'Pages/Login/__generated__/loginMember';
import { LOGIN_MEMBER } from 'Pages/Login/query';
import { ModalTitleClient } from 'Utils/modalTitleProps';
import { getModalEventName, getSignIn, getSignup } from 'Libs/GA4/common';

type ModalProps = {
  open: boolean;
  close: (shouldGoOn: boolean) => void;
  title?: {
    login: string;
    register: string;
  };
  page?: string;
  isRegister?: boolean;
  linkRedirect?: string;
};

export type FormData = {
  email: string;
  password: string;
};

export type FormDataRegister = {
  firstName: string;
  lastName: string;
  civility: CivilityEnum;
  password: string;
  email: string;
  birthDate?: string;
  newslettersDaily?: boolean;
  newslettersOpc?: boolean;
};

const Body = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 2rem 0;
`;

const LoginModal = (
  {
    open,
    close,
    title = {
      login: ModalTitleClient.login,
      register: ModalTitleClient.register,
    },
    isRegister = false,
    page,
    linkRedirect,
  }: ModalProps,
  ref: any
) => {
  const cookieMarketingStore = UiStore.useStoreState(
    (state) => state.cookieMarketing
  );

  const { register, handleSubmit, errors, formState } = useForm<FormData>({
    mode: 'onChange',
  });
  const signUp = () => {
    // if (localStorage.getItem('setSubscriptionType')) {
    //   const subscriptionTypeJson: any = localStorage.getItem(
    //     'setSubscriptionType'
    //   );
    //   getSignup(JSON.parse(subscriptionTypeJson));
    //   localStorage.removeItem('setSubscriptionType');
    // }
  };

  const signIn = () => {
    // if (localStorage.getItem('signIn')) {
    //   const signInJson: any = localStorage.getItem('signIn');
    //   localStorage.removeItem('signIn');
    // }
  };

  const apolloClient = useApolloClient();
  const [loading, setLoading] = useState(false);
  const [error, setErrors] = useState();

  const setAuth = AuthStore.useStoreActions((actions) => actions.setAuth);
  const webview = UiStore.useStoreState((state) => state.webview);
  const asPath = window.location.pathname;
  const isLogin = asPath === '/login/';
  const [changeToLogin, setChangeToLogin] = useState(isRegister);

  useEffect(() => {
    const cookieObj = {
      path: '/',
      expires: 1,
      secure: true,
      value: linkRedirect ? linkRedirect : document.location.href,
      name: 'redirection_url',
    };
    setCookie(cookieObj);
  }, []);

  const subscriptionURI = asPath === '/' ? '/home/index' : asPath;

  const regex = /(?:\d)+/;
  const regexParrain = /idparrain+/;
  const urlSponsorship = window.location.pathname.match(regex);

  const testRegex = regex.test(window.location.pathname);
  const testRegexParrain = regexParrain.test(window.location.pathname);
  const sponsorId =
    testRegexParrain && testRegex ? urlSponsorship && urlSponsorship[0] : null;

  const onSubmitLogin = async (emailValue: string, passValue: string) => {
    setLoading(true);
    const passwordSha = CryptoJS.SHA256(passValue).toString();
    try {
      const response = await apolloClient.mutate<
        loginMember,
        loginMemberVariables
      >({
        mutation: LOGIN_MEMBER,
        variables: {
          email: emailValue,
          password: passwordSha.toString(),
        },
      });
      if (response.data && response.data.loginMember) {
        const data = response.data.loginMember;
        setAuth(data);
        numberOfSession();
        // signIn();
        getSignIn({
          event: 'login',
          login_type: 'BZC',
          user_id: data.member.id,
        });
        redirect();
      }
    } catch (err: any) {
      const graphQLErrors =
        err && err.graphQLErrors && err.graphQLErrors[0].extensions.bzcMessage;
      const graphQLErrorCode =
        err && err.graphQLErrors && err.graphQLErrors[0].extensions.bzcCode;

      if (
        graphQLErrorCode !==
        ('ERROR_SERVER_GENERIC' || 'ERROR_TOO_MANY_REQUEST')
      ) {
        setErrors(graphQLErrors);
        toast.error(graphQLErrors);
      }
      if (graphQLErrorCode === 'ERROR_TOO_MANY_REQUEST') {
        // setError(graphQLErrors);
        toast.error('Requête non autorisée');
      }
      setLoading(false);
    }
  };

  const onSubmitRegister = async (form: FormDataRegister) => {
    const environment: EnvironmentInput = {
      application: isMobile ? ApplicationEnum.MOBILE : ApplicationEnum.DESKTOP,
      module: ModuleEnum.SITE,
    };
    setLoading(true);
    const newslettersPreferences = !!form.newslettersOpc
      ? [
          { newsletterId: 3, status: !!form.newslettersDaily ? true : false },
          { newsletterId: 6, status: false },
          { newsletterId: 9, status: !!form.newslettersOpc && true },
        ]
      : [
          { newsletterId: 3, status: !!form.newslettersDaily ? true : false },
          { newsletterId: 6, status: false },
        ];
    const member = omit<typeof form, 'newslettersDaily' | 'newslettersOpc'>(
      form,
      ['newslettersDaily', 'newslettersOpc']
    );

    try {
      const response = await apolloClient.mutate<registerMember>({
        mutation: REGISTER_MEMBER,
        variables: {
          sponsorId,
          subscriptionURI,
          environment,
          member,
          newslettersPreferences,
        },
      });
      if (response.data && response.data.registerMember) {
        const data = response.data.registerMember;
        if (data.member) setAuth(data);
        if (window.sessionStorage.getItem('sign_up_a')) {
          window.sessionStorage.setItem('sign_up_a', data.member.id);
        }

        getSignup({
          event: 'sign_up',
          signup_type: 'manual',
          user_id: data.member.id,
        });
      }
      window.localStorage.setItem('subscriptionTracking', 'inscription-ok');
      // signUp();
      redirect();
    } catch (err: any) {
      const graphQLErrors =
        err && err.graphQLErrors && err.graphQLErrors[0].extensions.bzcMessage;
      const graphQLErrorCode =
        err && err.graphQLErrors && err.graphQLErrors[0].extensions.bzcCode;
      if (
        graphQLErrorCode !==
        ('ERROR_SERVER_GENERIC' || 'ERROR_TOO_MANY_REQUEST')
      ) {
        setErrors(graphQLErrors);
        toast.error(graphQLErrors);
      }
      if (graphQLErrorCode === 'ERROR_TOO_MANY_REQUEST') {
        // setError(graphQLErrors);
        toast.error('Requête non autorisée');
      }
      // bugLogin(formData);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (cookieMarketingStore)
      getModalEventName(changeToLogin ? 'login' : 'sign_up');
  }, [changeToLogin]);

  return (
    <Modal
      fullHeight={isMobile}
      isOpen={open}
      onClose={() => close(false)}
      disabledOutsideClickHandler={false}
    >
      <Body>
        {changeToLogin ? (
          <>
            <LoginFormV2
              showLogo={isLogin}
              form={
                <LoginForm
                  type="modal"
                  close={close}
                  onSubmitLogin={onSubmitLogin}
                  loading={loading}
                />
              }
              switchTo={() => {
                setChangeToLogin(false);
              }}
              isLoginMethod={true}
              showReassurance={false}
              textTo={`Pas encore de compte ? ${'  '}`}
              linkTo={'Créez un compte.'}
              title={title.login}
              onOpenCookies={() => {
                RgpdModal({});
                close(false);
              }}
            />
          </>
        ) : (
          <LoginFormV2
            showLogo={isLogin}
            switchTo={() => {
              setChangeToLogin(true);
            }}
            form={
              <RegistrationFormV2
                onRegister={onSubmitRegister}
                loading={loading}
                type={'modal'}
                currentPage={page}
              />
            }
            isLoginMethod={false}
            showReassurance={false}
            textTo={`Déjà inscrit ? ${'  '}`}
            linkTo={'Se connecter'}
            title={title.register}
            onOpenCookies={() => {
              RgpdModal({});
              close(false);
            }}
            register={`Une fois votre compte créé, vous pourrez constituer votre liste d'envies et y accéder à tout moment.`}
          />
        )}
      </Body>
    </Modal>
  );
};

export default createModal(LoginModal);
