import { AppStyled, GlobalStyle } from 'GlobalStyle';
import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { init } from './Libs/apollo';
import Routes from './Routes';
import AuthStore from 'Store/AuthStore';
import UiStore from 'Store/UiStore';
import ConfigurationStore from 'Store/ConfigurationStore';
import InitiStoreApp from 'Store/InitStoreApp';
import { ThemeProvider } from 'styled-components';
import { theme } from 'Ui';

// const darkModeQuery = window.matchMedia('(prefers-color-scheme: dark)');

// type ThemeType = typeof theme;
declare const window: any;
const App = () => {
  // const [themes, setTheme]: any = useState(
  //   darkModeQuery.matches ? 'dark' : 'light'
  // );
  // useEffect(() => {
  //   darkModeQuery.addEventListener('change', (e) => {
  //     console.log({ e });
  //     setTheme(e.matches ? 'dark' : 'light');
  //   });
  //   // darkModeQuery.addEventListener((event) => {
  //   //   setTheme(event.matches ? 'dark' : 'light');
  //   // });
  // });

  const client = init();
  return (
    <AppStyled>
      <Router>
        <ThemeProvider theme={theme}>
          <AuthStore.Provider initialData={client}>
            <ConfigurationStore.Provider initialData={client}>
              <UiStore.Provider>
                <ApolloProvider client={client}>
                  <>
                    <InitiStoreApp />
                    <GlobalStyle />
                    <Routes />
                  </>
                </ApolloProvider>
              </UiStore.Provider>
            </ConfigurationStore.Provider>
          </AuthStore.Provider>
        </ThemeProvider>
      </Router>
    </AppStyled>
  );
};

export default App;
