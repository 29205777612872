import React, { useState, useEffect } from 'react';
import { Input, Button } from 'Ui';
import PasswordHelper from '../PasswordHelper';
import useForm from 'react-hook-form';

import Media from 'react-media';
import styled from 'styled-components';

import errorMessageModel from './store/';

const regexPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,50})/g;

export type FormData = {
  password: string;
  newPassword: string;
  newPasswordConfirmation: string;
};

type ModifyPasswordFormProps = {
  onModifyPassword?: (form: FormData) => void;
  loading?: boolean;
  onReset?: boolean;
};

const ButtonStyled = styled.div`
  text-align: right;
`;

export default ({
  loading,
  onModifyPassword,
  onReset,
}: ModifyPasswordFormProps) => {
  const [passwordInit, setpasswordInit] = useState(false);
  const message = errorMessageModel.useStoreState(
    (state) => state.messageOldPassword
  );

  const setMessage = errorMessageModel.useStoreActions(
    (action) => action.setMessageOldPassword
  );

  const {
    register,
    errors,
    getValues,
    formState,
    reset,
    triggerValidation,
  } = useForm<FormData>({
    mode: 'onChange',
  });
  const validating = (field: typeof formState.touched[0]) => {
    if (!field) return 'default';
    const typesErors = errors as { [index: string]: any };
    return typesErors[field]
      ? 'error'
      : formState.touched.includes(field)
      ? 'success'
      : 'default';
  };

  const validatingOldPassword = (field: typeof formState.touched[0]) => {
    if (!field) return 'default';
    const typesErors = errors as { [index: string]: any };
    return typesErors[field] || message.length !== 0
      ? 'error'
      : formState.touched.includes(field)
      ? 'default'
      : 'default';
  };

  useEffect(() => {
    if (onReset) {
      setpasswordInit(false);
      reset({
        password: undefined,
        newPassword: undefined,
        newPasswordConfirmation: undefined,
      });
    }
  }, [onReset]);

  const onValidate = async () => {
    try {
      const validationResult = await triggerValidation();
      if (validationResult && onModifyPassword) onModifyPassword(getValues());
    } catch (e) {
      console.log({ e });
    }
  };

  const styles = { marginBottom: '1rem' };
  return (
    <div>
      <Input
        block
        style={styles}
        kind={validatingOldPassword('password')}
        type="password"
        name="password"
        helperText={
          message ? message : errors.password && errors.password.message
        }
        onFocus={() => {
          setMessage('');
        }}
        placeholder="Mot de passe actuel"
        ref={register({
          required: 'Champ obligatoire',
        })}
      />

      <Input
        block
        style={styles}
        kind={validating('newPassword')}
        name="newPassword"
        type="password"
        placeholder="Nouveau mot de passe"
        onFocus={() => {
          setpasswordInit(true);
        }}
        helperText={errors.newPassword && errors.newPassword.message}
        ref={register({
          required: 'Champ obligatoire',
          pattern: {
            value: regexPassword,
            message: 'Veuillez saisir un mot de passe valide',
          },
        })}
      />

      {passwordInit && <PasswordHelper password={getValues().newPassword} />}

      <Input
        block
        style={styles}
        kind={validating('newPasswordConfirmation')}
        type="password"
        name="newPasswordConfirmation"
        placeholder="Confirmation de mot de passe"
        helperText={
          errors.newPasswordConfirmation &&
          errors.newPasswordConfirmation.message
        }
        ref={register({
          required: 'Champ obligatoire',
          validate: (value: string) => {
            if (value !== getValues().newPassword) {
              return 'Les mots de passe ne sont pas identiques';
            }
          },
        })}
      />

      <Media
        query={{ maxWidth: 767 }}
        render={() => (
          <Button block kind="primary" onClick={onValidate} loading={loading}>
            Valider
          </Button>
        )}
      />

      <Media
        query={{ minWidth: 768 }}
        render={() => (
          <ButtonStyled>
            <Button kind="primary" onClick={onValidate} loading={loading}>
              Valider
            </Button>
          </ButtonStyled>
        )}
      />
    </div>
  );
};
