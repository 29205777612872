import * as React from "react";
const SvgFb = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 21 20"
    {...props}
  >
    <path
      fill="#1877F2"
      d="M19.25 10a9 9 0 0 0-9-9 9 9 0 0 0-9 9 9 9 0 0 0 7.594 8.891v-6.29H6.559V10h2.285V8.017c0-2.255 1.343-3.501 3.4-3.501.984 0 2.014.175 2.014.175v2.215h-1.136c-1.118 0-1.466.695-1.466 1.407V10h2.496l-.399 2.602h-2.097v6.289A9 9 0 0 0 19.25 10"
    />
    <path
      fill="#fff"
      d="m13.753 12.602.4-2.602h-2.497V8.313c0-.712.348-1.407 1.466-1.407h1.136V4.691s-1.03-.175-2.015-.175c-2.056 0-3.4 1.246-3.4 3.501V10H6.56v2.602h2.285v6.289a9 9 0 0 0 2.812 0v-6.29z"
    />
  </svg>
);
export default SvgFb;
