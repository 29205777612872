import UiStore from 'Store/UiStore';
import { Button, Input, InputGroup, theme } from 'Ui';
import { IconFacebook } from 'Ui/Icons';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
// @ts-ignore
import { useApolloClient } from '@apollo/client';
import {
  registerMemberFacebook,
  registerMemberFacebookVariables,
} from 'Components/RegistrationForm/__generated__/registerMemberFacebook';
import { REGISTER_MEMBER_FACEBOOK } from 'Components/RegistrationForm/query';
import AuthStore from 'Store/AuthStore';
import { toast } from 'Components/Toaster';
import { numberOfSession } from 'Utils/cookie';
import { bugLogin, emailRegex, login, redirect } from 'Utils/login';
import ReactFacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import useForm from 'react-hook-form';

type FormData = {
  email: string;
  password: string;
};

const HeaderLogin = styled.div`
  margin: 0 auto;
  width: 90%;
  min-height: 10.5rem;
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  -webkit-box-pack: justify;
  padding: 1.2rem 0 0rem;
  font-size: 1.4rem;
  flex-direction: row;
  @media screen and (max-width: 1200px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const LogoStyled = styled.div<{ error?: boolean; errorEmail?: boolean }>`
  width: 35rem;
  .logo {
    ${(props) => (props.error ? 'margin-bottom: 17px' : 'margin-bottom: 0')};
    ${(props) =>
      props.errorEmail ? 'margin-bottom: 24px' : 'margin-bottom: 0'};
    width: 100%;
    height: auto;
  }
`;

const Header = styled.div`
  width: 100%;
  position: relative;
  top: 0px;
  z-index: 100000000;
`;

const ConnexionLogin = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const ConnexionForm = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 8px;

  .inputGroup {
    /* align-items: center; */
  }
`;

const ConnexionTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 58.1rem;
`;

const ForgotPassword = styled.div`
  font-family: 'MontserratSemiBold';
  font-size: 1.5rem;
  color: #3e3e3e;
  cursor: pointer;
  text-decoration: underline;
  font-weight: 600;
  font-size: 1.5rem;
`;

const ConnexionP = styled.div`
  font-family: 'granville-bold';
  font-size: 2.3rem;
`;

const Icon = styled.div<{ error?: boolean }>`
  cursor: pointer;
  margin-left: 1rem;
  color: #3c5997;
  ${(props) => (props.error ? 'margin: 0.5rem 0' : 'margin: auto')};
  svg {
    font-size: 3.5rem;
  }
`;

const ConnexionButton = styled.div<{ error?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 11.2rem;
  ${(props) => (props.error ? 'align-items: none' : 'align-items: center')};
  .rounded {
    border-radius: 1rem !important;
    padding: 0 1.4rem;
    font-size: 2rem;
    height: 5rem;
  }
  .separation {
    border-left: 1px solid #707070;
    height: 5rem;
    margin-left: 2rem;
  }
`;

const Error = styled.div`
  margin-top: 8px;
  margin-left: 7px;
  color: ${theme.light.colors.error};
  font-size: 14px;
`;

export default () => {
  // Get the prefill Values to connect
  const inputRef: any = useRef();
  const passwordRef: any = useRef();
  const apolloClient = useApolloClient();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const cookieMarketingStore = UiStore.useStoreState(
    (state) => state.cookieMarketing
  );

  const setAuth = AuthStore.useStoreActions((actions) => actions.setAuth);

  const { register, handleSubmit, errors, formState } = useForm<FormData>({
    mode: 'onChange',
  });

  const validating = (field: (typeof formState.touched)[0]) => {
    if (!field) return 'default';
    const typesErors = errors as { [index: string]: any };
    return typesErors[field]
      ? 'error'
      : formState.touched.includes(field)
      ? 'success'
      : 'default';
  };

  const connectMemberWithFacebook = async (data: any) => {
    const accessToken: string = data.accessToken;
    setLoading(true);
    try {
      const response = await apolloClient.mutate<
        registerMemberFacebook,
        registerMemberFacebookVariables
      >({
        mutation: REGISTER_MEMBER_FACEBOOK,
        variables: {
          accessToken,
        },
      });

      if (response.data && response.data.registerMemberFacebook) {
        const data = response.data.registerMemberFacebook;
        if (data.member) setAuth(data);
      }
      // window.localStorage.setItem('loginType', 'FB');
      // window.localStorage.setItem(
      //   'signIn',
      //   JSON.stringify({ event: 'signIn', loginType: 'FB' })
      // );
      redirect();
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const onSubmit = handleSubmit((formData, e) => {
    e.preventDefault();
    setLoading(true);
    login(formData, apolloClient)
      .then((data) => {
        if (data) {
          const loginData = data;
          if (loginData.member) {
            setAuth(loginData);
            // window.localStorage.setItem('loginType', 'BZC');
          }
          if (sessionStorage.getItem('bugLogin')) {
            sessionStorage.removeItem('bugLogin');
          }
          redirect();
          numberOfSession();
        }
      })
      .catch((err) => {
        const graphQLErrors =
          err &&
          err.graphQLErrors &&
          err.graphQLErrors[0].extensions.bzcMessage;
        const graphQLErrorCode =
          err && err.graphQLErrors && err.graphQLErrors[0].extensions.bzcCode;
        console.log({ graphQLErrorCode });
        if (
          graphQLErrorCode !==
          ('ERROR_SERVER_GENERIC' || 'ERROR_TOO_MANY_REQUEST')
        ) {
          setError(graphQLErrors);
        }
        if (graphQLErrorCode === 'ERROR_TOO_MANY_REQUEST') {
          // setError(graphQLErrors);
          toast.error('Requête non autorisée');
        }
        bugLogin(formData);
        setLoading(false);
      });
  });

  useEffect(() => {}, [error]);

  return (
    <Header>
      <HeaderLogin>
        <LogoStyled
          error={error == null ? false : true}
          errorEmail={
            errors && errors.email ? true : errors.password ? true : false
          }
        >
          <img
            className="logo"
            src="https://cdn.bazarchic.com/media/logos/500/Bazarchic-Logo-noir.png"
            alt="Vente Privée BazarChic"
          />
        </LogoStyled>
        <ConnexionLogin>
          <ConnexionTitle>
            <ConnexionP>Connexion</ConnexionP>
            <ForgotPassword>
              <a href="/login/emailForResetPassword/">Mot de passe oublié ?</a>
            </ForgotPassword>
          </ConnexionTitle>

          <ConnexionForm>
            <form onSubmit={onSubmit} style={{ display: 'flex' }} noValidate>
              <InputGroup className="inputGroup" block gutter={-1} even>
                <div style={{ marginRight: '2.5rem' }}>
                  <Input
                    block
                    isLogin={true}
                    autoComplete="off"
                    className="fontMontserrat"
                    type="email"
                    placeholder="E-mail"
                    name="email"
                    style={{ width: '28rem' }}
                    initialValue={inputRef}
                    kind={error ? 'error' : validating('email')}
                    ref={register({
                      required: 'Champ obligatoire',
                      pattern: {
                        value: emailRegex,
                        message: "L'adresse e-mail n'est pas valide",
                      },
                    })}
                    helperText={errors.email && errors.email.message}
                  />
                </div>
                <div style={{ marginRight: '2.5rem' }}>
                  <Input
                    block
                    isLogin={true}
                    autoComplete="off"
                    placeholder="Mot de passe"
                    type="password"
                    className="fontMontserrat"
                    initialValue={passwordRef}
                    style={{ width: '28rem' }}
                    name="password"
                    kind={
                      error
                        ? 'error'
                        : errors.password && errors.password.message
                        ? 'error'
                        : 'default'
                    }
                    // initialValue={passwordRef}
                    ref={register({
                      required: 'Champ obligatoire',
                    })}
                    helperText={errors.password && errors.password.message}
                  />
                </div>
              </InputGroup>
              <ConnexionButton
                error={errors.email ? true : errors.password ? true : false}
              >
                <Button
                  type="submit"
                  loading={loading}
                  className="rounded fontMontserratSemiBold"
                >
                  OK
                </Button>
                {cookieMarketingStore && (
                  <ReactFacebookLogin
                    appId={process.env.REACT_APP_FACEBOOK_ID || ''}
                    callback={connectMemberWithFacebook}
                    isMobile={false}
                    render={(props: any) => (
                      <>
                        <div className="separation"></div>
                        <Icon
                          error={errors.email ? true : false}
                          onClick={props.onClick}
                        >
                          <IconFacebook loading={loading} />
                        </Icon>
                      </>
                    )}
                  />
                )}
              </ConnexionButton>
            </form>
          </ConnexionForm>
          {error && <Error>{error}</Error>}
        </ConnexionLogin>
      </HeaderLogin>
    </Header>
  );
};
