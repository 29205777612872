// Components to render outside of main app, in the old PHP site for example

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import AutosuggestRender from './AutosuggestRender';
import AutosuggestRenderPLaceholderCustom from './AutosuggestRenderPlaceholderDefault';
import ReactDOM from 'react-dom';
import Header from './Header';
import Menu from './Menu';
import Footer from './Footer';
import RegisterFormHomeDesktop from './RegisterFormHomeDesktop';
import RegisterFormHomeMobile from './RegisterFormHomeMobile';
import ModifyPassword from './ModifyPassword';
import DataLayer from './DataLayer';
import FacebookConnect from './Facebook';
import NotFound from './PageNotFound';
import ScrollUp from './ScrollUp';
import RegistrationCurrentSale from './RegistrationCurrentSale';
import LoginDesktop from './Login/Desktop';
import LoginMobile from './Login/Mobile';
import LoginMobileConnect from './Login/Mobile/LoginMobileConnect';
import LoginMobileRegister from './Login/Mobile/RegisterMobile';
import Toaster from './Toaster';
import LoginBarExternal from './Login/Desktop/LoginBarExternal';
import Preference from 'External/PreferenceCenter';
import BannerRgpd from 'External/BannerRGPD';

const render = (className: string, reactNode: any) => {
  if (document.querySelectorAll(className).length)
    [].slice
      .call(document.querySelectorAll(className))
      .map((htmlNode: any) => ReactDOM.render(reactNode, htmlNode));
};

export const renderPartials = () => {
  document.addEventListener('DOMContentLoaded', function () {
    console.log('Loading external injection');
    /** Autosuggest */
    render('#react-autosuggest', <AutosuggestRender />);
    /** Autosuggest placeholder custom */
    render(
      '#react-autosuggest-placeholder-default',
      <AutosuggestRenderPLaceholderCustom />
    );

    /** Preference center */
    render('#react-preference-center', <Preference />);

    /** Header */
    render('#react-header', <Header />);

    /** Menu */
    render('#react-menu', <Menu />);
    /** Footer */
    render('#react-footer', <Footer />);
    /** Register Form Home Desktop */
    render('.react-register-home-desktop', <RegisterFormHomeDesktop />);
    /** Register Form Home Mobile */
    render('.react-register-home-mobile', <RegisterFormHomeMobile />);
    /** Modify Password */
    render('#react-modify-password', <ModifyPassword />);
    /** Datalayer */
    render('#react-dataLayer', <DataLayer />);
    /** FacebookConnect */
    render('#react-facebook-connect', <FacebookConnect />);

    /** Page not found*/
    render('#react-page-no-found', <NotFound />);

    /** ScrollUp for home */
    render('#react-scrollup', <ScrollUp />);
    render('#react-registration-current-sales', <RegistrationCurrentSale />);
    /** Page Login desktop */
    render('#react-login', <LoginDesktop />);
    render('#react-login-bar', <LoginBarExternal />);
    render('#react-login-mobile', <LoginMobile />);
    render('#react-login-mobile-register', <LoginMobileRegister />);
    render('#react-login-mobile-connect', <LoginMobileConnect />);
    /** TEST TOASTER */
    render('.react-toaster', <Toaster />);
    //for page SEO
    render('#react-banner-rgpd', <BannerRgpd />);
  });
};
