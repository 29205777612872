import * as React from "react";
const SvgLinkedin = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 25 25"
    {...props}
  >
    <path
      fill="#CCC"
      fillRule="evenodd"
      d="M4.735 1.5c.175.028.352.045.523.084 1.87.433 2.278 2.626 1.256 3.796-.384.44-.873.688-1.44.783-.606.101-1.204.068-1.767-.206-.773-.376-1.196-1.006-1.292-1.862Q2.009 4.06 2 4.024v-.33c.03-.167.046-.339.094-.5.261-.884.87-1.394 1.741-1.61.171-.042.349-.057.523-.084zm4.52 20.19c0-.214-.004-.428-.003-.643l.008-2.234q.01-3.9.013-7.8c0-.957-.014-1.913-.02-2.87l-.001-.071h4.487v1.89l.018.007q.025-.033.048-.068c.357-.543.779-1.025 1.318-1.395a4.26 4.26 0 0 1 1.948-.715c.856-.1 1.701-.043 2.523.233 1.316.441 2.222 1.332 2.779 2.598.303.69.472 1.416.538 2.165.037.42.052.845.053 1.267q.004 3.76.002 7.52 0 .06-.003.116h-4.487l-.002-.115v-6.976c-.001-.214-.003-.429-.015-.643-.027-.46-.103-.912-.28-1.34-.228-.553-.594-.974-1.171-1.167a2.4 2.4 0 0 0-1.707.042c-.696.28-1.136.816-1.411 1.5-.13.325-.144.67-.145 1.014q-.002 3.785 0 7.57l-.002.115zm-6.977 0c-.003-.033-.009-.065-.009-.098V8.072h4.496V21.69z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgLinkedin;
