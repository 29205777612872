import * as React from "react";
const SvgSecurePayment = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 17 17"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M13.408 7.646H4.075c-.736 0-1.333.597-1.333 1.333v4.667c0 .736.597 1.333 1.333 1.333h9.333c.737 0 1.334-.597 1.334-1.333V8.979c0-.736-.597-1.333-1.334-1.333"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M5.408 7.646V4.979a3.333 3.333 0 1 1 6.667 0v2.667"
    />
  </svg>
);
export default SvgSecurePayment;
