export const ModalTitleClient = {
  login: 'Connectez-vous pour accéder à votre compte et à vos avantages',
  register: 'Nouveau sur BazarChic ? Créez votre compte.',
}

export const ModalTitleWishlist = {
  login: 'Pour accéder à vos favoris, connectez-vous.',
  register: 'Nouveau sur BazarChic ? Créez votre compte.',
}

export const ModalTitleTunnel = {
  login: 'Pour finaliser votre achat, connectez-vous.',
  register: 'Nouveau sur BazarChic ? Créez votre compte.',
}
