import { theme } from 'Ui/theme';
import styled, { css } from 'styled-components/macro';
import { IconMinus, IconPlus } from './Icons';

const CounterStyled = styled.div<{ error?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${theme.light.colors.white};
  font-size: 1.6rem;
  border: ${(props) =>
    props.error ? `1px solid ${theme.colors.error}}` : '1px solid #000'};
  margin-bottom: ${(props) => (props.error ? '0.5rem' : '0rem')};
  width: 9.8rem;
  height: 3.8rem;
  border-radius: 0.5rem;
`;

const ButtonDefaultStyled = styled.div<{ disabled: boolean }>`
  user-select: none;
  font-size: 2.6rem;
  height: 2.6rem;
  justify-content: center;
  display: flex;
  align-items: center;
  line-height: 0;
  ${(props) =>
    props.disabled
      ? css`
          color: #ccc;
          //
        `
      : css`
          cursor: pointer;
          &:hover {
            color: ${theme.light.colors.purple};
          }
          //
        `}
`;

const ValueCounter = styled.div`
  width: 45px;
  color: ${theme.light.colors.black};
  font-size: 2rem;
  font-family: 'MontserratSemiBold', sans-serif;
  font-size: 1.4rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 600;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  disabled: boolean;
`;

const WrapperCounter = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 0rem 0;
  @media screen and (min-width: 630px) {
    margin: 0 1.5rem 0;
  }

  /* height: 5rem; */
`;

const MessageError = styled.div`
  color: ${theme.colors.error};
  font-family: 'Montserrat';
  font-size: 1rem;
  position: relative;
  @media screen and (min-width: 630px) {
    position: absolute;
  }
`;

interface CounterProps {
  max?: number;
  onChange: (value: number) => void;
  value: number;
  [index: string]: any;
  error?: boolean;
  msgError?: string;
}

export const CounterV2 = ({
  max = 10,
  value,
  onChange,
  error,
  msgError,
  ...rest
}: CounterProps) => {
  const increment = () => {
    // if (value < max) {
    //   onChange(value + 1);
    // }
    onChange(value + 1);
  };

  const decrement = () => {
    if (value > 1) {
      onChange(value - 1);
    }
  };

  return (
    <div>
      <WrapperCounter>
        <CounterStyled error={error} {...rest}>
          <ButtonDefaultStyled
            disabled={value <= 1}
            onClick={() => decrement()}
          >
            <IconMinus />
          </ButtonDefaultStyled>
          <ValueCounter>{value}</ValueCounter>
          <ButtonDefaultStyled disabled={error} onClick={() => increment()}>
            <IconPlus />
          </ButtonDefaultStyled>
        </CounterStyled>
      </WrapperCounter>
      {error && <MessageError>{msgError}</MessageError>}
    </div>
  );
};
