import gql from "graphql-tag";

export default gql`
  query getHeaderNavigation($device: DeviceEnum!, $os: OsEnum!) {
    headerNavigation: headerNavigationLinks(device: $device, os: $os) {
      url
      label
      color
      alt
      type
      target
    }
  }
`;
