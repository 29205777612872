import * as React from "react";
const SvgAlmaReassurance = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 45 14"
    {...props}
  >
    <path
      fill="#FA5022"
      d="M40.858 3.823V5.05c-.695-.892-1.704-1.45-2.901-1.45-2.31 0-4.101 2.07-4.101 4.624s1.79 4.623 4.1 4.623c1.283 0 2.348-.637 3.044-1.641v1.42h2.21V3.823zm-2.327 6.82c-1.285 0-2.325-1.083-2.325-2.42 0-1.335 1.04-2.418 2.325-2.418s2.325 1.083 2.325 2.419-1.04 2.419-2.325 2.419M13.76 1.38h2.352v11.245H13.76zm5.056 6.256c0-2.42 1.484-4.034 3.539-4.034 1.276 0 2.284.568 2.892 1.535.609-.967 1.616-1.535 2.892-1.535 2.055 0 3.539 1.614 3.539 4.034v4.99h-2.352V7.381c0-.944-.625-1.56-1.452-1.56-.826 0-1.451.617-1.451 1.56v5.245h-2.352V7.381c0-.944-.625-1.56-1.452-1.56s-1.451.617-1.451 1.56v5.245h-2.352zM9.503 3.915C8.946 1.827 7.77 1.159 6.65 1.159s-2.298.668-2.854 2.756l-2.417 8.712H3.76c.388-1.455 1.584-2.265 2.889-2.265s2.5.812 2.889 2.265h2.383zM6.65 8.332c-.759 0-1.446.288-1.953.755l1.366-5.07c.13-.485.324-.64.588-.64.265 0 .458.155.589.64l1.364 5.07a2.88 2.88 0 0 0-1.954-.755"
    />
  </svg>
);
export default SvgAlmaReassurance;
