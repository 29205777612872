import * as React from "react";
const SvgReassurance = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 53 40"
    {...props}
  >
    <g clipPath="url(#reassurance_svg__a)">
      <path
        fill="currentColor"
        stroke="#fff"
        strokeWidth={0.2}
        d="M33.52 28.843v.801a4.66 4.66 0 0 1-1.337 3.268 4.54 4.54 0 0 1-3.223 1.355H15.895l-4.747 4.818a1.97 1.97 0 0 1-2.804-.005 2.03 2.03 0 0 1-.583-1.42v-3.387h-2.2a4.53 4.53 0 0 1-3.224-1.356A4.66 4.66 0 0 1 1 29.65V14.533a4.66 4.66 0 0 1 1.337-3.268A4.54 4.54 0 0 1 5.56 9.91h10.565c.624 0 1.13.512 1.13 1.144s-.506 1.144-1.13 1.144H5.555a2.3 2.3 0 0 0-1.628.686 2.36 2.36 0 0 0-.676 1.649V29.65c.002.619.245 1.211.676 1.649a2.3 2.3 0 0 0 1.627.686h3.33c.3 0 .587.12.798.335.212.214.331.505.331.809v3.873l4.617-4.686c.212-.216.5-.338.801-.338h13.545a2.3 2.3 0 0 0 1.627-.685c.432-.437.675-1.03.676-1.649v-.801c.07-.574.55-1.004 1.12-1.004s1.052.43 1.122 1.004Zm18.625-23.22V20.74a4.66 4.66 0 0 1-1.337 3.268 4.54 4.54 0 0 1-3.223 1.356h-2.201v3.392a2.03 2.03 0 0 1-.582 1.42 1.97 1.97 0 0 1-1.401.59c-.526 0-1.03-.21-1.403-.585l-4.747-4.817H24.18a4.54 4.54 0 0 1-3.223-1.356 4.66 4.66 0 0 1-1.337-3.268V5.623a4.66 4.66 0 0 1 1.337-3.267A4.54 4.54 0 0 1 24.18 1h23.4c1.21 0 2.37.487 3.227 1.354a4.66 4.66 0 0 1 1.34 3.27Zm-2.257 0a2.36 2.36 0 0 0-.676-1.649 2.3 2.3 0 0 0-1.627-.685H24.18a2.3 2.3 0 0 0-1.627.685 2.36 2.36 0 0 0-.676 1.65V20.74c.002.62.245 1.212.676 1.65a2.3 2.3 0 0 0 1.627.685h13.545c.301 0 .59.12.802.337l4.617 4.686V24.22c0-.632.505-1.144 1.128-1.144h3.33a2.3 2.3 0 0 0 1.627-.686c.431-.437.675-1.03.676-1.649V5.623z"
      />
      <circle cx={29} cy={13} r={2} fill="url(#reassurance_svg__b)" />
      <circle cx={36} cy={13} r={2} fill="url(#reassurance_svg__c)" />
      <circle cx={43} cy={13} r={2} fill="url(#reassurance_svg__d)" />
    </g>
    <defs>
      <linearGradient
        id="reassurance_svg__b"
        x1={29}
        x2={29}
        y1={11}
        y2={15}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6C4DFF" />
        <stop offset={1} stopColor="#8268FF" />
      </linearGradient>
      <linearGradient
        id="reassurance_svg__c"
        x1={36}
        x2={36}
        y1={11}
        y2={15}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6C4DFF" />
        <stop offset={1} stopColor="#8268FF" />
      </linearGradient>
      <linearGradient
        id="reassurance_svg__d"
        x1={43}
        x2={43}
        y1={11}
        y2={15}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6C4DFF" />
        <stop offset={1} stopColor="#8268FF" />
      </linearGradient>
      <clipPath id="reassurance_svg__a">
        <path fill="#fff" d="M0 0h53v40H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgReassurance;
