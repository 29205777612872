import { ApolloClient } from '@apollo/client';
import CryptoJS from 'crypto-js';
import Cookies from 'js-cookie';
import { LOGIN_MEMBER } from 'Pages/Login/query';
import {
  loginMember,
  loginMemberVariables,
} from 'Pages/Login/__generated__/loginMember';
import { SET_ANONYMOUS_SESSION } from 'Store/query';

export const redirect = () => {
  /** @todo once the app is fully React, change those ugly cookie to som url / router params */
  const url = Cookies.get('redirection_url');
  // Cookies.remove("redirection_url", { domain: '.bazarchic.com', path: "/" });
  // console.log(url)
  /** @todo once the app is fully React, change that to a router location push */
  window.location.href = url || '/';
};

export const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const validating = (
  formState: any,
  errors: any,
  field: typeof formState.touched[0]
) => {
  if (!field) return 'default';
  const typesErors = errors as { [index: string]: any };
  return typesErors[field]
    ? 'error'
    : formState.touched.includes(field)
      ? 'success'
      : 'default';
};

export type FormData = {
  email: string;
  password: string;
};

export const bugLogin = (form: FormData) => {
  const getBugLogin = JSON.parse(sessionStorage.getItem('bugLogin'));
  let getBugLoginCount = getBugLogin && getBugLogin.count;
  if (!sessionStorage.getItem('bugLogin')) {
    sessionStorage.setItem(
      'bugLogin',
      JSON.stringify({ email: `${form.email}`, count: 1 })
    );
  } else if (!!sessionStorage.getItem('bugLogin')) {
    if (getBugLogin && getBugLogin.count) {
      sessionStorage.setItem(
        'bugLogin',
        JSON.stringify({
          email: `${form.email}`,
          count: getBugLogin && getBugLogin.count + 1,
        })
      );
    }
  }
};

export const login = async (
  form: FormData,
  apolloClient: ApolloClient<Object>
) => {
  const passwordSha = CryptoJS.SHA256(form.password);
  try {
    const { data } = await apolloClient.mutate<
      loginMember,
      loginMemberVariables
    >({
      mutation: LOGIN_MEMBER,
      variables: {
        email: form.email,
        password: passwordSha.toString(),
      },
    });

    return data?.loginMember;
  } catch (e) {
    throw e;
  }
};

export const LoginV2 = async (
  form: FormData,
  apolloClient: ApolloClient<Object>
) => {
  const passwordSha = CryptoJS.SHA256(form.password);
  try {
    const { data } = await apolloClient.mutate<
      loginMember,
      loginMemberVariables
    >({
      mutation: LOGIN_MEMBER,
      variables: {
        email: form.email,
        password: passwordSha.toString(),
      },
    });

    return data?.loginMember;
  } catch (e) {
    throw e;
  }
}

export const createAnonymousSession = async (
  apolloClient: ApolloClient<Object>
) => {
  try {
    const { data } = await apolloClient.mutate({
      mutation: SET_ANONYMOUS_SESSION,
    });
    return data;
  } catch (e) {
    throw e;
  }
};

// const registerMemberWithFacebook = async (data: any, setLoading: any, apolloClient: ApolloClient<Object>) => {
//   const accessToken: string = data.accessToken;
//   setLoading(true);
//   try {
//     const response = await apolloClient.mutate<
//       registerMemberFacebook,
//       registerMemberFacebookVariables
//     >({
//       mutation: REGISTER_MEMBER_FACEBOOK,
//       variables: {
//         accessToken,
//       },
//     });

//     if (response.data && response.data.registerMemberFacebook) {
//       const data = response.data.registerMemberFacebook;
//       if (data.member) setAuth(data);
//     }
//     window.localStorage.setItem(
//       'setSubscriptionType',
//       JSON.stringify({ event: 'signUp', subscriptionType: 'FB' })
//     );
//     window.localStorage.setItem('subscriptionTracking', 'inscription-ok')
//     redirect();
//   } catch (e) {
//     setLoading(false);
//   }
// };
