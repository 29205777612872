import React from 'react';
import Media from 'react-media';
import TunnelDesktopHeader from './TunnelDesktopHeader';
import TunnelMobileHeader from './TunnelMobileHeader';

export default () => {
  return (
    <>
      <Media query={{ minWidth: 768 }}>
        <TunnelDesktopHeader />
      </Media>
      <Media query={{ maxWidth: 767 }}>
        <TunnelMobileHeader />
      </Media>
    </>
  );
};