import React from 'react';
import TagManager from 'react-gtm-module';
import VisibilitySensor from 'react-visibility-sensor';
import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import dayjs from 'dayjs';

export const getGtmHomePageSaleClick = (
  sale: any,
  index: any,
  size: string | number,
  univers?: any
) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'eePromoClick',
      pageType: 'HomePage',
      pageTypeB: `HomePage ${univers ? univers.toLowerCase() : ''}`,
      promotions: [
        {
          id: sale.id,
          name: `${sale.name} - ${dayjs(
            sale.startDateTime || sale.dateStart
          ).format('YYMMDD')}`,
          creative: `Ventes ${sale.promoMulti} ${
            sale.promoCategory
          } ${sale.saleTargets.join('|')}`,
          position: `HomePage  ${index} ${isMobile ? 'Large' : size}`,
        },
      ],
    },
  });
};

let saleVisibilityBuffer: {
  index: number;
  sale: any;
  size: string | number;
  position: number;
  universe?: string;
}[] = [];
let saleVisibilityIndex = 0;
const saleVisibilityBufferSize = 5;

export const purgeVisibility = () => {
  saleVisibilityBuffer = [];
};

export const setGtmSaleVisibility = (
  sale: any,
  size: string | number,
  position: number,
  universe?: string
) => {
  saleVisibilityIndex += 1;

  saleVisibilityBuffer.push({
    index: saleVisibilityIndex,
    sale,
    size,
    position,
    universe,
  });

  if (saleVisibilityBuffer.length >= saleVisibilityBufferSize) {
    TagManager.dataLayer({
      dataLayer: {
        event: 'eePromoView',
        pageType: 'HomePage',
        pageTypeB: `HomePage ${universe?.toLowerCase()}`,
        promotions: saleVisibilityBuffer.map((saleWrap, index) => ({
          id: saleWrap.sale.id,
          name: `${saleWrap.sale.name}  ${dayjs(
            saleWrap.sale.startDateTime
          ).format('YY-MM-DD')}`,
          creative: `Ventes ${saleWrap.sale.promoMulti} ${
            saleWrap.sale.promoCategory
          } ${saleWrap.sale.saleTargets.join('|')}`,
          position: `HomePage  ${saleWrap.position} ${
            isMobile ? 'Large' : saleWrap.size
          }`,
        })),
      },
    });
    saleVisibilityBuffer = [];
  }
};

export const SpySaleVisibility = ({
  sale,
  size,
  position,
  universe,
}: {
  sale: any;
  size: string | number;
  position: any | number;
  universe?: string;
}) => {
  const [active, setActive] = useState(true);
  const onChange = (visible: boolean) => {
    if (visible) {
      setActive(false);
      setGtmSaleVisibility(sale, size, position, universe);
    }
  };
  return (
    <VisibilitySensor active={active} onChange={onChange} delayedCall={true}>
      <div
        style={{
          height: 1,
          width: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
        }}
      />
    </VisibilitySensor>
  );
};
