import * as React from "react";
const SvgIconeClose = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="icone-close_svg__Calque_1"
    data-name="Calque 1"
    viewBox="0 0 23.49 23.49"
    width="1em"
    height="1em"
    {...props}
  >
    <defs>
      <style>
        {
          ".icone-close_svg__cls-1{fill:none;stroke:#232929;stroke-linecap:round;stroke-linejoin:round;stroke-width:4px}"
        }
      </style>
    </defs>
    <path
      d="m2 2 19.49 19.49M21.49 2 2 21.49"
      className="icone-close_svg__cls-1"
    />
  </svg>
);
export default SvgIconeClose;
