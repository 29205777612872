import amex_footer from 'Assets/images/amex_footer.png';
import cb_footer from 'Assets/images/CB_footer.png';
import mastercard_footer from 'Assets/images/mastercard_footer.png';
import alma_footer from 'Assets/images/alma_footer.png';
import paypal_footer from 'Assets/images/paypal_footer.png';
import visa_footer from 'Assets/images/visa_footer.png';
import styled from 'styled-components';

const PaymentCardContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1.6rem;
  margin: auto auto;
  width: fit-content;
`;

export const Payments = () => (
  <PaymentCardContainer>
    <img
      width={36}
      height={22}
      className="footer_payment_img"
      alt="CB"
      src={cb_footer}
      loading="lazy"
    />
    <img
      width={36}
      height={22}
      className="footer_payment_img"
      alt="Visa"
      src={visa_footer}
      loading="lazy"
    />
    <img
      width={36}
      height={22}
      className="footer_payment_img"
      alt="American Express"
      src={amex_footer}
      loading="lazy"
    />
    <img
      width={36}
      height={22}
      className="footer_payment_img"
      alt="MasterCard"
      src={mastercard_footer}
      loading="lazy"
    />
    <a
      href="https://fr.bazarchic.com/contenu/conditions-generales-de-ventes/"
      target="_blank"
      rel="noreferrer"
    >
      <img
        width={36}
        height={22}
        className="footer_payment_img"
        alt="Alma"
        src={alma_footer}
        loading="lazy"
      />
    </a>
    <img
      width={36}
      height={22}
      className="footer_payment_img"
      alt="PayPal"
      src={paypal_footer}
      loading="lazy"
    />
  </PaymentCardContainer>
);
