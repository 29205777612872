import * as React from "react";
const SvgMailMenu = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.3}
      d="M20.253 4H3.747A2.74 2.74 0 0 0 1 6.729v10.542A2.74 2.74 0 0 0 3.747 20h16.506A2.74 2.74 0 0 0 23 17.27V6.73A2.74 2.74 0 0 0 20.253 4Zm-16.506.773h16.506c.697 0 1.31.363 1.66.908l-8.466 7.943c-.798.748-2.096.748-2.894 0L2.087 5.681c.35-.545.963-.908 1.66-.908Zm-1.95 12.754a2 2 0 0 1-.019-.257V6.73q.001-.13.02-.257L7.687 12zm18.456 1.7H3.747a1.97 1.97 0 0 1-1.66-.909l6.168-5.787 1.764 1.655c.546.512 1.264.769 1.981.769s1.435-.257 1.981-.77l1.764-1.654 6.167 5.787a1.97 1.97 0 0 1-1.66.908ZM22.22 6.728V17.27q-.002.13-.018.257L16.31 12l5.892-5.527q.016.126.018.256Z"
    />
  </svg>
);
export default SvgMailMenu;
