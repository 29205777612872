import React from 'react';

import { ApolloProvider } from '@apollo/client';
import { init } from 'Libs/apollo';
import { GlobalStyle, AppStyled } from '../GlobalStyle';
import { BrowserRouter } from 'react-router-dom';
import AuthStore from 'Store/AuthStore';
import InitiStore from 'Store/InitiStore';
import UiStore from 'Store/UiStore';

import Media from 'react-media';
import SideMenu from 'Components/SideMenu';
import DesktopHeader from 'Components/Header/DesktopHeader/HeaderDesktopExternal';
import MobileHeader from 'Components/Header/MobileHeader/HeaderMobileExternal';
import ModalContainer from 'react-modal-promise';
import Toaster from 'Components/Toaster';
import SearchAutoSuggest from 'Components/SearchAutoSuggest';

export default () => {
  const client = init();

  return (
    <AppStyled>
      <BrowserRouter>
        <AuthStore.Provider initialData={client}>
          <UiStore.Provider>
            <ApolloProvider client={client}>
              <InitiStore />
              <>
                <Toaster />
                <GlobalStyle />
                <SideMenu />
                <Media query={{ minWidth: 768 }}>
                  <DesktopHeader />
                </Media>
                <Media query={{ maxWidth: 767 }}>
                  <MobileHeader />
                </Media>
                <ModalContainer />
              </>
            </ApolloProvider>
          </UiStore.Provider>
        </AuthStore.Provider>
      </BrowserRouter>
    </AppStyled>
  );
};
