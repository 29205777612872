import React from 'react';
import { GlobalStyle, AppStyled } from '../GlobalStyle';
import RegistrationForm from 'Components/RegistrationForm';
import styled from 'styled-components';
import { ApolloProvider } from '@apollo/client';
import { init } from 'Libs/apollo';
import Toaster from 'Components/Toaster';
import AuthStore from 'Store/AuthStore';
import UiStore from 'Store/UiStore';

const StyledForm = styled.div`
  padding: 1.5rem;
  background-color: rgba(255, 255, 255, 0.9);
`;

export default () => {
  const client = init();
  return (
    <ApolloProvider client={client}>
      <AuthStore.Provider initialData={client}>
        <UiStore.Provider>
          <AppStyled>
            <GlobalStyle />
            <StyledForm>
              <RegistrationForm />
              <Toaster />
            </StyledForm>
          </AppStyled>
        </UiStore.Provider>
      </AuthStore.Provider>
    </ApolloProvider>
  );
};
