import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import RegistrationForm from 'Components/RegistrationForm';
import { BACKGROUND_LOGIN } from '../query';
import { backgroundLogin } from 'Pages/Login/__generated__/backgroundLogin';
import fonts from 'Fonts';
import LoginBar from './LoginBar';
import Cookies from 'js-cookie';
import { setCookie } from 'Utils/cookie';
import { IconClose } from 'Ui/Icons';
import { theme } from 'Ui';

const Page = styled.div`
  /* margin-bottom: 30px; */
  font-family: 'Montserrat' !important;
  position: relative;
`;

const ImageLogin = styled.div<{ backgroundImage: string }>`
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  &:before {
    content: '';
    background-color: rgba(0, 1, 17, 0.3);
    opacity: 0.7;
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    /* background-image: url(${(props) => props.backgroundImage}); */
    background-size: cover;
    background-position-x: center;
    background-position-y: 0;
  }
  &::after {
    content: '';
    position: absolute;
    z-index: 0;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: center;
    background-position-y: 0;
    background-image: url(${(props) => props.backgroundImage});
  }
`;

const LoginContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  position: relative;
  align-items: center;
  z-index: 100;
  height: 100%;
  /* margin-bottom: 25px; */
  margin: 0rem 2rem 2.5rem;
`;

const StyledForm = styled.div`
  padding: 1.5rem 3rem;
  background-color: rgba(255, 255, 255, 0.55);
  width: 338px;
  border-radius: 25px;
  flex: none;
  /* margin: auto 0; */
  /* margin-top: 9.6rem; */
  @media screen and (max-width: 768px) {
    margin-left: 2rem;
  }
  @media screen and (min-width: 968px) {
    padding: 1.5rem 10rem;
    width: 555px;
  }
  @media screen and (min-width: 1024px) {
    padding: 1.5rem 10rem;
    width: 525px;
  }
  @media screen and (min-width: 1186px) {
    padding: 1.5rem 10rem;
    width: 515px;
  }
  @media screen and (min-width: 1440px) {
    width: 510px;
  }
`;

const ContentTitle = styled.div`
  @font-face {
    font-family: 'granville-bold';
    src: url(${fonts.granvileBold}) format('opentype');
    font-weight: 400;
    font-style: normal;
  }

  margin: 0 0rem;
  align-self: center !important;
  color: #fff;
  font-size: 4.5rem;
  text-align: center;
  font-weight: 700;
  font-family: 'granville-bold' !important;
  @media screen and (min-width: 600px) and (max-width: 1000px) {
    font-size: 3.5rem;
    align-self: center !important;
  }
  @media screen and (min-width: 1024px) and (max-width: 1200px) {
    margin: 0 0 18rem 0;
  }
`;

const TitleForm = styled.h1`
  @font-face {
    font-family: 'granville-bold';
    src: url(${fonts.granvileBold}) format('opentype');
    font-weight: 400;
    font-style: normal;
  }
  color: #000;
  font-weight: 700;
  font-family: 'granville-bold' !important;
  color: ${theme.light.colors.purple};
  font-size: 5rem;
  text-align: center;
  margin: 1rem 0rem 1.3rem 0rem;
`;

const Welcome = styled.div`
  position: fixed;
  text-transform: uppercase;
  z-index: 100;
  width: 100%;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0);
  color: #fff;
  text-align: left;
  font-size: 1.6rem;
  font-family: 'Montserrat';
  line-height: 2.4rem;
  padding: 1rem 0rem 1rem 1rem;
  p {
    width: 90%;
    font-weight: 400;
  }
  .hideBannerWelecome {
    cursor: pointer;
    position: absolute;
    top: 12px;
    right: 10px;
  }
  @media screen and (min-width: 768px) {
    text-align: center;
  }
`;

export default () => {
  //start image background
  const { data } = useQuery<backgroundLogin>(BACKGROUND_LOGIN);
  const bgImage = data && data;
  const image: any = bgImage && bgImage.pageDatas;
  const urlBackgroundDesktop1x =
    image && image.loginBackgroundImages[0].urlBackgroundDesktop1x;
  const [shohBannerWelcome, setShohBannerWelcome] = useState(
    !Cookies.get('hideBannerWelcome')
  );
  const bannerWelcome = () => {
    // Cookie.set('hideBannerWelcome', 'true', configCookie);
    const objectCookie = {
      path: '/',
      expires: 180,
      secure: true,
      name: 'hideBannerWelcome',
      value: 'true',
    };
    setCookie(objectCookie);
    setShohBannerWelcome(false);
  };

  return (
    <>
      <Page>
        {/* <ImageLogin backgroundImage={urlBackgroundDesktop1x}> */}
        <LoginContent>
          <ContentTitle>
            <p style={{ margin: '0 0' }}>Le meilleur de la mode premium</p>
            <p style={{ margin: '0 0' }}>en ventes privées JUSQU’À -70%</p>
          </ContentTitle>
          <StyledForm>
            <TitleForm>Inscription</TitleForm>
            <RegistrationForm />
          </StyledForm>
        </LoginContent>
        {/* </ImageLogin> */}
        {/* {shohBannerWelcome && (
          <Welcome>
            <span style={{ fontWeight: 700 }}>10€ offerts</span> sur votre 1
            <sup>ère</sup> commande avec le code «BIENVENUE»<sup>*</sup>
            <div className="hideBannerWelecome" onClick={() => bannerWelcome()}>
              <IconClose />
            </div>
          </Welcome>
        )} */}
      </Page>
    </>
  );
};
