import * as React from "react";
const SvgThreeCol = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path fill="#FFF" fillRule="nonzero" d="M0 0h32v32H0z" />
      <g fill="currentColor" fillRule="nonzero">
        <path d="M6 6h5.5v5.5H6zM13.25 6h5.5v5.5h-5.5zM20.5 6H26v5.5h-5.5zM20.5 13.25H26v5.5h-5.5zM20.5 20.5H26V26h-5.5zM13 20.5h5.5V26H13zM13.25 13.25h5.5v5.5h-5.5zM6 13.25h5.5v5.5H6zM6 20.5h5.5V26H6z" />
      </g>
    </g>
  </svg>
);
export default SvgThreeCol;
