export const logScreenName = (screenName: any, window: any) => {
  if (typeof window.WebInterfaceAndroid !== 'undefined') {
    try {
      window.WebInterfaceAndroid.logScreenName(screenName);
    } catch (error) {
      console.error('Interface WebInterfaceAndroid non disponible');
    }
  } else {
    console.error('Interface WebInterfaceAndroid non disponible');
  }
};

export const removeFromCart = (data: any, window: any) => {
  if (typeof window.WebInterfaceAndroid !== 'undefined') {
    try {
      window.WebInterfaceAndroid.removeToCart(data);
    } catch (error) {
      console.error('Interface WebInterfaceAndroid non disponible');
    }
  } else {
    console.error('Interface WebInterfaceAndroid non disponible');
  }
};

export const addToCart = (data: any, window: any) => {
  if (typeof window.WebInterfaceAndroid !== 'undefined') {
    try {
      window.WebInterfaceAndroid.addToCart(data);
    } catch (error) {
      console.error('Interface WebInterfaceAndroid non disponible');
    }
  } else {
    console.error('Interface WebInterfaceAndroid non disponible');
  }
};

export const beginCheckOut = (data: any, window: any) => {
  if (typeof window.WebInterfaceAndroid !== 'undefined') {
    try {
      window.WebInterfaceAndroid.beginCheckOut(data);
    } catch (error) {
      console.error('Interface WebInterfaceAndroid non disponible');
    }
  } else {
    console.error('Interface WebInterfaceAndroid non disponible');
  }
};
export const signInApp = (typeLogin: string, window: any) => {
  if (typeof window.WebInterfaceAndroid !== 'undefined') {
    try {
      window.WebInterfaceAndroid.login(typeLogin);
    } catch (error) {
      console.error('Interface WebInterfaceAndroid non disponible');
    }
  } else {
  }
};

export const signUpApp = (typeLogin: string, window: any) => {
  if (typeof window.WebInterfaceAndroid !== 'undefined') {
    try {
      window.WebInterfaceAndroid.signup(typeLogin);
    } catch (error) {
      console.error('Interface WebInterfaceAndroid non disponible');
    }
  } else {
    console.error('Interface WebInterfaceAndroid non disponible');
  }
};

export const trackCTCartAdjust = (
  products: { productId: string; price: number; quantity: number }[],
  window: any
) => {
  if (typeof window.WebInterfaceAndroid !== 'undefined') {
    try {
      window.WebInterfaceAndroid.trackCTCartAdjust(JSON.stringify(products));
    } catch (error) {
      console.error('Interface WebInterfaceAndroid non disponible');
    }
    window.WebInterfaceAndroid.trackCTCartAdjust(JSON.stringify(products));
  } else {
    console.error('Interface WebInterfaceAndroid non disponible');
  }
};

export const trackSignInAdjust = (memberId: string, window: any) => {
  if (typeof window.WebInterfaceAndroid !== 'undefined') {
    try {
      window.WebInterfaceAndroid.trackSignInAdjust(memberId);
    } catch (error) {
      console.error('Interface WebInterfaceAndroid non disponible');
    }
  } else {
    console.error('Interface WebInterfaceAndroid non disponible');
  }
};

export const trackSignUPAdjust = (memberId: string, window: any) => {
  if (typeof window.WebInterfaceAndroid !== 'undefined') {
    try {
      window.WebInterfaceAndroid.trackSignUPAdjust(memberId);
    } catch (error) {
      console.error('Interface WebInterfaceAndroid non disponible');
    }
  } else {
    console.error('Interface WebInterfaceAndroid non disponible');
  }
};

export const addPaymentInfo = (data: any, window: any) => {
  if (typeof window.WebInterfaceAndroid !== 'undefined') {
    try {
      window.WebInterfaceAndroid.onPaymentInformationAdded(data);
    } catch (error) {
      console.error('Interface WebInterfaceAndroid non disponible');
    }
  } else {
    console.error('Interface WebInterfaceAndroid non disponible');
  }
};
