import * as React from "react";
const SvgIconeBottom = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Calque 1"
    viewBox="0 0 26.49 15.24"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      d="m2 2 11.24 11.24L24.49 2"
      style={{
        fill: "none",
        stroke: "#232929",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: 4,
      }}
    />
  </svg>
);
export default SvgIconeBottom;
