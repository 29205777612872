export const getUserType = (sessionMemberType: string) => {
  switch (sessionMemberType) {
    case 'user':
    case 'admin':
    case 'bat':
    case 'batadmin':
      return 'membre';
    case 'suspect':
      return 'suspect';
    case 'anonymous':
      return 'anonyme';
    default:
      return '';
  }
};