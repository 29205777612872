import React from 'react';
import styled from 'styled-components/macro';
import { theme } from './theme';
import { Link } from 'Ui';
import IconeRight from './Icons/icons/IconeRight';

type Props = {
  path: { url: string; label: string; internal?: boolean }[];
  viewHome?: boolean;
  title?: string;
};

const BreadCrumbs = styled.div`
  display: flex;
  line-height: 2rem;
  align-items: center;
  flex-wrap: wrap;
`;

const BreadCrumb = styled.div`
  font-size: 1.4rem;
  font-family: 'Montserrat';
  font-weight: 'bold';
  display: flex;
  align-items: center;

  a {
    color: ${theme.light.colors.primary};
    font-weight: 'regular';
  }
  .separator {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  svg path {
    stroke-width: 3 !important;
  }
`;

export const BreadcrumbProduct = ({ path, viewHome, title }: Props) => {
  const data: {
    url: string;
    label: string;
    internal?: boolean | undefined;
  }[] = [];

  let tmpLabel = '';
  const titleArray = [
    {
      url: '',
      label: title,
      internal: false,
    },
  ];

  const datas = path.map((step, index) => {
    if (index == 0) {
      data.push({
        url: step.url,
        label: step.label,
        internal: false,
      });
    } else {
      if (path.length === 3) {
        if (index === 1) {
          tmpLabel = step.label;
        }
        if (index === 2) {
          data.push({
            url: step.url,
            label: `${tmpLabel}-${step.label}`,
            internal: false,
          });
        }
      } else {
        data.push({
          url: step.url,
          label: step.label,
          internal: false,
        });
      }
    }
    return data;
  });

  const dataBreadCrum = title ? [...data, ...titleArray] : [...data];

  return (
    <BreadCrumbs>
      <BreadCrumb>
        {viewHome && (
          <>
            <div
              style={{
                fontFamily: 'MontserratBold',
                fontWeight: 700,
                color: `${theme.light.colors.purple}`,
              }}
            >
              <Link to={'/'}>Accueil</Link>
            </div>
            {!!dataBreadCrum.length && <IconeRight className="separator" />}
          </>
        )}
      </BreadCrumb>
      {dataBreadCrum.map((step, index) => {
        const lastOne = index >= dataBreadCrum.length - 1;
        return (
          <BreadCrumb key={index}>
            <div>
              {!lastOne ? (
                <Link to={step.url} internal={false}>
                  <div>{step.label}</div>
                </Link>
              ) : (
                <>
                  {title ? (
                    <div>{step.label}</div>
                  ) : (
                    <Link to={step.url} internal={false}>
                      <div>{step.label}</div>
                    </Link>
                  )}
                </>
              )}
            </div>
            {!lastOne && <IconeRight className="separator" />}
          </BreadCrumb>
        );
      })}
    </BreadCrumbs>
  );
};
