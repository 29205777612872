import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { theme } from './theme';
import { darken } from 'polished';
import 'dayjs/locale/fr';

dayjs.locale('fr');

const StyledDatePicker = styled.div<Props>`
  //max-width: 40rem;
  font-family: 'Montserrat';
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Selecta = styled.div`
  padding: 0.5rem;
  display: inline-block;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

const Side = styled.div`
  background-color: ${theme.light.colors.purple};
  color: #fff;
  border-radius: 1.1rem 1.1rem 0 0;
  padding: 1.5rem;
`;

const Year = styled.div`
  font-size: 2rem;
`;

const StyledDate = styled.div`
  font-size: 2.5rem;
  text-transform: capitalize;
`;

const CalendarHeader = styled.div`
  font-size: 1.5rem;
  background-color: ${darken(0.2, theme.light.colors.purple)};
  color: #fff;
  display: flex;
  margin-bottom: 1rem;
  padding: 0 1rem;

  & > div {
    padding: 1rem;
    text-align: center;
    flex: 1 1 auto;
  }
`;
const cellHeight = 50;

const View = styled.div`
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  max-height: 40rem;
  overflow: auto;
  & > div {
    width: 33%;
    font-size: 1.8rem;
    height: ${cellHeight}px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
    cursor: pointer;
    &.selected {
      font-weight: bold;
      color: ${theme.light.colors.purple};
      background-color: #eee;
    }
    &:hover {
      background-color: #eee;
    }
    &.no-select {
      cursor: initial;
      height: 0;
      &:hover {
        background-color: transparent;
      }
    }
  }
`;

const DayView = styled(View)`
  & > div {
    width: ${(1 / 7) * 100}%;
  }
`;

const Selector = styled.div`
  margin-bottom: 1rem;
`;

type Props = React.Props<HTMLDivElement> & {
  onSelect?: (date: Date) => void;
  initialView?: 'day' | 'month' | 'year';
  date?: Date | string;
  initialDate?: Date | string;
  minDate?: Date | string;
  maxDate?: Date | string;
};

const months = new Array(12)
  .fill(0)
  .map((_, index) => dayjs(new Date(0, index, 1)).format('MMMM'));
export const DatePicker = ({
  ref: _,
  onSelect,
  date: jsDate,
  initialDate: initialJsDate = new Date(),
  minDate: nativeMinDate,
  maxDate: nativeMaxDate,
  initialView = 'day',
}: Props) => {
  const initialDate = dayjs(jsDate || initialJsDate);

  const [view, setView] = useState<'day' | 'month' | 'year'>(initialView);
  const [date, setDate] = useState(initialDate);
  const [DayValide, setDayValide] = useState(false);

  const minDate = nativeMinDate
    ? dayjs(nativeMinDate)
    : initialDate.add(-100, 'year');
  const maxDate = nativeMaxDate
    ? dayjs(nativeMaxDate)
    : initialDate.add(100, 'year');

  const minYear = minDate.get('year');
  const maxYear = maxDate.get('year');

  const monthLength = date.daysInMonth();
  const firstDay = date.startOf('month').day();

  const days = new Array(6 + firstDay + monthLength).fill(0);

  const thisYear = date.get('year');
  const thisMonth = date.get('month');

  /** calculate year position and scroll to it when view is year */

  let thisYearPosition = 0;

  const years = new Array(maxYear - minYear + 1).fill(0).map((_, index) => {
    if (thisYear === index + minYear)
      thisYearPosition = Math.floor(index / 3) * cellHeight;
    return index + minYear;
  });

  let viewElement: HTMLElement | null;

  useEffect(() => {
    if (view === 'year' && viewElement) {
      viewElement.scrollTop = thisYearPosition - 3 * cellHeight;
    }
  }, [view]);

  useEffect(() => {
    if (DayValide) {
      onSelect && onSelect(date.toDate());
    }
  }, [DayValide]);

  /** Set global datas */

  const setMonth = (month: number) => {
    setDate(date.set('month', month));
    setView('day');
  };

  const setYear = (year: number) => {
    setDate(date.set('year', year));
    setView('month');
  };

  const setDay = (day: number) => {
    setDate(date.set('date', day));
    setView('day');
    setDayValide(true);
  };

  return (
    <StyledDatePicker>
      <Side>
        <Year>
          <Selecta onClick={() => setView('year')}>{date.get('year')}</Selecta>
        </Year>
        <StyledDate>
          <Selecta onClick={() => setView('day')}>
            {date.format('dddd DD')}
          </Selecta>
          <Selecta onClick={() => setView('month')}>
            {date.format('MMMM')}
          </Selecta>
        </StyledDate>
      </Side>
      <Selector>
        {view === 'day' && (
          <div>
            <CalendarHeader>
              <div>Lu</div>
              <div>Ma</div>
              <div>Me</div>
              <div>Je</div>
              <div>Ve</div>
              <div>Sa</div>
              <div>Di</div>
            </CalendarHeader>
            <DayView>
              {days.map((day, index) => {
                /** Careful : the first day of the first week is a sunday ! */
                const monthDay = index - (5 + firstDay);
                const dayDate = monthDay > 0 ? monthDay : '';
                const selectedDay = dayDate === date.get('date');
                if (!dayDate)
                  return <div key={monthDay} className="no-select" />;
                return (
                  <div
                    key={dayDate}
                    className={selectedDay ? 'selected' : ''}
                    onClick={() => {
                      setDay(dayDate);
                    }}
                  >
                    {dayDate}
                  </div>
                );
              })}
            </DayView>
          </div>
        )}

        {view === 'month' && (
          <div>
            <View>
              {months.map((month, index) => (
                <div
                  key={month}
                  className={thisMonth === index ? 'selected' : ''}
                  onClick={() => setMonth(index)}
                >
                  {month}
                </div>
              ))}
            </View>
          </div>
        )}

        {view === 'year' && (
          <View ref={(ref) => (viewElement = ref)}>
            {years.map((year) => (
              <div
                style={{ minWidth: '33%' }}
                key={year}
                className={thisYear === year ? 'selected' : ''}
                onClick={(e) => {
                  e.stopPropagation();
                  setYear(year);
                }}
              >
                {year}
              </div>
            ))}
          </View>
        )}
      </Selector>
      {/* <div style={{ padding: 10 }}>
        <Button
          block
          kind="primary"
          onClick={() => onSelect && onSelect(date.toDate())}
        >
          Valider
        </Button>
      </div> */}
    </StyledDatePicker>
  );
};
