import React from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import AuthStore from 'Store/AuthStore';
import UiStore from 'Store/UiStore';
import { InputSearchMobile } from 'Ui/InputSearchMobile';
import {
  addSearchHistoy_addSearchHistory,
  addSearchHistoyVariables,
} from './__generated__/addSearchHistoy';
import { ADD_SEARCH_HISTORY } from './query';

interface Props {
  placeholder?: string;
  className?: string;
  isLogin?: boolean;
}

const SearchInput = ({ placeholder = '', isLogin }: Props) => {
  const apolloClient = useApolloClient();
  const sessionMemberType = AuthStore.useStoreState(
    (state) => state.sessionMemberType
  );
  const searchString = UiStore.useStoreState(
    (state) => state.searchStringHeader
  );
  const addSearch = async (item?: any) => {
    try {
      const response = await apolloClient.mutate<
        addSearchHistoy_addSearchHistory,
        addSearchHistoyVariables
      >({
        mutation: ADD_SEARCH_HISTORY,
        variables: {
          search: item,
        },
      });
    } catch (e) {
      console.log(e);
    }
  };
  const searchUrl = (search: string) => `/recherche?search=${search}`;
  const onNavigate = (search: string) => {
    const url = searchUrl(searchString);
    if (sessionMemberType !== 'anonymous') {
      addSearch(search);
    }
    document.location.href = url;
  };

  const onPressEnter = () => {
    onNavigate(searchString);
  };
  return (
    <>
      <InputSearchMobile
        onPressEnter={onPressEnter}
        placeholder={placeholder}
      />
    </>
  );
};
export default SearchInput;
