import * as React from "react";
const SvgHeadphones = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 39 38"
    {...props}
  >
    <path
      fill="currentColor"
      stroke="currentColor"
      d="M5.207 18.787h-.118c-.588 0-1.124.242-1.512.631l-.041.04a2.15 2.15 0 0 0-.588 1.478v3.593c0 .59.241 1.128.629 1.517s.924.631 1.512.631h.118zm14.917 12.376h3.258a2.547 2.547 0 0 1 2.463 1.956 1 1 0 0 1 .164-.014h.658c1.426 0 2.72-.583 3.659-1.524a5.19 5.19 0 0 0 1.52-3.67V15.335c0-3.403-1.39-6.5-3.628-8.743S22.894 2.953 19.5 2.953s-6.482 1.394-8.719 3.638a12.36 12.36 0 0 0-3.627 8.743v12.32c0 .539-.436.976-.973.976H5.089a4.07 4.07 0 0 1-2.888-1.204A4.1 4.1 0 0 1 1 24.53v-3.593c0-1.098.437-2.1 1.145-2.838l.056-.06a4.07 4.07 0 0 1 2.888-1.204h.118v-1.5c0-3.942 1.608-7.527 4.198-10.124S15.568 1 19.5 1c3.93 0 7.505 1.613 10.094 4.21a14.3 14.3 0 0 1 4.199 10.124v1.5h.118c1.125 0 2.148.461 2.888 1.204l.056.06A4.1 4.1 0 0 1 38 20.936v3.593c0 1.129-.46 2.154-1.2 2.897a4.07 4.07 0 0 1-2.889 1.204h-.154a7.14 7.14 0 0 1-2.055 4.331 7.1 7.1 0 0 1-5.035 2.098h-.658a1 1 0 0 1-.164-.014c-.11.463-.349.879-.674 1.206l-.004.004a2.52 2.52 0 0 1-1.785.745h-3.258a2.52 2.52 0 0 1-1.785-.745l-.004-.004a2.53 2.53 0 0 1-.743-1.79v-.759c0-.699.286-1.334.743-1.793a2.52 2.52 0 0 1 1.789-.746Zm3.258 1.953h-3.258a.586.586 0 0 0-.584.586v.76c0 .162.065.309.17.414s.252.17.414.17h3.258a.59.59 0 0 0 .414-.17.6.6 0 0 0 .17-.414v-.76a.586.586 0 0 0-.584-.586Zm10.529-14.329h-.118v7.89h.118c.588 0 1.124-.242 1.512-.631s.63-.927.63-1.517v-3.593c0-.572-.225-1.093-.588-1.479l-.042-.039a2.13 2.13 0 0 0-1.512-.63Z"
    />
  </svg>
);
export default SvgHeadphones;
