import React, { useEffect } from 'react';
import SearchInputAutoSuggest from 'Components/SearchInputAutoSuggest';
import { useState } from 'react';
import styled from 'styled-components/macro';
import posed from 'react-pose';
import Highlighter from 'react-highlight-words';
import { Link, theme } from 'Ui';
import OutsideClickHandler from 'react-outside-click-handler';
import { useMutation, useQuery } from '@apollo/client';
import useLocalStorage from 'react-use/lib/useLocalStorage';
import query from '../../SearchAutoSuggest/query';
import {
  getAutosuggest,
  getAutosuggestVariables,
} from '../../SearchAutoSuggest/__generated__/getAutosuggest';
import { IconCloseCircle, IconCloseModal } from 'Ui/Icons';
import { useDebounce } from 'Utils/hooks';
import {
  searchHistory_searchHistory,
  searchHistory,
} from './__generated__/searchHistory';
import {
  addSearchHistoy_addSearchHistory,
  addSearchHistoyVariables,
} from './__generated__/addSearchHistoy';
import {
  SEARCH_HISTORY,
  ADD_SEARCH_HISTORY,
  DELETE_SEARCH_HISTORY,
  SEARCH_SALES,
} from './query';

import AuthStore from 'Store/AuthStore';
import { useApolloClient } from '@apollo/client';
import UiStore from 'Store/UiStore';
import {
  deleteSearchHistory,
  deleteSearchHistoryVariables,
  deleteSearchHistory_deleteSearchHistory,
} from './__generated__/deleteSearchHistory';
import { useNavigate } from 'react-router-dom';
import getProductsQuery from 'Pages/Search/Products/query';
import Media from 'react-media';
import { SearchSale } from './SearchSales';
import { SearchProduct } from './SearchProduct';
import SearchInputMobile from './SearchInputMobile';
import LoopSearch from 'Ui/Icons/icons/LoopSearch';
import SearchInput from '../SearchInput';
import { Alma } from './Alma';
import { BestSales, Slider } from 'Utils/HomePage/homePageQueries';
import { Sales } from './Sales';

const searchUrl = (search: string) => `/recherche?search=${search}`;

const AutoSuggest = styled.div`
  position: relative;
  z-index: 600;
  max-width: 1259px;
  margin: 0 auto;
  padding: 1rem 0;
  @media screen and (max-width: 480px) {
    z-index: 0;
    margin: 0 auto;
    padding: 0rem 0;
  }
`;

const OptionTitle = styled.div`
  /* margin-top: 1.8rem; */
  font-family: 'AlbraSemiBold' !important;
  margin-bottom: 2rem;
  color: #000;

  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  @media screen and (max-width: 48rem) {
    font-weight: 500;
  }
`;

const Option = styled.a`
  display: block;
  align-items: center;
  font-size: 14px;
  padding: 0.5rem 0;
  font-family: 'Inter' !important;
  cursor: pointer;
  &:hover,
  &.selected {
    color: ${theme.light.colors.purple};
  }
`;

const OptionWithIcon = styled.div<{ justifyContent?: string }>`
  display: flex;
  align-items: center;
  justify-content: ${(props) =>
    props.justifyContent ? `${props.justifyContent}` : 'inherit'};
  svg {
    font-size: 2.7rem;
    cursor: pointer;
    margin-left: 1rem;
    &:hover {
      color: ${theme.light.colors.purple};
    }
  }
  @media screen and (max-width: 48rem) {
    svg {
      margin-left: 0rem;
    }
  }
`;

const OptionsFlex = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 0.5rem;
  width: 70%;
  & > div {
    flex: 1;
  }
  @media screen and (min-width: ${theme.breakpoints.small}px) {
    width: 50%;
    /* padding-left: 6rem; */
    flex-direction: row;
  }
`;
const OptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 28px;
  @media screen and (max-width: 48rem) {
    flex-direction: column;
    gap: 1.6rem;
  }
`;

const Options = posed(styled.div`
  display: flex !important;
  justify-content: flex-start;
  top: 0;
  left: 0;
  right: 0;
  padding: 2rem 0;
  background-color: #fff;
  overflow: hidden;
  transform-origin: top;
  font-size: 1.5rem;
  border-radius: 5px;
  @media screen and (max-width: 768px) {
    justify-content: flex-start;
    padding: 2rem 0rem;
    flex-direction: column;
    gap: 1.6rem;
  }
`)({
  open: {
    applyAtStart: { display: 'block' },
    opacity: 1,
    transition: { duration: 200 },
  },
  close: {
    applyAtEnd: { display: 'none' },
    opacity: 0,
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0)',
    transition: { duration: 200 },
  },
});

const ProductAvailableSearch = styled.div`
  color: var(--Text-light_grey, #848484);
  font-family: 'Inter';
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 1.6rem;
  margin-left: 5px;
  @media screen and (max-width: 48rem) {
    font-size: 13px;
  }
`;

const SeeAll = styled.div`
  color: var(--Text-light_grey, #848484);
  font-family: 'Inter' !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
  text-align: center;
  margin-top: 8px;
`;

const OptionTitleOpen = styled.div`
  font-family: 'AlbraSemiBold' !important;
  margin-bottom: 1.1rem;
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  @media screen and (max-width: 48rem) {
    font-weight: 500;
  }
`;

const SearchContainer = styled.div`
  @media screen and (max-width: 48rem) {
    padding: 0 0.6rem;
  }
`;

type Props = {
  onChange?: (value: string) => void;
  cleanHistory: (option?: string) => void;
  trendingSearch: string[];
  searchHistory?: string[];
  setSearchHistory?: any;
  internalRouting: boolean;
  member: any;
};

/** Main Autosuggest component */

const AutoSuggestComponent = ({
  trendingSearch,
  searchHistory,
  setSearchHistory,
  cleanHistory,
  internalRouting,
}: Props) => {
  let member: any;
  const sessionMemberType = AuthStore.useStoreState(
    (state) => state.sessionMemberType
  );

  const searchString = UiStore.useStoreState(
    (state) => state.searchStringHeader
  );

  const apolloClient = useApolloClient();

  const history = useNavigate();
  const [search, setSearch] = useState('');
  const [open, setOpen] = useState(true);
  const [preSelected, setPreSelected] = useState(0);
  const debouncedSearch = useDebounce(searchString, 200);
  const [historic, setHistoric] = useState<any>([]);
  const isLoggedIn = AuthStore.useStoreState((state) => state.isLoggedIn);

  // Get the history of search
  const { data: dataHistory, refetch } = useQuery<searchHistory>(
    SEARCH_HISTORY,
    {
      skip: sessionMemberType === 'anonymous',
    }
  );

  const timeOut = () => {
    setTimeout(function () {
      refetch();
    }, 1000);
  };

  useEffect(() => {
    if (sessionMemberType !== 'anonymous') {
      refetch();
      if (
        dataHistory &&
        dataHistory.searchHistory.searchHistory &&
        !!dataHistory.searchHistory.searchHistory.length
      ) {
        setHistoric(dataHistory && dataHistory.searchHistory.searchHistory);
      }
    }
  }, [dataHistory]);
  const addSearch = async (item?: any) => {
    try {
      const response = await apolloClient.mutate<
        addSearchHistoy_addSearchHistory,
        addSearchHistoyVariables
      >({
        mutation: ADD_SEARCH_HISTORY,
        variables: {
          search: item,
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  // Delete search
  const deleteSearch = async (search: string, searchArray?: string[]) => {
    try {
      const response = await apolloClient.mutate<
        deleteSearchHistory,
        deleteSearchHistoryVariables
      >({
        mutation: DELETE_SEARCH_HISTORY,
        variables: {
          delete: search,
          deleteMulti: searchArray,
        },
      });
      if (response) {
        const deleteData =
          (response.data && response.data.deleteSearchHistory.searchHistory) ||
          [];
        if (!!deleteData.length) {
          setHistoric([...response.data.deleteSearchHistory.searchHistory]);
        } else {
          setHistoric([]);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const { data, loading } = useQuery<getAutosuggest, getAutosuggestVariables>(
    query,
    {
      skip: !debouncedSearch,
      variables: { search: debouncedSearch, first: 5 },
    }
  );

  const { data: searchSaleData } = useQuery(SEARCH_SALES, {
    skip: !debouncedSearch,
    variables: { search: debouncedSearch, pagination: { first: 5 } },
  });

  const { data: searchProductData } = useQuery(getProductsQuery, {
    skip: !debouncedSearch,
    variables: { search: debouncedSearch, pagination: { first: 4 } },
  });
  const { dataSlider } = Slider('ACCUEIL');
  const { dataBestSales } = BestSales('ACCUEIL');
  const bestSalesList = dataBestSales && dataBestSales.bestSalesByUniverse;
  const homeSliderSale = dataSlider && dataSlider.slider;
  const options = !!data && !!data.autoSuggest ? data.autoSuggest : [];

  /** Reset arrow selection */
  useEffect(() => {
    setPreSelected(0);
  }, [search]);

  /** Navigate, internally or externally */
  const onNavigate = (search: string) => {
    const url = searchUrl(search);
    if (sessionMemberType !== 'anonymous') {
      addSearch(search);
    }
    if (internalRouting) history(url);
    else document.location.href = url;
  };

  const Separator = ({ isNotDebounced = false }) => (
    <>
      <Media
        query={{ minWidth: 481 }}
        render={() => (
          <div
            style={{
              maxHeight: '40rem',
              margin: isNotDebounced ? '0 0rem' : '0 2.4rem',
            }}
          >
            <div style={{ height: '100%', borderLeft: '1px solid #DEDCDC' }} />
          </div>
        )}
      />

      <Media
        query={{ maxWidth: 480 }}
        render={() => (
          <div style={{ margin: '0rem 0' }}>
            <div style={{ height: '1px', borderTop: '1px solid #DEDCDC' }} />
          </div>
        )}
      />
    </>
  );

  return (
    <AutoSuggest onClick={() => setOpen(true)}>
      <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
        <Media
          query={{ maxWidth: 480 }}
          render={() => (
            <SearchInputMobile
              placeholder={'Rechercher une vente, un produit ...'}
            />
          )}
        />
        <Media
          query={{ minWidth: 481 }}
          render={() => (
            <SearchInput placeholder={'Rechercher une vente, un produit ...'} />
          )}
        />
        <SearchContainer>
          <Options pose={!!open ? 'open' : 'close'}>
            <>
              {!debouncedSearch && (
                <OptionsContainer>
                  {historic && !!historic.length && (
                    <div>
                      <OptionTitleOpen className="fontGranville">
                        Recherches récentes
                      </OptionTitleOpen>
                      {historic.map((option: any, index: any) => (
                        <OptionWithIcon
                          key={index}
                          justifyContent="space-between"
                        >
                          <div
                            style={{ display: 'flex', flexDirection: 'row' }}
                          >
                            <LoopSearch width="24" height="24" />
                            <Option
                              className="searchoptionV4 fontMontserrat"
                              onClick={() => onNavigate(option)}
                            >
                              {option}
                            </Option>
                          </div>
                          <IconCloseModal
                            onClick={(e: any) => {
                              e.preventDefault();
                              deleteSearch(option);
                              timeOut();
                            }}
                            className="close"
                            height={'16px'}
                            width={'16px'}
                          />
                        </OptionWithIcon>
                      ))}
                      <Option
                        className="searchoptionV4 fontMontserrat"
                        style={{
                          marginBottom: '1.6rem',
                          color: `var(--Text-light_grey, #848484)`,
                          textAlign: 'center',
                          borderBottom: `1px solid var(--Text-light_grey, #848484)`,
                          fontSize: '1.4rem !important',
                          marginTop: '1.6rem',
                          display: 'flex',
                          width: 'max-content',
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          deleteSearch('', historic);
                          timeOut();
                        }}
                      >
                        Tout supprimer
                      </Option>
                    </div>
                  )}
                  {historic && !!historic.length && (
                    <Separator isNotDebounced={true} />
                  )}
                  {!isLoggedIn && (
                    <>
                      <div>
                        <OptionTitle className="fontGranville">
                          Recherches populaires
                        </OptionTitle>
                        {trendingSearch &&
                          trendingSearch.map((option, index) => (
                            <Option
                              className="searchoptionV4 fontMontserrat"
                              key={index}
                              onClick={() => {
                                onNavigate(option);
                              }}
                            >
                              {option}
                            </Option>
                          ))}
                      </div>
                      <Separator isNotDebounced={true} />
                    </>
                  )}

                  {((!!dataBestSales && !!bestSalesList?.length) ||
                    (!!homeSliderSale && !!homeSliderSale.length)) && (
                    <Sales
                      saleOfTheMoment={homeSliderSale}
                      bestSales={bestSalesList}
                    />
                  )}
                </OptionsContainer>
              )}

              {debouncedSearch && (
                <>
                  <div style={{ width: '22rem' }}>
                    {!!options && !!options.length ? (
                      <>
                        {!!options.length &&
                          options.map((option: any, index: any) => (
                            <OptionWithIcon
                              key={index}
                              onClick={() => onNavigate(option)}
                              style={{
                                gap: '8px',
                                marginBottom: '8px',
                                cursor: 'pointer',
                              }}
                              className={
                                index === preSelected - 1
                                  ? 'selected'
                                  : 'fontMontserrat'
                              }
                            >
                              <LoopSearch width="24" height="24" />
                              <Highlighter
                                highlightStyle={{
                                  fontWeight: 700,
                                  color: 'inherit',
                                  backgroundColor: 'transparent',
                                }}
                                className="fontMontserrat"
                                searchWords={searchString.split(' ')}
                                autoEscape={true}
                                textToHighlight={option || option}
                              />
                            </OptionWithIcon>
                          ))}
                      </>
                    ) : (
                      <i className="searchoptionV4 fontMontserrat">
                        Aucune suggestion
                      </i>
                    )}
                  </div>

                  <Separator />

                  <div style={{ width: '30rem' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <OptionTitle className="fontGranville">
                        Produits
                      </OptionTitle>
                      <ProductAvailableSearch className="fontMontserrat">
                        ({searchProductData?.products?.edges.length} sur{' '}
                        {searchProductData?.products?.totalCount})
                      </ProductAvailableSearch>
                    </div>
                    {!!searchProductData &&
                    searchProductData?.products?.totalCount !== 0 ? (
                      <>
                        <SearchProduct products={searchProductData.products} />
                        <Link to={`/recherche/?search=${searchString}`}>
                          <SeeAll className="fontMontserrat">
                            Voir tout les produits
                          </SeeAll>
                        </Link>
                      </>
                    ) : (
                      <i className="fontMontserrat">Aucune suggestion</i>
                    )}
                  </div>

                  <Separator />

                  <div style={{ width: '35rem' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <OptionTitle className="fontGranville">
                        Ventes
                      </OptionTitle>
                      <ProductAvailableSearch className="fontMontserrat">
                        (
                        {searchSaleData?.sales?.edges?.length!! >= 4
                          ? 4
                          : searchSaleData?.sales?.edges?.length}{' '}
                        sur {searchSaleData?.sales?.totalCount})
                      </ProductAvailableSearch>
                    </div>
                    {!!searchSaleData &&
                    searchSaleData?.sales?.totalCount !== 0 ? (
                      <SearchSale sales={searchSaleData?.sales} />
                    ) : (
                      <i className="fontMontserrat">Aucune suggestion</i>
                    )}
                  </div>
                  <Separator />
                  <div>
                    <Alma />
                  </div>
                </>
              )}
            </>
          </Options>
        </SearchContainer>
      </OutsideClickHandler>
    </AutoSuggest>
  );
};

/** Overarching component - get history and trending just once */
export default ({
  internalRouting = false,
  isMember = false,
  iconClose = false,
}) => {
  const { data } = useQuery<getAutosuggest, getAutosuggestVariables>(query, {
    variables: { search: '', first: 5 },
  });

  const member = isMember
    ? AuthStore.useStoreState((state) => state.member)
    : null;
  const [searchHistory, setSearchHistory] = useLocalStorage<string[]>(
    'searchHistory',
    []
  );
  const trendingSearch = !!data && !!data.autoSuggest ? data.autoSuggest : [];

  /** Clean search history, completely or of one search */
  const cleanHistory = (item?: string) => {
    if (item) {
      setSearchHistory(searchHistory.filter((search) => search !== item));
    } else {
      setSearchHistory([]);
    }
  };

  return (
    <AutoSuggestComponent
      member={member}
      setSearchHistory={setSearchHistory}
      trendingSearch={trendingSearch}
      cleanHistory={cleanHistory}
      internalRouting={internalRouting}
    />
  );
};
