import React, { useState } from 'react';
import styled from 'styled-components/macro';
import SmoothScroll from 'smooth-scroll';
import { theme } from 'Ui/theme';
import { IconIcoBack } from 'Ui/Icons';

const ScrollupStyled = styled.div<{ display: boolean }>`
  width: 30px;
  height: 30px;
  position: fixed;
  bottom: 78px;
  margin-bottom: -25px;
  right: 10px;
  display: ${(props) => (props.display ? 'flex' : 'none')};
  border-radius: 100%;
  border 1px solid ${theme.light.colors.gray};
  background-color: ${theme.light.colors.white};
  z-index: 111;
  cursor: pointer;
  
  /* @media screen and (min-width: 769px) {
    right: 50px;
  } */
  @media screen and (min-width: 768px) {
    bottom: 50px;
  }
`;

const StyledArrow = styled.div`
  position: relative;
  font-size: 2rem;
  transform: rotate(91deg);
  svg path {
    stroke: ${theme.light.colors.gray};
    stroke-width: 0.3rem;
  }
`;

export const Scrollup = () => {
  const [display, setDisplay] = useState(false);

  window.addEventListener('scroll', (e) => handleNavigation(e));
  const handleNavigation = (e: any) => {
    const window = e.currentTarget;
    if (window.scrollY > 25) {
      setDisplay(true);
    } else if (0 === window.scrollY) {
      setDisplay(false);
    }
  };

  const scrollTo = () => {
    const smoothScroll = new SmoothScroll();
    var options = {
      speed: 200,
      offset: 50,
      updateURL: false,
      speedAsDuration: true,
    };
    smoothScroll.animateScroll(document.querySelector('body'), null, options);
  };
  return (
    <ScrollupStyled display={display} onClick={() => scrollTo()}>
      <StyledArrow>
        <IconIcoBack />
      </StyledArrow>
    </ScrollupStyled>
  );
};
