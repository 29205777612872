import styled from 'styled-components/macro';
import { theme } from './theme';

export const PageContainer = styled.div`
  max-width: 96.5rem;
  margin: 0 auto;
  padding: 0rem 2rem;
  @media screen and (min-width: ${theme.breakpoints.medium}px) {
    padding: 0rem 3rem;
  }
  @media screen and (min-width: ${theme.breakpoints.large}px) {
    padding: 0;
  }
`;
