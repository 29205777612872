import styled from "styled-components";
import { theme } from "Ui";
import React from "react";
import { IconExclamationCircle, IconCheckCircle } from "Ui/Icons";

type Props = {
  password: string;
  kind?: "default" | "primary" | "error" | "success";
};

export default ({ password = " ", kind }: Props) => {
  // if (!password) return null;
  const lengthRule = !!password.match(/^(?=.{8,50})/gm);
  const lowerCaseRule = !!password.match(/^(?=.*[a-z])/gm);
  const upperCaseRule = !!password.match(/^(?=.*[A-Z])/gm);
  const numberRule = !!password.match(/^(?=.*[0-9])/gm);
  return (
    <StyledBox kind={kind}>
      <Title className="Montserrat">Votre mot de passe doit contenir :</Title>
      <RuleInfo className="Montserrat" kind={lengthRule ? "success" : "error"}>
        {lengthRule ? <IconCheckCircle /> : <IconExclamationCircle />} Entre 8
        et 50 caractères
      </RuleInfo>
      <RuleInfo kind={lowerCaseRule ? "success" : "error"}>
        {lowerCaseRule ? <IconCheckCircle /> : <IconExclamationCircle />} Au
        moins une minuscule
      </RuleInfo>
      <RuleInfo kind={upperCaseRule ? "success" : "error"}>
        {upperCaseRule ? <IconCheckCircle /> : <IconExclamationCircle />} Au
        moins une majuscule
      </RuleInfo>
      <RuleInfo kind={numberRule ? "success" : "error"}>
        {numberRule ? <IconCheckCircle /> : <IconExclamationCircle />} Au moins
        un chiffre
      </RuleInfo>
    </StyledBox>
  );
};

const RuleInfo = styled.div<{ kind?: "success" | "error" }>`
  svg {
    color: ${props => {
      if (props.kind === "success") return theme.colors.success;
      if (props.kind === "error") return theme.colors.error;
      else return "#999";
    }};

    font-size: 1.5rem;
    margin-right: 1rem;
  }
  color: #999;
  font-size: 1.2rem;
  font-family: 'Montserrat' !important;
  display: flex;
  align-items: center;
  padding: 0.5rem;
`;

const StyledBox = styled.div<Pick<Props, "kind">>`
  color: #999;
  position: relative;
  font-size: 1.3rem;
  padding-bottom: 1rem;
`;

const Title = styled.div`
  padding: 0 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 1.2rem;
  font-family: 'Montserrat';
`;
