import React from 'react';
import { Modal } from 'Ui';
import styled from 'styled-components/macro';

type ModalProps = {
  open: boolean;
  close: (shouldGoOn: boolean) => void;
};

const DataProtectionModalBody = styled.div`
  font-family: 'Montserrat';
  p {
    font-size: 1.4rem;
    margin-bottom: 2rem;
    line-height: normal;
    font-family: 'Montserrat';
    a {
      border-bottom: 1px dashed;
    }
  }
`;

const DataProtectionModal = ({ open, close }: ModalProps) => (
  <Modal
    isOpen={open}
    onClose={() => close(false)}
    title="PROTECTION DES DONNÉES"
  >
    <DataProtectionModalBody>
      <p>
        Vos données sont collectées et utilisées par BazarChic afin de gérer
        votre compte et vous proposer ses services.
      </p>
      <p>
        Vous bénéficiez d’un droit d’accès de rectification de vos données que
        vous pouvez exercer à tout moment en adressant un e-mail via le{' '}
        <a href="mailto:servicerelationmembres@bazarchic.com">lien suivant</a>.
      </p>
    </DataProtectionModalBody>
  </Modal>
);

export default DataProtectionModal;
