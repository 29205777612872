import * as React from "react";
const SvgNewCloche = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="new-cloche_svg__Calque_1"
    x={0}
    y={0}
    viewBox="0 0 60 60"
    width="1em"
    height="1em"
    {...props}
  >
    <style>
      {
        ".new-cloche_svg__st0{fill:none;stroke:#232929;stroke-width:2;stroke-miterlimit:10}"
      }
    </style>
    <switch>
      <foreignObject
        width={1}
        height={1}
        x={0}
        y={0}
        requiredExtensions="http://ns.adobe.com/AdobeIllustrator/10.0/"
      />
      <g>
        <path
          d="M30.17 56.41c3.16 0 5.73-2.22 6.02-5.05H24.15c.29 2.83 2.86 5.05 6.02 5.05zM50.63 48.19c-1.84-2.45-5.78-5.26-5.96-11.2l.02-.02c-.16-5.31 2.87-17.33-7.21-24.12-7.6-5.12-14.58 0-14.58 0-10.19 7.36-7.05 18.81-7.21 24.12-.18 5.96-4.12 8.77-5.96 11.23s0 2.81 0 2.81h38.54c0-.01 4.2-.36 2.36-2.82z"
          className="new-cloche_svg__st0"
        />
        <path
          d="M32.71 10.5A3.916 3.916 0 0 0 30 3.59c-2.16.1-3.83 1.93-3.73 4.1.05 1.1.55 2.08 1.32 2.76z"
          className="new-cloche_svg__st0"
        />
      </g>
    </switch>
  </svg>
);
export default SvgNewCloche;
