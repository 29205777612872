import React from 'react';
import styled from 'styled-components/macro';

// @ts-ignore
// import ImageFadeIn from 'react-image-fade-in';

type Props = {
  /** Add width for image */
  width?: number;
  /** Add height for image */
  height?: number;
  /** Add a macaroon on the image */
  sticker?: boolean;
  /** Url of the image  */
  src: string;
  /** crop image */
  crop?: boolean;
  /** Add a color background quad the image does not load */
  placeholderColor?: string;
  /** Add class custom */
  className?: string;
  mutlipleImage?: string;
  alt?: string;
};

const ImageStyled = styled.div`
  width: 100%;
  overflow: hidden;
  img {
    width: 100%;
  }
`;

export const ProgressiveImage = ({
  width = 0,
  height = 0,
  src,
  className,
  crop = false,
  placeholderColor,
  mutlipleImage,
  alt,
}: Props) => {
  const params = {
    dimensions: {
      width: width,
      height: height,
      crop: !crop,
    },
  };

  // const imageRetina = mutlipleImage ? `&${mutlipleImage}` : '';
  const paramsUrl = JSON.stringify(params);
  const aspectRatio = height / width;

  return (
    <div
      style={{
        paddingBottom: aspectRatio * 100 + '%',
        width: '100%',
      }}
      className={className}
    >
      <ImageStyled
        style={{
          backgroundColor: placeholderColor,
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
      >
        {/* <ImageFadeIn
          style={{ verticalAlign: "top" }}
          src={`${src}?params=${paramsUrl}${imageRetina}`}
        /> */}
        <img
          src={`${src}?params=${paramsUrl}`}
          data-srcset={`${src} x2`}
          alt={alt}
        />
      </ImageStyled>
    </div>
  );
};
