import styled from "styled-components/macro";

export const InputGroup = styled.div<{
  block?: boolean;
  gutter?: number;
  even?: boolean;
}>`
  box-sizing: border-box;
  display: inline-flex;
  width: ${props => (props.block ? "100%" : "auto")};
  & > * {
    ${props => props.even && "flex : 1;"}
    margin-left: ${props => props.gutter || 0}px;
    &:hover {
      z-index: 1;
    }
    &:first-child {
      margin: 0;
    }
  }
`;
