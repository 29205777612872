import React from 'react';
import { createModal } from 'react-modal-promise';
import { Modal, Button, Link } from 'Ui';
import styled from 'styled-components/macro';
import Media from 'react-media';
import ModalImageBackground from 'Assets/images/PopInAnniversaire.jpg';
import Cookie from 'js-cookie';
import dayjs from 'dayjs';
import { IconeClose } from 'Ui/Icons/icons';

type ModalProps = {
  open: boolean;
  close: (shouldGoOn: boolean) => void;
};

const ModalContainer = styled.div`
  background-image: url(${ModalImageBackground});
  background-size: cover;
  /* padding:  20px 35px; */
  width: 63rem;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

const BirthDayModal = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 4rem 0;
  position: relative;
`;

const ModalClose = styled.div`
  position: absolute;
  top: 25px;
  right: 25px;
  font-size: 13px;
  cursor: pointer;
`;

const BirthdayGiftTitle = styled.div`
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 2.4rem;
  color: #000000;
  margin-bottom: 2.7rem;
  @media screen and (max-width: 767px) {
    font-size: 1.3rem;
  }
`;

const BirthdayGiftPara = styled.div`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 1.6rem;
  color: #000000;
  @media screen and (max-width: 767px) {
    font-size: 1.3rem;
  }
`;

const BirthdayGiftAmount = styled.div`
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 3.5rem;
  color: #000000;
  margin-bottom: 2.7rem;
  @media screen and (max-width: 767px) {
    font-size: 3rem;
  }
`;

const BirthdaySecondPara = styled.div`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 1.4rem;
  color: #000000;
  margin-bottom: 2.7rem;
  @media screen and (max-width: 767px) {
    font-size: 1.3rem;
  }
`;

const BirthdayButtonTitle = styled.div`
  font-family: 'MontserratBold';
  font-size: 1.4rem;
  font-weight: 700;
  color: #ffffff;
  width: 100%;
  padding: 2rem 4rem;
  @media screen and (max-width: 767px) {
    font-size: 1.1rem;
  }
`;

const BirthdayGiftModal = ({ open, close }: ModalProps) => {
  // const member = AuthStore.useStoreState((state) => state.member);
  const voucherBirthDate = Cookie.get('voucher_birthdate_remain');
  // const configCookie = {
  //   path: '/',
  //   domain: '.bazarchic.com',
  //   expires: 60,
  // };

  // const timestampAndCount = (voucherBirthDate: string) => {
  //     const splitBridate = voucherBirthDate.split('_');
  //     const timeStamp = parseInt(splitBridate[0], 10);
  //     const count = parseInt(splitBridate[1], 10);
  //     return [timeStamp, count];
  // };

  //   if (voucherBirthDate) {
  //   const [timestamp, count] = timestampAndCount(voucherBirthDate);

  //   // recréé un timestamp avec un count
  //   const newTimeStamp = dayjs().add(10, 'day').unix();
  //   const incrementCount = count + 1;
  //   const newbirthdayReminder = `${newTimeStamp}_${incrementCount}`;
  //   Cookie.set('birthdayReminder', newbirthdayReminder);
  //   setView(false);
  // }

  // if (voucherBirthDate) {
  //   const [timestamp, count] = timestampAndCount(birthdayReminder);
  //   createCookieBirtdayReminder(count);
  // }

  // Get coupons if User has a BirthdayCoupon
  // const birthdayCoupon = member && member.vouchers.filter((voucher: any) => voucher.operationName === 'Joyeux Anniversaire').map((birthdayVoucher: any) => {
  //     return birthdayVoucher
  // })

  // if (birthdayCoupon && (BirthdayPopIn(member.birthDate) <= 21) &&
  //       !Cookie.get('voucher_birthdate_remain')
  //     ) {
  //     Cookie.set('voucher_birthdate_remain', 'value', configCookie)
  // }
  // const birthdayCookie = {
  //     path: '/',
  //     domain: '.bazarchic.com',
  //     expires: 60,
  // };

  // const birthdayvoucher = Cookie.get('voucher_birthdate_remain');

  const updateCookie = () => {
    const newTimeStamp = String(dayjs().add(1, 'day').unix());
    // const newvoucher = `${newTimeStamp}`;
    if (Cookie.get('voucher_birthdate_remain')) {
      Cookie.set('voucher_birthdate_remain', newTimeStamp);
    }
  };

  const BirthDayContent = () => (
    <BirthDayModal>
      <ModalClose
        onClick={() => {
          close(false);
          updateCookie();
        }}
      >
        <IconeClose className="close" />
      </ModalClose>
      <BirthdayGiftTitle>À l'occasion de votre anniversaire</BirthdayGiftTitle>
      <BirthdayGiftPara>Faites-vous plaisir avec </BirthdayGiftPara>
      <BirthdayGiftAmount>10€ offerts</BirthdayGiftAmount>
      <BirthdaySecondPara>
        Rendez-vous dans votre panier
        <br />
        pour en profiter
      </BirthdaySecondPara>
      <Button color="#000000">
        <BirthdayButtonTitle
          onClick={() => {
            close(false);
            updateCookie();
          }}
        >
          <Link
            onClick={() => {
              close(true);
              updateCookie();
            }}
            to="/"
            internal={true}
          >
            DÉCOUVRIR LES VENTES
          </Link>
        </BirthdayButtonTitle>
      </Button>
    </BirthDayModal>
  );

  return (
    <>
      <Media query={{ minWidth: 768 }}>
        <Modal
          isClose={false}
          isOpen={open}
          onClose={() => {
            updateCookie();
            close(true);
          }}
          size={630}
          noPadding={true}
        >
          <ModalContainer>
            <BirthDayContent />
          </ModalContainer>
        </Modal>
      </Media>
      <Media query={{ maxWidth: 767 }}>
        <Modal
          isClose={false}
          isOpen={open}
          onClose={() => {
            close(false);
            updateCookie();
          }}
          size={250}
          noPadding={true}
        >
          <ModalContainer>
            <BirthDayContent />
          </ModalContainer>
        </Modal>
      </Media>
    </>
  );
};

export default createModal(BirthdayGiftModal);
