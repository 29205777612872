import { useState, useEffect } from 'react';
import query from './query';
import { HeaderIcon } from '../HeaderIcon';
import { getBasket, getBasket_basket } from './__generated__/getBasket';
import dayjs from 'dayjs';
import styled, { css } from 'styled-components/macro';
import { theme } from 'Ui/theme';
import { useQuery } from '@apollo/client';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/fr';
import { Basket } from 'Ui/Icons/icons';
import AuthStore from 'Store/AuthStore';
import { allowedAllTypes } from 'Utils/memberTypes';

const toMinutes = (seconds: number) => {
  if (seconds < 0) seconds = 0;
  const finalSeconds = ('0' + (seconds % 60)).slice(-2);
  const finalMinutes = Math.floor(seconds / 60);
  return `${finalMinutes}:${finalSeconds}`;
};

const StyledTimedBasket = styled.a<{ cursor?: boolean; selected?: boolean }>`
  position: relative;
  .iconLabel {
    height: auto;
  }
  .iconLabel .label {
    display: block;
    font-size: 1.2rem;
    text-transform: uppercase;
    margin-top: 0.8rem;
    /* margin-left: 1rem; */
    font-family: 'MontserratSemiBold';
    /* padding: 0 0 3rem 1rem ; */
    /* &.hide {
        opacity: 0;
      } */
  }
  svg {
    font-weight: 600;
    fill: ${theme.light.colors.grayUltraDark};
  }
  svg path {
    stroke-width: 3.5px !important;
  }
  svg circle {
    stroke-width: 3.5px !important;
  }
  cursor: pointer;
`;

const Label = styled.div<{ isBasket?: boolean }>`
  display: flex;
  justify-content: center;
  flex: 1;
  color: var(--text-black, #000);
  font-family: 'MontserratSemiBold';
  font-size: 1rem;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.029px;
  position: absolute;
  width: 100%;
  ${(props) =>
    props.isBasket &&
    css`
      bottom: -13px;
    `}
`;

const ItemCount = styled.div`
  position: absolute;
  top: 13px;
  left: 2rem;
  font-size: 1rem;
  color: white;
  background-color: ${theme.light.colors.purple};
  font-family: 'MontserratSemiBold';
  padding: 2px;
  height: 1.5rem;
  width: 1.5rem;
  text-align: center;
  border-radius: 100%;
  transform: translate3d(-50%, -50%, 0);
`;

const BasketHasItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
`;

const TimedBasket = ({
  basket,
  remainingTime,
  onTimeEnd,
  isBasket = false,
}: // sale,
// productInformation
{
  basket: getBasket_basket;
  // sale?: getBasket_basket_basketSales_sale;
  // productInformation?: getBasket_basket_basketSales_basketProducts;
  remainingTime: number;
  onTimeEnd: () => void;
  isBasket?: boolean;
}) => {
  const [timeLeft, setTimeLeft] = useState(remainingTime);

  if (timeLeft <= 0) {
    onTimeEnd();
  }

  // if (timeLeft === 0 && first) {
  //   gtmDeleteRemoveCard();
  //   setFirst(false);
  // }

  useEffect(() => {
    setTimeLeft(remainingTime);

    const interval = setInterval(() => {
      setTimeLeft((timeLeft) => timeLeft - 1);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [basket.updatedAt, remainingTime]);

  return (
    <>
      <StyledTimedBasket href="/tunnel/basket/">
        <BasketHasItem>
          <Basket width="30" height="30" />
          <ItemCount>{basket.productCount}</ItemCount>
          <Label isBasket className="fontMontserratSemiBold">
            {toMinutes(timeLeft)}
          </Label>
        </BasketHasItem>
      </StyledTimedBasket>
    </>
  );
};

export default ({ isBasket = false }: { isBasket?: boolean }) => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.locale('fr');
  dayjs.tz.setDefault('Europe/Paris');
  const sessionMemberType = AuthStore.useStoreState(
    (state) => state.sessionMemberType
  );
  const isMember = allowedAllTypes.includes(sessionMemberType);
  const { data, refetch } = useQuery<getBasket>(query, { skip: !isMember });
  const basket = data && data.basket;

  if (!basket || basket.productCount === 0) {
    return (
      <HeaderIcon>
        <Basket width="30" height="30" />
      </HeaderIcon>
    );
  }

  const now = new Date();
  const endTime = dayjs(new Date(basket.updatedAt)).tz().add(20, 'minute');
  const remainingTime = dayjs(endTime).tz().diff(now.toISOString(), 'second');

  return (
    <TimedBasket
      basket={basket}
      isBasket={isBasket}
      remainingTime={remainingTime}
      onTimeEnd={refetch}
    />
  );
};
