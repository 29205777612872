export const aProposLinks = [
  {
    linkName: 'Qui sommes-nous?',
    linkUrl: '/home/whois/',
    isLoggedIn: false,
  },
  {
    linkName: 'Avis clients',
    linkUrl: '/home/avis-clients/',
    isLoggedIn: false,
  },
  {
    linkName: 'Presse',
    linkUrl: 'https://ressources.bazarchic.com/contact-presse/',
    linkTarget: '_blank',
    isLoggedIn: false,
  },
  {
    linkName: 'Recrutement',
    linkUrl: 'https://carrieres.groupegalerieslafayette.com/bazarchic/',
    linkTarget: '_blank',
    isLoggedIn: false,
  },
  {
    linkName: 'Galeries Lafayette',
    linkUrl: 'https://www.galerieslafayette.com/',
    linkTarget: '_blank',
    isLoggedIn: false,
  },
  {
    linkName: 'Welcome to the jungle',
    linkUrl: 'https://www.welcometothejungle.com/fr/companies/bazarchic',
    linkTarget: '_blank',
    isLoggedIn: false,
  },
];

export const FaqLinks = [
  {
    linkName: 'F.A.Q',
    linkUrl: '/faq/index/',
    isLoggedIn: false,
  },
  {
    linkName: 'Conditions générales de vente',
    linkUrl: 'https://fr.bazarchic.com/contenu/conditions-generales-de-ventes/',
    linkTarget: '_blank',
    isLoggedIn: false,
  },
  {
    linkName: 'Politique de Vie Privée',
    linkUrl: 'https://fr.bazarchic.com/contenu/politique-de-vie-privee/  ',
    linkTarget: '_blank',
    isLoggedIn: false,
  },
];

export const ServicesLinks = [
  {
    linkName: 'Applications mobiles',
    linkUrl: '/home/mobilite/',
    isLoggedIn: false,
  },
  // {
  //   linkName: 'Club privilège',
  //   linkUrl: '/accueil/privilege/',
  //   isLoggedIn: false,
  // },
  {
    linkName: 'Parrainage',
    linkUrl: '/accueil/parrainage/',
    isLoggedIn: true,
  },
  {
    linkName: 'Mode Femme',
    linkUrl: '/ventes-privees-femme/',
    isLoggedIn: false,
  },
  {
    linkName: 'Nos marques',
    linkUrl: '/marques/',
    isLoggedIn: false,
  },
  {
    linkName: 'Offres du moment',
    linkUrl: 'https://fr.bazarchic.com/contenu/',
    isLoggedIn: false,
  },
];

export const ClientLinks = [
  {
    linkName: 'Mon compte',
    linkUrl: '/account/index/',
    isLoggedIn: true,
  },
  {
    linkName: 'Mes commandes',
    linkUrl: '/commandes/index/',
    isLoggedIn: true,
  },
  {
    linkName: 'Mes bons de retours',
    linkUrl: '/account/retours/',
    isLoggedIn: true,
  },
  {
    linkName: 'Mon centre de préférences',
    linkUrl: '/account/newsletters/',
    linkTarget: '',
    isLoggedIn: true,
  },
];
