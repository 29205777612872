import RegistrationForm from 'Components/RegistrationForm';
import React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'Ui';
import { IconChevronLeft } from 'Ui/Icons';

const LoginRegisterTitleGroup = styled.div`
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  h1 {
    margin-top: 0 !important;
  }
`;

const BackToLogin = styled.div`
  text-align: left;
  position: relative;
  top: 2.5rem;
  left: 1.5rem;
  svg {
    font-size: 2.7rem;
  }
  svg path {
    stroke-width: 3px;
  }
`;

const LoginRegisterPage = styled.div`
  padding: 0rem 2rem 2rem;
  margin-top: -2.5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;

const LoginRegisterTitle = styled.h1`
  font-family: 'granville-bold';
  font-size: 3.9rem;
  text-align: center;
  padding: 0 2rem;
  color: ${theme.light.colors.purple};
  font-weight: 700;
`;

const LoginRegisterWelcome = styled.div`
  font-family: 'MontserratSemiBold';
  font-size: 1.6rem;
  text-align: center;
  margin-top: 1rem;
  color: ${theme.light.colors.gray};
  font-weight: 600;
`;

const LoginRegisterHelp = styled.div`
  font-family: 'MontserratSemiBold';
  font-size: 1.2rem;
  color: ${theme.light.colors.grayDark};
  font-weight: 600;
  margin-bottom: 1.5rem;
  a {
    color: ${theme.light.colors.purple};
    text-decoration: underline;
  }
`;

const StyledForm = styled.div`
  padding: 1.5rem;
  background-color: #ffffff;
`;

export default () => {
  return (
    <>
      <BackToLogin>
        <a href="/">
          <IconChevronLeft />
        </a>
      </BackToLogin>
      <LoginRegisterPage>
        <LoginRegisterTitleGroup>
          <LoginRegisterTitle>Inscription</LoginRegisterTitle>
          <LoginRegisterWelcome>
            Inscrivez-vous et profitez de 10€ offerts
          </LoginRegisterWelcome>
        </LoginRegisterTitleGroup>
        <StyledForm>
          <RegistrationForm />
        </StyledForm>
        <div style={{ marginTop: '2rem' }}>
          <LoginRegisterHelp>
            Vous avez déjà un compte ?{' '}
            <a href="/?anchor=connexion">Connectez-vous</a>
          </LoginRegisterHelp>
          <LoginRegisterHelp>
            Vous avez besoin d'aide ?{' '}
            <a href="/contact/question/">Contactez-nous</a>
          </LoginRegisterHelp>
        </div>
      </LoginRegisterPage>
    </>
  );
};
