export const onResetPassword = (data: any, window: any) => {
  if (typeof window.WebInterfaceDeeplink !== 'undefined') {
    try {
      window.WebInterfaceDeeplink.onResetPassword(data);
    } catch (error) {
      console.error('Interface WebInterfaceDeeplink non disponible');
    }
  } else {
    console.error('Interface WebInterfaceDeeplink non disponible');
  }
};

export const navigateToOnBoarding = (window: any) => {
  if (typeof window.WebInterfaceAndroid !== 'undefined') {
    try {
      window.WebInterfaceAndroid.navigateToOnBoarding();
    } catch (error) {
      console.error('Interface WebInterfaceAndroid non disponible');
    }
  } else {
    console.error('Interface WebInterfaceAndroid non disponible');
  }
};
