import gql from 'graphql-tag';

export const SEARCH_HISTORY = gql`
  query searchHistory {
    searchHistory {
      searchHistory
    }
  }
`;

export const ADD_SEARCH_HISTORY = gql`
  mutation addSearchHistoy($search: String!) {
    addSearchHistory(search: $search) {
      searchHistory
    }
  }
`;

export const DELETE_SEARCH_HISTORY = gql`
  mutation deleteSearchHistory($delete: String!, $deleteMulti: [String]) {
    deleteSearchHistory(delete: $delete, deleteMulti: $deleteMulti) {
      searchHistory
    }
  }
`;

export const SEARCH_SALES = gql`
  query SearchSales($search: String, $pagination: PaginationInput) {
    sales: searchSale(search: $search, pagination: $pagination) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          name
          coverLogoUrl
          id
        }
      }
    }
  }
`;
