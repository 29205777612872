import { GtmInfo } from "Fragments/GtmInfo";
import gql from "graphql-tag";

export const BACKGROUND_LOGIN = gql`
  query backgroundLogin {
    pageDatas {
      loginBackgroundImages {
        urlBackgroundDesktop1x
        urlBackgroundDesktop2x
        urlBackgroundMobile1x
      }
    }
  }
`;

export const LOGIN_MEMBER = gql`
  mutation loginMember($email: String!, $password: String!) {
    loginMember (email: $email, password: $password) {
      jwt
      sessionId
      member {
        id
        isVip
        email
        firstName
        lastName
        phoneNumber
        ...GtmInfo
      }
    }
  }
  ${GtmInfo}
`;

