import styled from 'styled-components';
import { theme } from 'Ui/theme';

const Terms = styled.div`
  font-family: 'Montserrat' !important;
  font-size: 1.2rem;
  color: ${theme.v2.text.darkGray};
  line-height: 1.6rem;
  margin: 1.6rem 0;
  a {
    color: ${theme.v2.brand.violet} !important;
    text-decoration: underline !important;
  }
  @media screen and (min-width: ${theme.breakpoints.medium}px) {
    margin: 2.4rem 0;
  }
  .sentenceUnderAge {
    color: #4A4A4A;
    font-family: 'Montserrat';
    font-size: 1.2rem;_
    font-weight: 400;
    line-height: 1.6rem;
    margin-top: 8px;
  }
`;

export const RegistrationTerms = () => {
  return (
    <Terms className="fontMontserrat">
      <p style={{ marginBottom: '0.8rem' }}>
        En m'inscrivant, j'accepte{' '}
        <a
          href="https://fr.bazarchic.com/contenu/conditions-generales-de-ventes/"
          target="_blank"
          rel="noreferrer"
        >
          les CGV
        </a>
      </p>
      <p>
        J'ai bien pris connaissance de la{' '}
        <a
          href="https://fr.bazarchic.com/contenu/politique-de-vie-privee/  "
          target="_blank"
          rel="noreferrer"
        >
          Politique de Vie Privée
        </a>
      </p>
      <p className="sentenceUnderAge">
        Vous devez avoir 16 ans ou plus pour commander sur BazarChic
      </p>
    </Terms>
  );
};
