import { SearchProducts_products_edges_node } from 'Pages/Search/Products/__generated__/SearchProducts';
import UiStore from 'Store/UiStore';
import { Link, theme } from 'Ui';
import { createUrlParams } from 'Utils/imageParams';
import { hasMin } from 'Utils/tools';
import { AppliedPriceEnum } from '__generated__/graphql-global-types';

import Highlighter from 'react-highlight-words';
import styled from 'styled-components/macro';

type SearchCardProps = {
  product: SearchProducts_products_edges_node;
  crop?: boolean;
};

const SearchCardContainer = styled.div`
  display: flex;
  padding: 1px 0px;
  align-items: center;
  gap: 16px;
`;

const SearchCardProductInfos = styled.div`
  display: flex;
  width: 200px;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
`;

const SearchCardBrand = styled.div`
  color: ${theme.v2.black};
  font-family: 'AlbraSemiBold' !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const SearchCardProductTitleBis = styled.div`
  color: ${theme.v2.black};
  font-family: 'InterMedium' !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 20rem;
`;

const SearchCardPrices = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

const SearchCardPrice = styled.div`
  color: ${theme.v2.brand.violet};
  font-family: 'InterBold' !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media screen and (max-width: 48rem) {
    font-size: 13px;
  }
`;

const SearchCardPublicPrice = styled.div`
  color: ${theme.v2.grayScale[300]};
  font-family: 'InterBold' !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration-line: line-through;
  @media screen and (max-width: 48rem) {
    font-size: 13px;
  }
`;

const ImageContainer = styled.div`
  width: 39px;
  height: 48px;
  flex-shrink: 0;
  img {
    border-radius: 3px;
    width: 100%;
    height: auto;
  }
`;

export const SearchCard = ({ product, crop = false }: SearchCardProps) => {
  const searchString = UiStore.useStoreState(
    (state) => state.searchStringHeader
  );
  const paramsUrl = JSON.stringify({
    dimensions: {
      width: 39,
      height: 48,
      crop: !crop,
    },
  });
  const paramsUrlRetina = JSON.stringify({
    dimensions: {
      width: 39 * 2,
      height: 48 * 2,
      crop: !crop,
    },
  });

  const paramsPicture = {
    dimensions: {
      width: 490,
      height: 618,
      crop: true,
      retina: false,
    },
  };

  const paramsPictureRetina = {
    dimensions: {
      width: 980,
      height: 1236,
      crop: true,
      retina: false,
    },
  };

  const productImg =
    product &&
    product.imagesDatas &&
    product.imagesDatas[0] &&
    product.imagesDatas[0].urlListPage;
  const productImage = `${productImg}?params=${createUrlParams(
    paramsPicture.dimensions
  )}`;
  const productImageRetina = `${productImg}?params=${createUrlParams(
    paramsPictureRetina.dimensions
  )}`;
  const minPrice = hasMin('sellPrice', product?.sizeDatas);
  const formPricePrice =
    product?.appliedPrice === AppliedPriceEnum.SIZE
      ? minPrice.sellPrice
      : product?.sellPrice;

  const formPricePricePublic =
    product?.appliedPrice === AppliedPriceEnum.SIZE
      ? minPrice.publicPrice
      : product?.publicPrice;
  let publicPrice = formPricePricePublic || 0;
  let sellPrice = formPricePrice || 0;
  return (
    <Link to={`/ventes/produit/id/${product.groupId}/idcat/${product.rangeId}`}>
      <SearchCardContainer>
        <ImageContainer>
          <img
            src={productImage}
            data-srcSet={`${productImageRetina} x2`}
            alt={product.title || ''}
          />
        </ImageContainer>
        <SearchCardProductInfos>
          <SearchCardBrand className="fontGranville">
            {product.brandDatas?.name || ''}
          </SearchCardBrand>
          <SearchCardProductTitleBis className="line-clamp fontMontserratMedium">
            <Highlighter
              highlightStyle={{
                fontWeight: 700,
                color: 'inherit',
                backgroundColor: 'transparent',
              }}
              className="fontMontserratMedium"
              searchWords={searchString.split(' ')}
              autoEscape={true}
              textToHighlight={product?.title || ''}
            />
          </SearchCardProductTitleBis>
          {/* <SearchCardProductTitle className="line-clamp">
            {product.title}
          </SearchCardProductTitle> */}
          <SearchCardPrices className="fontMontserratBold">
            <SearchCardPrice className="fontMontserratBold">
              {sellPrice} €
            </SearchCardPrice>
            {publicPrice > sellPrice && (
              <SearchCardPublicPrice className="fontMontserratBold">
                {publicPrice} €
              </SearchCardPublicPrice>
            )}
          </SearchCardPrices>
        </SearchCardProductInfos>
      </SearchCardContainer>
    </Link>
  );
};
