import React, { useState } from 'react';
import HeaderDropDownMenu from './HeaderDropMenu';

import styled from 'styled-components/macro';
import ConsultedSalesSideMenu from './ConsultedSalesSideMenu';
import { useQuery } from '@apollo/client';
import { getSales, getSalesVariables } from './__generated__/getSales';
import gql from 'graphql-tag';
import UiStore from 'Store/UiStore';
import AuthStore from 'Store/AuthStore';
import { useApolloClient } from '@apollo/client';
import { Personna } from 'Ui/Icons/icons';
import { DropDownUser } from './DropDown/DropDownUser';
import { DropDownAnonymous } from './DropDown/DropDownAnonymous';
import { Header, LinkLine } from './DropDown/styles';
import { IconMyInfos, IconMyOrders, IconMyReturn } from 'Ui/Icons';
import { allowedTypes } from 'Utils/memberTypes';

export const GET_SALES = gql`
  query getSales($ids: [ID!]!) {
    salesByIds(ids: $ids) {
      id
      name
      imageUrl
    }
  }
`;

const Body = styled.div`
  border-radius: 8px;
  background: #fff;
  width: 33rem;
  box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.14);
`;

const Side = styled.div`
  border-right: 1px solid #ddd;
  padding: 2rem;
  width: ${96.5 - 25 - 0.2}rem;
  max-width: ${(props) => {
    var w =
      Math.max(document.documentElement.clientWidth, window.innerWidth || 0) /
      10;
    return w - 6 - 25 - 0.2;
  }}rem;
  background-color: #f1f2f3;
  height: 100%;
`;
const MenuContainer = styled.div`
  padding: 2.4rem 3.1rem 0.5rem;
  border-bottom: 1px solid #dedcdc;
  border-right: 1px solid #dedcdc;
  border-left: 1px solid #dedcdc;
  border-radius: 0 0 8px 8px;
`;

export default () => {
  const isLoggedIn = AuthStore.useStoreState((state) => state.isLoggedIn);
  const sessionMemberType = AuthStore.useStoreState(
    (state) => state.sessionMemberType
  );
  const consultedSales = UiStore.useStoreState((state) => state.consultedSales);
  const member = AuthStore.useStoreState((state) => state.member);

  const { data } = useQuery<getSales, getSalesVariables>(GET_SALES, {
    variables: { ids: consultedSales },
    // fetchPolicy: "cache-and-network",
  });

  const consultedSalesObjects = (data && data.salesByIds) || [];

  const consultedSalesNumber = Math.min(6, consultedSalesObjects.length);
  const setSideMenu = UiStore.useStoreActions(
    (action) => action.setSaleSideMenu
  );
  const sideMenu = UiStore.useStoreState((state) => state.saleSideMenu);
  const apolloClient = useApolloClient();
  const SeenSales = () => (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <div>Ventes Vues</div>
      {!!consultedSalesNumber && <div>{consultedSalesNumber}</div>}
    </div>
  );
  return (
    <HeaderDropDownMenu
      icon={<Personna width="30" height="30" />}
      iconOpen={<Personna width="30" height="30" />}
      inletColor="#f1f2f3"
      inletPosition={157}
      onClose={() => setSideMenu('')}
    >
      {sideMenu === 'sales' && (
        <div
          style={{
            position: 'absolute',
            left: '-71rem',
            bottom: '0%',
            height: '100%',
          }}
        >
          <Side>
            <ConsultedSalesSideMenu
              consultedSales={consultedSalesObjects.slice(
                0,
                consultedSalesNumber
              )}
            />
          </Side>
        </div>
      )}
      <Body>
        {allowedTypes.includes(sessionMemberType) ? (
          <DropDownUser />
        ) : (
          <DropDownAnonymous />
        )}
      </Body>
    </HeaderDropDownMenu>
  );
};
