import React, { useEffect } from "react";
import styled from "styled-components/macro";
import posed, { PoseGroup } from "react-pose";
import { useState } from "react";
import { getSales_salesByIds } from "./__generated__/getSales";

const ConsultedSalesSideMenu = styled.div`
  display: grid;
  display: -ms-grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  justify-items: stretch;
  align-items: stretch;
  height: 100%;
`;

const SaleItem = posed(styled.a<{ image: string }>`
  position: relative;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  .image {
    position: relative;
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ccc;
    background: url(${props => props.image});
    background-size: cover;
    &:before {
      content: "";
      position: absolute;
      opacity: 0;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.7);
      transition: 0.2s;
      z-index: 5;
    }
    .link {
      opacity: 0;
      transform: translateY(-1rem);
      position: relative;
      padding: 1.5rem 3rem;
      border: 1px solid;
      color: #fff;
      z-index: 6;
      transition: 0.2s;
    }
  }
  &:hover {
    .image:before,
    .image .link {
      opacity: 1;
      transform: translateY(0);
    }
  }
  .label {
    text-align: center;
    padding: 0.5rem;
  }
`)({
  enter: {
    y: 0,
    opacity: 1,
    delay: ({ i }: { i: number }) => i * 50
  },
  exit: {
    opacity: 0,
    y: -10
  }
});

export default ({
  consultedSales
}: {
  consultedSales: getSales_salesByIds[];
}) => {
  const [delay, setDelay] = useState(false);
//https://express-purchase-tracking.preprod.bazarchic.com/imageManager/resizeImage/sLocale/fr_FR/sFormat/vente_vues/iCategoryId/${sale.id}.jpg
  useEffect(() => {
    setTimeout(() => setDelay(true), 10);
  }, []);
  return (
    <ConsultedSalesSideMenu>
      <PoseGroup>
        {delay &&
          consultedSales.map((sale, index) => {
            const paramsDimensions = JSON.stringify({"dimensions":{"width":320,"height":240,"crop":false}});

            const image = JSON.stringify(`https://cdn.bazarchic.com/api/sale/${sale.id}.jpg?params=${paramsDimensions}`);
            return (
              <SaleItem
                href={`/ventes/cat/id/${sale.id}/`}
                key={sale.id as string}
                i={index}
                image={image}

              >
                <div className="image">
                  <div className="link">Voir la vente</div>
                </div>
                <div className="label">{sale.name}</div>
              </SaleItem>
            );
          })}
      </PoseGroup>
    </ConsultedSalesSideMenu>
  );
};
