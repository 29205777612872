export { default as AlmaLogo } from "./AlmaLogo";
export { default as AlmaReassurance } from "./AlmaReassurance";
export { default as BcBackground } from "./BcBackground";
export { default as BcBackgroundMobile } from "./BcBackgroundMobile";
export { default as BcBackgroundPage } from "./BcBackgroundPage";
export { default as Basket } from "./Basket";
export { default as BzcLogo } from "./BzcLogo";
export { default as Chat } from "./Chat";
export { default as CheckCircle } from "./CheckCircle";
export { default as Check } from "./Check";
export { default as ChevronDown } from "./ChevronDown";
export { default as ChevronLeft } from "./ChevronLeft";
export { default as ChevronRight } from "./ChevronRight";
export { default as ChevronPaiement } from "./ChevronPaiement";
export { default as CloseCircle } from "./CloseCircle";
export { default as Close } from "./Close";
export { default as DownloadApp } from "./DownloadApp";
export { default as ExclamationCircle } from "./ExclamationCircle";
export { default as Facebook } from "./Facebook";
export { default as FourteenDays } from "./FourteenDays";
export { default as Group } from "./Group";
export { default as HomeFilled } from "./HomeFilled";
export { default as Home } from "./Home";
export { default as HomeNew } from "./HomeNew";
export { default as Hotline } from "./Hotline";
export { default as IcnEmail } from "./IcnEmail";
export { default as IcnFacebook } from "./IcnFacebook";
export { default as IcnPinterest } from "./IcnPinterest";
export { default as IcnTwitter } from "./IcnTwitter";
export { default as InformationsIcone } from "./InformationsIcone";
export { default as Instagram } from "./Instagram";
export { default as Order2 } from "./Order2";
export { default as Paiement } from "./Paiement";
export { default as Params } from "./Params";
export { default as PictoCb } from "./PictoCb";
export { default as Pinterest } from "./Pinterest";
export { default as Return } from "./Return";
export { default as Search } from "./Search";
export { default as Twitter } from "./Twitter";
export { default as User } from "./User";
export { default as Youtube } from "./Youtube";
export { default as Apple } from "./Apple";
export { default as ArrowBack } from "./ArrowBack";
export { default as ArrowBottom } from "./ArrowBottom";
export { default as Arrow } from "./Arrow";
export { default as BasketFilled } from "./BasketFilled";
export { default as BasketNew } from "./BasketNew";
export { default as BonAchat } from "./BonAchat";
export { default as Bzc } from "./Bzc";
export { default as Chatbox } from "./Chatbox";
export { default as CheckMark } from "./CheckMark";
export { default as Cloche } from "./Cloche";
export { default as CloseModal } from "./CloseModal";
export { default as CoeurMenu } from "./CoeurMenu";
export { default as Coeur } from "./Coeur";
export { default as Createurs } from "./Createurs";
export { default as CrossClose } from "./CrossClose";
export { default as Delivery } from "./Delivery";
export { default as EndSale } from "./EndSale";
export { default as Error } from "./Error";
export { default as Eye } from "./Eye";
export { default as EyeClosed } from "./EyeClosed";
export { default as Faqs } from "./Faqs";
export { default as Fb } from "./Fb";
export { default as Fbook } from "./Fbook";
export { default as Ggl } from "./Ggl";
export { default as Google } from "./Google";
export { default as Headphones } from "./Headphones";
export { default as Heart } from "./Heart";
export { default as HeartHeader } from "./HeartHeader";
export { default as IcoCb } from "./IcoCb";
export { default as IcoAccount } from "./IcoAccount";
export { default as IcoAchatExpress } from "./IcoAchatExpress";
export { default as IcoAvisVerifie } from "./IcoAvisVerifie";
export { default as IcoBack14J } from "./IcoBack14J";
export { default as IcoBack } from "./IcoBack";
export { default as IcoBasketMultiSales } from "./IcoBasketMultiSales";
export { default as IcoBasket } from "./IcoBasket";
export { default as IcoCloche } from "./IcoCloche";
export { default as IcoClose } from "./IcoClose";
export { default as IcoCompetetivePrice } from "./IcoCompetetivePrice";
export { default as IcoContact } from "./IcoContact";
export { default as IcoCross } from "./IcoCross";
export { default as IcoDelivery } from "./IcoDelivery";
export { default as IcoEasyBack } from "./IcoEasyBack";
export { default as IcoEndSale } from "./IcoEndSale";
export { default as IcoEye } from "./IcoEye";
export { default as IcoFacebook } from "./IcoFacebook";
export { default as IcoFaq } from "./IcoFaq";
export { default as IcoFiltreNotification } from "./IcoFiltreNotification";
export { default as IcoFiltre } from "./IcoFiltre";
export { default as IcoGuideTaille } from "./IcoGuideTaille";
export { default as IcoHome } from "./IcoHome";
export { default as IcoInstagram } from "./IcoInstagram";
export { default as IcoLivraison } from "./IcoLivraison";
export { default as IcoLocalisation } from "./IcoLocalisation";
export { default as IcoMarquePremium } from "./IcoMarquePremium";
export { default as IcoMenu } from "./IcoMenu";
export { default as IcoNotification } from "./IcoNotification";
export { default as IcoOrder } from "./IcoOrder";
export { default as IcoPaiment3X4X } from "./IcoPaiment3X4X";
export { default as IcoPinterest } from "./IcoPinterest";
export { default as IcoSearch } from "./IcoSearch";
export { default as IcoSponsorship } from "./IcoSponsorship";
export { default as IcoSuccess } from "./IcoSuccess";
export { default as IconTelFooter } from "./IconTelFooter";
export { default as IconeAccount } from "./IconeAccount";
export { default as IconeFaq } from "./IconeFaq";
export { default as IconeFinVentes } from "./IconeFinVentes";
export { default as IconeGuideTaille } from "./IconeGuideTaille";
export { default as IconeInformations } from "./IconeInformations";
export { default as IconeLogout } from "./IconeLogout";
export { default as IconeMenu } from "./IconeMenu";
export { default as IconePanier } from "./IconePanier";
export { default as IconeSearchBold } from "./IconeSearchBold";
export { default as IconeBack } from "./IconeBack";
export { default as IconeBottom } from "./IconeBottom";
export { default as IconeClose } from "./IconeClose";
export { default as IconeEye } from "./IconeEye";
export { default as IconeEyecross } from "./IconeEyecross";
export { default as IconeHome } from "./IconeHome";
export { default as IconeLivraison } from "./IconeLivraison";
export { default as IconePanierActif } from "./IconePanierActif";
export { default as IconeRight } from "./IconeRight";
export { default as IconeSearch } from "./IconeSearch";
export { default as IconeSponsorship } from "./IconeSponsorship";
export { default as IconePictoApplication } from "./IconePictoApplication";
export { default as Iconpreference } from "./Iconpreference";
export { default as Ig } from "./Ig";
export { default as IgColor } from "./IgColor";
export { default as IgGray } from "./IgGray";
export { default as IgGray1 } from "./IgGray1";
export { default as Info } from "./Info";
export { default as InfoV2 } from "./InfoV2";
export { default as InfosOney } from "./InfosOney";
export { default as Linkedin } from "./Linkedin";
export { default as Lock } from "./Lock";
export { default as LogoMobile } from "./LogoMobile";
export { default as Logout } from "./Logout";
export { default as Loop } from "./Loop";
export { default as LoopSearch } from "./LoopSearch";
export { default as MailFooter } from "./MailFooter";
export { default as MailMenu } from "./MailMenu";
export { default as Menu } from "./Menu";
export { default as Minus } from "./Minus";
export { default as MyFilleuls } from "./MyFilleuls";
export { default as MyInfos } from "./MyInfos";
export { default as MyOrders } from "./MyOrders";
export { default as MyPref } from "./MyPref";
export { default as MyReturn } from "./MyReturn";
export { default as NewCloche } from "./NewCloche";
export { default as OneyLogo } from "./OneyLogo";
export { default as Order } from "./Order";
export { default as Personna } from "./Personna";
export { default as PictoMobile } from "./PictoMobile";
export { default as Placeholder } from "./Placeholder";
export { default as Plus } from "./Plus";
export { default as PoubelleOuverte } from "./PoubelleOuverte";
export { default as Poubelle } from "./Poubelle";
export { default as Power } from "./Power";
export { default as Privilege } from "./Privilege";
export { default as Question } from "./Question";
export { default as QuestionMark } from "./QuestionMark";
export { default as Reassurance } from "./Reassurance";
export { default as Retoursretour } from "./Retoursretour";
export { default as SecurePayment } from "./SecurePayment";
export { default as ServiceClient } from "./ServiceClient";
export { default as SizeGuide } from "./SizeGuide";
export { default as ThreeCol } from "./ThreeCol";
export { default as To } from "./To";
export { default as Trash } from "./Trash";
export { default as TwoCol } from "./TwoCol";
export { default as UserFilled } from "./UserFilled";
export { default as UsersFilled } from "./UsersFilled";
export { default as UsersSponsorship } from "./UsersSponsorship";
export { default as Users } from "./Users";
export { default as ValidCheckAvailable } from "./ValidCheckAvailable";
export { default as Wrong } from "./Wrong";
