import React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'Ui/theme';

interface PropsLabel {
  title: string;
  count: number;
}

const SpanStyled = styled.span`
  color: ${theme.light.colors.purpleLight};
  width: 11px;
  display: inline-block;
`;

export const SpanLabel = ({ title, count }: PropsLabel) => {
  return (
    <div>
      {title} <SpanStyled>{count > 0 && `(${count})`}</SpanStyled>
    </div>
  );
};
