import * as React from "react";
const SvgReturn = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.2}
      d="M21.886 6.75a.43.43 0 0 0-.042-.353l-2.359-3.708C19.227 2.288 18.441 2 18.033 2H5.962c-.409 0-1.2.288-1.453.689L2.15 6.397a.41.41 0 0 0-.042.353A1 1 0 0 0 2 7.193v13.789C2 21.545 2.456 22 3.02 22h17.96c.564 0 1.02-.455 1.02-1.018V7.193a1.1 1.1 0 0 0-.114-.443Zm-3.133-3.6 1.927 3.025h-6.243l-.54-3.318h4.135c.193.006.637.197.72.293Zm-8.404 3.893h3.284v1.971h-3.284zm.078-.862.54-3.318h2.053l.54 3.318zM5.23 3.156c.09-.108.522-.3.726-.3h4.136l-.54 3.319H3.308zm15.9 17.832a.16.16 0 0 1-.156.155H3.014a.16.16 0 0 1-.156-.155V7.199c0-.084.072-.156.156-.156h6.477v2.403c0 .239.192.43.432.43h4.148a.43.43 0 0 0 .432-.43V7.043h6.477c.084 0 .156.072.156.156v13.789z"
    />
    <path
      fill="url(#Return_svg__a)"
      d="m8.753 15.357 1.873-1.928c.518-.542 1.294.271.777.814l-.633.66c-.144.15-.097.272.104.272h3.418c.303 0 .549.257.549.575a.563.563 0 0 1-.549.575h-3.417c-.202 0-.248.122-.104.273l.632.66c.518.542-.259 1.355-.776.813l-1.872-1.908a.58.58 0 0 1-.002-.806"
    />
    <defs>
      <linearGradient
        id="Return_svg__a"
        x1={8.591}
        x2={14.841}
        y1={15.75}
        y2={15.75}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6C4DFF" />
        <stop offset={1} stopColor="#8268FF" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgReturn;
