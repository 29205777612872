import styled from 'styled-components';
import { theme } from 'Ui';
import { IconIconpreference } from 'Ui/Icons';
import { NewCheckbox } from 'Ui/Newcheckbox';

const PreferenceNewsletterContainer = styled.div`
  background-color: #f8f8f8;
  padding: 1.5rem 2.4rem;
  border-radius: 8px;
  border: 1px solid #dedcdc;
  .svgTitle {
    svg {
      font-size: 2.8rem;
    }
  }
`;

const PreferenceContainer = styled.div`
  display: flex;
  margin-bottom: 1.6rem;
  align-items: center;
  gap: 6px;
  flex-direction: column;
  @media screen and (min-width: ${theme.breakpoints.medium}px) {
    flex-direction: row;
    gap: 10px;
    letter-spacing: 0.16px;
  }
`;
const PreferenceTitle = styled.div`
  font-family: 'granville-regular' !important;
  font-size: 1.6rem;
`;

type PreferenceProps = {
  dailyChecked: boolean;
  onChangeDaily: () => void;
  opcChecked: boolean;
  onChangeOpc: () => void;
};

const PreferenceNewsletter = ({
  dailyChecked,
  onChangeDaily,
  opcChecked,
  onChangeOpc,
}: PreferenceProps) => {
  return (
    <PreferenceNewsletterContainer>
      <PreferenceContainer className="svgTitle">
        <IconIconpreference />
        <PreferenceTitle>
          Mes préférences de communication e-mail
        </PreferenceTitle>
      </PreferenceContainer>
      <div>
        <div>
          <NewCheckbox
            checked={dailyChecked}
            align
            onChange={() => {
              onChangeDaily && onChangeDaily();
            }}
            label={
              <>
                <div
                  style={{
                    fontSize: '1.2rem',
                    letterSpacing: '0.54px',
                    lineHeight: '1.7rem',
                    color: theme.v2.text.darkGray,
                    fontFamily: 'Montserrat',
                  }}
                >
                  Je souhaite être à l'affut des nouveautés en recevant chaque
                  jour les nouvelles ventes.
                </div>
              </>
            }
          />
          <NewCheckbox
            align
            checked={opcChecked}
            onChange={() => {
              onChangeOpc && onChangeOpc();
            }}
            label={
              <>
                <div
                  style={{
                    fontSize: '1.2rem',
                    letterSpacing: '0.54px',
                    lineHeight: '1.7rem',
                    color: theme.v2.text.darkGray,
                    fontFamily: 'Montserrat',
                  }}
                >
                  Je souhaite faire le plein d'inspiration avec une sélection
                  des meilleures offres et tendances du moment.
                </div>
              </>
            }
          />
        </div>
      </div>
    </PreferenceNewsletterContainer>
  );
};

export default PreferenceNewsletter;
