import { useQuery } from '@apollo/client';
import logoBzcPath from 'Assets/images/BzcLogo1.svg';
import BannerApp from 'Components/BannerApp';
import ModalSearch from 'Components/modals/ModalSearch';
import { purgeVisibility } from 'Libs/Gtm/home';
import { storageFactory } from 'Libs/storageFactory';
import AuthStore from 'Store/AuthStore';
import UiStore from 'Store/UiStore';
import { theme } from 'Ui';
import { IconIcoSearch } from 'Ui/Icons';
import IconeMenu from 'Ui/Icons/icons/IconeMenu';
import { HeaderLinks } from 'Ui/Link';
import { DeviceEnum, OsEnum } from '__generated__/graphql-global-types';
import { isAndroid, isIOS, isMobile } from 'react-device-detect';
import styled from 'styled-components/macro';
import HeaderBasket from '../HeaderBasket';
import { getHeaderNavigation } from '../__generated__/getHeaderNavigation';
import headerNavigationQuery from '../headerNavigationQuery';
import { MenuBar, MobileScroller } from '../menuComponents';
import SearchInput from './SearchInput';
import WishListMenu from '../DesktopHeader/WishListMenu';
import Personna from 'Ui/Icons/icons/Personna';
import { useEffect } from 'react';
import { CheckMark, LogoMobile } from 'Ui/Icons/icons';

const MobileHeaderHeight = 4.8;

// const StyledHeader = styled.div`
//   padding-top: ${MobileHeaderHeight}rem;
// `;

const HeaderMobileContainer = styled.div`
  width: 100%;
  padding: 1.6rem 1.6rem 0.8rem;
`;

const HeaderNavContainer = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderLogoContainer = styled.div`
  flex: 1;
  /* width: 65%; */
  display: flex;
  /* margin-left: 1.8rem; */
`;

const HeaderMenuContainer = styled.div`
  display: flex;
  /* width: 35%; */
  /* justify-content: space-around; */
`;

const TopBar = styled.div`
  display: flex;
  align-items: center;
  /* height: ${MobileHeaderHeight}rem; */
  justify-content: space-between;
  /* padding: 0 1.5rem; */
  /* border-bottom: 1px solid #eee; */
  background-color: #fff;
  font-size: 2.5rem;
  img {
    width: 19rem;
  }
`;

const FixBar = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 1000;
  .addShadow {
    box-shadow: 0px 1px 9px 0px rgba(0, 0, 0, 0.1);
  }
`;

const Search = styled.div`
  @media screen and (max-width: ${theme.breakpoints.small}px) {
    margin-right: 1rem;
    svg {
      height: 2.6rem;
      width: 3.6rem;
    }
    svg path {
      stroke: ${theme.light.colors.purple};
      stroke-width: 3.8px;
    }
  }
  @media screen and (max-width: ${theme.breakpoints.micro}px) {
    margin: 0rem 0.5rem;
  }
`;
const Basket = styled.div`
  @media screen and (max-width: ${theme.breakpoints.small}px) {
    /* margin-right: 1rem; */
    svg {
      /* height: 2.6rem; */
    }
    svg path {
      // stroke: ${theme.light.colors.purple};
      stroke-width: 4px;
    }
  }
`;

const SuccessIcon = styled.div`
  background-color: ${theme.light.colors.purple};
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: 1rem auto; */
  margin-right: 1rem;
  position: absolute;
  bottom: 12px;
  left: 14px;
  svg path {
    stroke: white !important;
  }
  svg {
    font-size: 0.8rem;
  }
`;
export default () => {
  const isLoggedIn = AuthStore.useStoreState((state) => state.isLoggedIn);
  const setSideMenuOpen = UiStore.useStoreActions(
    (actions) => actions.setSideMenuOpen
  );

  const cookieMobileAppPopup = UiStore.useStoreState(
    (state) => state.cookieMobileApp
  );

  const urlSplit = (url: any) => {
    const splitUrl = url.split('/');
    return splitUrl[1];
  };
  const loggedIn = AuthStore.useStoreState((state) => state.isLoggedIn);
  const localStore = storageFactory(() => localStorage);

  const menuDevice: DeviceEnum = isMobile
    ? DeviceEnum.MOBILE
    : DeviceEnum.DESKTOP;
  const { data } = useQuery<getHeaderNavigation>(headerNavigationQuery, {
    variables: { device: menuDevice, os: OsEnum.SITE },
  });
  const links = (data && data.headerNavigation && data.headerNavigation) || [];
  // const home = '/accueil/index/' === window.location.pathname;
  const deco = '/deco/index/' === window.location.pathname;
  const wine = '/wines/index/' === window.location.pathname;
  const setLinkType = UiStore.useStoreActions((action) => action.setLinkType);

  // const homeType = 'ACCEUIL';
  const linksMenu = links.map((link, index) => {
    const urlLink = link.url;
    const linkUrlWithoutSlash = urlLink.slice(0, -1);
    const urlMatch =
      window.location.pathname === linkUrlWithoutSlash
        ? linkUrlWithoutSlash
        : link.url;
    return {
      label: link.label,
      url: urlMatch,
      color: link.color,
      alt: link.alt,
      type: link.type,
      target: link.target,
      nameUrl: urlSplit(link.url),
    };
  });

  const selectedScroll = (el: any) => {
    const nameUrl = urlSplit(window.location.pathname);
    if (nameUrl) {
      const category = el && el.dataset && el.dataset.url;
      if (nameUrl === category) {
        const scrolllElement = document.querySelector('.mobileScroll');
        if (category !== 'index') {
          if (scrolllElement) {
            scrolllElement.scrollLeft = el.offsetLeft - 150;
          }
        }
      }
    }
  };
  const setPathHeaderMenu = UiStore.useStoreActions(
    (action) => action.setPathHeaderMenu
  );
  setPathHeaderMenu(links);

  const scrollShadow = document.getElementById('addShadowJs');
  const headerMobilePadding: any = document.getElementById('headermobile');
  useEffect(() => {
    const detectScroll = (event: any) => {
      const getScroll = event.currentTarget.scrollY;
      // if (scrollShadow) {
      if (getScroll > 40) {
        scrollShadow.style.boxShadow = '0px 1px 9px 0px rgba(0, 0, 0, 0.1)';
        headerMobilePadding.style.padding = '1.6rem 1.6rem 1.6rem';
      } else {
        scrollShadow.style.boxShadow = 'none';
        headerMobilePadding.style.padding = '1.6rem 1.6rem 0.8rem';
      }
      // }
    };

    window.addEventListener('scroll', detectScroll);
    return () => {
      window.removeEventListener('scroll', detectScroll);
    };
  }, [!!scrollShadow]);

  // const setColor = (color: any, link: string) => {
  //   return color
  //     ? color
  //     : link === window.location.pathname
  //     ? `${theme.light.colors.purple}`
  //     : '#000';
  // };

  // const isLoggedIn =
  //   loggedIn || !!localStore.getItem('isLoggedIn') ? true : false;

  // if (!loggedIn) return <AnonymousHeader />;

  // if (!!localStore.getItem('isLoggedIn') === false) {
  //   return <AnonymousHeader />;
  // } else if (!!localStore.getItem('isLoggedIn') === false && !loggedIn) {
  //   return <AnonymousHeader />;
  // }

  return (
    <>
      {/* {loggedIn && ( */}
      <>
        <FixBar id="addShadowJs">
          {cookieMobileAppPopup && (isAndroid || isIOS) && (
            <BannerApp android={isAndroid} />
          )}

          <TopBar>
            <HeaderMobileContainer id="headermobile">
              <HeaderNavContainer>
                <HeaderLogoContainer>
                  <a href="/">
                    <LogoMobile width="156" height="18" />
                  </a>
                </HeaderLogoContainer>
                <HeaderMenuContainer>
                  <div
                    style={{ position: 'relative' }}
                    onClick={() => {
                      setSideMenuOpen(true);
                    }}
                  >
                    {isLoggedIn && (
                      <SuccessIcon>
                        <CheckMark />
                      </SuccessIcon>
                    )}
                    <Personna width={30} height={30} />
                  </div>
                  <div style={{ padding: '0 1.1rem' }}>
                    <WishListMenu />
                  </div>
                  <Basket>
                    <HeaderBasket />
                  </Basket>
                </HeaderMenuContainer>
              </HeaderNavContainer>
              <div
                style={{ width: '100%', marginTop: '0.8rem' }}
                data-tut="headerTuto_position"
                data-test="searchHeader"
                onClick={async () => (await ModalSearch) && ModalSearch({})}
              >
                <SearchInput
                  isLogin={true}
                  placeholder="Rechercher une vente, un produit ..."
                />
              </div>
            </HeaderMobileContainer>
          </TopBar>
        </FixBar>
        <MobileScroller>
          <MenuBar className="mobileScroll reactHomeMenuV4">
            {linksMenu
              .filter((link) => !!link.label)
              .map((link, index) => (
                <>
                  <div
                    data-url={link.nameUrl}
                    key={index}
                    ref={(node) => selectedScroll(node)}
                  >
                    <HeaderLinks
                      to={link.url}
                      internal={false}
                      style={{ color: link.color }}
                      activeClassName="active"
                      key={index}
                      onClick={() => {
                        setLinkType(link.type.toUpperCase());
                        purgeVisibility();
                      }}
                    >
                      {link.label}
                    </HeaderLinks>
                  </div>
                </>
              ))}
          </MenuBar>
        </MobileScroller>
      </>
      {/* )} */}
    </>
  );
};
