import React from 'react';
import styled from 'styled-components/macro';
import Conviction1 from 'Assets/images/Conviction1_2.png';
import Conviction2 from 'Assets/images/Conviction2_2.png';
import Conviction3 from 'Assets/images/Conviction3_2.png';
import { theme } from 'Ui';
import { IcnFacebook } from 'Ui/Icons/icons';
import { IconFacebook, IconIcoInstagram, IconInstagram } from 'Ui/Icons';

const Suscribe = styled.div`
  display: block;
  text-align: center;
  font-family: 'Montserrat';
  background-color: ${theme.light.colors.purple};
  border-radius: 8rem 0 8rem 0;
  margin: 3rem 0;
  padding: 4rem 15rem;
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: -0.05px;
  line-height: 38px;
  color: #ffffff;
  /* width: 50%; */
  @media screen and (max-width: 480px) {
    font-size: 1.5rem;
    padding: 4rem 2rem;
  }
`;

const ConvictionTitle = styled.div`
  background-color: #f5f5f5;
  padding: 4rem 0;

  .title {
    font-family: 'granville-bold';
    font-size: 3.5rem;
    font-weight: 700;
    color: ${theme.light.colors.purple};
    text-align: center;
    padding-bottom: 1rem;
  }
  .paragraph {
    font-family: 'Montserrat';
    font-size: 2rem;
    font-weight: 500;
    color: ${theme.light.colors.grayUltraDark};
    text-align: center;
  }
  @media screen and (max-width: 480px) {
    padding: 3rem 0;
    .title {
      font-size: 3.5rem;
    }
    .paragraph {
      font-size: 1.5rem;
    }
  }
`;

const StyleAccessible = styled.div`
  position: relative;
`;

const Image = styled.div`
  img {
    width: 100%;
  }
`;

const Title = styled.h2`
  text-align: center;
  font-family: 'granville-bold';
  font-size: 3.5rem;
  font-weight: 700;
  color: ${theme.light.colors.purple};
  background-color: #f5f5f5;
  padding: 5rem 0;
  margin-top: 7.5rem;
  @media screen and (max-width: 480px) {
    font-size: 3.5rem;
  }
`;

const ImageText = styled.div<{
  top?: number;
  left?: number;
  topResponsive?: number;
  leftResponsive?: number;
  height?: any;
}>`
  position: absolute;
  top: 18rem;
  left: 9rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-content: center;
  .title {
    font-family: 'granville-bold';
    font-size: 3.5rem;
    margin: 3rem 0;
    font-weight: 700;
    color: ${theme.light.colors.purple};
  }
  .paragraph {
    font-family: 'Montserrat';
    font-size: 1.7rem;
    font-weight: 400;
    color: ${theme.light.colors.grayUltraDark};
    line-height: 29px;
  }
  @media screen and (max-width: 480px) {
    left: ${(props) => props.leftResponsive && `${props.leftResponsive}rem`};
    top: ${(props) => props.topResponsive && `${props.topResponsive}rem`};
    justify-content: center;
    .title {
      font-size: 3.5rem;
      /* left: ${(props) =>
        props.leftResponsive && `${props.leftResponsive}rem`};
            top: ${(props) =>
        props.topResponsive && `${props.topResponsive}rem`}; */
    }
    .paragraph {
      font-size: 1.5rem;
    }
  }
  @media screen and (min-width: 481px) and (max-width: 768px) {
    top: 5rem;
    justify-content: center;
  }
`;

const SecondaryParagraph = styled.div`
  font-family: 'Montserrat';
  font-size: 2rem;
  padding: 5rem 0;
  font-weight: 400;
  background-color: ${theme.light.colors.purple};
  color: #ffffff;
  text-align: center;
  line-height: 38px;
  position: absolute;
  width: 100%;
  bottom: -7.5rem;
  @media screen and (max-width: 480px) {
    font-size: 1.5rem;
  }
`;

const ImageTextBackground = styled.div`
  text-align: center;
  background-color: #ffffff94;
  width: 70%;
  margin: 0 auto;
  position: absolute;
  top: 33rem;
  left: 15%;
  .title {
    font-family: 'granville-bold';
    font-size: 3.5rem;
    margin: 10rem 14rem 3rem 14rem;

    font-weight: 700;
    color: ${theme.light.colors.purple};
  }
  .paragraph {
    font-family: 'Montserrat';
    font-size: 1.7rem;
    font-weight: 400;
    color: ${theme.light.colors.grayUltraDark};
    line-height: 30px;
    letter-spacing: -0.05px;
  }
  .purple {
    color: ${theme.light.colors.purple};
  }
  .paddingParagraph {
    margin: 3rem 0;
  }
  @media screen and (max-width: 480px) {
    top: 13rem;
    width: 90%;
    left: 5%;
    .title {
      font-size: 3.5rem;
    }
    .paragraph {
      font-size: 1.5rem;
    }
  }
  @media screen and (min-width: 481px) and (max-width: 768px) {
    top: 32rem;
    width: 90%;
    left: 5%;
  }
`;

const ThirdParagraph = styled.div`
  display: flex;
  margin-top: 5rem;
  align-items: center;
  justify-content: space-between;
  .img {
    width: 50%;
  }
  .text {
    font-weight: 400;
    line-height: 2.9rem;
    font-size: 1.7rem;
    width: 40%;
    text-align: right;
    margin-bottom: 12rem;
  }
  @media screen and (max-width: 480px) {
    margin: 1rem;
    .img {
      display: none;
    }
    .text {
      font-size: 1.5rem;
      text-align: center;
      width: 100%;
    }
  }
`;

const SocialMedia = styled.div`
  display: flex;
  flex-direction: row;
  width: 10%;
  justify-content: space-around;
  margin: auto;
  svg {
    font-size: 3rem;
  }
  svg:hover {
    color: ${theme.light.colors.purple};
  }
  @media screen and (max-width: 480px) {
    margin-top: 20rem;
  }
`;

const FourParagraph = styled.div`
  position: relative;
  margin-bottom: 34rem;
  @media screen and (max-width: 480px) {
    margin-bottom: 58rem;
  }
`;
export default () => {
  return (
    <div>
      <ConvictionTitle>
        <h2 className="title">La vocation BazarChic</h2>
        <p className="paragraph">
          Rendre le premium et les marques de créateurs
        </p>
        <p className="paragraph">accessibles au plus grand nombre</p>
      </ConvictionTitle>
      <div style={{ position: 'relative' }}>
        <StyleAccessible>
          <Image>
            <img src={Conviction1} alt="Le style accessible" />
          </Image>
          <ImageText topResponsive={-7} leftResponsive={1}>
            <div>
              <h2 className="title">Le style accessible</h2>
            </div>
            <div>
              <p className="paragraph">
                Matières nobles, qualité irréprochable
              </p>
              <p className="paragraph">ou encore fabrication durable</p>
              <p className="paragraph">Une sélection minutieuse de pièces</p>
              <p className="paragraph">mises en ligne chaque jour</p>
            </div>
          </ImageText>
        </StyleAccessible>
        <SecondaryParagraph>
          <p>BazarChic offre à ses membres</p>
          <p>le meilleur de la mode et de la maison.</p>
          <p>Des produits soigneusement</p>
          <p>sélectionnés, remisés jusqu'à -70%</p>
        </SecondaryParagraph>
      </div>
      <Title>Une mode plus responsable</Title>
      <ThirdParagraph>
        <img
          className="img"
          src={Conviction3}
          alt="Suspension eco responsable"
        />
        <div className="text">
          Depuis plus de 15 ans, BazarChic œuvre afin de valoriser des pièces
          déjà commercialisées par ses marques partenaires lors de saisons
          précédentes.
          <span style={{ color: `${theme.light.colors.purple}` }}>
            &nbsp;Nous aimons autant que nos membres l’idée que notre industrie
            devienne plus circulaire pour préserver notre planète.
          </span>
          &nbsp;Une belle aventure portée également par nos fournisseurs et
          partenaires.
          <span style={{ color: `${theme.light.colors.purple}` }}>
            &nbsp;Ensemble, nous façonnons la mode de demain.
          </span>
        </div>
      </ThirdParagraph>
      <FourParagraph>
        <Image style={{ position: 'relative' }}>
          <img src={Conviction2} alt="Portant de vêtements" />
        </Image>
        <ImageTextBackground>
          <h2 className="title">Un service de qualité, tout en simplicité</h2>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              flexDirection: 'column',
            }}
          >
            <p className="paragraph">
              Le meilleur de la mode premium, au meilleur prix ? Oui mais sans
              impacter la qualité des services proposés.
              <span
                className="purple"
                style={{ color: `${theme.light.colors.purple}` }}
              >
                &nbsp;Chaque colis est minutieusement préparé et expédié par les
                équipes situées en région parisienne.
              </span>
            </p>
            <p className="paragraph paddingParagraph">
              Le Service Relation Membres reste joignable par téléphone ou sur
              Instagram @bazarchic et Facebook @bazarchic
            </p>
          </div>
        </ImageTextBackground>
      </FourParagraph>
      <SocialMedia>
        <a
          href="https://www.facebook.com/BazarChicFR"
          className="footer_social_ico"
          target="__blank"
          rel="noopener noreferrer"
        >
          <IconFacebook />
        </a>

        <a
          href="https://www.instagram.com/bazarchic/"
          className="footer_social_ico"
          target="__blank"
          rel="noopener noreferrer"
        >
          <IconIcoInstagram />
        </a>
      </SocialMedia>
      <Suscribe>
        Inscrivez-vous pour rejoindre notre communauté exclusive et découvrir
        les ventes du moment !
      </Suscribe>
    </div>
  );
};
