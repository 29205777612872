import { deleteSearchHistoryVariables, deleteSearchHistory_deleteSearchHistory } from "Components/Header/SearchHeader/__generated__/deleteSearchHistory";
import { searchHistory, searchHistory_searchHistory } from "Components/Header/SearchHeader/__generated__/searchHistory";
import { action, Action, Thunk, thunk, createContextStore } from "easy-peasy";
import Cookies from "js-cookie";
import { update } from "lodash";
import { getCookie } from 'Utils/cookie';
import { setUrlQueryParams } from "Utils/locationSearch";
import { SortEnum } from "__generated__/graphql-global-types";

type UiModel = {
  sideMenuOpen: boolean;
  consultedSales: string[];
  cookieMarketing: boolean;
  acceptedAndRefusedCookieReload: boolean;
  cookiePreheaderEmail: boolean;
  cookieMobileApp: boolean;
  disclaimer: boolean;
  menuMobileBannerHeight: number;
  webview: boolean;
  saleIdLG: string | null;
  pathHeaderMenu: { url: string, type: string, label: string }[] | null;
  accordionSelected: number;
  showButtonAvailable: boolean;
  utmSource: string;
  viewFacet: boolean;
  currentPage: string;
  errors: any;
  labelPosition: number;
  scrollEnable: boolean;
  isChecked: boolean;
  unsubscribeReason: string;
  unsubscribeReasonMessage: string;
  msgPreference: boolean;
  isErrorModalPref: boolean;
  optinResponse: any;
  saleSideMenu: string;
  gtmConsentMode: string;
  searchStringHeader: string;

  setSearchStringHeader: Action<UiModel, string>;
  setGtmConsentMode: Action<UiModel, string>;
  setMsgPreference: Action<UiModel, boolean>;
  setIsErrorModalPref: Action<UiModel, boolean>;
  setSaleSideMenu: Action<UiModel, string>;
  setUnsubscribeReason: Action<UiModel, string>;
  setUnsubscribeReasonMessage: Action<UiModel, string>;
  setScrollEnable: Action<UiModel, boolean>;
  setLabelPosition: Action<UiModel, number>;
  setErrors: Action<UiModel, any>;
  setViewFacet: Action<UiModel, boolean>;
  setIsChecked: Action<UiModel, boolean>;
  facetStore: { [index: string]: string[] };
  sortStore: SortEnum;
  searchFacetStore: { [index: string]: string[] };
  searchSortStore: SortEnum;
  productIdStore: string;
  productCursorStore: string;
  productRangeIdStore: string;
  resetFacetsStore: Action<UiModel>;
  resetSearchFacetsStore: Action<UiModel>;
  setOptinResponse: Action<UiModel, string>

  setProductIdStore: Action<UiModel, string>;
  setProductCursorStore: Action<UiModel, string>
  setProductRangeIdStore: Action<UiModel, string>;
  setSearchSortStore: Action<UiModel, SortEnum>;
  setSearchFacetStore: Action<UiModel, { facetName: string; options: string[] }>;
  setSortStore: Action<UiModel, SortEnum>;
  resetScrollRestoreStore: Action<UiModel>;
  setFacetStore: Action<UiModel, { facetName: string; options: string[] }>;
  setUtmSource: Action<UiModel, string>
  linkType: string;
  countWishlist: number;

  search: string[];
  searchString: string;

  setAcceptedAndRefusedCookieReload: Action<UiModel, boolean>
  setSearch: Action<UiModel, string[] | null>
  setLinkType: Action<UiModel, string>
  setSideMenuOpen: Action<UiModel, boolean>;
  setConsultedSales: Action<UiModel, string[]>;
  addConsultedSale: Action<UiModel, string>;
  setPathHeaderMenu: Action<UiModel, { url: string, type: string, label: string }[]>;
  setCookieMarketing: Action<UiModel, boolean>;
  setCookieMobileApp: Action<UiModel, boolean>;
  setMenuMobileBannerHeight: Action<UiModel, number>;
  setWebview: Action<UiModel, boolean>;
  setSaleIdLG: Action<UiModel, string>;
  setShowButtonAvailable: Action<UiModel, boolean>;
  setDisclaimer: Action<UiModel, boolean>;
  initUi: Thunk<UiModel, void>;
  setAccordionSelected: Action<UiModel, number>;
  setCookiePreheaderEmail: Action<UiModel, boolean>;
  setCurrentPage: Action<UiModel, string>;
  setSearchString: Action<UiModel, string>;
  setCountWishlist: Action<UiModel, number>;

};

const updateUrl = (state: any) => {
  const queryObject = {
    facets: Object.keys(state.facetStore).length
      ? JSON.stringify(state.facetStore)
      : null,
    sort: state.sortStore === SortEnum.RELEVANCE ? null : state.sortStore,
    productId: state.productIdStore,
    productCursor: state.productCursorStore,
    endCursor: null,
  } as {
    [index: string]: number | string | null | undefined;
  };
  setUrlQueryParams(queryObject);
};

const updateSearchUrl = (state: any) => {
  const queryObject = {
    // search: state.searchString,
    facets: Object.keys(state.searchFacetStore).length
      ? JSON.stringify(state.searchFacetStore)
      : null,
    sort: state.searchSortStore === SortEnum.RELEVANCE ? null : state.searchSortStore,
    // endCursor: null,
  } as {
    [index: string]: number | string | null | undefined;
  };
  setUrlQueryParams(queryObject);
};

const uiModel: UiModel = {
  acceptedAndRefusedCookieReload: false,
  sideMenuOpen: false,
  saleSideMenu: '',
  pathHeaderMenu: null,
  search: null,
  searchString: '',
  consultedSales: [],
  cookieMarketing: !!Cookies.get("_marketing"),
  cookieMobileApp: !!!Cookies.get("mobileAppPopup"),
  menuMobileBannerHeight: !!!Cookies.get("mobileAppPopup") ? (47 + 66) : 47,
  webview: !!Cookies.get("isWebView"),
  saleIdLG: null,
  showButtonAvailable: false,
  disclaimer: false,
  accordionSelected: 0,
  linkType: "",
  utmSource: "",
  viewFacet: false,
  cookiePreheaderEmail: !getCookie("preheader_wremail"),
  currentPage: "",
  errors: null,
  sortStore: SortEnum.RELEVANCE,
  searchSortStore: SortEnum.RELEVANCE,
  facetStore: {},
  searchFacetStore: {},
  countWishlist: 0,
  optinResponse: null,
  gtmConsentMode: 'default',
  productIdStore: "",
  productCursorStore: "",
  productRangeIdStore: "",
  labelPosition: 0,
  scrollEnable: false,
  isChecked: false,
  unsubscribeReason: '',
  unsubscribeReasonMessage: '',
  msgPreference: false,
  isErrorModalPref: false,
  searchStringHeader: '',

  setSearchStringHeader: action((state, payload) => {
    state.searchStringHeader = payload
  }),

  setGtmConsentMode: action((state, payload) => {
    state.gtmConsentMode = payload
  }),
  setOptinResponse: action((state, payload) => {
    state.optinResponse = payload
  }),
  setMsgPreference: action((state, payload) => {
    state.msgPreference = payload
  }),

  setIsErrorModalPref: action((state, payload) => {
    state.isErrorModalPref = payload
  }),

  setUnsubscribeReason: action((state, payload) => {
    state.unsubscribeReason = payload
  }),
  setSaleSideMenu: action((state, payload) => {
    state.saleSideMenu = payload
  }),
  setUnsubscribeReasonMessage: action((state, payload) => {
    state.unsubscribeReasonMessage = payload
  }),
  setScrollEnable: action((state, payload) => {
    state.scrollEnable = payload
  }),
  setIsChecked: action((state, payload) => {
    state.isChecked = payload;
  }),
  setLabelPosition: action((state, payload) => {
    state.labelPosition = payload
  }),
  setCountWishlist: action((state, payload) => {
    state.countWishlist = payload;
  }),
  setAcceptedAndRefusedCookieReload: action((state, payload) => {
    state.acceptedAndRefusedCookieReload = payload;
  }),
  setProductIdStore: action((state, payload) => {
    state.productIdStore = payload;
  }),
  setProductCursorStore: action((state, payload) => {
    state.productCursorStore = payload;
  }),
  setProductRangeIdStore: action((state, payload) => {
    state.productRangeIdStore = payload;
  }),
  resetFacetsStore: action((state) => {
    state.facetStore = {};
    updateUrl(state)
  }),
  resetSearchFacetsStore: action((state) => {
    state.searchFacetStore = {};
    updateSearchUrl(state)
  }),
  resetScrollRestoreStore: action((state) => {
    state.productIdStore = "";
  }),
  setSearchSortStore: action((state, payload) => {
    state.searchSortStore = payload;
    updateSearchUrl(state)
  }),
  setErrors: action((state, payload) => {
    state.errors = payload
  }),

  setSearchFacetStore: action((state, payload) => {
    const { facetName, options } = payload;
    state.searchFacetStore[facetName] = options;
    if (options && !options.length) delete state.searchFacetStore[facetName];
    updateSearchUrl(state)
  }),

  setSortStore: action((state, payload) => {
    state.sortStore = payload;
    updateUrl(state);
  }),

  setFacetStore: action((state, payload) => {
    const { facetName, options } = payload;
    state.facetStore[facetName] = options;
    if (options && !options.length) delete state.facetStore[facetName];
    updateUrl(state);
  }),

  setLinkType: action((state, payload) => {
    state.linkType = payload;
  }),
  setViewFacet: action((state, payload) => {
    state.viewFacet = payload;
  }),
  setSearch: action((state, payload) => {
    state.search = payload;
  }),
  setSearchString: action((state, payload) => {
    state.searchString = payload;
    updateSearchUrl(state)
  }),
  setUtmSource: action((state, payload) => {
    state.utmSource = payload;
  }),
  setAccordionSelected: action((state, payload) => {
    state.accordionSelected = payload;
  }),
  setPathHeaderMenu: action((state, payload) => {
    state.pathHeaderMenu = payload;
  }),

  setSideMenuOpen: action((state, payload) => {
    state.sideMenuOpen = payload;
  }),
  setConsultedSales: action((state, payload) => {
    state.consultedSales = payload;
  }),
  setSaleIdLG: action((state, payload) => {
    state.saleIdLG = payload;
  }),
  /** @todo This is for the future : when the sale page is part od react */
  addConsultedSale: action((state, payload) => {
    state.consultedSales.push(payload);
  }),

  setCookieMarketing: action((state, payload) => {
    state.cookieMarketing = payload;
  }),
  setDisclaimer: action((state, payload) => {
    state.disclaimer = payload;
  }),
  setCookieMobileApp: action((state, payload) => {
    state.cookieMobileApp = payload;
  }),

  setMenuMobileBannerHeight: action((state, payload) => {
    state.menuMobileBannerHeight = payload;
  }),

  setWebview: action((state, payload) => {
    state.webview = payload;
  }),

  setShowButtonAvailable: action((state, payload) => {
    state.showButtonAvailable = payload;
  }),

  setCookiePreheaderEmail: action((state, payload) => {
    state.cookiePreheaderEmail = payload
  }),


  setCurrentPage: action((state, payload) => {
    state.currentPage = payload;
  }),


  /** This Thunk initializes the ui, getting some data from the dumb previous
   * implementation - ie : stored in f***king cookie.
   * As soon as we can let's get out of this.
   */
  initUi: thunk(actions => {
    const consultedSales = Cookies.get("sPagesHistoryV2") || "";
    actions.setConsultedSales(consultedSales.split(",").filter(Boolean));
  })
};

export default createContextStore(uiModel, { name: "uiState" });

