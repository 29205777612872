import React from 'react';
import styled from 'styled-components';
import { IconCheck } from 'Ui/Icons';
import { IconeClose } from 'Ui/Icons/icons';

const ResponseContainer = styled.div<{ isError: boolean; showMsg?: boolean }>`
  height: 5.7rem;
  width: 100%;
  background-color: ${(props) => (props.isError ? `#EFC7BA` : `#C5EFBA`)};
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 5px;
  margin: 1rem 0;
  padding: 0 2rem;
`;

const ResponseText = styled.div`
  font-family: 'MontserratMedium';
  font-size: 1.1rem;
  padding-left: 2rem;
`;

const IconContainer = styled.div<{ isError: boolean }>`
  border-radius: 100%;
  background-color: ${(props) => (props.isError ? `#FFE0D7` : `#D9FFCF`)};
  padding: 0.7rem;
  display: flex;
  svg {
    font-size: 7px;
  }
`;

type ResponseProps = {
  text: string;
  isError: boolean;
};

const ResponseToast = ({ text, isError }: ResponseProps) => {
  return (
    <ResponseContainer isError={isError}>
      <IconContainer isError={isError}>
        {isError ? <IconeClose /> : <IconCheck />}
      </IconContainer>
      <ResponseText>{text}</ResponseText>
    </ResponseContainer>
  );
};

export default ResponseToast;
