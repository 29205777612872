import React from 'react';
import styled from 'styled-components';
import { theme } from './theme';
import { IconError } from './Icons';

const StyledFormInfo = styled.div<Props>`
  color: ${(props) => theme.colors[props.kind || 'default']};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: 'Montserrat';
  font-size: 1.2rem;
  margin: 0.8rem 0 0 1rem;
`;

const Message = styled.span<{ alignHelperText?: string }>`
  padding: 0.5rem;
  font-size: 1.2rem;
  font-family: 'Montserrat' !important;
  display: flex;
  align-items: center;
  line-height: 1.5rem;
`;

type Props = React.Props<HTMLDivElement> & {
  message?: string;
  kind?: 'default' | 'primary' | 'success' | 'error' | 'black' | 'purple';
  style?: React.CSSProperties;
  alignHelperText?: string;
};

export const FormInfo = ({
  alignHelperText,
  children,
  ref: _,
  ...rest
}: Props) => {
  return (
    <StyledFormInfo {...rest}>
      {children && (
        <>
          {rest.kind === 'error' && <IconError width="19px" height="19px" />}
          <Message alignHelperText={alignHelperText}>{children}</Message>
        </>
      )}
    </StyledFormInfo>
  );
};
