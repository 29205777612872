import styled from 'styled-components';

const SocialContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 0.4rem;
`;
const SocialTitle = styled.div`
  color: #000;
  font-family: 'granville-bold' !important;
  font-size: 1.6rem;
  font-style: normal;
  line-height: normal;
`;
const SocialContent = styled.div``;

type SocialProps = {
  title: string;
  content: any;
};

export const Social = ({ title, content }: SocialProps) => {
  return (
    <SocialContainer>
      <SocialTitle>{title}</SocialTitle>
      <SocialContent>{content}</SocialContent>
    </SocialContainer>
  );
};
