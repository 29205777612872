import * as React from "react";
const SvgCheckMark = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 10 10"
    {...props}
  >
    <path stroke="#fff" strokeWidth={2} d="M1.4 3.926 4.1 7.48l4.5-6.286" />
  </svg>
);
export default SvgCheckMark;
