import * as React from "react";
const SvgArrow = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="1em"
    height="1em"
    viewBox="0 0 30 12"
    {...props}
  >
    <defs>
      <path
        id="arrow_svg__a"
        d="m21.15.11 8.1 5.4-8.1 5.4V6.19L0 6.192V4.828l21.15-.001z"
      />
    </defs>
    <g fill="none" fillRule="evenodd" transform="matrix(1 0 0 -1 0 11.273)">
      <mask id="arrow_svg__b" fill="#fff">
        <use xlinkHref="#arrow_svg__a" />
      </mask>
      <use xlinkHref="#arrow_svg__a" fill="#0D0C18" />
      <g mask="url(#arrow_svg__b)">
        <path fill="#0D0C18" d="M-8.182-15h167.727v40.909H-8.182z" />
      </g>
    </g>
  </svg>
);
export default SvgArrow;
