import React from 'react';
import styled, { css } from 'styled-components/macro';

interface SelectorProps {
  isActive: boolean;
  children: React.ReactNode;
  onClick: (event: any) => void;
  className?: string;
  whoWeAre?: boolean;
}

const ContentStyled = styled.div<{ margin?: boolean }>`
  margin-right: 3.1em;
`;

const TabStyled = styled.h2<{ isActive: boolean; whoWeAre?: boolean }>`
  color: ${(props) => (props.isActive ? '#000' : '#0D0C18')};
  opacity: ${(props) => (props.isActive ? '1' : '0.6')};
  height: 3.5em;
  font-size: 1.6em;
  font-family: ${(props) =>
    props.isActive
      ? props.whoWeAre
        ? 'Montserrat'
        : 'MontserratBold'
      : 'Montserrat'};
  cursor: pointer;
  :after {
    content: '';
    display: flex;
    width: 0;
    height: 3px;
    background: #000;
    // transition: 0.3s;
    margin: 0;
    margin-top: 1.3rem;
  }
  &:hover:after {
    margin-left: 0;
    transition: width 200ms ease-in-out 0s;
  }

  ${(props) =>
    props.isActive &&
    css`
      &:after {
        width: 100%;
        margin-left: 0;
      }
    `}
`;

export default ({
  isActive,
  children,
  onClick,
  className,
  whoWeAre = false,
}: SelectorProps) => {
  return (
    <ContentStyled>
      <TabStyled
        whoWeAre={whoWeAre}
        onClick={onClick}
        isActive={isActive}
        className={className}
      >
        {children}
      </TabStyled>
    </ContentStyled>
  );
};
