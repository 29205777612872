import React from "react";
import styled from "styled-components/macro";
import { theme } from "./theme";

type Props = {
  path: { url: string; label: string; internal?: boolean }[];
};

const BreadCrumbs = styled.div`
  display: flex;
  height: 3.5rem;
  align-items: center;
`;

const BreadCrumbCrop = styled.div`
  width: 188px;
  @media screen and (min-width: 360px) {
    width: 235px;
  }
  overflow: hidden;
  height: 50px;
  line-height: 50px;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
`;

const BreadCrumb = styled.div`
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  a {
    color: ${theme.colors.primary};
  }
  .separator {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
`;

export const BreadcrumbMobile = ({ path }: Props) => {
  let cropMenu: string = "";
  for (const { step, index } of path.map((step, index) => ({ step, index }))) {
    const lastOne = index >= path.length - 1;
    if (!lastOne) {
      if (index !== 0) {
        cropMenu += `${step.label} - `;
      }
    } else {
      if (index === 0 || index === 1) {
        cropMenu += step.label;
      } else {
        cropMenu += step.label;
      }
    }
  }
  return (
    <BreadCrumbs>
      <BreadCrumbCrop>{cropMenu}</BreadCrumbCrop>
    </BreadCrumbs>
  );
};
