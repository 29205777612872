import { theme } from 'Ui';
import { IconIconeRight } from 'Ui/Icons';
import { Chatbox, To } from 'Ui/Icons/icons';
import styled from 'styled-components';
import BcBackgroundMobile from 'Ui/Icons/svg/BCBackgroundMobile.svg';

export const Header = styled.div`
  /* display: flex;
  align-items: center;
  justify-content: center;
  height: 20%;
  background: var(--text-very-dark-grey, #2f2f2f);
  padding: 0 1.5rem;
  font-size: 1.4rem;
  color: #fff; */
  /* text-transform: capitalize; */
  /* svg {
    width: 34px;
    height: 34px;
    color: ${theme.light.colors.white};
  } */
  background: ${theme.light.colors.purple};
`;

export const WelcomeMember = styled.div`
  font-family: 'MontserratSemiBold';
  font-size: 16px;
  text-align: center;
  /* padding-top: 1.5rem; */
  color: ${theme.light.colors.white};
`;

export const HeaderMaincontainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const HeaderBackgroundMobileConnected = styled.div`
  background-image: url(${BcBackgroundMobile}),
    var(
      --gradient-normal-violet,
      linear-gradient(180deg, #6c4dff 0%, #8268ff 100%)
    );
  background-repeat: no-repeat;
  padding: 3.8rem 3.9rem;
  height: 10.6rem;
`;

export const HeaderLogoContainer = styled.div`
  border: 2px solid ${theme.light.colors.purple};
  border-radius: 100%;
  padding: 1.5rem;
`;

export const Link = styled.a<{
  hasBorder?: boolean;
  hasBorderTop?: boolean;
  hasMarginTop?: boolean;
}>`
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  max-width: 25rem;
  margin: ${(props) =>
    props.hasMarginTop ? '2.4rem 1.6rem 2.4rem' : '0 1.6rem 2.4rem'};
  border-bottom: ${(props) => props.hasBorder && '1px solid #ccc'};
  border-top: ${(props) => props.hasBorderTop && '1px solid #ccc'};
  padding-top: ${(props) => props.hasBorderTop && '1rem'};
  margin-bottom: ${(props) => props.hasBorderTop && '0rem !important'};
  /* height: 40px; */
`;

export const HeaderConnect = styled.div`
  color: #fff;
  font-family: 'MontserratSemiBold' !important;
  font-size: 1.6rem;
  /* padding: 1.9rem 3.9rem; */
  /* border-radius: 8px 8px 0px 0px; */
  background: ${theme.light.colors.purple};
`;

export const HeaderBackgroundMobile = styled.div`
  background-image: url(${BcBackgroundMobile}),
    var(
      --gradient-normal-violet,
      linear-gradient(180deg, #6c4dff 0%, #8268ff 100%)
    );
  background-repeat: no-repeat;
  padding: 2.4rem 3.9rem 0rem;
  height: 11rem;
`;

export const HeaderActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 0 0 2.4rem;
`;

export const ConnectSubscribe = styled.div`
  text-decoration: underline;
  cursor: 'pointer';
`;

type LineProps = {
  icon?: React.ReactNode;
  text?: string;
  src?: string;
  target?: string;
  onClick?: () => void;
  withIcon?: boolean;
  hasBorder?: boolean;
  hasBorderTop?: boolean;
  hasMarginTop?: boolean;
};

export const LinkLine = ({
  icon,
  text,
  src,
  target = '',
  onClick,
  withIcon = true,
  hasBorder = true,
  hasBorderTop = false,
  hasMarginTop = false,
}: LineProps) => {
  return (
    <Link
      hasBorder={hasBorder}
      hasBorderTop={hasBorderTop}
      href={src}
      onClick={onClick}
      target={target}
      hasMarginTop={hasMarginTop}
    >
      <div style={{ width: 40, height: '2.5rem', fontSize: '2.5rem' }}>
        {icon}
      </div>
      <div style={{ flex: 1, lineHeight: '18px' }}>{text}</div>
      {withIcon && <IconIconeRight />}
    </Link>
  );
};

export const NeddHelpContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 30px 34px;
  /* flex-direction: column; */
  align-items: center;
  gap: 29px;
  flex-shrink: 0;
  border-radius: 4px;
  background: var(
    --gradient-light-violet,
    linear-gradient(117deg, #ede9ff 0%, #f7f5ff 100%)
  );
  margin: 1.5rem;
  svg {
  }
`;
export const NeddHelpTitle = styled.div`
  font-family: 'granville-bold';
  font-size: 16px;
  font-style: normal;
  line-height: normal;
`;

export const NeddHelpText = styled.div`
  font-family: 'Montserrat';
  color: var(--principal-color-dark-grey, #4a4a4a);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.24px;
`;
export const NeedHelpArrow = styled.div`
  color: var(--text-black, #000);
  font-family: 'MontserratMedium';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 183.333% */
  padding-left: 5px;
`;

export const LogoContainer = styled.div`
  svg {
    width: 47.179px;
    height: 35.671px;
    flex-shrink: 0;
  }
`;
export const Logo = styled.div`
  display: flex;
  align-items: center;
`;

export const NeedHelp = () => (
  <NeddHelpContainer>
    <LogoContainer>
      <Chatbox />
    </LogoContainer>
    <div>
      <NeddHelpTitle>Besoin d'aide ?</NeddHelpTitle>
      <NeddHelpText>
        Contacter le service client pour toute demande
      </NeddHelpText>
      <Logo>
        <To />
        <NeedHelpArrow>Nous contacter</NeedHelpArrow>
      </Logo>
    </div>
  </NeddHelpContainer>
);
