import React, { useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components/macro';
import { useState } from 'react';
import posed, { PoseGroup } from 'react-pose';

import OutsideClickHandler from 'react-outside-click-handler';
import { HeaderIcon } from '../HeaderIcon';
import { CheckMark } from 'Ui/Icons/icons';
import { theme } from 'Ui';
import AuthStore from 'Store/AuthStore';
import { allowedTypes } from 'Utils/memberTypes';

export const ArrowDropDown = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="12"
    viewBox="0 0 20 12"
    fill="none"
  >
    <path
      d="M1.41422 8.58578L8.58579 1.41421C9.36683 0.633165 10.6332 0.633165 11.4142 1.41421L18.5858 8.58579C19.8457 9.84572 18.9534 12 17.1716 12H2.82843C1.04662 12 0.154286 9.84571 1.41422 8.58578Z"
      fill="#2F2F2F"
    />
  </svg>
);

const Global = createGlobalStyle`
  body {
    // overflow : hidden;
  }
`;

const DropMenuStyled = styled.div`
  display: inline-block;
  position: relative;
  &hover {
    cursor: pointer;
  }
`;

const Trigger = styled.div`
  position: relative;
  font-size: 3.2rem;
`;

const Body = posed(styled.div<{ inletPosition: number; inletColor: string }>`
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%);
  top: 4.5rem;
  z-index: 20;
  font-size: 1.4rem;
  display: flex;
`)({
  enter: { opacity: 1, transition: { duration: 200 } },
  exit: { opacity: 0, transition: { duration: 200 } },
});

const SuccessIcon = styled.div`
  background-color: ${theme.light.colors.purple};
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: 1rem auto; */
  margin-right: 1rem;
  position: absolute;
  bottom: 4px;
  left: 15px;
  svg path {
    stroke: white !important;
  }
  svg {
    font-size: 0.8rem;
  }
`;

type Props = {
  icon?: React.ReactNode;
  iconOpen?: React.ReactNode;
  children?: React.ReactNode;
  label?: String;
  inletPosition?: number;
  inletColor?: string;
  onClose?: () => void;
};

export default ({
  icon,
  iconOpen,
  children,
  label,
  inletPosition,
  inletColor,
  onClose,
}: Props) => {
  const isLoggedIn = AuthStore.useStoreState((state) => state.isLoggedIn);
  const sessionMemberType = AuthStore.useStoreState(
    (state) => state.sessionMemberType
  );
  const [open, setOpen] = useState(false);
  // useEffect(() => {
  //   if (open) window.addEventListener('scroll', () => setOpen(false), true);
  //   if (!open) onClose && onClose();
  //   return () => {
  //     window.removeEventListener('scroll', () => setOpen(false), true);
  //   };
  // }, [open]);
  return (
    <>
      {open && <Global />}
      <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
        <DropMenuStyled>
          <Trigger onClick={() => setOpen(!open)}>
            <HeaderIcon selected={open} cursor="pointer">
              {icon && allowedTypes.includes(sessionMemberType) && (
                <SuccessIcon>
                  <CheckMark />
                </SuccessIcon>
              )}
              {open ? iconOpen || icon : icon}
            </HeaderIcon>
          </Trigger>
          <PoseGroup flipMove={false}>
            {open && (
              <Body
                key={'body'}
                inletColor={inletColor || '#fff'}
                inletPosition={inletPosition || 0}
              >
                {/* <div
                  style={{
                    position: 'absolute',
                    top: '-1rem',
                    left: '9.5rem',
                  }}
                >
                  <ArrowDropDown />
                </div> */}
                <div className="dropV4">{children}</div>
              </Body>
            )}
          </PoseGroup>
        </DropMenuStyled>
      </OutsideClickHandler>
    </>
  );
};
