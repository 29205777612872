import React, { useState, useEffect } from 'react';
import posed from 'react-pose';
import styled, { css } from 'styled-components/macro';
import OutsideClickHandler from 'react-outside-click-handler';
import { Button } from './Button';
import { formatPrice } from 'Utils/tools';

import AuthStore from 'Store/AuthStore';
import showLoginModal from 'Components/modals/Login';
import IconeBottom from './Icons/icons/IconeBottom';

const theme = {
  select: {
    backgroundColor: {
      default: '#f1f2f3',
    },
    borderColor: {
      default: '#999',
    },
  },
};

type StyleType = {
  block?: boolean;
  disabled?: boolean;
  kind?: 'default';
  hollow?: boolean;
  relativeBody?: boolean;
  bodyMaxHeight?: number;
  titleMargin?: boolean;
};

type SelectProps<T> = Omit<React.Props<HTMLDivElement>, 'ref' | 'prefix'> &
  StyleType & {
    options: {
      value: T extends Array<any> ? T[0] : T;
      label: string;
      sellPrice?: string | null;
      disabled?: boolean;
    }[];
    onChange: (value: T) => void;
    placeholder?: string;
    buttonDelete?: boolean;
    value: T;
    style?: React.CSSProperties;
    available?: boolean;
    initOpen?: boolean;
  };

const Head = styled.div<
  StyleType & { opened: boolean; cursor: string; isPaddingCustom: boolean }
>`
  cursor: ${(props) => props.cursor};
  padding: ${(props) => (props.isPaddingCustom ? '0 1rem' : '0 2rem')};
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4.6rem;
  font-family: 'MontserratMedium';

  border: 1px solid
    ${(props) =>
      props.hollow
        ? theme.select.borderColor[props.kind || 'default']
        : theme.select.backgroundColor[props.kind || 'default']};

  ${(props) =>
    !props.hollow &&
    css`
      background-color: ${
        theme.select.backgroundColor[props.kind || 'default']
      };
      &:hover: ${theme.select.backgroundColor[props.kind || 'default']};
      //
    `}
`;

const ButtonDeleteStyled = styled.div`
  display: flex;
  justify-content: center;
`;

const Title = styled.div<{ isMarginCustom: boolean }>`
  margin-right: 5rem;
  font-weight: 'Montserrat';
  ${(props) =>
    props.isMarginCustom &&
    css`
      @media screen and (max-width: 320px) {
        margin-right: 0.5rem;
      }
      @media screen and (min-width: 360px) {
        margin-right: 4rem;
      }
      @media screen and (min-width: 414px) {
        margin-right: 5rem;
      }
    `}
`;

const SelectStyled = styled.div<{ block: boolean }>`
  position: relative;
  display: inline-block;
  font-family: 'Montserrat';
  font-size: 1.4rem;
  /* z-index: 5; */
  ${(props) => props.block && 'width:100%;'}
`;

const Content = styled.div<StyleType>`
  ${(props) =>
    props.bodyMaxHeight &&
    css`
      max-height: ${props.bodyMaxHeight}px;
      overflow: auto;
      //
    `}

  position: ${(props) => (props.relativeBody ? 'relative' : 'absolute')};

  border: 1px solid
    ${(props) =>
      props.hollow
        ? theme.select.borderColor[props.kind || 'default']
        : theme.select.backgroundColor[props.kind || 'default']};

  background-color: ${(props) =>
    props.hollow
      ? '#fff'
      : theme.select.backgroundColor[props.kind || 'default']};

  padding: 0.5rem 0rem;
  width: 100%;
  border-top: none;
  z-index: 101;
  //
`;

const Option = styled.div<StyleType & { selected: Boolean }>`
  display: flex;
  align-items: center;
  height: 3.2rem;
  color: #000;
  font-family: 'Montserrat';
  padding: 0 2rem;
  ${(props) => props.selected && 'color: #6C4DFF'};
  font-weight: ${(props) => (props.selected ? 'bold' : 'normal')};
  &:hover {
    cursor: pointer;
    color: #6c4dff !important;
  }
`;

const OptionUnavailable = styled.div<StyleType>`
  display: flex;
  align-items: center;
  height: 3.2rem;
  padding: 0 2rem;
  color: #ccc;
  cursor: pointer;
`;

const IconChevronDownAnimated = posed.div({
  opened: {
    rotateZ: '180deg',
    transition: { ease: 'easeInOut', duration: 200 },
  },
  closed: { rotateZ: '0deg', transition: { ease: 'easeInOut', duration: 200 } },
});

const SellPrice = styled.div<{ exhausted?: boolean }>`
  display: flex;
  span {
    margin: 0 1rem;
  }
  .exhausted {
    font-style: italic;
  }
  min-width: 50%;

  ${(props) =>
    props.exhausted &&
    css`
      position: relative;
      .lineThrough {
        position: absolute;
        height: 1px;
        width: 100%;
        background-color: #ccc;
        top: 7px;
      }
    `}
`;

const StyledExhausted = styled.div`
  display: flex;
  span {
    margin: 0 1rem;
  }

  .lineThrough {
    position: absolute;
    height: 1px;
    width: 66%;
    background-color: #ccc;
    top: 18px;
  }
`;

export const Select = <T extends {} = string>({
  options,
  onChange,
  value,
  placeholder,
  buttonDelete = false,
  block = false,
  kind = 'default',
  hollow = false,
  disabled = false,
  relativeBody = false,
  bodyMaxHeight,
  available = false,
  initOpen = false,
  titleMargin = false,
  ...rest
}: SelectProps<T>) => {
  const [opened, setOpened] = useState(initOpen);
  const [hideChevron, setHideChevron] = useState(false);
  const [cursor, setCursor] = useState('pointer');
  const isLoggedIn = AuthStore.useStoreState((state) => state.isLoggedIn);

  const onSelect = (selectedValue: any) => {
    if (value instanceof Array) {
      let result = [];
      if (value.includes(selectedValue))
        result = value.filter((v) => v !== selectedValue);
      else result = [...value, selectedValue];
      // @ts-ignore
      onChange(result);
    } else {
      onChange(selectedValue);
      setOpened(false);
    }
  };

  const onClose = () => {
    setOpened(false);
    if (initOpen) {
      setOpened(true);
    } else {
      setOpened(false);
    }
  };

  const deleteValues = () => {
    // @ts-ignore
    onChange((value = []));
  };

  let selectedOption = options.find((option) => option.value === value);
  // const optionLabel =
  //   selectedOption && selectedOption.disabled ? ' - épuisé' : '';

  useEffect(() => {
    if (options.length === 1) {
      setHideChevron(true);
      setCursor('default');
      setOpened(false);
    } else {
      setHideChevron(false);
      setCursor('pointer');
    }
  }, [options]);

  const SellPriceBySize = ({ option }: any) => {
    return (
      <>
        {option.sellPrice ? (
          <SellPrice>
            <p>{option.label}</p>
            <span>-</span>
            <p>{formatPrice(option.sellPrice)}</p>
            {option.disabled && (
              <>
                <span>-</span>
                <p className="exhausted">épuisé</p>
              </>
            )}
          </SellPrice>
        ) : (
          option.label
        )}
      </>
    );
  };

  const ProductExhausted = ({ option }: any) => {
    return (
      <SellPrice exhausted={option.disabled}>
        <p>{option.label}</p>
        <span>-</span>
        {option.sellPrice && (
          <>
            <p className="exhausted">{formatPrice(option.sellPrice)}</p>
            <span>-</span>
          </>
        )}
        <p className="exhausted">épuisé</p>
        <div className="lineThrough"></div>
      </SellPrice>
    );
  };

  return (
    <SelectStyled block={block} {...rest}>
      <OutsideClickHandler onOutsideClick={(e) => onClose()}>
        {hideChevron ? (
          <Head
            isPaddingCustom={titleMargin}
            data-test="select-option"
            opened={opened}
            cursor={cursor}
            hollow={hollow}
          >
            <Title isMarginCustom={titleMargin}>
              {selectedOption ? (
                <SellPriceBySize option={selectedOption} />
              ) : (
                placeholder
              )}
            </Title>
          </Head>
        ) : (
          <Head
            isPaddingCustom={titleMargin}
            data-test="select-option"
            opened={opened}
            cursor={cursor}
            hollow={hollow}
            onClick={() =>
              isLoggedIn ? setOpened(!opened) : showLoginModal({})
            }
          >
            <Title isMarginCustom={titleMargin}>
              {selectedOption ? (
                <SellPriceBySize option={selectedOption} />
              ) : (
                placeholder
              )}
            </Title>
            <IconChevronDownAnimated pose={opened ? 'opened' : 'closed'}>
              <IconeBottom />
            </IconChevronDownAnimated>
          </Head>
        )}

        {opened && (
          <Content
            hollow={hollow}
            bodyMaxHeight={bodyMaxHeight}
            relativeBody={relativeBody}
          >
            {buttonDelete && (
              <ButtonDeleteStyled>
                <Button
                  type="button"
                  kind="primary"
                  onClick={() => deleteValues()}
                  children="Supprimer"
                />
              </ButtonDeleteStyled>
            )}
            {options.map((option, index) => {
              const selected = Array.isArray(value)
                ? value.includes(option.value)
                : option.value === value;
              return (
                <div key={index}>
                  {!available && option.disabled ? (
                    <OptionUnavailable
                      data-test="select-option"
                      key={option.label}
                      onClick={() => onSelect(option.value)}
                      disabled={option.disabled}
                    >
                      <ProductExhausted option={option} />
                    </OptionUnavailable>
                  ) : (
                    <Option
                      data-test="select-option"
                      key={option.label}
                      onClick={() => onSelect(option.value)}
                      selected={selected}
                      disabled={option.disabled}
                    >
                      <SellPriceBySize option={option} />
                    </Option>
                  )}
                </div>
              );
            })}
          </Content>
        )}
      </OutsideClickHandler>
    </SelectStyled>
  );
};
