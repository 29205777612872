import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { CivilityEnum } from '__generated__/graphql-global-types';
import { IconIconeInformations } from 'Ui/Icons';
import { Button, DateInput, FormInfo, Input, RadioGroup, theme } from 'Ui';
import useForm from 'react-hook-form';
import UiStore from 'Store/UiStore';
import PreferenceNewsletter from './PreferenceNewsletter';
import { RegistrationTerms } from './RegistrationTerms';
import InfoBox from './PasswordHelper';
import { InputLogin } from 'Components/Login/InputLogin';
import { useMediaQuery } from 'react-responsive';

const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export type FormDataRegister = {
  firstName: string;
  lastName: string;
  civility: CivilityEnum;
  password: string;
  newslettersDaily: boolean;
  newslettersOpc: boolean;
  email: string;
  birthDate?: string;
};

type RegistrationFormProps = {
  onRegister?: (form: FormDataRegister) => void;
  preFill?: { email?: string; firstName?: string; lastName?: string };
  close?: (shouldGoOn: boolean) => void;
  loading: boolean;
  type?: string;
  currentPage?: string;
};

const StyledIconInfo = styled(IconIconeInformations)`
  font-size: 1.3rem;
  cursor: pointer;
  margin-left: 0.4rem;
  margin-bottom: -2px;
  /* position: relative; */
  bottom: 1.6rem;
  &:hover,
  &:active {
    fill: ${theme.light.colors.purple};
  }
`;

const BirthdayText = styled.div`
  font-family: 'MontserratMedium' !important;
  color: ${theme.v2.text.darkGray};
  padding: 0 0 8px 2px;
  font-size: 1.2rem;
  line-height: 16px;

  @media screen and (min-width: ${theme.breakpoints.medium}px) {
    letter-spacing: 0.36px;
  }
`;

const RadioGroupFlex = styled.div`
  .inline-radio-button {
    justify-content: flex-start;
    /* font-family: 'MontserratSemiBold' !important; */
    /* width: 100%; */
    margin: auto;
    display: flex;
    /* flex: 1 100%; */
    margin-bottom: none;
    .radioGroup {
      display: flex;
      align-items: center;
      margin-right: 2rem;
      /* font-family: 'MontserratSemiBold' !important; */
    }
    .radioGroup:nth-child(1n) {
      margin-bottom: 0px;
    }

    .radioGroup:last-child {
      margin-bottom: 0px;
    }
  }
`;

export default ({
  onRegister,
  preFill,
  loading = false,
  close,
  type,
  currentPage,
}: RegistrationFormProps) => {
  const {
    register,
    setValue,
    handleSubmit,
    errors,
    getValues,
    setError,
    formState,
    triggerValidation,
    watch,
  } = useForm<FormDataRegister>({
    mode: 'onChange',
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
    },
  });

  useEffect(() => {
    if (formState.submitCount > 0) {
      window.scrollTo(0, 0);
    }
  }, [formState]);

  const cookieMarketing = UiStore.useStoreState(
    (state) => state.cookieMarketing
  );

  const [loadingF, setLoadingF] = useState(false);

  const [showDataProtectionModal, setShowDataProtectionModal] = useState(false);
  const [passwordInit, setpasswordInit] = useState(false);
  const [gender, setGender] = useState('');
  const [daily, setDaily] = useState(false);
  const [opc, setOpc] = useState(false);

  const onSubmit = handleSubmit((formData: FormDataRegister, e: any) => {
    e.preventDefault();
    onRegister && onRegister(formData);
  });

  const values = getValues();

  useEffect(() => {
    /** Custom fields */
    register({ name: 'civility' }, { required: 'Champ obligatoire' });
    register({ name: 'birthDate' });
    register({ name: 'newslettersOpc' });
    register({ name: 'newslettersDaily' });
    /** Enter pre-filling values on proper fields */
    if (preFill) {
      // @ts-ignore
      // Object.keys(preFill).map((key) => setValue(key, preFill[key]));
    }
  }, []);

  const setDailyNews = (value: boolean) => {
    setValue('newslettersDaily', value);
    triggerValidation({ name: 'newslettersDaily' });
  };

  const genderCivility = (value: CivilityEnum) => {
    setValue('civility', value);
    triggerValidation({ name: 'civility' });
  };

  const setDailyOpcsNews = (value: boolean) => {
    setValue('newslettersOpc', value);
    triggerValidation({ name: 'newslettersOpc' });
  };

  const validating = (field: (typeof formState.touched)[0]) => {
    if (!field) return 'default';
    const typesErors = errors as { [index: string]: any };
    return typesErors[field]
      ? 'error'
      : formState.touched.includes(field)
      ? 'success'
      : 'default';
  };

  const validatingCivility = (field: (typeof formState.touched)[0]) => {
    if (!field) return 'default';
    const typesErors = errors as { [index: string]: any };
    return typesErors[field]
      ? 'error'
      : formState.touched.includes(field)
      ? 'default'
      : 'default';
  };

  const styles = { marginBottom: '1.6rem' };
  const options = [
    {
      value: CivilityEnum.MME,
      label: 'Femme',
    },
    {
      value: CivilityEnum.MR,
      label: 'Homme',
    },
  ];

  const placeHolderMandatory = (placeHolder: string) => (
    <div>
      {placeHolder}
      <sup>*</sup>
    </div>
  );

  const [hideOnMobile, setHideOnMobile] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  useEffect(() => {
    setHideOnMobile(isMobile);
  }, [isMobile]);
  if (!!currentPage) {
    window.sessionStorage.setItem('sign_up_a', 'sign_up_a');
  }
  return (
    <form
      onKeyDown={(e: any) => {
        if (hideOnMobile) {
          if (e.key === 'Return' || e.key === 'Retour') {
            e.preventDefault();
          }
        }
      }}
      style={{ marginTop: '1rem' }}
      onSubmit={onSubmit}
      noValidate
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          margin: '2.4rem 0 1.6rem 0',
        }}
      >
        <div
          style={{
            fontFamily: 'Montserrat !important',
            color: `${theme.v2.text.darkGray}`,
            marginRight: '2rem',
            fontSize: '1.4rem',
          }}
          className="fontMontserrat"
        >
          Vous êtes<sup>*</sup>
        </div>
        <>
          <RadioGroupFlex>
            <RadioGroup
              radioHeight={1.8}
              radioWidth={1.8}
              radioHeightChecked={1}
              radioWidthChecked={1}
              options={options}
              top={2}
              left={1.9}
              value={gender}
              kind={
                gender === 'MME' || 'MR'
                  ? 'purple'
                  : validatingCivility('civility')
              }
              onChange={(gender) => {
                setGender(gender);
                genderCivility(gender);
              }}
            />
          </RadioGroupFlex>
        </>
      </div>
      {errors.civility && (
        <FormInfo kind={'error'} style={styles}>
          {errors.civility && errors.civility.message}
        </FormInfo>
      )}
      <div style={{ marginBottom: '1.6rem' }}>
        <InputLogin
          block
          type="text"
          className="fontMontserrat"
          kind={validating('firstName')}
          name="firstName"
          label={placeHolderMandatory('Prénom')}
          ref={register({ required: 'Champ obligatoire' })}
          onKeyDown={(e: any) => {
            if (hideOnMobile) {
              if (e.key === 'Return' || e.key === 'Retour') {
                e.preventDefault();
              }
            }
          }}
          helperText={errors.firstName && errors.firstName.message}
          style={styles}
        />
      </div>
      <div style={{ marginBottom: '1.6rem' }}>
        <InputLogin
          label={placeHolderMandatory('Nom')}
          type="text"
          block
          name="lastName"
          className="fontMontserrat"
          kind={validating('lastName')}
          helperText={errors.lastName && errors.lastName.message}
          ref={register({ required: 'Champ obligatoire' })}
          style={styles}
          onKeyDown={(e: any) => {
            if (hideOnMobile) {
              if (e.key === 'Return' || e.key === 'Retour') {
                e.preventDefault();
              }
            }
          }}
        />
      </div>
      <div style={{ marginBottom: '1.6rem' }}>
        <InputLogin
          block
          type="email"
          name="email"
          className="fontMontserrat"
          label={placeHolderMandatory('E-mail')}
          kind={validating('email')}
          ref={register({
            required: 'Champ obligatoire',
            pattern: {
              value: emailRegex,
              message: "L'adresse e-mail n'est pas valide",
            },
          })}
          onKeyDown={(e: any) => {
            if (hideOnMobile) {
              (e.key === 'Return' || e.key === 'Retour') && e.preventDefault();
            }
          }}
          helperText={errors.email && errors.email.message}
          style={styles}
          autoComplete="off"
        />
      </div>
      <div style={{ marginBottom: '1.6rem' }}>
        <Input
          block
          type="password"
          name="password"
          className="fontMontserrat"
          placeholder={placeHolderMandatory('Mot de passe')}
          kind={validating('password')}
          helperText={errors.password && errors.password.message}
          value={watch('password')}
          style={styles}
          onFocus={() => {
            setError('password', 'notMatch', '');
            setpasswordInit(true);
          }}
          ref={register({
            required: 'Champ obligatoire',
            pattern: {
              value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,50})/g,
              message: 'Veuillez saisir un format de mot de passe valide',
            },
          })}
          onKeyDown={(e: any) => {
            if (hideOnMobile) {
              (e.key === 'Return' || e.key === 'Retour') && e.preventDefault();
            }
          }}
        />
      </div>
      {passwordInit && (
        <InfoBox
          kind={validating('password')}
          password={values.password}
        ></InfoBox>
      )}
      <BirthdayText className="fontMontserratMedium">
        BazarChic vous réserve une attention particulière pour votre
        anniversaire.
      </BirthdayText>
      <DateInput
        block
        className="fontMontserrat"
        placeholder="Date d'anniversaire"
        style={styles}
        datePickerProps={{
          initialView: 'year',
          initialDate: getValues().birthDate || dayjs().add(-16, 'y').toDate(),
          minDate: dayjs().add(-90, 'y').toDate(),
          maxDate: dayjs().add(-16, 'y').toDate(),
        }}
        // value={watch('birthDate')}
        kind={!!values.birthDate ? 'success' : 'default'}
        onChange={(e) => setValue('birthDate', dayjs(e).format('YYYY-MM-DD'))}
      />
      <div style={{ display: 'flex' }} className="fontMontserrat"></div>
      <PreferenceNewsletter
        dailyChecked={daily}
        onChangeDaily={() => {
          setDaily(!daily);
          setDailyNews(!daily);
        }}
        opcChecked={opc}
        onChangeOpc={() => {
          setOpc(!opc);
          setDailyOpcsNews(!opc);
        }}
      />
      <RegistrationTerms />
      <div
        style={{
          fontFamily: 'MontserratMedium !important',
          color: theme.v2.text.lightGray,
          margin: '16px 0 8px 0',
          textAlign: 'center',
          lineHeight: '1.4rem',
          fontSize: '1rem',
        }}
        className="fontMontserratMedium"
      >
        Champs obligatoires<sup>*</sup>
      </div>

      <Button
        style={{
          marginTop: '1rem',
          fontFamily: 'MontserratSemiBold',
          fontSize: '1.4rem',
          display: 'flex',
          textTransform: 'uppercase',
          justifyContent: 'center',
          margin: isMobile ? '0' : 'auto',
          width: isMobile ? '100%' : 'fit-content',
        }}
        type="submit"
        kind="primary"
        loading={loading}
      >
        créer un compte
      </Button>
    </form>
  );
};
