import React from 'react';
import styled from 'styled-components/macro';
import { Button } from 'Ui';
import notFound from 'Assets/images/notFound.jpg';
import showLoginModal from 'Components/modals/LoginRegister';
import AuthStore from 'Store/AuthStore';
import Media from 'react-media';

const ContainerNofFound = styled.div`
  display: flex;
  height: 100vh;
  background-image: url(${notFound});
  background-repeat-y: no-repeat;
  background-size: contain;
  padding: 8.5rem 2rem 0rem 2rem;
  justify-content: center;

  @media screen and (min-width: 375px) {
    height: 100%;
    padding: 15rem 3rem 9.9rem 3rem;
  }

  @media screen and (min-width: 414px) and (max-width: 480px) {
    height: 100vh;
    padding-top: 21rem 3rem 0rem 3rem;
  }

  @media screen and (min-width: 768px) {
    height: unset;
    align-items: center;
    background-image: none;
    max-width: 96.5rem;
    margin: 0 auto;
    padding: 0;
    background-repeat-y: unset;
    background-size: unset;
  }
`;

const Title = styled.div`
  font-family: 'granville-bold';
  font-weight: 700;
  font-size: 2.4rem;
  margin-bottom: 2rem;
  color: #000000;
  text-align: center;
  @media screen and (min-width: 360px) {
    padding: 0rem 2rem;
  }
  @media screen and (min-width: 375px) {
    padding: 0rem 1rem;
  }
  @media screen and (min-width: 414px) {
    padding: 0rem 3rem;
  }

  @media screen and (min-width: 768px) {
    font-size: 3.6rem;
    padding: 0 2.5rem;
  }
`;

const SubTitle = styled.div`
  /* font-family: 'granville-bold'; */
  font-weight: 400;
  font-size: 1.4rem;
  color: #000000;
  text-align: center;
  margin: 1rem 0rem 3rem 0rem;
  @media screen and (min-width: 768px) {
    margin: 2rem 0rem 5.9rem 0rem;
  }
`;

const InformationStyeld = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  margin: 0 auto auto auto;
  padding: 1.5rem 2rem 0rem 2rem;
  width: 100%;

  @media screen and (min-width: 768px) {
    margin: 0;
    padding: 0 2rem;
    width: 47.7rem;
  }
`;

const PictureStyled = styled.div`
  width: 48.3rem;
  img {
    max-width: 100%;
  }
`;

const ContentButton = styled.div`
  display: flex;
  flex-direction: column;
  width: 22rem;
  margin: auto;
`;

export default () => {
  const isLoggedIn = AuthStore.useStoreState((state) => state.isLoggedIn);
  const redirectTo = () => {
    window.location.href = '/';
  };
  // const subtitle = isLoggedIn
  //   ? 'Découvrir les plus belles offres du moment'
  //   : 'Connectez-vous pour découvrir les plus belles offres du moment';
  return (
    <ContainerNofFound>
      <InformationStyeld>
        <Title className="notFoundTitle">
          Nous sommes navrés mais la page n’existe plus
        </Title>
        {/* <SubTitle>{subtitle}</SubTitle> */}
        <ContentButton>
          {isLoggedIn ? (
            <Button
              style={{ marginBottom: '3rem' }}
              onClick={() => redirectTo()}
              kind="primary"
            >
              Retour à l'accueil
            </Button>
          ) : (
            <>
              <Button
                style={{ marginBottom: '3rem' }}
                onClick={() => {
                  showLoginModal({ isRegister: false });
                }}
                kind="primary"
              >
                Je m'inscris
              </Button>
              <Button
                style={{ marginBottom: '3rem' }}
                onClick={() => {
                  showLoginModal({ isRegister: true });
                }}
                kind="black"
                hollow
              >
                Je me connecte
              </Button>
            </>
          )}
        </ContentButton>
      </InformationStyeld>
      <Media query={{ minWidth: 768 }}>
        <PictureStyled>
          <img src={notFound} alt="notFound" />
        </PictureStyled>
      </Media>
    </ContainerNofFound>
  );
};
