import styled from 'styled-components';
import { SocialApp } from './SocialGroup/SocialApp';
import { SocialGL } from './SocialGroup/SocialGL';
import { SocialNetworks } from './SocialGroup/SocialNetworks';
import { Ratings } from './SocialGroup/Ratings';

const SocialContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 12.5rem;
  @media screen and (max-width: 48rem) {
    flex-direction: column;
    justify-content: center;
    width: calc(96.5% - 2rem);
    align-items: center;
    gap: 2.9rem;
  }
  @media screen and (min-width: 968px) and (max-width: 1024px) {
    gap: 8rem;
    width: calc(96.5% - 2rem);
    flex-direction: row;
    /* justify-content: space-around; */
  }
  @media screen and (min-width: 768px) and (max-width: 967px) {
    gap: 4rem;
    width: calc(96.5% - 2rem);
    flex-direction: row;
    /* justify-content: space-around; */
  }
`;

export const Socials = () => {
  return (
    <SocialContainer>
      <SocialGL />
      <Ratings />
      <SocialNetworks />
      <SocialApp />
    </SocialContainer>
  );
};
