import React, { useState } from 'react';
import posed from 'react-pose';
import styled, { css } from 'styled-components/macro';
import { theme } from 'Ui';

const color = theme.light.colors;
const ContainerInformationCard = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ContainerChildren = styled.div``;

const Title = styled.div`
  display: flex;
  color: ${color.grayUltraDark};
  font-family: 'Inter';
  font-size: 1.4rem;
  font-weight: 700;
  text-transform: uppercase;
`;

type InformationCard = {
  title?: React.ReactNode;
  children: React.ReactNode;
};

export const InformationCard = ({ title, children }: InformationCard) => {
  return (
    <ContainerInformationCard>
      <Title>{title}</Title>
      <ContainerChildren>{children}</ContainerChildren>
    </ContainerInformationCard>
  );
};
