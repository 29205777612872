import { FormInfo, theme } from 'Ui';
import { IconEyeClosed, IconIcoEye } from 'Ui/Icons';
import GenericType from 'Ui/types/GenericType';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';

interface LoginInput
  extends Pick<GenericType, 'block' | 'kind'>,
    Omit<React.HTMLProps<HTMLInputElement>, 'kind'> {
  type?: string;
  dataTest?: string;
  label?: any;
  name?: string;
  value?: string;
  onChange?: any;
  autoComplete?: string;
  helperText?: string | boolean;
  alignHelperText?: string;
  isLoginShadow?: boolean;
  ref?: any;
}

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const InputContainer = styled.div<LoginInput>`
  font-family: 'Montserrat';
  background-color: #ffffff;
  border-radius: 10px !important;
  background: transparent;
  border-radius: 10px !important;
  width: 100%;
  margin-right: 2.5rem;
  border: 1px solid #969696;

  &:hover {
    border-color: #222;
  }
  ${(props) => {
    if (!props.kind || props.kind === 'primary') return null;
    const color = theme.light.colors[props.kind];
    return css`
      &,
      &:hover {
        border-color: ${color};
      }
    `;
  }}
  ${(props) =>
    props.isLoginShadow &&
    css`
      box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 50%);
    `}


  .styledInput {
    display: flex;
    justify-content: space-between;
    background-color: #ffffff;
    border-radius: 10px;
    width: 100%;
  }
  .input-container input {
    height: 44px !important;
    background-color: #ffffff;
    border-radius: 10px !important;
    border: none !important;
    padding: 16px 0px 2px 16px;
    font-size: 14px;
    line-height: 1;
    outline: none;
    box-shadow: none;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    border-radius: 10px !important;
    width: 100%;
  }

  input:-webkit-autofill {
    -webkit-border-radius: 10px 10px 10px 10px !important;
    -webkit-box-shadow: 0 0 0 50px white inset;
    -webkit-text-fill-color: #333;
    border-radius: 10px !important;
  }
  input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 50px white inset;
    -webkit-text-fill-color: #333;
    border-radius: 10px !important;
  }
  .input-container input:focus {
    box-shadow: 0 0 0 2px #ffffff;
    -webkit-box-shadow: 0 0 0 50px white inset;
  }
  .input-container .filled {
    transform: translate(0, 15px) scale(0.8);
  }
  .input-container label {
    position: absolute;
    font-family: 'Montserrat' !important;
    color: ${theme.v2.text.gray};
    font-size: 1.4rem;
    pointer-events: none;
    transform: translate(0, 15px) scale(1);
    transform-origin: top left;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    letter-spacing: 0.42px;

    /* font-size: 11px; */
    line-height: 1;
    left: 16px;
  }
  .input-container label .filled {
    font-weight: 400;
    font-family: 'Montserrat' !important;
  }
  .input-container {
    position: relative !important;
    display: flex !important;
    flex-direction: row !important;
    border-radius: 10px !important;
    justify-content: space-between;
    width: 100%;
  }
  .input-container:focus-within label {
    transform: translate(0, 9px) scale(0.8);
    color: #848484;
  }
  input:-internal-autofill-selected {
    background-color: #ffffff !important;
  }
  .input-container:focus-within {
    background-color: #ffffff;
  }
  .input-container input:not(:placeholder-shown) ~ label {
    transform: translate(0, 9px) scale(0.8);
    color: #848484;
  }
  .input-container input:-webkit-autofill ~ label {
    transform: translate(0, 9px) scale(0.8);
    color: #848484;
  }
`;

const RightIcon = styled.div<{ kindError?: boolean }>`
  width: ${(props) => (props.kindError ? '4rem' : '5rem')};
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  /* right: 4.5rem; */
  svg {
    width: ${(props) => (props.kindError ? '2rem' : '4rem')};
    height: 100%;
    font-size: 5px;
    padding-bottom: 1px;
  }
`;

export const InputLogin = React.forwardRef(
  (
    {
      type = 'text',
      dataTest,
      block = false,
      kind = 'default',
      placeholder = '',
      isLoginShadow = false,
      onFocus,
      // initialValue,
      defaultValue,
      onChange,
      onBlur,
      name,
      autoComplete,
      pattern,
      label,
      // ref: oldRef,
      value,
      helperText,
      style,
      readOnly,
      alignHelperText,
      ...rest
    }: LoginInput,
    ref: any
  ) => {
    const [RevealPassword, setRevealPassword] = useState(false);
    let currentType = type;
    if (type === 'password')
      if (RevealPassword) currentType = 'text';
      else currentType = 'password';

    return (
      <InputWrapper>
        <InputContainer isLoginShadow={isLoginShadow} kind={kind} block={block}>
          <div className="styledInput">
            <div className="input-container">
              {/* <div> */}
              <input
                data-test={dataTest}
                placeholder=" "
                autoComplete={autoComplete}
                className="tt"
                name={name}
                type={currentType}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                pattern={pattern}
                ref={ref}
              />
              <label className={value && 'filled fontMontserrat'}>
                {label}
              </label>
              {/* </div> */}
              {type === 'password' && (
                <div style={{ display: 'flex' }}>
                  <RightIcon
                    onClick={() => setRevealPassword(!RevealPassword)}
                    style={{
                      opacity: 1,
                      fontSize: '2rem',
                      cursor: 'pointer',
                    }}
                  >
                    {RevealPassword ? (
                      <span
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: '24px',
                        }}
                      >
                        <IconIcoEye />
                      </span>
                    ) : (
                      <span
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: '24px',
                        }}
                      >
                        <IconEyeClosed />
                      </span>
                    )}
                  </RightIcon>
                </div>
              )}
              {kind === 'success' && (
                <div style={{ color: theme.colors.success }}></div>
                // <RightIcon style={{ color: theme.colors.success }}>
                // <IconCheck />
                // </RightIcon>
              )}
            </div>
          </div>
        </InputContainer>
        {helperText && (
          <FormInfo alignHelperText={alignHelperText} kind={kind}>
            {helperText}
          </FormInfo>
        )}
      </InputWrapper>
    );
  }
);
