import React, { useState } from 'react';
import styled, { css } from 'styled-components/macro';

import TabSelector from 'Components/Tabs/TabSelector';
import TabPanel from 'Components/Tabs/TabPanel';

const Tabs = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* margin: 3rem 0 0rem 15em; */
  .tab {
    margin: auto;
    text-align: center;
  }
  @media screen and (max-width: 480px) {
    margin: 3rem 0 1rem 12rem;
    .tab {
      font-size: 1.4rem;
    }
  }
`;

type TabsProps = {
  datas: {
    title: string;
    desc: any;
  }[];
};

export default ({ datas }: TabsProps) => {
  const [selected, setSelected] = useState(1);
  return (
    <>
      <Tabs>
        {datas.map((tab, index) => {
          const id = index + 1;
          return (
            <TabSelector
              whoWeAre={true}
              key={id}
              isActive={selected === id}
              onClick={() => setSelected(id)}
              className="tab"
            >
              {tab.title}
            </TabSelector>
          );
        })}
      </Tabs>

      {datas.map((tab, index) => {
        const id = index + 1;
        return (
          <TabPanel paddingTop={0} isActive={selected === id} key={id}>
            {tab.desc}
          </TabPanel>
        );
      })}
    </>
  );
};
