import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { toast } from 'react-toastify';
import { Modal, theme } from 'Ui';
import { createModal } from 'react-modal-promise';
import { ResetPasswordIntruction } from './ResetPasswordInstructions';
import { ResetPasswordForm } from './ResetPasswordForm';
import { useApolloClient } from '@apollo/client';
import { FORGOT_PASSWORD } from 'Pages/ForgotPassword/query';
import {
  sendResetPasswordLink,
  sendResetPasswordLinkVariables,
} from 'Pages/ForgotPassword/__generated__/sendResetPasswordLink';
import { isMobile } from 'react-device-detect';
import { getModalEventName } from 'Libs/GA4/common';
import UiStore from 'Store/UiStore';

type ModalProps = {
  open: boolean;
  close: (shouldGoOn: boolean) => void;
};

const Body = styled.div`
  width: 50rem;
  display: flex;
  flex-direction: column;
  margin-left: 3rem;
  padding: 2rem 1rem;
  @media screen and (max-width: 48rem) {
    margin-left: 1rem;
  }
`;

const ModalTitle = styled.div`
  display: flex;
  font-size: 2rem;
  font-family: 'MontserratMedium' !important;
  justify-content: center;
  text-align: center;
  padding-bottom: 0.8rem;
  color: ${theme.v2.text.black};
  margin: 3.6rem auto 0;
  @media screen and (min-width: ${theme.breakpoints.medium}px) {
    font-size: 2.4rem;
    justify-content: start;
  }
`;

const ModalParagraph = styled.div`
  display: flex;
  font-size: 1.4rem;
  font-family: 'MontserratMedium' !important;
  color: ${theme.v2.text.lightGray};
  margin-bottom: 1.6rem;
  line-height: 21px;
  text-align: center;
  justify-content: center;
  @media screen and (min-width: ${theme.breakpoints.medium}px) {
    font-size: 1.4rem;
    /* justify-content: start; */
  }
`;

export type ForgotFormData = {
  email: string;
};

const ForgotPasswordModal = ({ open, close }: ModalProps) => {
  const cookieMarketingStore = UiStore.useStoreState(
    (state) => state.cookieMarketing
  );
  const emailFromBuglogin: any = sessionStorage.getItem('bugLogin');

  const emailParsed = JSON.parse(emailFromBuglogin);

  const [loading, setLoading] = useState(false);
  const [resetInstruction, setResetInstruction] = useState(false);
  const [error, setError] = useState(false);

  const [emailSend, setEmailSend] = useState('');
  const client = useApolloClient();
  useEffect(() => {
    if (cookieMarketingStore) getModalEventName('forgot_password_request');
  }, []);
  const onSubmit = async (email: string) => {
    setLoading(true);
    client
      .query<sendResetPasswordLink, sendResetPasswordLinkVariables>({
        query: FORGOT_PASSWORD,
        variables: {
          email,
        },
      })
      .then((response) => {
        if (cookieMarketingStore) getModalEventName('forgot_password_sucess');
        setResetInstruction(response.data?.sendResetPasswordLink);
        setEmailSend(email);
        setLoading(false);
      })
      .catch((err) => {
        const graphQLErrors =
          err &&
          err.graphQLErrors &&
          err.graphQLErrors[0].extensions.bzcMessage;

        const graphQLErrorCode =
          err && err.graphQLErrors && err.graphQLErrors[0].extensions.bzcCode;

        if (graphQLErrorCode !== 'ERROR_SERVER_GENERIC') {
          setError(graphQLErrors);
        }

        if (graphQLErrorCode === 'ERROR_UNAUTHORIZED') {
          setError(graphQLErrors);
          setLoading(false);
          toast.error('Requête non autorisée');
        }
      });
  };

  return (
    <Modal fullHeight={isMobile} isOpen={open} onClose={() => close(false)}>
      <Body>
        <ModalTitle>Vous avez oublié votre mot de passe ?</ModalTitle>
        <>
          {!!resetInstruction ? (
            ResetPasswordIntruction()
          ) : (
            <>
              <ModalParagraph>
                Saisissez votre adresse de connexion pour recevoir un mail de
                réinitialisation de mot de passe :
              </ModalParagraph>
              <ResetPasswordForm
                onSubmitForgotPassword={onSubmit}
                emailFromModal={undefined}
                close={close}
                loading={false}
                type="modal"
              />
            </>
          )}
        </>
      </Body>
    </Modal>
  );
};

export default createModal(ForgotPasswordModal);
