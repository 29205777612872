import AuthStore from 'Store/AuthStore';
import { isAndroid } from 'react-device-detect';
import styled from 'styled-components';
import { Header, HeaderBackground, LinkLine, Separator } from './styles';
import { IconFaqs, IconMyPref, IconPrivilege } from 'Ui/Icons';
import { InfoV2 } from 'Ui/Icons/icons';
import showLoginModal from 'Components/modals/LoginRegister';

const HeaderActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

const ConnectSubscribe = styled.div`
  text-decoration: underline;
  cursor: pointer;
`;
const MenuContainer = styled.div`
  padding: 2.4rem 3.1rem 0.5rem;
  /* border-bottom: 1px solid #dedcdc;
  border-right: 1px solid #dedcdc;
  border-left: 1px solid #dedcdc; */
  border-radius: 0 0 8px 8px;
`;
export const DropDownAnonymous = () => {
  const isLoggedIn = AuthStore.useStoreState((state) => state.isLoggedIn);
  const linkDownload = isAndroid
    ? '/home/app/p/android/source/interstitiel/'
    : '/home/app/p/ios/source/interstitiel/';

  return (
    <>
      <Header>
        <HeaderBackground>
          <HeaderActions>
            <ConnectSubscribe
              onClick={() => showLoginModal({ isRegister: false })}
            >
              S'inscrire{' '}
            </ConnectSubscribe>
            <Separator />
            <ConnectSubscribe
              onClick={() => showLoginModal({ isRegister: true })}
            >
              Se connecter
            </ConnectSubscribe>
          </HeaderActions>
        </HeaderBackground>
      </Header>
      <MenuContainer>
        <LinkLine
          text="Aide & contact"
          src="/contact/"
          className="aide"
          icon={<IconFaqs width={24} heigh={24} />}
          withIcon={false}
        />
        <LinkLine
          text="Les applis mobile BazarChic"
          src="/home/mobilite/"
          icon={<IconMyPref width={24} height={24} />}
          withIcon={false}
        />
        {/* <LinkLine
          withIcon={false}
          text="Club privilège"
          src="/accueil/privilege/"
          icon={<IconPrivilege width={24} heigh={24} />}
        /> */}
      </MenuContainer>
    </>
  );
};
