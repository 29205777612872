import React from 'react';
import RegistrationModal from 'Components/modals/RegistrationModal';

export default () => {
  const currenSale = document.querySelector('.home_sells');
  currenSale.addEventListener('click', function () {
    RegistrationModal({});
  });
  return <div></div>;
};
