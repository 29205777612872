import { SearchCard } from 'Components/SearchCard/Product';
import {
  SearchProducts_products,
  SearchProducts_products_edges,
} from 'Pages/Search/Products/__generated__/SearchProducts';

type SearchProductProps = {
  products: SearchProducts_products | null;
};

export const SearchProduct = ({ products }: SearchProductProps) => {
  return (
    <>
      {products &&
        products.edges &&
        products.edges.map((p: SearchProducts_products_edges) => {
          const product = p.node;
          return (
            <div style={{ marginBottom: '1.6rem' }}>
              <SearchCard product={product!!} />
            </div>
          );
        })}
    </>
  );
};
