import * as React from "react";
const SvgCreateurs = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 66 66"
    {...props}
  >
    <path
      fill="currentColor"
      d="M60.544 43.978 42.051 33.05a6.1 6.1 0 0 1-2.115-2.1l-.858.515.837-.549a8.1 8.1 0 0 0-5.892-3.847 3.7 3.7 0 0 1 1.377-2.708l1.451-1.161A5.728 5.728 0 0 0 33.272 13h-.544A5.734 5.734 0 0 0 27 18.728V21h2v-2.272A3.73 3.73 0 0 1 32.728 15h.544a3.73 3.73 0 0 1 2.33 6.639L34.149 22.8a5.7 5.7 0 0 0-2.137 4.265 8.14 8.14 0 0 0-5.948 3.884 6.1 6.1 0 0 1-2.115 2.1L5.456 43.978A5.02 5.02 0 0 0 3 48.282V52a1 1 0 0 0 1.506.862L7.683 51h50.634l3.177 1.862A1 1 0 0 0 63 52v-3.718a5.02 5.02 0 0 0-2.456-4.304M11.1 49 33 36.159 54.905 49zM61 50.255l-2-1.173V49h-.141L33.506 34.138a1 1 0 0 0-1.012 0L7.141 49H7v.082l-2 1.173v-1.973A3.01 3.01 0 0 1 6.474 45.7l18.493-10.928a8.1 8.1 0 0 0 2.833-2.828 6.064 6.064 0 0 1 10.4 0l.021.035a8.1 8.1 0 0 0 2.812 2.793L59.526 45.7A3.01 3.01 0 0 1 61 48.282z"
    />
  </svg>
);
export default SvgCreateurs;
