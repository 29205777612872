import React, { useState } from 'react';
import { useApolloClient } from '@apollo/client';
import ModifyPassword from './ModifyPassword';
import { toast } from 'Components/Toaster';
import { updatePasswordQuery } from './query';
import {
  updatePassword,
  updatePasswordVariables,
} from './__generated__/updatePassword';

import { errorMessages } from 'Utils/errorMessages';
import errorMessageModel from './store/';

export default () => {
  const [loading, setLoading] = useState(false);
  const [reset, setReset] = useState(false);
  const apolloClient = useApolloClient();

  const messageError = errorMessageModel.useStoreActions(
    (action) => action.setMessageOldPassword
  );

  const modifyPasswordMember = async (form: any) => {
    setLoading(true);
    const member = form;
    try {
      await apolloClient.mutate<updatePassword, updatePasswordVariables>({
        mutation: updatePasswordQuery,
        variables: {
          password: member.password,
          newPassword: member.newPassword,
        },
      });

      toast.success('Votre mot de passe a été modifié avec succès');
      setReset(true);
      setTimeout(function () {
        setReset(false);
      }, 200);
    } catch (e: any) {
      setReset(false);
      if (e.graphQLErrors) {
        if (e[0].extensions.bzcCode === 'ERROR_INVALID_OLD_PASSWORD_GENERIC') {
          const bzcCode = e.graphQLErrors[0].extensions.bzcCode;
          messageError(errorMessages[bzcCode]);
        }
      }
    }

    setLoading(false);
  };

  return (
    <ModifyPassword
      loading={loading}
      onModifyPassword={modifyPasswordMember}
      onReset={reset}
    />
  );
};
