import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Button, theme } from 'Ui';
import { isMobile } from 'react-device-detect';
import { borderRadius } from 'polished';
import UiStore from 'Store/UiStore';
import {
  ApplicationEnum,
  ModuleEnum,
} from '__generated__/graphql-global-types';
import { IconeRight } from 'Ui/Icons/icons';
import { registerSuspect } from 'Utils/registerSuspect';
import { useApolloClient } from '@apollo/client';
import AuthStore from 'Store/AuthStore';
import { toast } from 'react-toastify';

interface GenericType {
  /** add a style to the button */
  kind?: 'default' | 'primary' | 'success' | 'error' | 'black' | 'purple';
  /** internal sytle without background with a border  */
  hollow?: boolean;
  /** the element take all the space  */
  block?: boolean;
  /** the element is not usable */
  disabled?: boolean;
}

export type FormData = {
  email: string;
  password: string;
};
interface Props
  extends Pick<GenericType, 'block' | 'kind'>,
    Omit<React.HTMLProps<HTMLInputElement>, 'kind'> {
  placeholder?: string;
  label?: string;
  className?: string;
  style?: React.CSSProperties;
  helperText?: string | boolean;
  alignHelperText?: string;
  initialValue?: string;
  readOnly?: boolean;
  errorMsg?: boolean;
  onClick?: any;
  isLogin?: boolean;
  isRadius?: boolean;
}

type InsideProps = {
  focus?: boolean;
  open?: boolean;
};

const InputContainer = styled.div<{ isRadius?: boolean }>`
  flex: 1;
  position: relative;
  padding-right: 10px;
  height: 100%;
  display: flex;
  background-color: transparent;
  &:last-child {
    justify-content: flex-end;
  }
  font-family: 'Montserrat' !important;
  ${(props) =>
    props.isRadius &&
    css`
      background-color: #f9f9f9;
    `}
  background-color: #ffffff;
  border-radius: 10px !important;
  background: transparent;
  border-radius: 10px !important;
  width: 100%;

  &:hover {
    border-color: #222;
  }

  .styledInput {
    display: flex;
    justify-content: space-between;
    background-color: #ffffff;
    ${(props) =>
      props.isRadius &&
      css`
        background-color: #f9f9f9;
      `}
    border-radius: 10px;
    width: 100%;
  }
  .input-container input {
    height: 44px !important;
    background-color: #ffffff;
    ${(props) =>
      props.isRadius &&
      css`
        background-color: #f9f9f9;
      `}
    border-radius: 10px !important;
    border: none !important;
    padding: 16px 0px 2px 16px;
    font-size: 14px;
    line-height: 1;
    outline: none;
    box-shadow: none;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    border-radius: 10px !important;
    width: 100%;
  }

  input:-webkit-autofill {
    -webkit-border-radius: 10px 10px 10px 10px !important;
    -webkit-box-shadow: 0 0 0 50px white inset;
    -webkit-text-fill-color: #333;
    border-radius: 10px !important;
  }
  input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 50px white inset;
    -webkit-text-fill-color: #333;
    border-radius: 10px !important;
  }
  .input-container input:focus {
    box-shadow: 0 0 0 2px #ffffff;
    ${(props) =>
      props.isRadius &&
      css`
        background-color: #f9f9f9;
      `}
    -webkit-box-shadow: 0 0 0 50px #f9f9f9 inset;
  }
  .input-container .filled {
    transform: translate(0, 15px) scale(0.8);
  }
  .input-container label {
    position: absolute;
    font-family: 'MontserratLight' !important;
    color: ${theme.v2.text.gray};
    pointer-events: none;
    transform: translate(0, 15px) scale(1);
    transform-origin: top left;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    font-size: 14px;
    line-height: 1;
    left: 16px;
    letter-spacing: 0.42px;
  }
  .input-container label .filled {
    font-weight: 300;
    font-family: 'MontserratLight' !important;
  }
  .input-container {
    position: relative !important;
    display: flex !important;
    flex-direction: row !important;
    border-radius: 10px !important;
    justify-content: space-between;
    width: 100%;
  }
  .input-container:focus-within label {
    transform: translate(0, 5px) scale(0.8);
    color: #848484;
  }
  input:-internal-autofill-selected {
    background-color: #ffffff !important;
    ${(props) =>
      props.isRadius &&
      css`
        background-color: #f9f9f9;
      `}
  }
  .input-container:focus-within {
    background-color: #ffffff;
    ${(props) =>
      props.isRadius &&
      css`
        background-color: #f9f9f9;
      `}
  }
  .input-container input:not(:placeholder-shown) ~ label {
    transform: translate(0, 5px) scale(0.8);
    color: #848484;
  }
  .input-container input:-webkit-autofill ~ label {
    transform: translate(0, 5px) scale(0.8);
    color: #848484;
  }
`;

const Wrapper = styled.div<Props>`
  width: ${(props) => (props.isLogin ? '100%' : '31rem')};
  border-bottom: ${(props) =>
    props.errorMsg
      ? `1px solid ${theme.colors.error}`
      : `1px solid ${theme.light.colors.grayUltraLight}`};
  display: flex;
  align-items: center;
  svg path {
    stroke: white !important;
  }
  svg {
    width: 0.6rem;
    height: 1.2rem;
  }

  ${(props) =>
    props.isRadius &&
    css`
      border-radius: 1.1rem;
      border: 1px solid #d8d8d8;
      background: #f9f9f9;
      padding: 0 4px 0 0px;
    `}
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;

const OptinInput = React.forwardRef(
  (
    {
      type = 'text',
      block = false,
      kind = 'default',
      placeholder = '',
      label,
      errorMsg = false,
      onFocus,
      initialValue,
      onChange,
      ref: oldRef,
      value,
      helperText = false,
      style,
      readOnly,
      alignHelperText,
      onClick,
      isLogin = false,
      isRadius = false,
      ...rest
    }: Props,
    ref
  ) => {
    const setAuth = AuthStore.useStoreActions((actions) => actions.setAuth);
    const apolloClient = useApolloClient();
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [emailValue, setEmailValue] = useState('');
    const [msg, setMsg] = useState('');
    const [emailKind, setEmailKind] = useState(false);
    const isControlled = !!onChange;
    const [focus, setFocus] = useState(false);
    const [localValue, setLocalValue] = useState(
      isControlled ? value : initialValue
    );
    const isOpen = focus || (isControlled ? !!value : !!localValue);
    let currentType = type;
    const setOptinResponse = UiStore.useStoreActions(
      (action) => action.setOptinResponse
    );

    const subscriptionURI =
      window.location.pathname === '/'
        ? '/home/index'
        : window.location.pathname;

    const registerOptin = (e: any) => {
      e.preventDefault();
      const application = isMobile
        ? ApplicationEnum.MOBILE
        : ApplicationEnum.DESKTOP;
      const module = ModuleEnum.SITE;
      setLoading(true);
      registerSuspect(
        emailValue,
        application,
        module,
        subscriptionURI,
        apolloClient
      )
        .then((res) => {
          const payload = {
            jwt: res.registerSuspect.jwt,
            sessionId: res.registerSuspect.sessionId,
            member: res.registerSuspect.member,
          };
          if (!!res) {
            setAuth(payload);
          }
          setFocus(false);
          setOptinResponse(res.registerSuspect);
          setLoading(false);
        })
        .catch((e) => {
          const graphQLErrorCode =
            e && e.graphQLErrors && e.graphQLErrors[0].extensions.bzcCode;
          if (
            e?.response?.errors[0].extensions.bzcCode ===
            'ERROR_UNKNOWN_GENERIC'
          ) {
            setMsg(`L'e-mail n'est pas valide`);
            setLoading(false);
          } else if (graphQLErrorCode === 'ERROR_TOO_MANY_REQUEST') {
            toast.error('Requête non autorisée');
            setLoading(false);
          } else {
            setMsg(e?.response?.errors[0].extensions.bzcMessage);
            setLoading(false);
          }
          setLoading(false);
          setTimeout(() => {
            setMsg('');
          }, 5000);
        });
    };

    return (
      <>
        <form onSubmit={registerOptin} noValidate>
          <Wrapper errorMsg={!!msg} isLogin={isLogin} isRadius={isRadius}>
            <InputContainer isRadius={isRadius}>
              <div className="styledInput">
                <div className="input-container">
                  <input
                    data-test="opt-in-subscribe-input-panel"
                    placeholder=" "
                    className="tt"
                    type={currentType}
                    value={value}
                    onChange={(e: any) => {
                      setEmailValue(e.target.value);
                    }}
                  />
                  <label className={value && 'filled'}>{label}</label>

                  {kind === 'success' && (
                    <div style={{ color: theme.colors.success }}></div>
                  )}
                </div>
              </div>
            </InputContainer>
            <Button
              dataTest="opt-in-subscribe-footer-panel-submit"
              type="submit"
              loading={loading}
              style={{
                height: '3.1rem',
                width: isLogin ? '5.2rem' : isRadius ? '1.1rem' : '4.5rem',
                borderRadius: isRadius ? '1.1rem' : '2.5rem',
              }}
              icon={<IconeRight />}
            />
          </Wrapper>
        </form>
        <div
          style={{
            color: `${theme.light.colors.error}`,
            paddingTop: '1rem',
            fontFamily: 'Montserrat !important',
            width: '31rem',
          }}
        >
          {msg}
        </div>
      </>
    );
  }
);

export default OptinInput;
