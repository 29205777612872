import React from 'react';
import styled, { createGlobalStyle } from 'styled-components/macro';
import posed, { PoseGroup } from 'react-pose';
import UiStore from 'Store/UiStore';
import AuthStore from 'Store/AuthStore';
import { useApolloClient } from '@apollo/client';
import { isAndroid } from 'react-device-detect';
import { SideMenuMember } from './SideMenuMember';
import { SideMenuAnonymous } from './SideMenuAnonymous';
import { allowedTypes } from 'Utils/memberTypes';

const GlobalStyle = createGlobalStyle`
  body{
    overflow-y : hidden;
  }
`;

const Overlay = posed(styled.div`
  background: #000;
  opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 160000;
`)({
  enter: { opacity: 0.5, transition: { duration: 200 } },
  exit: { opacity: 0, transition: { duration: 200 } },
});

const SideMenuBody = posed(styled.div`
  width: 87%;
  background-color: white;
  position: fixed;
  font-family: 'Montserrat';
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 160001;
`)({
  enter: { opacity: 1, x: 0, transition: { duration: 200 } },
  exit: { opacity: 0, x: '-20%', transition: { duration: 200 } },
});

export default () => {
  const setAuth = AuthStore.useStoreActions((actions) => actions.setAuth);
  const sideMenuOpen = UiStore.useStoreState((state) => state.sideMenuOpen);
  const setSideMenuOpen = UiStore.useStoreActions(
    (actions) => actions.setSideMenuOpen
  );
  const member = AuthStore.useStoreState((state) => state.member);
  const sessionMemberType = AuthStore.useStoreState(
    (state) => state.sessionMemberType
  );
  const apolloClient = useApolloClient();
  const linkDownload = isAndroid
    ? '/home/app/p/android/source/interstitiel/'
    : '/home/app/p/ios/source/interstitiel/';

  return (
    <>
      {sideMenuOpen && <GlobalStyle />}
      <PoseGroup>
        {sideMenuOpen && (
          <Overlay
            key="overlayBackground"
            onClick={() => setSideMenuOpen(false)}
          />
        )}
        {sideMenuOpen && (
          <SideMenuBody key="sideMenuBody" className="dropV4">
            {allowedTypes.includes(sessionMemberType) ? (
              <SideMenuMember />
            ) : (
              <SideMenuAnonymous
                onClickConnect={() => setSideMenuOpen(false)}
              />
            )}
          </SideMenuBody>
        )}
      </PoseGroup>
    </>
  );
};
