export const tunnelUrl = [
  '/v2/tunnel/basket',
  '/v2/tunnel/basket/',
  '/v3/tunnel/livraison/',
  'v3/tunnel/livraison',
  '/v3/tunnel/recap',
  '/v3/tunnel/recap/',
  '/v3/tunnel/cancel',
  '/v3/tunnel/cancel/',
];

export const preferenceUrl = ['/centre-preferences/'];
