import { useApolloClient } from '@apollo/client';
import { getUrlQueryParams } from 'Utils/locationSearch';
import Cookies from 'js-cookie';
import { omit } from 'lodash';
import { useState } from 'react';
import RegistrationForm, { FormData } from './RegistrationForm';
import { toast } from 'Components/Toaster/';
import { REGISTER_MEMBER, REGISTER_MEMBER_FACEBOOK } from './query';

import AuthStore from 'Store/AuthStore';
import {
  ApplicationEnum,
  EnvironmentInput,
  ModuleEnum,
} from '__generated__/graphql-global-types';
import {
  registerMember,
  registerMemberVariables,
} from './__generated__/registerMember';
import {
  registerMemberFacebook,
  registerMemberFacebookVariables,
} from './__generated__/registerMemberFacebook';

import { logScreenName, signUpApp, trackSignUPAdjust } from 'Libs/Gtm/GtmApp';
import UiStore from 'Store/UiStore';
import { addEmailAndPasswordForConnectionApp } from 'Utils/webview/utilsSession';
import { isMobile } from 'react-device-detect';

const subscriptionURI =
  window.location.pathname === '/' ? '/home/index' : window.location.pathname;

export default () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const preFill = getUrlQueryParams();
  const apolloClient = useApolloClient();
  const setAuth = AuthStore.useStoreActions((actions) => actions.setAuth);

  const regex = /(?:\d)+/;
  const regexParrain = /idparrain+/;
  const urlSponsorship = window.location.pathname.match(regex);

  const testRegex = regex.test(window.location.pathname);
  const testRegexParrain = regexParrain.test(window.location.pathname);
  const sponsorId =
    testRegexParrain && testRegex ? urlSponsorship && urlSponsorship[0] : null;

  const webview = UiStore.useStoreState((state) => state.webview);
  const cookieMarketing = UiStore.useStoreState(
    (state) => state.cookieMarketing
  );

  const redirect = () => {
    /** @todo once the app is fully React, change those ugly cookie to som url / router params */
    const url = Cookies.get('redirection_url');
    Cookies.remove('redirection_url');

    /** @todo once the app is fully React, change that to a router location push */
    window.location.href = url || '/';
  };

  const registerMember = async (form: FormData) => {
    const environment: EnvironmentInput = {
      application: isMobile ? ApplicationEnum.MOBILE : ApplicationEnum.DESKTOP,
      module: ModuleEnum.SITE,
    };

    setLoading(true);
    const member = omit<typeof form, 'CGV'>(form, ['CGV']);

    try {
      const response = await apolloClient.mutate<
        registerMember,
        registerMemberVariables
      >({
        mutation: REGISTER_MEMBER,
        variables: {
          sponsorId,
          subscriptionURI,
          environment,
          member,
        },
      });
      if (response.data && response.data.registerMember) {
        const data = response.data.registerMember;
        const member = data && data.member;
        if (data.member) setAuth(data);
        // Webview App
        if (webview && member && cookieMarketing) {
          trackSignUPAdjust(member.id, window);
          logScreenName('Register', window);
        }
        if (webview && member) {
          signUpApp('email', window);
          addEmailAndPasswordForConnectionApp(form.email, form.password);
        }
      }

      window.localStorage.setItem('subscriptionTracking', 'inscription-ok');
      redirect();
    } catch (err: any) {
      const graphQLErrorCode =
        err && err.graphQLErrors && err.graphQLErrors[0].extensions.bzcCode;
      console.log({ graphQLErrorCode });
      if (graphQLErrorCode === 'ERROR_TOO_MANY_REQUEST') {
        toast.error('Requête non autorisée');
      }
      setLoading(false);
    }
  };

  const registerMemberWithFacebook = async (data: any) => {
    const accessToken: string = data.accessToken;
    setLoading(true);
    try {
      const response = await apolloClient.mutate<
        registerMemberFacebook,
        registerMemberFacebookVariables
      >({
        mutation: REGISTER_MEMBER_FACEBOOK,
        variables: {
          accessToken,
        },
      });

      if (response.data && response.data.registerMemberFacebook) {
        const data = response.data.registerMemberFacebook;
        if (data.member) setAuth(data);
        if (webview && data.member) {
          signUpApp('Facebook', window);
        }
      }

      window.localStorage.setItem('subscriptionTracking', 'inscription-ok');
      redirect();
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <RegistrationForm
      onRegister={registerMember}
      onFacebookRegister={registerMemberWithFacebook}
      preFill={preFill}
      loading={loading}
    />
  );
};
