import { Button, Link, theme } from 'Ui';
import { useState } from 'react';
import styled from 'styled-components/macro';
import { InputLogin } from '../InputLogin';
import { emailRegex } from 'Utils/login';
import { isMobile } from 'react-device-detect';
import ForgotPasswordModal from 'Components/modals/LoginRegister/FogotPassword';

type LoginFormProps = {
  onSubmitLogin?: (
    emailValue: string,
    passValue: string
    // apolloClient?: any
  ) => void;
  close?: any;
  loading: boolean;
  type?: any;
  resetPasswordType?: any;
  goBack?: () => void;
};

const MandatoryField = styled.div`
  font-size: 1rem;
  font-family: 'Montserrat';
  line-height: 1.4rem;
  color: #aaaaaa;
  text-align: center;
  /* padding: 1.5rem 0; */
`;

const EmailForResetPassword = styled.div`
  text-align: center;
  font-family: 'Montserrat' !important;
  font-size: 1.4rem;
  color: rgb(74, 74, 74);
  text-decoration: underline;
  margin: 2.4rem 0 1.6rem 0;
  cursor: pointer;
  :hover {
    color: ${theme.light.colors.purple};
  }
`;
const Error = styled.div`
  margin-top: 8px;
  color: ${theme.light.colors.error};
  font-size: 14px;
`;

export const LoginForm = ({
  onSubmitLogin,
  loading = false,
  close,
  type,
  goBack,
  resetPasswordType,
}: LoginFormProps) => {
  const [error, setErrors] = useState();
  const [passMsg, setPassMsg] = useState('');
  const [passValue, setPassValue] = useState('');
  const [emailValue, setEmailValue] = useState('');
  const [emailKind, setEmailKind] = useState<boolean>(false);
  const [emailMsg, setEmailMsg] = useState('');

  return (
    <form
      autoComplete="on"
      onSubmit={(e: any) => {
        e.preventDefault();
        onSubmitLogin && onSubmitLogin(emailValue, passValue);
      }}
      id="formUserInscription"
      noValidate
    >
      <div style={{ marginBottom: '1.6rem' }}>
        <InputLogin
          block
          onChange={(e: any) => {
            setEmailValue(e.target.value);
            if (!e.target.value.match(emailRegex)) {
              setEmailMsg(`L'adresse e-mail n'est pas valide`);
              setEmailKind(false);
            } else {
              setEmailMsg('');
              setEmailKind(true);
            }
          }}
          dataTest="email"
          name="email"
          autoComplete="username"
          type="email"
          label="E-mail"
          value={emailValue}
          kind={
            error
              ? 'error'
              : emailMsg && emailMsg
              ? 'error'
              : emailKind
              ? 'default'
              : 'default'
          }
          required
          helperText={emailMsg && emailMsg}
        />
      </div>
      <InputLogin
        block
        dataTest="password"
        onChange={(e: any) => {
          setPassValue(e.target.value);
          if (!e.target.value) {
            setPassMsg(`Champ obligatoire`);
          } else {
            setPassMsg('');
          }
        }}
        name="password"
        type="password"
        autoComplete="current-password"
        label="Mot de passe"
        value={passValue}
        kind={error ? 'error' : passMsg && passMsg ? 'error' : 'default'}
        helperText={passMsg && passMsg}
      />
      {/* {error && <Error>{error}</Error>} */}
      {resetPasswordType}

      {type === 'modal' ? (
        <div
          onClick={() => {
            close(true);
            ForgotPasswordModal({});
          }}
        >
          <EmailForResetPassword className="fontMontserrat">
            Mot de passe oublié ?
          </EmailForResetPassword>
        </div>
      ) : (
        <Link to="/login/emailForResetPassword/">
          <EmailForResetPassword className="fontMontserrat">
            Mot de passe oublié ?
          </EmailForResetPassword>
        </Link>
      )}
      <div style={{ margin: isMobile ? '1.5rem 0' : ' 1.5rem' }}>
        <Button
          style={{
            width: isMobile ? '100%' : '22.6rem',
            margin: '1rem auto 0 auto',
            display: 'flex',
            fontFamily: 'MontserratSemiBold',
          }}
          type="submit"
          loading={loading}
        >
          se connecter
        </Button>
      </div>
    </form>
  );
};
