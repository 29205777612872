const params = {
  dimensions: {
    width: 467.5,
    height: 228,
  },
};

const paramsBanner = {
  dimensions: {
    width: 1914,
    height: 620,
  },
};
const paramsBannerRetina = {
  dimensions: {
    width: 2160,
    height: 624,
  },
};

const paramsRetina = {
  dimensions: {
    width: 934,
    height: 453,
  },
};

export const paramsUrl = JSON.stringify(params);
export const paramsRetinaUrl = JSON.stringify(paramsRetina);
export const paramsBannerUrl = JSON.stringify(paramsBanner);
export const paramsRetinaBannerUrl = JSON.stringify(paramsBannerRetina);

export const createUrlParams = (params: {
  width: number;
  height: number;
  retina?: boolean;
}) => {
  return encodeURIComponent(
    JSON.stringify({
      dimensions: {
        width: params.width * (params.retina ? 2 : 1),
        height: params.height * (params.retina ? 2 : 1),
      },
    })
  );
};