import React from 'react';
import TagManager from 'react-gtm-module';
import Cookies from 'js-cookie';
import dayjs from 'dayjs';
import { getUserType } from 'Utils/memberType';

const tagManagerArgs = {
  gtmId: 'GTM-KHM4FXW',
};

export const initGtm = () => {
  if (!Cookies.get('isWebView')) {
    TagManager.initialize(tagManagerArgs);
  }
};

export const gtmBeginCheckout = (datas: any) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'begin_checkout',
      ...datas,
    },
  });
};

type SignupData = {
  event: string;
  signup_type: string;
  user_id: string;
};

type SigninData = {
  event: string;
  login_type: string;
  user_id: string;
};

export const getSignup = (data: SignupData) => {
  TagManager.dataLayer({
    dataLayer: {
      ...data,
    },
  });
};

export const getModalEventName = (modalName: string) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'modal_display',
      modal_name: modalName,
    },
  });
};

export const getSignIn = (data: SigninData) => {
  TagManager.dataLayer({
    dataLayer: {
      ...data,
    },
  });
};

function getCleanUrl() {
  var url = window.location.href;
  if (!url.includes('?')) {
    return url;
  }
  return url.split('?')[0];
}
export const getPageView = (bzcPagePathName?: string, bzcPageUrl?: string) => {
  const currentPath = getCleanUrl();
  const storedPreviousPath = sessionStorage.getItem('previousPath');
  if (storedPreviousPath && storedPreviousPath !== currentPath) {
    TagManager.dataLayer({
      dataLayer: {
        event: 'page_view',
        page_path_name: bzcPagePathName,
        page_url: bzcPageUrl,
        referrer: storedPreviousPath,
      },
    });
  }

  sessionStorage.setItem('previousPath', currentPath);
};

export const pageInit = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'page_init',
    },
  });
};

export const gtmDeleteItemCard = (datas: any) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'remove_from_cart',
      ecommerce: {
        ...datas,
      },
    },
  });
};

export const gtmUpdateItemQttyItem = (datas: any) => {
  TagManager.dataLayer({
    dataLayer: {
      ...datas,
    },
  });
};

export const gtmAddPaymentInfos = (datas: any) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'add_payment_info',
      ecommerce: {
        ...datas,
      },
    },
  });
};

export const setGtmUserData = (user?: any, sessionMemberType?: any) => {
  const userLastOrder =
    user && user.lastOrderAtTimestamp
      ? dayjs.unix(user.lastOrderAtTimestamp).format('YYMMDD')
      : '';

  const userRegisterDate =
    user && user.registeredAtTimestamp
      ? dayjs.unix(user.registeredAtTimestamp).format('YYMMDD')
      : '';
  const birthDate =
    user && user.birthDate ? dayjs.unix(user.birthDate).format('YYMMDD') : '';
  const crmOtpins = () => {
    if (user) {
      if (user.isOptIn === '') {
        return 'none';
      } else if (user.isOptIn === 'oui') {
        return 'e_news_q';
      } else if (user.isOptIn === 'hebdo') {
        return 'e_news_h';
      }
    } else {
      return '';
    }
  };
  const getGender = () => {
    if (user) {
      if (user.gender === 'none') {
        return '';
      } else if (user.gender === 'male') {
        return 'M';
      } else {
        return 'F';
      }
    } else {
      return '';
    }
  };
  TagManager.dataLayer({
    dataLayer: {
      user_id: user && user.id ? user.id : '',
      user_firstname: user && user.firstName ? user.firstName : '',
      user_age: user && user.age ? user.age : '',
      user_gender: getGender(),
      user_birthdate: birthDate,
      user_email_md5: user && user.emailMd5 ? user.emailMd5 : '',
      user_email_sha256: user && user.emailHash ? user.emailHash : '',
      user_crm_statut: crmOtpins(),
      user_privilege:
        user && user.isVip ? (user.isVip === true ? 'Yes' : 'No') : 'No',
      user_type: getUserType(sessionMemberType),
      user_last_order: userLastOrder,
      user_duration:
        user && user.dayCountFromRegister ? user.dayCountFromRegister : '',
      user_consent_marketing: Cookies.get('_marketing'),
      user_consent_performance: Cookies.get('_performance'),
      user_signup_date: userRegisterDate,
      user_app_connect: user && user.validMobilesCount ? '1' : '0',
      user_total_transactions: user && user.orderCount ? user.orderCount : '',
    },
  });
};

export const getPrivacyChoice = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'privacy_choice',
    },
  });
};
