import * as React from "react";
const SvgArrowBottom = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 25"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.578 15.685 18.81 9.68l-.65-.734-6.233 6.004a.465.465 0 0 1-.664-.014L5.439 8.858l-.677.708 5.824 6.077c.546.57 1.425.588 1.992.042"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgArrowBottom;
