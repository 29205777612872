import * as React from "react";
const SvgServiceClient = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.3}
      d="M17.528 21.23c.559-.324.96-.718 1.228-1.167.221-.372.355-.786.412-1.235l-4.436-2.809-1.616 1.123a.54.54 0 0 1-.593.013 12.3 12.3 0 0 1-2.046-1.501 12.8 12.8 0 0 1-3.149-4.39 11.6 11.6 0 0 1-.788-2.662.51.51 0 0 1 .225-.508l1.425-.99L6.9 2.107a3.4 3.4 0 0 0-1.333-.02c-.523.1-1.035.342-1.532.75C1.831 4.65 1.64 7.67 2.646 10.773c.562 1.73 1.492 3.481 2.658 5.07s2.565 3.014 4.063 4.091c2.688 1.934 5.685 2.737 8.162 1.296Zm2.143-.652c-.357.598-.88 1.116-1.601 1.535-2.902 1.688-6.317.817-9.334-1.352-1.586-1.142-3.065-2.647-4.294-4.322-1.23-1.677-2.213-3.526-2.807-5.356-1.13-3.48-.867-6.907 1.717-9.03.64-.528 1.315-.843 2.011-.978.688-.132 1.39-.086 2.097.107.185.044.34.183.389.375l1.454 5.636a.51.51 0 0 1-.207.543L7.64 8.75c.142.707.363 1.419.664 2.119.334.778.767 1.54 1.3 2.267.481.654 1.017 1.244 1.592 1.765.504.457 1.039.86 1.593 1.206l1.622-1.128a.54.54 0 0 1 .594-.012l4.987 3.158a.51.51 0 0 1 .257.468c-.04.721-.222 1.39-.578 1.986Z"
    />
  </svg>
);
export default SvgServiceClient;
