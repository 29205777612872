import styled from 'styled-components/macro';
export const CreditCards = styled.div`
  img {
    height: 21px;
    width: 31px;
  }
  img:nth-child(2) {
    margin: 0rem 1.6rem;
  }
`;
