import * as React from "react";
const SvgExclamationCircle = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 32 32"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M.119 16.017C.119 7.256 7.245.119 16.017.119c8.761 0 15.898 7.137 15.898 15.898s-7.126 15.898-15.898 15.898S.119 24.778.119 16.017m2.193 0c0 7.564 6.141 13.705 13.705 13.705 7.553 0 13.705-6.141 13.705-13.705S23.581 2.312 16.017 2.312 2.312 8.453 2.312 16.017"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      d="M16.017 6.697c-.897 0-1.645.81-1.645 1.811v9.536c0 1.001.748 1.81 1.645 1.81s1.645-.809 1.645-1.81V8.508c0-1.001-.748-1.811-1.645-1.811M16.017 25.336a1.645 1.645 0 1 0 0-3.29 1.645 1.645 0 0 0 0 3.29"
    />
  </svg>
);
export default SvgExclamationCircle;
