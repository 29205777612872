import gql from "graphql-tag";

export const GET_SALES_BY_IDS = gql`
    query getSalesByUniverse(
        $salesByUniverseUniverse: String,
        $salesByUniversePagination: PaginationInput,
        $salesByUniverseDate: String,
        $salesByUniverseNewSalesOnly: Boolean,
        ) {
        salesByUniverse(
            universe: $salesByUniverseUniverse,
            pagination: $salesByUniversePagination,
            date: $salesByUniverseDate,
            newSalesOnly: $salesByUniverseNewSalesOnly,
            ) {
                totalCount
                pageInfo {
                endCursor
                hasNextPage
                }
                edges {
                    cursor
                    node {
                        id
                        name
                        rootId
                        isOpen
                        isOpenVip
                        coverUrl
                        coverLogoUrl
                        logoUrl
                        endDateTime
                        startDateTime
                        isLastingSale
                        position
                        hasWine
                        promoCategory
                        promoMulti
                        saleType
                        saleTargets
                        stickersDatas {
                            id
                            position
                            url
                        }
                    }
                }
            }
    }
`

export const GET_FUTURES_SALES_BY_IDS = gql`
    query getFuturesSalesByIds (
        $salesByUniverseUniverse: String,
        $salesByUniverseDate: String,
        $salesByUniverseNewSalesOnly: Boolean,
        ) {
        salesByUniverse(
            universe: $salesByUniverseUniverse,
            date: $salesByUniverseDate,
            newSalesOnly: $salesByUniverseNewSalesOnly,
            ) {
                totalCount
                pageInfo {
                endCursor
                hasNextPage
                }
                edges {
                    cursor
                    node {
                        name
                        coverLogoUrl
                        coverUrl
                        startDateTime
                        vipStartDateTime
                        id
                        logoUrl
                    }
                }
            }
    }
`
export const HOME_SLIDER = gql`
    query homeSlider (
        $sliderDevice: DeviceEnum!, 
        $sliderOs: OsEnum!,
        $sliderUniverse: String!
        ) {
        slider(
            device: $sliderDevice,
            os: $sliderOs, 
            universe: $sliderUniverse
            ) {
                image
                url
                name
                dateStart
                dateEnd
                target
                alt
                googleAnalyticsTag
                position
                color
            }
    }
`;

export const BEST_SALES = gql`
    query bestSales (
        $universe: String!
        $date: String,
        ) {
        bestSalesByUniverse(
            date: $date
            universe: $universe
            ) {
                id
                name
                rootId
                isOpen
                isOpenVip
                coverUrl
                coverLogoUrl
                logoUrl
                endDateTime
                startDateTime
                isLastingSale
                maximumRootDiscountPercent
                position
                hasWine
                promoCategory
                promoMulti
                saleType
                saleTargets
                stickersDatas {
                    id
                    position
                    url
                }  
            }
    }
`;