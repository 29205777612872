//@ts-ignore
import granvileBold from './Granville/Granville-Bold.otf';
//@ts-ignore
import granvileBoldItalic from './Granville/Granville-Bold-Italic.otf';
//@ts-ignore
import granvileItalic from './Granville/Granville-Italic.otf';
//@ts-ignore
import granvileLightItalic from './Granville/Granville-Light-Italic.otf';
//@ts-ignore
import granvileLight from './Granville/Granville-Light.otf';
//@ts-ignore
import granvileRegular from './Granville/Granville-Regular.otf';
//@ts-ignore
import MontserratRegular from './Montserrat/Montserrat-Regular.ttf';
//@ts-ignore
import MontserratLight from './Montserrat/Montserrat-Light.ttf';
//@ts-ignore
import MontserratMedium from './Montserrat/Montserrat-Medium.ttf';
//@ts-ignore
import MontserratSemiBold from './Montserrat/Montserrat-SemiBold.ttf';
//@ts-ignore
import MontserratBold from './Montserrat/Montserrat-Bold.ttf';
//@ts-ignore
import InterRegular from './inter/Inter-Regular.ttf';
//@ts-ignore
import InterMedium from './inter/Inter-Medium.ttf';
//@ts-ignore
import InterSemiBold from './inter/Inter-SemiBold.ttf';
//@ts-ignore
import InterBold from './inter/Inter-Bold.ttf';
//@ts-ignore
import AlbraRegular from './albra/Albra-Regular.woff2';
//@ts-ignore
import AlbraLight from './albra/Albra-Thin.woff2';
//@ts-ignore
import AlbraMedium from './albra/Albra-Medium.woff2';
//@ts-ignore
import AlbraSemiBold from './albra/Albra-Semi.woff2';
//@ts-ignore
import AlbraBold from './albra/Albra-Bold.woff2';

const Fonts = {
  granvileBold,
  granvileBoldItalic,
  granvileItalic,
  granvileLightItalic,
  granvileLight,
  granvileRegular,
  MontserratRegular,
  MontserratLight,
  MontserratMedium,
  MontserratSemiBold,
  MontserratBold,
  InterRegular,
  InterMedium,
  InterSemiBold,
  InterBold,
  AlbraRegular,
  AlbraLight,
  AlbraMedium,
  AlbraSemiBold,
  AlbraBold,
};

export default Fonts;
