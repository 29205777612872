import gql from "graphql-tag";

export default gql`
  query getBasket {
    basket {
      id
      remainingTime
      totalPrice
      productCount
      updatedAt
      basketSales {
        basketProducts {
          productId
          price
          size
          productCount
          product{ 
            title
          }
        }
        sale {
          promoMulti
          promoCategory
          name
          id
          productDeliveryDate
        }
      }
    }
  }
`;

