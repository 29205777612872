import React from 'react';

import { ApolloProvider } from '@apollo/client';
import { init } from 'Libs/apollo';
import { GlobalStyle, AppStyled } from '../GlobalStyle';
import { BrowserRouter } from 'react-router-dom';
import AuthStore from 'Store/AuthStore';
import InitiStore from 'Store/InitiStore';
import UiStore from 'Store/UiStore';
import Media from 'react-media';
import MobileMenu from 'Components/Header/MobileHeader/MenuMobileExternal';
import DesktopMenu from 'Components/Header/DesktopHeader/MenuDesktopExternal';

export default () => {
  const client = init();
  return (
    <AppStyled>
      <BrowserRouter>
        <AuthStore.Provider initialData={client}>
          <UiStore.Provider>
            <ApolloProvider client={client}>
              <InitiStore />
              <>
                <GlobalStyle />
                <Media query={{ minWidth: 768 }}>
                  <DesktopMenu />
                </Media>
                <Media query={{ maxWidth: 767 }}>
                  <MobileMenu />
                </Media>
              </>
            </ApolloProvider>
          </UiStore.Provider>
        </AuthStore.Provider>
      </BrowserRouter>
    </AppStyled>
  );
};
