import React from 'react';
import { GlobalStyle, AppStyled } from '../GlobalStyle';
import BtnFacebookConnect from 'Components/Facebook';
import { ApolloProvider } from '@apollo/client';
import { init } from 'Libs/apollo';
import Toaster from 'Components/Toaster';
import AuthStore from 'Store/AuthStore';

export default () => {
  const client = init();
  return (
    <ApolloProvider client={client}>
      <AuthStore.Provider>
        <AppStyled>
          <GlobalStyle />
          <BtnFacebookConnect />
          <Toaster />
        </AppStyled>
      </AuthStore.Provider>
    </ApolloProvider>
  );
};
