import * as React from "react";
const SvgPoubelleOuverte = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="poubelle-ouverte_svg__Calque_1"
    x={0}
    y={0}
    viewBox="0 0 100 100"
    width="1em"
    height="1em"
    {...props}
  >
    <style>
      {
        ".poubelle-ouverte_svg__st0{fill:none;stroke:#232929;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}"
      }
    </style>
    <switch>
      <foreignObject
        width={1}
        height={1}
        x={0}
        y={0}
        requiredExtensions="http://ns.adobe.com/AdobeIllustrator/10.0/"
      />
      <g>
        <path
          d="m67.26 29.95-2.13 45.19c-.04.8-.72 1.42-1.55 1.42H36.03c-.86 0-1.56-.65-1.59-1.48l-1.7-45.14h34.52zM41.02 37l2.2 32.95M59.79 37l-2.2 32.95M50.16 37v32.95M31.16 12.39l45.77 16.74M47.53 18.37l1.68-4.61c.3-.81 1.22-1.22 2.06-.91l10.45 3.82c.84.31 1.29 1.21.99 2.02l-1.68 4.61"
          className="poubelle-ouverte_svg__st0"
        />
      </g>
    </switch>
  </svg>
);
export default SvgPoubelleOuverte;
