import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components/macro';
import { IconClose } from 'Ui/Icons';
import { Button } from 'Ui/Button';
import UIstore from 'Store/UiStore';
import UiStore from 'Store/UiStore';
import { setCookie } from 'Utils/cookie';

type bannerProps = {
  android: boolean;
};

const slideIn = keyframes`
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
`;

const BannerAppStyled = styled.div`
  display: flex;
  width: 100%;
  height: 6.6rem;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  background: #fff;

  position: absolute;
  z-index: 1;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    &.slideIn {
      animation: ${slideIn} 0.5s ease-in-out forwards;
    }
  }
  position: relative @media screen and (min-width: 320px) {
    padding: 0 15px;
  }

  @media screen and (min-width: 768px) {
    justify-content: left;
  }
`;
const BannerCLose = styled.div`
  svg {
    width: 1.8rem;
    height: 4rem;
    @media screen and (min-width: 768px) {
      width: 2.5rem;
      height: 2.5rem;
    }
  }
`;

const BannerLogoApp = styled.div`
  width: 3.8rem;
  height: 3.8rem;
  img{
    display: block
    width: 100%;
    width: 3.8rem;
    height: 3.8rem;
  }
  @media screen and (min-width: 320px) {
    margin: 0 1rem;
  }

  @media screen and (min-width: 768px) {
    margin: 0 1rem;
  }
`;

const Bannerdescription = styled.div`
  font-size: 1.2rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'os-regular', 'Open Sans', Arial, sans-serif;
  padding-right: 0.5rem;

  @media screen and (min-width: 768px) {
    flex: 0 0 calc(100% - 172px);
    font-size: 1.6rem;
  }
  .strong {
    font-weight: bold;
  }
`;

const BannerButton = styled.div`
  height: 3rem;
`;

export default ({ android }: bannerProps) => {
  const setCookieMobileApp = UIstore.useStoreActions(
    (action) => action.setCookieMobileApp
  );
  const setMenuMobileHeight = UiStore.useStoreActions(
    (action) => action.setMenuMobileBannerHeight
  );

  const close = () => {
    addCookieMobileDownload();
  };

  const downloadApp = (url: string) => {
    window.location.href = url;
    addCookieMobileDownload();
  };

  const addCookieMobileDownload = () => {
    setMenuMobileHeight(47);
    setCookieMobileApp(false);
    const objectCookie = {
      path: '/',
      domian: '.bazarchic.com',
      expires: 365,
      secure: true,
      value: '1',
      name: 'mobileAppPopup',
    };
    setCookie(objectCookie);
  };

  const store = android ? 'Google Play Store' : "l'app Store";
  const link = android
    ? '/home/app/p/android/source/interstitiel/'
    : '/home/app/p/ios/source/interstitiel/';
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsVisible(true);
    }, 100);

    return () => clearTimeout(timeoutId);
  }, []);
  return (
    <>
      {isVisible && (
        <BannerAppStyled>
          <BannerCLose>
            <IconClose onClick={close} className="close" />
          </BannerCLose>
          <BannerLogoApp>
            <img
              src="https://cdn.bazarchic.com/media/logos/logo-app-purple.png"
              srcSet="https://cdn.bazarchic.com/media/logos/logo-app-purple.png 2x"
              alt="logo BazarChic"
            />
          </BannerLogoApp>
          <Bannerdescription>
            <p>
              Tout <span className="strong">BazarChic</span> dans votre poche
            </p>
            <p>Gratuit sur {store}</p>
          </Bannerdescription>
          <BannerButton>
            <Button
              onClick={(e) => downloadApp(link)}
              kind="primary"
              style={{ fontSize: '1rem', height: '3rem' }}
            >
              {' '}
              Découvrir{' '}
            </Button>
          </BannerButton>
        </BannerAppStyled>
      )}
    </>
  );
};
