import * as React from "react";
const SvgPoubelle = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="poubelle_svg__Calque_1"
    x={0}
    y={0}
    viewBox="0 0 100 100"
    width="1em"
    height="1em"
    {...props}
  >
    <style>
      {
        ".poubelle_svg__st0{fill:none;stroke:#232929;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}"
      }
    </style>
    <switch>
      <foreignObject
        width={1}
        height={1}
        x={0}
        y={0}
        requiredExtensions="http://ns.adobe.com/AdobeIllustrator/10.0/"
      />
      <g>
        <path
          d="M25.79 29.95h48.73M32.73 29.95l1.7 45.14c.03.82.74 1.48 1.59 1.48h27.55c.83 0 1.51-.63 1.55-1.42l2.13-45.19M41.02 37l2.2 32.95M59.79 37l-2.2 32.95M50.16 37v32.95M43.22 29.95v-4.91c0-.86.73-1.56 1.63-1.56h11.13c.9 0 1.63.7 1.63 1.56v4.91"
          className="poubelle_svg__st0"
        />
      </g>
    </switch>
  </svg>
);
export default SvgPoubelle;
