import { useQuery } from '@apollo/client';
import { homeSlider, homeSliderVariables } from "Pages/Home/__generated__/homeSlider";
import { BEST_SALES, GET_FUTURES_SALES_BY_IDS, GET_SALES_BY_IDS, HOME_SLIDER } from "Pages/Home/query";
import { DeviceEnum, OsEnum } from "__generated__/graphql-global-types";
import { getSalesByUniverse, getSalesByUniverseVariables } from "Pages/Home/__generated__/getSalesByUniverse";
import { getFuturesSalesByIds, getFuturesSalesByIdsVariables } from "Pages/Home/__generated__/getFuturesSalesByIds";
import dayjs from "dayjs";
import { bestSales, bestSalesVariables } from 'Pages/Home/__generated__/bestSales';

const responsive = window.matchMedia(`(max-width: 767px)`).matches;

export const Slider = (saleType: string,) => {
  const { data: dataSlider, loading: loadingSlider } = useQuery<
    homeSlider,
    homeSliderVariables
  >(HOME_SLIDER, {
    variables: {
      sliderDevice: responsive ? DeviceEnum.MOBILE : DeviceEnum.DESKTOP,
      sliderOs: OsEnum.SITE,
      sliderUniverse: saleType || 'ACCUEIL',
    },
  });
  return { dataSlider, loadingSlider }
}

export const BestSales = (saleType: string,) => {
  const { data: dataBestSales, loading: loadingBestSales } = useQuery<
    bestSales,
    bestSalesVariables
  >(BEST_SALES, {
    variables: {
      date: dayjs().format('YYYY-MM-DD'),
      universe: saleType || 'ACCUEIL',
    },
  });
  return { dataBestSales, loadingBestSales }
}

export const Outlet = () => {
  const { data: dataOutlet, loading: loadingOutlet } = useQuery<
    getSalesByUniverse,
    getSalesByUniverseVariables
  >(GET_SALES_BY_IDS, {
    variables: {
      salesByUniverseUniverse: 'OUTLET',
    },
  });
  return { dataOutlet, loadingOutlet }
}

export const UpcommingSale = (saleType: string) => {
  const { data: dataCarousel } = useQuery<
    getFuturesSalesByIds,
    getFuturesSalesByIdsVariables
  >(GET_FUTURES_SALES_BY_IDS, {
    variables: {
      salesByUniverseUniverse: saleType || 'ACCUEIL',
      salesByUniverseDate: dayjs().add(1, 'day').format('YYYY-MM-DD'),
      salesByUniverseNewSalesOnly: true,
    },
  });
  return { dataCarousel }
}

export const FirstSaleQuery = (saleType: string, adminSaleFutures: string) => {
  const { data: firstSale, loading: loadingFirstSale } = useQuery<
    getSalesByUniverse,
    getSalesByUniverseVariables
  >(GET_SALES_BY_IDS, {
    skip: saleType ? false : true,
    variables: {
      salesByUniverseUniverse: saleType,
      salesByUniverseDate: adminSaleFutures,
      salesByUniversePagination: {
        first: 2,
      },
    },
  });
  return { firstSale, loadingFirstSale }
};

export const Sales = (saleType: string, adminSaleFutures: string, isLoggedIn: boolean, first: number, setTotalCount: any) => {
  const { data, loading, error, fetchMore } = useQuery<
    getSalesByUniverse,
    getSalesByUniverseVariables
  >(GET_SALES_BY_IDS, {
    skip: saleType ? false : true,
    variables: {
      salesByUniverseUniverse: saleType,
      salesByUniverseDate: adminSaleFutures,
      salesByUniversePagination: {
        first: isLoggedIn ? first : 8,
      },
    },
    onCompleted: (results) => {
      const newTotalCount =
        (results &&
          results.salesByUniverse &&
          results.salesByUniverse.totalCount) ||
        0;
      setTotalCount(newTotalCount);
      // setLoadingResults(false);
      setTimeout(() => {
        const saleId = sessionStorage.getItem('SaleId');
        const saleCard =
          (saleId &&
            document.querySelectorAll(`[data-product-id="${saleId}"]`)[0]) ||
          null;
        if (saleCard) {
          const elemRect = saleCard.getBoundingClientRect();
          const offset = elemRect.top + window.scrollY;
          window.scrollTo(0, offset - 62);
          sessionStorage.removeItem('SaleId');
        }
      }, 100);
    },
  })
  return { data, loading, error, fetchMore }
}


