import React from 'react';
import { GlobalStyle, AppStyled } from '../GlobalStyle';
import ModifyPassword from 'Components/ModifyPassword';
import Toaster from 'Components/Toaster';

import { init } from '../../Libs/apollo';
import { ApolloProvider } from '@apollo/client';

import AuthStore from 'Store/AuthStore';
import InitiStore from 'Store/InitiStore';
import UiStore from 'Store/UiStore';

import MessageStore from '../../Components/ModifyPassword/store';

export default () => {
  const client = init();

  return (
    <AppStyled>
      <AuthStore.Provider initialData={client}>
        <UiStore.Provider>
          <ApolloProvider client={client}>
            <InitiStore />
            <GlobalStyle />
            <MessageStore.Provider>
              <ModifyPassword />
            </MessageStore.Provider>
          </ApolloProvider>
        </UiStore.Provider>
        <Toaster />
      </AuthStore.Provider>
    </AppStyled>
  );
};
