import * as React from "react";
const SvgCloseCircle = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 32 32"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="square"
      strokeWidth={1.3}
      d="m10.867 21.099 10.232-10.232M10.867 10.867l10.232 10.232"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.3}
      d="M15.984 31.288c8.452 0 15.304-6.852 15.304-15.304C31.288 7.53 24.436.678 15.984.678 7.53.678.678 7.53.678 15.984c0 8.452 6.852 15.304 15.306 15.304Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCloseCircle;
