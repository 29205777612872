import { useQuery } from '@apollo/client';
import starsRatesInactive from 'Assets/images/ico-star-grey.svg';
import starsRatesActive from 'Assets/images/ico-star.svg';
import trustedReviewsLogoPath from 'Assets/images/trustedReviewsLogo.png';
import { getReviews } from 'Components/Footer/sections/__generated__/getReviews';
import { theme } from 'Ui';
import gql from 'graphql-tag';
import styled from 'styled-components';
import { Social } from '../Social';

type TypeSeo = {
  imageUrlSEO?: string;
  seoObject?: any;
};

const StarCounter = styled.div<{ starCount: number }>`
  position: relative;
  width: 10rem;
  min-width: 10rem;
  height: 1.5rem;
  display: inline-flex;
  &:before,
  :after {
    content: '';
    width: 2rem;
    height: 1.5rem;
    background-size: 2rem 100%;
    position: absolute;
  }
  &:before {
    background-image: url(${starsRatesActive});
    left: 0;
    width: ${(props) => props.starCount * 2}rem;
  }
  &::after {
    background-image: url(${starsRatesInactive});
    background-position-x: right;
    right: 0;
    width: ${(props) => (5 - props.starCount) * 2}rem;
  }
`;

const RatingsContainer = styled.div`
  display: flex;
  flex: none;
  justify-content: center;
  align-items: center;
`;

const StarContainer = styled.div`
  padding-left: 0.5rem;
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    text-align: start;
  }
`;
const StarNumber = styled.div`
  color: ${theme.light.colors.grayDark};
  font-family: 'MontserratMedium' !important;
  font-size: 1.4rem;
  font-style: normal;
  line-height: normal;
`;

const GET_REVIEWS = gql`
  query getReviews {
    reviews {
      note
      reviewCount
    }
  }
`;

export const Ratings = () => {
  const { data } = useQuery<getReviews>(GET_REVIEWS);
  const opinion = `${data && data.reviews && data.reviews.note}/5 sur ${
    data && data.reviews && data.reviews.reviewCount
  } avis`;
  const starReviews = (data && data.reviews && data.reviews.note) || 0;

  const Rates = () => (
    <RatingsContainer>
      <img src={trustedReviewsLogoPath} alt="Avis verifiés" loading="lazy" />
      <StarContainer>
        <StarCounter starCount={starReviews} />
        <StarNumber>{opinion}</StarNumber>
      </StarContainer>
    </RatingsContainer>
  );
  return <Social title="L’avis de nos clients" content={<Rates />} />;
};
