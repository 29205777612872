import styled from 'styled-components';
import { theme } from 'Ui';
import { IconIconeRight } from 'Ui/Icons';
import BcBackground from 'Ui/Icons/svg/BCBackground.svg';

type LineProps = {
  icon?: React.ReactNode;
  text?: string | React.ReactNode;
  src?: string;
  target?: string;
  onClick?: () => void;
  withIcon?: boolean;
  hasBorder?: boolean;
  disabled?: boolean;
  className?: string;
};
const Link = styled.a<{ hasBorder?: boolean }>`
  display: flex;
  align-items: center;
  font-family: 'MontserratMedium';
  font-size: 1.4rem;
  color: ${theme.light.colors.grayUltraDark};
  border-bottom: ${(props) => props.hasBorder && '1px solid #ccc'};
  :hover {
    color: ${theme.light.colors.purple};
    .aide {
      svg path {
        fill: ${theme.light.colors.purple};
      }
    }
  }
`;

export const Header = styled.div`
  color: #fff;
  font-family: 'MontserratSemiBold' !important;
  font-size: 1.6rem;
  border-radius: 8px 8px 0px 0px;
  box-shadow: 0px -1px 9px 0px rgba(0, 0, 0, 0.2);
  background: ${theme.light.colors.purple};
`;

export const HeaderBackground = styled.div`
  background-image: url(${BcBackground}),
    var(
      --gradient-normal-violet,
      linear-gradient(180deg, #6c4dff 0%, #8268ff 100%)
    );
  background-repeat: no-repeat;
  padding: 1.9rem 3.9rem;
  border-radius: 8px 8px 0px 0px;
  height: 5.5rem;
  width: 100%;
`;

export const LinkLine = ({
  icon,
  text,
  src,
  target = '',
  onClick,
  withIcon = true,
  className,
  hasBorder = false,
  disabled = false,
}: LineProps) => {
  return (
    <Link
      style={{ paddingBottom: '2rem' }}
      href={src}
      hasBorder={hasBorder}
      onClick={onClick}
      target={target}
    >
      <div
        className={className}
        style={{ width: 40, height: '2.4rem', fontSize: '2.4rem' }}
      >
        {icon}
      </div>
      <div
        style={{
          flex: 1,
          lineHeight: '18px',
        }}
      >
        {text}
      </div>
      {withIcon && <IconIconeRight />}
    </Link>
  );
};

export const Separator = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1"
    height="18"
    viewBox="0 0 1 18"
    fill="none"
  >
    <path d="M0.5 0V18" stroke="url(#paint0_linear_288_19369)" />
    <defs>
      <linearGradient
        id="paint0_linear_288_19369"
        x1="0.5"
        y1="9.02257"
        x2="1.50345"
        y2="9.02298"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EDE9FF" />
        <stop offset="1" stopColor="#F7F5FF" />
      </linearGradient>
    </defs>
  </svg>
);

export const Indicator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  border: 1px solid ${theme.light.colors.purple};
  border-radius: 50%;
`;

export const Side = styled.div`
  border-right: 1px solid #ddd;
  padding: 2rem;
  width: ${96.5 - 25 - 0.2}rem;
  max-width: ${(props) => {
    var w =
      Math.max(document.documentElement.clientWidth, window.innerWidth || 0) /
      10;
    return w - 6 - 25 - 0.2;
  }}rem;
  background-color: #f1f2f3;
`;
