import React, { useState, ComponentPropsWithoutRef } from 'react';
import { Input } from './Input';
import { DatePicker } from './DatePicker';
import { Modal } from './Modal';
import dayjs from 'dayjs';

type DateInputProps = Omit<
  React.ComponentPropsWithRef<typeof Input>,
  'onChange' | 'value'
> & {
  datePickerProps?: ComponentPropsWithoutRef<typeof DatePicker>;
  onChange?: (date: Date) => void;
  value?: any;
};
export const DateInput = React.forwardRef(
  (
    { datePickerProps, onChange, value: parentValue, ...rest }: DateInputProps,
    ref
  ) => {
    const [dateModal, setDateModal] = useState(false);
    const [value, setValue] = useState(parentValue);

    return (
      <>
        <div onClick={() => setDateModal(true)}>
          <Input
            readOnly
            {...rest}
            className="v4Input"
            ref={ref}
            value={value ? dayjs(value).format('DD/MM/YYYY') : ''}
            autoComplete="off"
            onChange={() => null}
            onFocus={() => setDateModal(true)}
          />
        </div>
        <Modal
          isOpen={dateModal}
          size={400}
          noPadding
          onClose={(e) => {
            setDateModal(false);
          }}
        >
          <DatePicker
            {...datePickerProps}
            date={value}
            onSelect={(date) => {
              setValue(date);
              onChange && onChange(date);
              setDateModal(false);
            }}
          ></DatePicker>
        </Modal>
      </>
    );
  }
);
