import React, { useEffect, useState } from 'react';

import AuthStore from 'Store/AuthStore';
import { useNavigate, useParams } from 'react-router-dom';

import { SET_AUTOLOG } from './autologQuery';
import { useApolloClient } from '@apollo/client';
import { setAutolog, setAutologVariables } from './__generated__/setAutolog';
import { Spinner } from 'Ui';
import styled from 'styled-components/macro';
import { allowedTypes } from 'Utils/memberTypes';

interface Props {
  component: React.ComponentType<any>;
  redirectTo?: any;
  pageType?: string;
  isLoggedIn?: boolean;
  preferenceJwt?: string;
}

const SpinnerStyled = styled.div`
  display: flex;
  justify-content: center;

  margin-top: 5rem;
  margin-bottom: 5rem;
`;

export default ({
  component: Component,
  redirectTo,
  pageType,
  isLoggedIn,
  preferenceJwt,
  ...rest
}: Props) => {
  const apolloClient = useApolloClient();
  const setAuth = AuthStore.useStoreActions((actions) => actions.setAuth);
  const setEmailSuspect = AuthStore.useStoreActions(
    (actions) => actions.setEmailSuspect
  );
  const setPrefrenceJwt = AuthStore.useStoreActions(
    (action) => action.setPrefrenceJwt
  );
  const [loading, setLoading] = useState(false);
  const history = useNavigate();
  const { key, memberId } = useParams();

  useEffect(() => {
    if (key) {
      setAutologs();
    } else {
      setLoading(true);
    }
  }, [key]);

  const setAutologs = async () => {
    try {
      const response = await apolloClient.mutate<
        setAutolog,
        setAutologVariables
      >({
        mutation: SET_AUTOLOG,
        variables: {
          token: key,
          memberId,
        },
      });

      if (response && response.data && response.data.autolog) {
        const data = response.data.autolog;
        if (allowedTypes.includes(data.sessionMemberType)) {
          setAuth(data);
          setPrefrenceJwt(data.jwt);
        }
        if (data.sessionMemberType === 'suspect') {
          setEmailSuspect(data && data.member && data.member.email);
          setPrefrenceJwt(data.jwt);
        }
        history(redirectTo);
      }
    } catch (e: any) {
      const graphQLErrorCode =
        e && e.graphQLErrors && e.graphQLErrors[0].extensions.bzcCode;
      if (graphQLErrorCode === 'ERROR_UNAUTHORIZED') {
        window.location.href = '/';
      }
    }
  };

  if (!loading) {
    return (
      <SpinnerStyled>
        <Spinner size={20} />
      </SpinnerStyled>
    );
  }
  return <>{loading && <Component preferenceJwt {...rest} />}</>;
};
