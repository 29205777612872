import React, { useState } from 'react';
import posed from 'react-pose';
import styled from 'styled-components/macro';
import { theme } from 'Ui';
import IconeBottom from './Icons/icons/IconeBottom';

const ExpandableCardStyled = styled.div<{ hollow: boolean; cursor: boolean }>`
  ${(props) =>
    props.hollow ? 'border-top: 1px solid #ccc;' : ' border: 1px solid #ccc;'}
  font-family: 'MontserratMedium', sans-serif;
  .head {
    font-weight: 500;
    cursor: pointer;
    ${(props) => (props.cursor ? 'cursor: pointer;' : ' cursor: default;')}
    ${(props) => (props.hollow ? 'padding: 0.5rem;' : ' padding: 1.5rem;')}
    font-size: 1.6rem;
    display: flex;
    /* color: ${theme.light.colors.purple}; */
    justify-content: space-between;
    align-items: center;
  }
  .body {
    padding: 1.5rem;
    font-size: 1.4rem;
  }
  :last-child {
    border-bottom: 1px solid #ccc;
  }
`;

const IconChevronDownAnimated = posed.div({
  opened: {
    rotateZ: '180deg',
    transition: { ease: 'easeInOut', duration: 200 },
  },
  closed: { rotateZ: '0deg', transition: { ease: 'easeInOut', duration: 200 } },
});

type ExpandableCardProps = {
  title: React.ReactNode;
  children: React.ReactNode;
  isOpenDefault?: boolean;
  isArrow?: boolean;
  hollow?: boolean;
  cursor?: boolean;
  activeFacet?: boolean;
  onToggle?: () => void;
};

export const ExpandableCard = ({
  title,
  children,
  isOpenDefault = false,
  isArrow = true,
  hollow = false,
  cursor = false,
  activeFacet,
  onToggle,
}: ExpandableCardProps) => {
  const [opened, setOpened] = useState(isOpenDefault);

  return (
    <ExpandableCardStyled hollow={hollow} cursor={cursor}>
      <div
        className="head"
        onClick={() => {
          // setOpened(!opened);
          onToggle && onToggle();
        }}
      >
        {activeFacet ? (
          <b style={{ color: `${theme.light.colors.purple}` }}>{title}</b>
        ) : (
          <b>{title}</b>
        )}
        {isArrow && (
          <IconChevronDownAnimated pose={activeFacet ? 'opened' : 'closed'}>
            <IconeBottom />
          </IconChevronDownAnimated>
        )}
      </div>
      {/* {opened && <div className="body">{children}</div>} */}
      {activeFacet && <div className="body">{children}</div>}
    </ExpandableCardStyled>
  );
};

export const ExpandableCardStack = styled.div`
  & > div {
    margin-top: -1px;
    &:first-of-type {
      margin-top: 0;
    }
  }
  @media screen and (max-width: 480px) {
    overflow: auto;
    margin-bottom: 3rem;
  }
`;
