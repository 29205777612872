import * as React from "react";
const SvgApple = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 18 20"
    {...props}
  >
    <path
      fill="#B3B3B3"
      d="M16.749 15.586a11 11 0 0 1-1.085 1.933q-.857 1.21-1.398 1.674-.837.763-1.796.784-.69 0-1.66-.392t-1.786-.392q-.855 0-1.837.392-.983.394-1.588.413-.92.04-1.837-.805-.585-.505-1.462-1.733-.94-1.31-1.546-3.043-.649-1.872-.649-3.627 0-2.01.878-3.465A5.1 5.1 0 0 1 2.82 5.483a5 5 0 0 1 2.485-.695q.73.002 1.922.443 1.19.443 1.525.444.252 0 1.69-.523 1.36-.484 2.298-.404 2.549.202 3.822 1.994-2.277 1.369-2.255 3.827.021 1.915 1.4 3.181.622.587 1.397.909-.167.482-.356.927M12.855.4Q12.854 1.9 11.75 3.2c-.887 1.027-1.96 1.621-3.123 1.527a3 3 0 0 1-.024-.379c0-.96.422-1.987 1.171-2.828q.562-.638 1.426-1.061Q12.065.04 12.833 0q.022.2.022.4"
    />
  </svg>
);
export default SvgApple;
