import styled from 'styled-components';
import { Advantages } from './sections/Advantages';
import { Reassurance } from './sections/Reassurance';
import { Socials } from './sections/Socials';
import { Payments } from './sections/Payments';
import { TermsAndCookies } from './sections/TermsAndCookie';
import { SiteMap } from './sections/SiteMap';
import { useEffect } from 'react';
import Cookie from 'js-cookie';
import RgpdBannerModal from 'Components/modals/RgdpBannerModal';
import OptinFooter from './sections/Optin/OptinFooter';

const FooterContainer = styled.div`
  padding: 3.1rem 0;
`;

const ReassuranceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 33px;
  margin: 4rem 0;
`;

const MenuContainer = styled.div`
  background-color: #f5f5f5;
  padding: 7.4rem 2.7rem 2.7rem;
`;

type FooterProps = {
  external?: boolean | undefined;
  marginTop?: number;
};

export const FooterV2 = ({ external, marginTop }: FooterProps) => {
  useEffect(() => {
    if (!Cookie.get('RgpdBanner')) {
      RgpdBannerModal({ external });
    }
  }, []);
  return (
    <FooterContainer>
      <OptinFooter />
      <Advantages />
      <ReassuranceContainer>
        <Reassurance />
        <Socials />
      </ReassuranceContainer>
      <MenuContainer>
        <SiteMap />
        <Payments />
      </MenuContainer>
      <TermsAndCookies />
    </FooterContainer>
  );
};
