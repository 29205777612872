import styled, { css } from "styled-components/macro";

const animatedBackground = css`
  @keyframes placeHolderShimmer {
    0% {
      background-position: -468px 0;
    }
    100% {
      background-position: 468px 0;
    }
  }
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  height: 96px;
  position: relative;
`;

export const PlaceholderLine = styled.div`
  ${animatedBackground}
  width : 100%;
  height: 15px;
  margin-top: 15px;
`;

export const PlaceholderImage = styled.div<{ ratio: number }>`
  width: 100%;
  padding-top: ${props => props.ratio * 100}%;
  position: relative;
  &:after {
    content: "";
    ${animatedBackground}
    position : absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
`;

export const PlaceholderWord = styled.div`
  ${animatedBackground}
  display: inline-block;
  width: 50px;
  background-size: 800px 40px;
  height: 15px;
  margin-top: 15px;
  margin-right: 10px;
`;

export const PlaceholderBlock = styled.div`
  ${animatedBackground}
  display: block;
  background-size: 800px 40px;
  height: 60px;
  margin-bottom: 10px;
`;
