import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import GenericType from './types/GenericType';
import UiStore from 'Store/UiStore';

interface Props
  extends Pick<GenericType, 'block' | 'kind'>,
    Omit<React.HTMLProps<HTMLInputElement>, 'kind'> {
  placeholder?: any;
  className?: string;
  onPressEnter?: () => void;
  style?: React.CSSProperties;
  dataTest?: string;
}

const Wrapper = styled.div<Props>`
  width: '100%';
`;

const InputStyled = styled.div<Props>`
  width: 100%;
  height: 4.4rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  svg {
    width: 1.7rem;
    height: 1.7rem;
    margin-right: 1rem;
  }
  /* border-radius: 3rem; */
  border-bottom: 1px solid #d8d8d8;
  padding: 0 4px 0 5px;
  height: 4.4rem;
  position: relative;
  box-sizing: border-box;

  /* Tricking browser auto fill color */
  input:-webkit-autofill {
    -webkit-border-radius: 10px 10px 10px 10px !important;
    -webkit-box-shadow: 0 0 0 50px white inset;
    -webkit-text-fill-color: #333;
    border-radius: 10px !important;
  }
  input:-webkit-autofill:focus {
    -webkit-border-radius: 10px 10px 10px 10px !important;
    -webkit-box-shadow: 0 0 0 50px white inset;
    -webkit-text-fill-color: #333;
    border-radius: 10px !important;
  }
`;

const InputContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
  height: 100%;
`;

const InputElement = styled.input`
  border: none;
  transition: 0.2s;
  /* background: #f9f9f9; */
  outline: none;
  width: 100%;
  :placeholder {
    width: 100%;
    overflow: hidden;
    font-size: 1.4rem;
    color: #4f4f4f;
    line-height: 1;
  }
  @media screen and (max-width: 48rem) {
    font-size: 1.4rem;
  }
`;

export const InputSearchMobile = React.forwardRef(
  (
    {
      type = 'text',
      placeholder = '',
      onChange,
      ref: oldRef,
      style,
      dataTest,
      onPressEnter,
      onKeyPress,
      ...rest
    }: Props,
    ref
  ) => {
    const setSearchStringHeader = UiStore.useStoreActions(
      (actions) => actions.setSearchStringHeader
    );
    const searchString = UiStore.useStoreState(
      (state) => state.searchStringHeader
    );
    const [value, setValue] = useState('');

    useEffect(() => {
      if (searchString === '') {
        setValue('');
      }
    }, [searchString === '']);

    return (
      <Wrapper {...{ style }}>
        <InputStyled>
          <InputContainer>
            <InputElement
              className="fontMontserrat"
              style={{
                fontFamily: 'Montserrat !important',
                fontSize: '14px !important',
              }}
              onKeyPress={(e: any) => {
                if (e.key === 'Enter') {
                  onPressEnter && onPressEnter();
                  // @ts-ignore
                  e.target.blur();
                }
                if (onKeyPress) onKeyPress(e);
              }}
              placeholder={placeholder}
              data-test={dataTest}
              value={value}
              onChange={async (e: any) => {
                setValue(e.target.value);
                setSearchStringHeader(e.target.value);
              }}
              ref={(thisRef: any) => {
                // @ts-ignore
                ref && ref(thisRef);
              }}
              {...rest}
              as="input"
            />
          </InputContainer>
        </InputStyled>
      </Wrapper>
    );
  }
);
