import React, { useEffect, useState } from 'react';
import { IconCoeur } from 'Ui/Icons';
import styled from 'styled-components/macro';
import { Link, theme } from 'Ui';
import { CoeurMenu, HeartHeader } from 'Ui/Icons/icons';

const WishListMenu = styled.div<{ isActive?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  a {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  /* svg {
    width: 2.8rem;
    height: 2.8rem;
  } */
  svg path {
    /* stroke-width: 4px !important; */
    stroke: ${(props) =>
      props.isActive ? `${theme.light.colors.purple} !important` : '#000000'};
  }
`;

const MenuItem = styled.div<{ isActive?: boolean }>`
  font-family: 'MontserratSemiBold';
  font-size: 1.2rem;
  text-transform: uppercase;
  font-weight: 600;
  margin-top: 0.8rem;
  color: ${(props) =>
    props.isActive ? `${theme.light.colors.purple} !important` : '#000000'};
`;

export default () => {
  const [isActive, setIsActive] = useState(false);
  useEffect(() => {
    if (window.location.pathname === '/mes-favoris/') {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [window.location.pathname]);

  return (
    <WishListMenu isActive={isActive}>
      <Link to="/mes-favoris/">
        <HeartHeader width="30" height="30" />
      </Link>
    </WishListMenu>
  );
};
