import styled from 'styled-components/macro';

const theme = {
    cardSaleHeight: {
      Xs: 155,
      responsive: 183,
      Small: 228,
      Medium: 312.25,
      Large: 310,
    } as { [index: string]: number },

};

export const SaleContainer = styled.div<{ size?: string | number}>`
  position: relative;
  display: block;
  height: ${(props) => {
      if (typeof props.size === 'number') return props.size + 'px';
      else return theme.cardSaleHeight[props.size] + 'px';
  }};
  @media (max-width: 968px) {
    width: 100%;
    height: inherit;
  }
`;
