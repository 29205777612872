import { GtmInfo } from 'Fragments/GtmInfo';
import gql from 'graphql-tag';

export const LOGOUT = gql`
  query getLogout {
    logout {
      sessionMemberType
      jwt
      sessionId
      isMemberDisconnected
      isConnected
      member {
        id
        isVip
        email
        firstName
        lastName
        phoneNumber
        ...GtmInfo
      }
    }
  }
  ${GtmInfo}
`;
