// @ts-ignore
import ReactFacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import styled from 'styled-components';
import { ConnectWith } from './ConnectWith';
import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import UiStore from 'Store/UiStore';
import {
  ApplicationEnum,
  EnvironmentInput,
  ModuleEnum,
} from '__generated__/graphql-global-types';
import { IconApple, IconFb, IconGoogle } from 'Ui/Icons';
import { redirect } from 'Utils/login';
import AuthStore from 'Store/AuthStore';
import { setRegisterSocial } from './query';
import { useApolloClient } from '@apollo/client';
import { theme } from 'Ui';
import Helmet from 'react-helmet';
import { getSignup } from 'Libs/GA4/common';

const SocialContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1.6rem;
  width: 100%;
  /* margin: 0 30px; */
  @media screen and (max-width: 48rem) {
    display: inline-flex;
    margin: 0 0rem;
    gap: 0.8rem;
    justify-content: center;
  }
`;
const Separator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #dedcdc;
  /* margin: 0 3rem; */
  width: 100%;
  height: 1px;
  span {
    background-color: #fff;
    padding: 1rem;
    font-size: 1.4rem;
    text-transform: 'uppercase';
    font-family: 'MontserratMedium' !important;
  }
`;

const SeparatorContainer = styled.div`
  display: inline-flex;
  align-items: center;
  margin: 'auto';
  margin-top: '2rem';
  width: 100%;
  @media screen and (max-width: 48rem) {
    width: '30rem';
  }
`;

const TextBetweenSeparator = styled.span`
  background-color: #fff;
  color: ${theme.v2.text.darkGray};
  padding: 1rem;
  line-height: 1.4rem;
  font-size: 1.4rem;
  text-transform: 'uppercase';
  font-family: 'MontserratMedium' !important;
  text-transform: uppercase;
  padding: 0 3rem;
  display: inline-flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const DisabledText = styled.div`
  font-family: 'Montserrat';
  font-size: 12px;
  line-height: 16px;
  color: ${theme.v2.text.gray};
  margin: 1.9rem 0 0 0;
`;

const Strong = styled.span`
  font-family: 'MontserratMedium' !important;
  font-size: 12px;
  line-height: 16px;
  text-decoration: underline;
  color: ${theme.v2.text.gray};
  cursor: pointer;
`;

type Props = {
  onOpenCookies: () => void;
};

export const ConnectingWith = ({ onOpenCookies }: Props) => {
  const apolloClient = useApolloClient();
  const [loadingFb, setLoadingFb] = useState(false);
  const [loadingGoogle, setLoadingGoogle] = useState(false);
  const [loadingApple, setLoadingApple] = useState(false);
  const cookieMarketingStore = UiStore.useStoreState(
    (state) => state.cookieMarketing
  );

  const setAuth = AuthStore.useStoreActions((actions) => actions.setAuth);

  const environment: EnvironmentInput = {
    application: isMobile ? ApplicationEnum.MOBILE : ApplicationEnum.DESKTOP,
    module: ModuleEnum.SITE,
  };
  const connectMemberWithFacebook = async (data: any) => {
    const accessToken: string = data.accessToken;
    setLoadingFb(true);
    try {
      const response = await setRegisterSocial(
        apolloClient,
        'Facebook',
        window.location.href,
        environment,
        accessToken
      );
      if (response && response.data.registerMemberBySocialNetwork) {
        const data = response.data.registerMemberBySocialNetwork;
        if (!data.isCompletedProfile) {
          if (window.location.pathname === '/v2/tunnel/basket/') {
            sessionStorage.setItem('isSocial', 'oui');
          }
          if (data.member) {
            setAuth(data);
            getSignup({
              event: 'sign_up',
              signup_type: 'FB',
              user_id: data?.member?.id,
            });
          }
          window.location.href = '/login/';
        } else {
          if (data.member) {
            setAuth(data);
            getSignup({
              event: 'sign_up',
              signup_type: 'FB',
              user_id: data?.member?.id,
            });
          }
        }
      }

      window.localStorage.setItem('subscriptionTracking', 'inscription-ok');
      setLoadingFb(false);
      redirect();
    } catch (e) {
      console.log('e', e);
      setLoadingFb(false);
    }
  };

  async function handleCredentialResponse(response: any) {
    // decodeJwtResponse() is a custom function defined by you
    // to decode the credential response.
    try {
      //@ts-ignore
      const client = window.google.accounts.oauth2.initTokenClient({
        client_id:
          '238743526369-q5huuet5p5i6vjbeo4li3cl8g97ncmqe.apps.googleusercontent.com',
        scope: 'openid email profile',
        prompt: '', // Specified as an empty string to auto select the account which we have already consented for use.
        error_callback: (error: any) => {
          console.log('error', error);
        },
        callback: async (tokenResponse: any) => {
          try {
            setLoadingGoogle(true);
            const register = await setRegisterSocial(
              apolloClient,
              'Google',
              window.location.href,
              environment,
              tokenResponse.access_token
            );
            if (register?.data.registerMemberBySocialNetwork?.member) {
              if (
                !register.data.registerMemberBySocialNetwork?.isCompletedProfile
              ) {
                if (window.location.pathname === '/v2/tunnel/basket/') {
                  sessionStorage.setItem('isSocial', 'oui');
                }
                setLoadingGoogle(true);
                const data = register.data.registerMemberBySocialNetwork;
                setAuth(data);
                getSignup({
                  event: 'sign_up',
                  signup_type: 'Google',
                  user_id: data?.member?.id,
                });
                window.location.href = '/login/';
              } else {
                setLoadingGoogle(true);
                const data = register.data.registerMemberBySocialNetwork;
                setAuth(data);
                getSignup({
                  event: 'sign_up',
                  signup_type: 'Google',
                  user_id: data?.member?.id,
                });
                redirect();
              }
            }
          } catch (e) {
            console.log('callback error', e);
            setLoadingGoogle(false);
          }
        },
      });
      await client.requestAccessToken();
    } catch (error) {
      console.log('google error', error);
    }
  }
  // @ts-ignore
  const initApple = () => {
    // @ts-ignore
    window.AppleID.auth.init({
      clientId: 'com.bazarchic-prod.applesignin', // This is the service ID we created.
      scope: 'name email', // To tell apple we want the user name and emails fields in the response it sends us.
      redirectURI: `${process.env.REACT_APP_URI_APPLE}`, // As registered along with our service ID
      state: 'origin:web', // Any string of your choice that you may use for some logic. It's optional and you may omit it.
      usePopup: true, // Important if we want to capture the data apple sends on the client side.
    });

    // Listen for authorization success.
    document.addEventListener('AppleIDSignInOnSuccess', (event: any) => {
      // Handle successful response.
      console.log(event.detail.data);
    });

    // Listen for authorization failures.
    document.addEventListener('AppleIDSignInOnFailure', (event: any) => {
      // Handle error.
      console.log(event.detail.error);
    });
  };

  async function getJwt(data: any) {
    const accessToken = data.authorization.id_token;
    const register = await setRegisterSocial(
      apolloClient,
      'Apple',
      window.location.href,
      environment,
      accessToken
    );
    setLoadingApple(true);
    if (register.data.registerMemberBySocialNetwork?.member) {
      if (!register.data.registerMemberBySocialNetwork?.isCompletedProfile) {
        setLoadingApple(true);
        const data = register.data.registerMemberBySocialNetwork;
        if (window.location.pathname === '/v2/tunnel/basket/') {
          sessionStorage.setItem('isSocial', 'oui');
        }
        setAuth(data);
        getSignup({
          event: 'sign_up',
          signup_type: 'Apple',
          user_id: data?.member?.id,
        });
        window.location.href = '/login/';
      } else {
        setLoadingApple(true);
        const data = register.data.registerMemberBySocialNetwork;
        setAuth(data);
        getSignup({
          event: 'sign_up',
          signup_type: 'Apple',
          user_id: data?.member?.id,
        });
        redirect();
      }
    } else {
      setLoadingApple(true);
      getJwt(data);
    }
  }
  return (
    <>
      {cookieMarketingStore && (
        <Helmet>
          <script src="https://accounts.google.com/gsi/client" />
          <script
            src="https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js"
            onLoad={() => initApple()}
          />
        </Helmet>
      )}
      <div style={{ margin: '2.4rem 0 4rem 0' }}>
        <SeparatorContainer>
          <Separator />
          <TextBetweenSeparator style={{ minWidth: '20rem' }}>
            CONTINUER AVEC
          </TextBetweenSeparator>
          <Separator />
        </SeparatorContainer>
        {!cookieMarketingStore && (
          <DisabledText>
            Pour accéder à cette fonctionnalité, vous devez accepter les
            cookies. Vous pouvez modifier vos choix et cliquer sur le lien{' '}
            <Strong onClick={() => onOpenCookies && onOpenCookies()}>
              gérer mes cookies
            </Strong>
            .{' '}
          </DisabledText>
        )}
        <SocialContainer>
          {cookieMarketingStore ? (
            <ReactFacebookLogin
              appId={process.env.REACT_APP_FACEBOOK_ID || ''}
              callback={connectMemberWithFacebook}
              isMobile={false}
              version="17.0"
              render={(props: any) => (
                <ConnectWith
                  loading={loadingFb}
                  onClickConnectWith={props.onClick}
                  connectWithMethod={'Facebook'}
                  connectWithIcon={<IconFb />}
                />
              )}
            />
          ) : (
            <ConnectWith
              loading={loadingFb}
              onClickConnectWith={() => {}}
              connectWithMethod={'Facebook'}
              connectWithIcon={<IconFb className="facebook" />}
            />
          )}

          <ConnectWith
            loading={loadingApple}
            connectWithMethod={'Apple'}
            connectWithIcon={<IconApple />}
            onClickConnectWith={async () => {
              try {
                // @ts-ignore
                setLoadingApple(true);
                // @ts-ignore
                initApple();
                // @ts-ignore
                const data = await window.AppleID.auth.signIn();
                getJwt(data);
                // Handle successful response.
              } catch (error) {
                console.log('error', error);
                setLoadingApple(false);
                // Handle error.
              }
            }}
          />
          <ConnectWith
            loading={loadingGoogle}
            connectWithMethod={'google'}
            connectWithIcon={<IconGoogle />}
            onClickConnectWith={async () => {
              try {
                // @ts-ignore
                handleCredentialResponse();
                // Handle successful response.
              } catch (error) {
                console.log('error', error);
                // Handle error.
              }
            }}
          />
        </SocialContainer>
        <SeparatorContainer>
          <Separator />
          <TextBetweenSeparator style={{ maxWidth: '6rem' }}>
            OU
          </TextBetweenSeparator>
          <Separator />
        </SeparatorContainer>
      </div>
    </>
  );
};
