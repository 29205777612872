import * as React from "react";
const SvgHeartHeader = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 30 30"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M9.232 6.75h.006a5.28 5.28 0 0 1 3.874 1.633l.002.002 1.347 1.373.533.544.536-.542 1.347-1.362.003-.003a5.413 5.413 0 0 1 7.762 0c2.144 2.195 2.144 5.73 0 7.926h-.001l-3.186 3.271a4223 4223 0 0 1-6.453 6.617l-9.638-9.898-.003-.002c-2.152-2.194-2.145-5.728.002-7.925a5.37 5.37 0 0 1 3.87-1.634Z"
    />
  </svg>
);
export default SvgHeartHeader;
