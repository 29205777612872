import fevadLogoPath from 'Assets/images/fevadLogo_compressed.png';
import RgpdModal from 'Components/modals/RgpdModal';
import { theme } from 'Ui';
import styled from 'styled-components';

const TermsAndConditionContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 112rem;
  margin: 1.3rem auto;
  justify-content: space-between;
  @media screen and (max-width: 48rem) {
    width: calc(96.5% - 2rem);
    align-items: center;
  }
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    width: calc(96.5% - 2rem);
  }
`;

const ImgContainer = styled.div`
  img {
    width: 39px;
    height: 38px;
    flex-shrink: 0;
    @media screen and (max-width: 48rem) {
    }
  }
`;

const SmallLinks = styled.nav`
  padding: 1.6rem 0 2.5rem 0;
  font-family: 'MontserratSemiBold' !important;
  color: ${theme.light.colors.grayDark};
  font-size: 1.2rem;
  font-style: normal;
  line-height: 22px; /* 183.333% */
  text-transform: uppercase;
  display: flex;
  flex-wrap: wrap;

  a {
    &:hover {
      color: ${theme.light.colors.purple};
    }
  }
  span {
    margin: 0 0.5rem;
  }
  @media screen and (max-width: 48rem) {
    /* width: calc(90% - 2rem); */
    display: flex;
    flex-direction: row;
    font-size: 1rem;
    flex-wrap: wrap;
    padding: 0;
  }
`;

export const TermsAndCookies = () => {
  const date = new Date();
  const month = `© BazarChic.com ${date.getFullYear()}`;
  return (
    <TermsAndConditionContainer>
      <SmallLinks>
        <div>{month}</div>
        <span>-</span>
        <div>
          <a
            target="_blank"
            href="https://fr.bazarchic.com/contenu/conditions-generales-de-ventes/"
          >
            Conditions générales de vente
          </a>
        </div>
        <span>-</span>
        <div>
          <a
            target="_blank"
            href="https://ressources.bazarchic.com/conditions-des-offres/"
          >
            *CONDITIONS ET MODALITES DES OFFRES
          </a>
        </div>
        <span>-</span>
        <div>
          <a
            href="# "
            onClick={(e) => {
              e.preventDefault();
              RgpdModal({});
            }}
          >
            Paramétrage cookies
          </a>
        </div>
      </SmallLinks>
      <ImgContainer>
        <img
          className="footer_partners_img"
          src={fevadLogoPath}
          alt="Fevad"
          loading="lazy"
        />
      </ImgContainer>
    </TermsAndConditionContainer>
  );
};
