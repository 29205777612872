import React from 'react';
import { createModal } from 'react-modal-promise';
import { ModalTop } from 'Ui';
import styled from 'styled-components/macro';
import SearchAutoHeader from 'Components/Header/SearchHeader';

type ModalProps = {
  open: boolean;
  close: (shouldGoOn: boolean) => void;
  searchString?: any;
};

const SearchModalBody = styled.div`
  flex: 1;
  flex-direction: column;
  display: flex;
  align-items: center;
  font-family: 'Montserrat';
  justify-content: space-between;
  font-size: 1.6rem;
  width: 100%;
  .contentSearch {
    /* width: 96.5rem; */
    width: 100%;
    margin: 0 auto;
    @media screen and (max-width: 480px) {
      width: 100%;
      margin: 0;
      padding-top: 0.6rem;
    }
  }
  @media screen and (min-width: 48rem) {
    flex-direction: row;
  }
`;

const ModalSearch = ({ open, close }: ModalProps) => {
  return (
    <ModalTop isOpen={open} onClose={() => close(false)} size="full">
      <SearchModalBody>
        <div className="contentSearch">
          <SearchAutoHeader isMember />
        </div>
      </SearchModalBody>
    </ModalTop>
  );
};

export default createModal(ModalSearch);
