import { action, thunk, Action, Thunk, createContextStore } from "easy-peasy";
import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
// import { NormalizedCacheObject } from "apollo-cache-inmemory";
import { isMobile } from "react-device-detect";
import {
  Configuration,
  ConfigurationVariables
} from "./__generated__/Configuration";

import {
  OsEnum,
  DeviceEnum
} from "../__generated__/graphql-global-types";

import { DEVICE_CONFIGURATION } from "./query";

type ConfigurationModel = {
  apolloClient: ApolloClient<object> | undefined;
  showOutletBloc: boolean

  setShowOutletBloc: Action<ConfigurationModel, boolean>;

  // setAuth: Action<ConfigurationModel, AuthParam>;

  initConfiguration: Thunk<
    ConfigurationModel,
    void,
    { apolloClient: ApolloClient<NormalizedCacheObject> }
  >;
};

const congigurationModel = (
  apolloClient: ApolloClient<object> | undefined
): ConfigurationModel => ({
  apolloClient,
  showOutletBloc: false,

  // actions

  setShowOutletBloc: action((state, payload) => {
    state.showOutletBloc = payload;
  }),


  // thunks
  initConfiguration: thunk(async (actions, payload, { getState }) => {

    const apolloClient = getState().apolloClient;
    if (!apolloClient) return;
    const mobileOrDesktop = isMobile ? DeviceEnum.MOBILE : DeviceEnum.DESKTOP;

    try {
      const result = await apolloClient.query<Configuration, ConfigurationVariables>({
        query: DEVICE_CONFIGURATION,
        variables: { device: mobileOrDesktop, site: OsEnum.SITE }
      });

      if (result.data && result.data.deviceConfiguration) {
        const test = result.data.deviceConfiguration.showOutletBloc
        actions.setShowOutletBloc(test)
      }
    } catch (e) {
      console.log(e);
    }

  })
});

export default createContextStore(congigurationModel, { name: "configuration" });
