import AuthStore from 'Store/AuthStore';
import { isAndroid } from 'react-device-detect';
import styled from 'styled-components';
import { Header, HeaderBackground, Indicator, LinkLine } from './styles';
import { logout } from 'Utils/isLogout';
import { useApolloClient } from '@apollo/client';
import {
  IconMyInfos,
  IconMyOrders,
  IconMyReturn,
  IconMyPref,
  IconMyFilleuls,
  IconFaqs,
  IconPrivilege,
  IconMailMenu,
} from 'Ui/Icons';
import { BonAchat, InfoV2, Logout } from 'Ui/Icons/icons';
import UiStore from 'Store/UiStore';
import { getSales, getSalesVariables } from '../__generated__/getSales';
import { useQuery } from '@apollo/client';
import { useState } from 'react';
import { GET_SALES } from '../AccountMenu';
import { getBasket } from 'Components/Header/HeaderBasket/__generated__/getBasket';
import query from 'Components/Header/HeaderBasket/query';

const MenuContainer = styled.div`
  padding: 2.4rem 3.1rem 0.5rem;
  box-shadow: 0px 1px 9px 0px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 8px 8px;
  max-height: 80vh;
  overflow-y: auto;
`;

export const DropDownUser = () => {
  const [showSales, setShowSales] = useState(false);
  const setAuth = AuthStore.useStoreActions((action) => action.setAuth);
  const member = AuthStore.useStoreState((state) => state.member);
  const consultedSales = UiStore.useStoreState((state) => state.consultedSales);

  const { data } = useQuery<getSales, getSalesVariables>(GET_SALES, {
    variables: { ids: consultedSales },
    // fetchPolicy: "cache-and-network",
  });
  const { refetch } = useQuery<getBasket>(query);

  const consultedSalesObjects = (data && data.salesByIds) || [];

  const consultedSalesNumber = Math.min(6, consultedSalesObjects.length);
  const setSideMenu = UiStore.useStoreActions(
    (action) => action.setSaleSideMenu
  );
  const apolloClient = useApolloClient();
  const linkDownload = isAndroid
    ? '/home/app/p/android/source/interstitiel/'
    : '/home/app/p/ios/source/interstitiel/';

  const SeenSales = () => (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <div>Ventes Vues</div>
      {!!consultedSalesNumber && <Indicator>{consultedSalesNumber}</Indicator>}
    </div>
  );

  return (
    <>
      <Header>
        <HeaderBackground>
          Bonjour {member && member.firstName} !
        </HeaderBackground>
      </Header>
      <MenuContainer>
        <LinkLine
          text="Mes commandes"
          src="/commandes/index/"
          icon={<IconMyOrders width={24} heigh={24} />}
          withIcon={false}
        />
        <LinkLine
          text="Mon compte"
          src="/account/index/"
          icon={<IconMyInfos width={24} heigh={24} />}
          withIcon={false}
        />
        <LinkLine
          text="Mes retours"
          src="/account/retours/"
          icon={<IconMyReturn width={24} heigh={24} />}
          withIcon={false}
          hasBorder={showSales && consultedSalesNumber ? true : false}
        />
        <LinkLine
          text="Mes bons d'achat"
          src="/account/vouchers/"
          icon={<BonAchat width={24} height={24} />}
          withIcon={false}
        />
        {/* {!!consultedSalesNumber && (
          <div style={{ paddingTop: '1.9rem' }}>
            <LinkLine
              withIcon={false}
              hasBorder={true}
              text={<SeenSales />}
              disabled={!consultedSalesNumber}
              onClick={() => consultedSalesNumber && setSideMenu('sales')}
            />
          </div>
        )} */}
        <LinkLine
          text="Aide & contact"
          src="/contact/"
          className="aide"
          icon={<IconFaqs width={24} heigh={24} />}
          withIcon={false}
        />
        <LinkLine
          text="Mes préférences de communication"
          src="/account/newsletters/"
          icon={<IconMailMenu width={24} heigh={24} />}
          withIcon={false}
        />
        <LinkLine
          text="Les applis mobile BazarChic"
          src="/home/mobilite/"
          icon={<IconMyPref width={24} height={24} />}
          withIcon={false}
        />
        {/* <LinkLine
          withIcon={false}
          text="Club privilège"
          src="/accueil/privilege/"
          icon={<IconPrivilege width={24} heigh={24} />}
        /> */}
        <LinkLine
          text="Parrainage"
          src="/accueil/parrainage/"
          icon={<IconMyFilleuls width={24} heigh={24} />}
          withIcon={false}
          hasBorder={true}
        />
        <div style={{ paddingTop: '1.9rem', cursor: 'pointer' }}>
          <LinkLine
            withIcon={false}
            text="Déconnexion"
            icon={<Logout width={24} heigh={24} />}
            onClick={() => {
              logout(apolloClient)
                .then((data) => {
                  if (data) {
                    const payload = {
                      jwt: data.logout.jwt,
                      sessionId: data.logout.sessionId,
                      member: data.logout.member,
                    };
                    if (!!data) {
                      setAuth(payload);
                      refetch();
                      window.location.href = '/';
                    }
                  }
                })
                .catch((err) => {
                  console.log(err);
                  window.location.href = '/';
                });
            }}
          />
        </div>
      </MenuContainer>
    </>
  );
};
