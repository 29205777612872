import * as React from "react";
const SvgValidCheckAvailable = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 25 24"
    {...props}
  >
    <path
      stroke="url(#validCheckAvailable_svg__a)"
      strokeWidth={1.5}
      d="M4.88 10.896 10.488 17 21.08 7"
    />
    <defs>
      <linearGradient
        id="validCheckAvailable_svg__a"
        x1={12.98}
        x2={12.98}
        y1={7}
        y2={17}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6C4DFF" />
        <stop offset={1} stopColor="#8268FF" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgValidCheckAvailable;
