import styled from 'styled-components';
import { theme } from 'Ui';
import { CheckMark } from 'Ui/Icons/icons';

const Paragraph = styled.div`
  color: ${theme.v2.text.lightGray};
  text-align: center;
  font-family: 'MontserratMedium';
  font-size: 1.4rem;
  line-height: 2.1rem;
`;
const SuccessIcon = styled.div`
  background-color: ${theme.light.colors.purple};
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  svg path {
    stroke: white !important;
  }
  svg {
    font-size: 1rem;
  }
`;

const ThanksContainer = styled.div`
  font-family: 'MontserratSemiBold';
  font-size: 1.6rem;
  color: ${theme.v2.text.darkGray};
`;
const SuccessContainer = styled.div`
  display: flex;
  gap: 0.8rem;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 1.6rem 0 0.8rem 0;
`;
export const ResetPasswordIntruction = (emailSend?: string) => (
  <div>
    <SuccessContainer>
      <SuccessIcon>
        <CheckMark />
      </SuccessIcon>
      <ThanksContainer>Merci !</ThanksContainer>
    </SuccessContainer>
    <Paragraph className="fontMontserratMedium">
      Nous vous enverrons un e-mail avec un lien pour créer un nouveau mot de
      passe.
    </Paragraph>
  </div>
);

export const placeHolder = (
  <div style={{ fontFamily: 'MontserratLight !important' }}>
    E-mail<sup>*</sup>
  </div>
);
