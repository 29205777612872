import React from 'react';
import styled from 'styled-components/macro';
import { PageContainer } from 'Ui';
import query from '../headerNavigationQuery';
import { useQuery } from '@apollo/client';
import { getHeaderNavigation } from '../__generated__/getHeaderNavigation';
import { MenuBar, MenuItem } from '../menuComponents';
import AuthStore from 'Store/AuthStore';
import { storageFactory } from 'Libs/storageFactory';
import { DeviceEnum, OsEnum } from '__generated__/graphql-global-types';
import { isMobile } from 'react-device-detect';
import { theme } from 'Ui/theme';

// const DesktopHeaderHeight = 6.5;

const HeaderStyled = styled.div`
  background-color: white;
  //z-index: 10000;
  position: relative;
  // height: 9.3rem;
`;

// const LogoStyled = styled.img`
//   width: 31rem;
//   height: 100%;
// `;

// const TopBar = styled.div`
//   position: relative;
//   height: ${DesktopHeaderHeight}rem;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
// `;

// const FixBar = styled.div`
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100vw;
//   background-color: #fff;
//   z-index: 100;
// `;

// const SearchInputDiv = styled.div`
//   display: flex;
//   border-bottom: 1px solid #888;
//   box-sizing: border-box;
//   font-size: 1.4rem;
//   align-items: center;
//   width: 17.6rem;
//   padding-bottom: 0.6rem;
//   .colorGray {
//     color: #000000;
//   }
//   &:hover {
//     cursor: text;
//   }
// `;

// const StyledIconSearch = styled.div`
//   font-size: 3rem;
//   margin-right: 10px;
// `;

export default () => {
  const menuDevice: DeviceEnum = isMobile
    ? DeviceEnum.MOBILE
    : DeviceEnum.DESKTOP;

  const loggedIn = AuthStore.useStoreState((state) => state.isLoggedIn);
  const localStore = storageFactory(() => localStorage);
  const { data } = useQuery<getHeaderNavigation>(query, {
    variables: { device: menuDevice, os: OsEnum.SITE },
  });
  const links = (data && data.headerNavigation && data.headerNavigation) || [];
  // const home = '/accueil/index/' === window.location.pathname;
  // const homeType = 'ACCEUIL';
  const linksMenu = links.map((link, index) => {
    return {
      label: link.label,
      url: link.url,
      color: link.color,
      alt: link.alt,
      type: link.type,
      target: link.target,
      selected: link.url === window.location.pathname,
    };
  });

  const setColor = (color: any, link: string) => {
    return color
      ? color
      : link === window.location.pathname
      ? `${theme.light.colors.purple}`
      : '#000';
  };

  const isLoggedIn =
    loggedIn || !!localStore.getItem('isLoggedIn') ? true : false;

  return (
    <>
      {/* {!loggedIn && <AnonymousHeader />} */}
      <HeaderStyled>
        {isLoggedIn && (
          <>
            <div
              style={{
                boxShadow: '0px 1px 9px 0px rgba(0, 0, 0, 0.10)',
                marginBottom: '2rem',
              }}
            >
              <PageContainer>
                <MenuBar
                // className="reactHomeMenuV4"
                >
                  {linksMenu
                    .filter((link) => !!link.label)
                    .map((link, index) => (
                      <MenuItem
                        style={{ color: setColor(link.color, link.url) }}
                        href={link.url}
                        target={link.target || ''}
                        key={index}
                        selected={link.selected}
                        type={link.type || ''}
                      >
                        {link.label}
                      </MenuItem>
                    ))}
                </MenuBar>
              </PageContainer>
            </div>
          </>
        )}
      </HeaderStyled>
    </>
  );
};
