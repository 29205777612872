import gql from "graphql-tag";
import { InfoProduct } from "Fragments/Product";

export default gql`
  query SearchProducts(
    $search: String
    $facetFilters: [FacetInput]
    $pagination: PaginationInput
    $sort: SortEnum
    $filters: FiltersInput
  ) {
    products: searchProduct(
      search: $search
      facetFilters: $facetFilters
      pagination: $pagination
      sort: $sort
      filters: $filters
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          ...InfoProduct
        }
      }
    }
  }
  ${InfoProduct}
`;
