import AuthStore from 'Store/AuthStore';
import { InputSearch } from './InputSearch';
import UiStore from 'Store/UiStore';
import {
  addSearchHistoyVariables,
  addSearchHistoy_addSearchHistory,
} from './SearchHeader/__generated__/addSearchHistoy';
import { ADD_SEARCH_HISTORY } from './SearchHeader/query';
import { useApolloClient } from '@apollo/react-hooks';
interface Props {
  placeholder?: string;
  className?: string;
  testString?: any;
  isLogin?: boolean;
}

const SearchInput = ({ placeholder = '', isLogin, testString }: Props) => {
  const apolloClient = useApolloClient();
  const sessionMemberType = AuthStore.useStoreState(
    (state) => state.sessionMemberType
  );
  const searchString = UiStore.useStoreState(
    (state) => state.searchStringHeader
  );
  const addSearch = async (item?: any) => {
    try {
      const response = await apolloClient.mutate<
        addSearchHistoy_addSearchHistory,
        addSearchHistoyVariables
      >({
        mutation: ADD_SEARCH_HISTORY,
        variables: {
          search: item,
        },
      });
    } catch (e) {
      console.log(e);
    }
  };
  const searchUrl = (search: string) => `/recherche?search=${search}`;
  const onNavigate = (search: string) => {
    const url = searchUrl(searchString);
    if (sessionMemberType !== 'anonymous') {
      addSearch(search);
    }
    document.location.href = url;
  };

  const onPressEnter = () => {
    onNavigate(searchString);
  };
  return (
    <>
      <InputSearch placeholder={placeholder} onPressEnter={onPressEnter} />
    </>
  );
};
export default SearchInput;
