import styled, { css } from 'styled-components/macro';
import { theme } from 'Ui/theme';

export const MobileScroller = styled.div`
  position: relative;
  overflow: hidden;
  height: 4rem;
  /* border-bottom: 1px solid #f1f2f3; */
  box-shadow: 0px 1px 9px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
  background-color: white;

  :before,
  :after {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    height: 100%;
    width: 1rem;
  }

  :before {
    left: 0;
    background: linear-gradient(
      to right,
      #fff 50%,
      rgba(255, 255, 255, 0) 100%
    );
  }
  :after {
    right: 0;
    background: linear-gradient(to left, #fff 50%, rgba(255, 255, 255, 0) 100%);
  }
`;
export const MenuBar = styled.nav`
  padding: 0.5rem 2rem 0rem;
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 2rem;
  overflow: auto;
  position: relative;
  font-family: 'MontserratMedium' !important;
  /* border-bottom: 1px solid #f1f2f3; */
  &::-webkit-scrollbar {
    width: 0 !important;
  }
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  @media screen and (max-width: ${theme.breakpoints.small}px) {
    padding: 0.5rem 0;
    overflow: auto;
    font-family: 'MontserratMedium';
  }
  /* @media screen and (max-width: ${theme.breakpoints.small}px) {
    align-items: normal;
  } */
`;

export const MenuItem = styled.a<{ selected?: boolean; type?: string }>`
  font-size: 1.3rem;
  display: flex;
  white-space: nowrap;
  align-items: center;
  /* font-weight: bold; */
  text-transform: uppercase;
  padding-right: 1.5rem;
  padding: 0.5rem 0.8rem 0rem;
  cursor: pointer;
  margin: 0 0.5rem;
  flex-direction: column;
  /* font-family: 'MontserratMedium'; */
  color: ${(props) => (props.selected ? theme.light.colors.purple : '#000')};

  svg path {
    fill: ${(props) =>
      props.selected && props.type === 'ACCEUIL' ? '#09c' : '#fff'};
    stroke: ${(props) =>
      props.selected && props.type === 'ACCEUIL' ? '#09c' : '#000'};
  }

  &:hover {
    /* fill: ${theme.light.colors.purple}; */
    /* stroke: ${theme.light.colors.purple}; */
    color: ${theme.light.colors.purple} !important;
    /* svg path {
      fill: ${theme.light.colors.purple};
      stroke: ${theme.light.colors.purple};
    } */
  }

  :after {
    content: '';
    display: flex;
    width: 0;
    height: 3px;
    background: ${theme.light.colors.purple};
    transition: 0.3s;
    margin: 0;
    margin-top: 10px;
  }

  &:hover:after {
    /* width: 100%;
    margin-left: 0;
    transition: width 0.3s;
    color: ${theme.light.colors.purple}; */
  }
  &.no-underline {
    :after {
      content: none;
    }
  }

  ${(props) =>
    props.selected &&
    props.type !== 'ACCUEIL' &&
    css`
      &:after {
        width: 100%;
        margin-left: 0;
      }
    `}

  @media screen and (min-width: ${theme.breakpoints.small}px) {
    margin-top: 0.5rem;
    padding-right: 0;

    svg {
      margin-bottom: 1rem;
    }
  }
`;
