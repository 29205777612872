import React, { useState } from 'react';
import styled, { css } from 'styled-components/macro';
import OutsideClickHandler from 'react-outside-click-handler';
import posed from 'react-pose';

import AuthStore from 'Store/AuthStore';
import showLoginModal from 'Components/modals/Login';
import { theme } from './theme';
import IconeBottom from './Icons/icons/IconeBottom';

type StyleType = {
  block?: boolean;
  disabled?: boolean;
  kind?: 'default';
  hollow?: boolean;
  relativeBody?: boolean;
  bodyMaxHeight?: number;
  zIndex?: number | any;
  opened?: boolean;
};

type SelectProps<T> = StyleType & {
  options: {
    value: string;
    label: string;
    disabled?: boolean;
  }[];
  placeholder?: React.ReactNode;
  style?: React.CSSProperties;
  children?: React.ReactNode;
};

const Head = styled.div<StyleType & { opened: boolean }>`
  font-family: 'MontserratMedium', sans-serif;
  font-weight: 500;
  cursor: pointer;
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4.6rem;

  border: 1px solid
    ${(props) =>
      props.hollow
        ? theme.light.colors.select.borderColor[props.kind || 'default']
        : theme.light.colors.select.backgroundColor[props.kind || 'default']};

  ${(props) =>
    !props.hollow &&
    css`
      background-color: ${
        theme.light.colors.select.backgroundColor[props.kind || 'default']
      };
      &:hover: ${
        theme.light.colors.select.backgroundColor[props.kind || 'default']
      };
      //
    `}/* ${(props) =>
    props.opened &&
    css`
      background-color: #F1F2F3;
      &:hover: ${
        theme.light.colors.select.backgroundColor[props.kind || 'default']
      };
      border: 1px solid #F1F2F3;
      //
    `} */
`;

const Title = styled.div`
  margin-right: 3rem;
  width: 100%;
`;

const SelectStyled = styled.div<{ block: boolean; zIndex: number }>`
  position: relative;
  display: inline-block;
  font-size: 1.4rem;
  margin-right: 1.1rem;
  //width: 181px;
  width: 188px;

  ${(props) => props.block && 'width:100%;'}
  ${(props) => props.zIndex && `z-index: ${props.zIndex}`}
`;

const Content = styled.div<StyleType>`
  z-index: 1;
  /* overflow-y: scroll; */
  ${(props) =>
    props.bodyMaxHeight &&
    css`
      max-height: ${props.bodyMaxHeight}px;
      overflow: auto;
      //
    `}

  position: ${(props) => (props.relativeBody ? 'relative' : 'absolute')};

  border: 1px solid
    ${(props) =>
      props.hollow
        ? theme.light.colors.select.borderColor[props.kind || 'default']
        : theme.light.colors.select.backgroundColor[props.kind || 'default']};

  background-color: ${theme.light.colors.white};

  /* ${(props) =>
    props.opened &&
    css`
      background-color: #F1F2F3;
      &:hover: ${
        theme.light.colors.select.backgroundColor[props.kind || 'default']
      };
      border: 1px solid #F1F2F3;
      //
    `} */

  padding: 1rem 1rem;
  width: 100%;
  border-top: none;
  overflow-y: auto;
  max-height: 26.7rem;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 3px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    //
  }
`;

const IconChevronDownAnimated = posed.div({
  opened: {
    rotateZ: '180deg',
    transition: { ease: 'easeInOut', duration: 200 },
  },
  closed: { rotateZ: '0deg', transition: { ease: 'easeInOut', duration: 200 } },
});

export const SelectCheckBox = <T extends {} = string>({
  options,
  placeholder,
  block = false,
  kind = 'default',
  hollow = false,
  disabled = false,
  relativeBody = false,
  bodyMaxHeight,
  children,
  zIndex,
  ...rest
}: SelectProps<T>) => {
  const isLoggedIn = AuthStore.useStoreState((state) => state.isLoggedIn);

  const [opened, setOpened] = useState(false);
  const onClose = () => {
    setOpened(false);
  };

  return (
    <SelectStyled block={block} zIndex={zIndex} {...rest}>
      <OutsideClickHandler onOutsideClick={(e) => onClose()}>
        <Head
          opened={opened}
          hollow={hollow}
          onClick={() => (isLoggedIn ? setOpened(!opened) : showLoginModal({}))}
        >
          <Title>
            <p>{placeholder}</p>
          </Title>
          <IconChevronDownAnimated pose={opened ? 'opened' : 'closed'}>
            <IconeBottom />
          </IconChevronDownAnimated>
        </Head>
        {opened && (
          <Content
            className="divWithScroll"
            hollow={hollow}
            bodyMaxHeight={bodyMaxHeight}
            relativeBody={relativeBody}
            opened={opened}
          >
            {children}
          </Content>
        )}
      </OutsideClickHandler>
    </SelectStyled>
  );
};
