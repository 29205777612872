import gql from "graphql-tag";

export const InfoSale = gql`
  fragment InfoSale on Sale {
    id
    startDateTime
    endDateTime
    vipStartDateTime
    vipEndDateTime
    endDateTime
    saleTargets
    hasWine
    isUnder18
    isOpen
    isOpenVip
    showBrandName
    minimumDeliveryDate
    maximumDeliveryDate
    productDeliveryDate
    promoMulti
    promoCategory
    saleArea
  }
`;
