import styled from 'styled-components/macro';
import { theme } from 'Ui/theme';

export const HeaderIcon = styled.div<{ selected?: boolean; cursor?: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Montserrat';

  color: ${(props) => (props.selected ? theme.light.colors.purple : '#000')};
  .label {
    display: none;
  }
  @media screen and (max-width: ${theme.breakpoints.small}px) {
    margin-left: 0.3rem;
    /* svg path {
      stroke: ${theme.light.colors.purple};
      stroke-width: 4px;
    } */
  }
  @media screen and (min-width: ${theme.breakpoints.medium}px) {
    /* font-size: 3.2rem; */
    .iconLabel {
      height: auto;
    }
    .iconLabel .label {
      display: block;
      font-size: 1.2rem;
      text-transform: uppercase;
      margin-top: 0.8rem;
      /* margin-left: 1rem; */
      font-weight: 600;
      font-family: 'MontserratSemiBold';
      /* padding: 0 0 3rem 1rem ; */
      /* &.hide {
        opacity: 0;
      } */
    }
    svg {
      /* width: 2.6rem !important;
      height: 2.6rem !important;
      font-weight: 600; */
      /* fill: ${theme.light.colors.grayUltraDark}; */
    }
    svg path {
      /* stroke-width: 3.5px !important; */
    }
    svg circle {
      /* stroke-width: 3.5px !important; */
    }
  }
  cursor: ${(props) => (props.cursor ? props.cursor : 'default')};
`;
