import React, { useState } from 'react';
import posed from 'react-pose';
import styled, { css } from 'styled-components/macro';
import { theme } from 'Ui';
import { IconChevronRight } from 'Ui/Icons';
import IconeBottom from './Icons/icons/IconeBottom';

const ExpandableCardStyledV2 = styled.div<{
  hollow: boolean;
  cursor: boolean;
  noPaddingLeft: boolean;
  isFilters?: boolean;
}>`
  position: relative;
  ${(props) =>
    props.hollow
      ? 'border-top: 2px solid #E4D7C620'
      : ' border: 2px solid #E4D7C620;'}
  font-family: 'Montserrat', sans-serif;
  .head {
    ${(props) =>
      props.isFilters
        ? 'font-family: MontserratMedium '
        : 'font-family: Montserrat, sans-serif;'};
    font-weight: 500;
    cursor: pointer;
    ${(props) => (props.cursor ? 'cursor: pointer;' : ' cursor: default;')}
    ${(props) => (props.hollow ? 'padding: 0.5rem;' : ' padding: 1.5rem;')}
    font-size: 1.6rem;
    display: flex;
    /* color: ${theme.light.colors.purple}; */
    justify-content: space-between;
    align-items: center;
    ${(props) =>
      props.noPaddingLeft &&
      css`
        padding-left: 0rem;
      `}
  }
  .body {
    padding: 1.5rem;
    font-size: 1.4rem;
  }
  :last-child {
    border-bottom: 2px solid #e4d7c620;
  }

  .ligne {
    position: absolute;
    top: 2.8rem;
    width: 12.2rem;
    height: 1rem;
    background: #77b80110;
    z-index: -1;
  }
  ${(props) =>
    props.isFilters === false &&
    css`
      svg {
        font-size: 18px;
        svg path {
          stroke-width: 4px;
        }
      }
    `}
`;

const IconChevronDownAnimated = posed.div({
  opened: {
    rotateZ: '90deg',
    transition: { ease: 'easeInOut', duration: 200 },
  },
  closed: { rotateZ: '0deg', transition: { ease: 'easeInOut', duration: 200 } },
});

const IconChevronDownFiltersAnimated = posed.div({
  opened: {
    rotateZ: '180deg',
    transition: { ease: 'easeInOut', duration: 200 },
  },
  closed: { rotateZ: '0deg', transition: { ease: 'easeInOut', duration: 200 } },
});

type ExpandableCardProps = {
  title: React.ReactNode;
  separator?: boolean;
  children: React.ReactNode;
  isOpenDefault?: boolean;
  isArrow?: boolean;
  hollow?: boolean;
  cursor?: boolean;
  isFilters?: boolean;
  noPaddingLeft?: boolean;
  onclick?: () => void;
  disabled?: boolean;
};

export const ExpandableCardV2 = ({
  title,
  separator = false,
  children,
  isOpenDefault = false,
  isArrow = true,
  hollow = false,
  cursor = false,
  noPaddingLeft = false,
  isFilters = false,
  onclick,
  disabled = false,
}: ExpandableCardProps) => {
  const [opened, setOpened] = useState(isOpenDefault);

  return (
    <ExpandableCardStyledV2
      hollow={hollow}
      cursor={cursor}
      noPaddingLeft={noPaddingLeft}
      isFilters={isFilters}
    >
      {separator && <div className="ligne"></div>}
      <div
        className="head"
        onClick={() => {
          if (!disabled) {
            !!isArrow && setOpened(!opened);
            if (!opened == true) {
              onclick && onclick();
            }
          }
        }}
      >
        {opened ? (
          <b style={{ color: `${theme.light.colors.purple}` }}>{title}</b>
        ) : (
          <b>{title}</b>
        )}
        {isFilters && (
          <IconChevronDownFiltersAnimated pose={opened ? 'opened' : 'closed'}>
            <IconeBottom />
          </IconChevronDownFiltersAnimated>
        )}
        {isArrow && !isFilters && (
          <IconChevronDownAnimated pose={opened ? 'opened' : 'closed'}>
            <IconChevronRight />
          </IconChevronDownAnimated>
        )}
      </div>
      {opened && <div className="body">{children}</div>}
    </ExpandableCardStyledV2>
  );
};

export const ExpandableCardStackV2 = styled.div`
  & > div {
    margin-top: -1px;
    &:first-of-type {
      margin-top: 0;
    }
  }
  width: 100%;
  margin-bottom: 1.8rem;
`;
