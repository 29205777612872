import logoBzc from 'Assets/images/BzcLogo1.svg';
import { PageContainer } from 'Ui';
import styled from 'styled-components/macro';
import AnonymousHeader from '../AnonymousHeader';
import HeaderBasket from '../HeaderBasket';

import BannerApp from 'Components/BannerApp';
import { storageFactory } from 'Libs/storageFactory';
import AuthStore from 'Store/AuthStore';
import { isAndroid, isIOS } from 'react-device-detect';

import UiStore from 'Store/UiStore';

const LogoStyled = styled.img`
  width: 31rem;
  height: 100%;
`;

const TopBar = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 6.5rem;
  align-items: center;
  padding-bottom: 12px;
`;

const FixBar = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  width: 100vw;
  background-color: #fff;
  z-index: 10001;
  .addShadow {
    box-shadow: 0px 1px 9px 0px rgba(0, 0, 0, 0.1);
  }
`;

const Basket = styled.div`
  display: flex;
  align-items: center;
`;

export default () => {
  const cookieMobileAppPopup = UiStore.useStoreState(
    (state) => state.cookieMobileApp
  );

  return (
    <>
      <>
        <FixBar>
          {cookieMobileAppPopup && (isAndroid || isIOS) && (
            <BannerApp android={isAndroid} />
          )}
          <PageContainer>
            <TopBar>
              <div style={{ flex: '1 1 auto', textAlign: 'center' }}>
                <a href="/">
                  <LogoStyled src={logoBzc} />
                </a>
              </div>
              <Basket>
                <HeaderBasket isBasket={true} />
              </Basket>
            </TopBar>
          </PageContainer>
        </FixBar>
      </>
    </>
  );
};
