import queryString from "query-string";
export const getUrlQueryParams = (): {
  [index: string]: string | number | (string | number)[] | null | undefined;
} => {
  return queryString.parse(window.location.search);
};

export const setUrlQueryParams = (properties: {
  [index: string]: number | string | undefined | null;
}) => {
  const search = getUrlQueryParams();
  const newSearch = { ...search, ...properties };
  Object.keys(properties).map(
    (key) =>
      // @ts-ignore
      ["", undefined, null].includes(properties[key]) && delete newSearch[key]
  );

  const searchString = queryString.stringify(newSearch);
  window.history.replaceState(
    {},
    "",
    window.location.pathname + (searchString && "?" + searchString)
    // window.location.pathname + (searchString && "/" + searchString + "/")
  );
};
