import React from 'react';
import styled from 'styled-components';
import { Advantage } from './Advantage';
import { Createurs, Group, Reassurance, Retoursretour } from 'Ui/Icons/icons';

const AdvantagesContainer = styled.div<{ isLoggedIn?: boolean }>`
  display: flex;
  width: 112rem;
  padding: 4rem 8rem;
  justify-content: center;
  align-items: flex-start;
  gap: 13.8rem;
  border-radius: 1.5rem;
  border: 1px solid var(--border-default, #dedcdc);
  margin: auto;
  @media screen and (max-width: 48rem) {
    display: flex;
    width: calc(96.5% - 2rem);
    padding: 21px 2px;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    gap: 21px 12px;
    flex-wrap: wrap;
  }
  @media screen and (min-width: 49rem) and (max-width: 768px) {
    width: 100%;
    flex-wrap: wrap;
  }
  @media screen and (min-width: 769px) and (max-width: 967px) {
    width: calc(96.5% - 2rem);
    flex-wrap: wrap;
    gap: 5.8rem;
    padding: 4rem 1rem;
  }
  @media screen and (min-width: 968px) and (max-width: 1024px) {
    width: calc(96.5% - 2rem);
    flex-wrap: wrap;
    gap: 8rem;
    padding: 4rem 0rem;
  }
`;

const CountPayment = styled.div`
  color: #fff;
  font-family: 'Montserrat' !important;
  font-size: 8px;
  font-style: normal;
  line-height: normal;
  display: inline-flex;
  padding: 3px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 9px;
  border-radius: 5px;
  border: 2px solid #fff;
  background: var(
    --gradient-normal-violet,
    linear-gradient(180deg, #6c4dff 0%, #8268ff 100%)
  );
`;

const PaymentCountContainer = styled.div`
  position: absolute;
  bottom: 0.8rem;
  right: 1.2rem;
`;

export const Advantages = () => {
  const MultiplePayment = () => (
    <div style={{ position: 'relative' }}>
      <Group />
      <PaymentCountContainer>
        <CountPayment>3x</CountPayment>
        <CountPayment>4x</CountPayment>
      </PaymentCountContainer>
    </div>
  );
  return (
    <AdvantagesContainer>
      <Advantage
        icon={<Retoursretour />}
        title="Retour facile"
        secondaryText="14 jours pour changer d'avis"
      />
      <Advantage
        icon={<Createurs />}
        title="Marques de créateurs"
        secondaryText="selectionnées avec soin"
      />
      <a
        href="https://fr.bazarchic.com/contenu/conditions-generales-de-ventes/"
        target="_blank"
      >
        <Advantage
          icon={<MultiplePayment />}
          title="Paiement facilité"
          secondaryText="Le 3x et 4x avec ALMA"
        />
      </a>
      <Advantage
        icon={<Reassurance />}
        title="Service client"
        secondaryText="à votre écoute"
      />
    </AdvantagesContainer>
  );
};
