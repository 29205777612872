import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import VisibilitySensor from 'react-visibility-sensor';
import { useState } from 'react';
import { SearchProducts_products_edges_node } from 'Pages/Search/Products/__generated__/SearchProducts';
import { isMobile, isTablet } from 'react-device-detect';
import Cookies from 'js-cookie';
import dayjs from 'dayjs';
import CryptoJS from 'crypto-js';

type SignupData = {
  event: string;
  subscriptionType: string;
};

type SigninData = {
  event: string;
  loginType: string;
};

const tagManagerArgs = {
  gtmId: 'GTM-KHM4FXW',
};

export const initGtm = () => {
  if (!Cookies.get('isWebView')) {
    TagManager.initialize(tagManagerArgs);
  }
};

export const pageInit = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'bzcPageInit',
    },
  });
};

export const getGtmDownloadApp = (infos: any) => {
  TagManager.dataLayer({
    dataLayer: {
      ...infos,
      event: 'downloadApp',
    },
  });
};

export const getGtmCategory = (cat: { event?: any; promoGamme: string }) => {
  TagManager.dataLayer({
    dataLayer: {
      ...cat,
    },
  });
};

export const getGtmAddToCartBuyExpress = (
  infos: any,
  product: any,
  size: string,
  quantity: number,
  productDeliveryDate: string,
  promoMulti: string,
  promoCategory: string,
  promoId: number,
  itemCategory: string,
  isWishlist: string,
  addToCartType: string,
  list = ''
) => {
  TagManager.dataLayer({
    dataLayer: {
      addToCartType,
      ...infos,
      products: [
        {
          id: product.id,
          name: product.title,
          list,
          price: product.sellPrice,
          variant: size,
          quantity,
          productDeliveryDate,
          promoMulti,
          promoCategory,
          promoTitle: product.title,
          promoId: product.saleId,
          brand: product.brandDatas.name || '',
          currency: 'EUR',
          coupon: `${product.discountPercentage}%`,
          item_category: itemCategory || '',
          isWishlist,
        },
      ],
      event: 'addToCart',
    },
  });
};

export const getPageView = (bzcPagePathName?: string, bzcPageUrl?: string) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'bzcPageView',
      bzcPagePathName: bzcPagePathName,
      bzcPageUrl: bzcPageUrl,
    },
  });
};

export const setGtmPubAcquisition = (
  user: any,
  isLoggedIn: boolean,
  params: any,
  sessionMemberType?: string
) => {
  const birthDate = user.birthDate
    ? dayjs.unix(user.birthDate).format('YYMMDD')
    : '';

  const userLastOrder = user.lastOrderAtTimestamp
    ? dayjs.unix(user.lastOrderAtTimestamp).format('YYMMDD')
    : '';

  const registeredUser = user.registeredAtTimestamp
    ? dayjs.unix(user.registeredAtTimestamp).format('YYMMDD')
    : '';
  const crmOtpins = () => {
    if (user.isOptIn === '') {
      return 'none';
    } else if (user.isOptIn === 'oui') {
      return 'e_news_q';
    } else if (user.isOptIn === 'hebdo') {
      return 'e_news_h';
    }
  };
  const inscriptionOk = window.localStorage.getItem('subscriptionTracking');
  // const hashedEmailSha256 = CryptoJS.SHA256(user.email);
  const UIDSha256 = CryptoJS.SHA256(user.id);

  TagManager.dataLayer({
    dataLayer: {
      UID: user.id,
      UIDSha256: UIDSha256.toString(),
      crmOptins: crmOtpins(),
      device: isMobile ? 'mobile' : isTablet ? 'Tablette' : 'PC',
      hashedEmailMd5: user.emailMd5,
      hashedEmailSha256: user.emailHash,
      userLastOrder: userLastOrder,
      lastSale: user.dayCountFromLastOrder,
      userLoginType: window.localStorage.getItem('loginType') || 'none',
      userAge: user.age,
      userBirthdate: birthDate,
      userDuration: user.dayCountFromRegister,
      memberFirstConsentMarketing: Cookies.get('_marketing'),
      memberFirstConsentPerformance: Cookies.get('_performance'),
      memberFirstname: user.firstName,
      memberInscriptionDate: registeredUser,
      memberType: user.isVip ? 'vip' : 'classique',
      platform: 'site-desktop',
      sessionId: Cookies.get('ssession'),
      subscription: inscriptionOk ? inscriptionOk : 'inscription-nok',
      source: params,
      voucherAmount: user.vouchers[0] ? user.vouchers[0].value : '',
      voucherAvailable: user.vouchers[0] ? user.vouchers[0].operationName : '',
      voucherConditions: user.vouchers[0] ? user.vouchers[0].minOrderPrice : '',
      voucherExpirationDate: user.vouchers[0] ? user.vouchers[0].dateTo : '',
      oney_element: 1,
      appConnect: user.validMobilesCount,
      crmEmailHard: user.npai === 'hard' ? 1 : 0,
      sessionMemberType: sessionMemberType,
    },
  });
};

export const setGtmPubAcquisitionNotLoggued = (
  isLoggedIn?: boolean,
  params?: string
) => {
  const inscriptionOk = window.localStorage.getItem('subscriptionTracking');
  TagManager.dataLayer({
    dataLayer: {
      app_connect: 0,
      device: isMobile ? 'mobile' : isTablet ? 'Tablette' : 'PC',
      login_type: 'none',
      subscription: inscriptionOk ? inscriptionOk : 'inscription-nok',
      oney_element: 1,
      platform: isMobile ? 'site-mobile' : 'site-desktop',
      source: params,
    },
  });
};

const getMemberCategoryFromOrderCount = (orderCount: number) => {
  switch (orderCount) {
    case 0:
      return 'M';
    case 1:
      return 'B';
    case 2:
    case 3:
    case 4:
    case 5:
      return 'A';
    default:
      return 'O';
  }
};

// GTM for userData in the page
export const setGtmUserData = (
  user: any,
  pageType?: string,
  pageTypeB?: string,
  sessionMemberType?: string
) => {
  const userLastOrder = user.lastOrderAtTimestamp
    ? dayjs.unix(user.lastOrderAtTimestamp).format('YYMMDD')
    : '';

  const birthDate = user.birthDate
    ? dayjs.unix(user.birthDate).format('YYMMDD')
    : '';

  const crmOtpins = () => {
    if (user.isOptIn === '') {
      return 'none';
    } else if (user.isOptIn === 'oui') {
      return 'e_news_q';
    } else if (user.isOptIn === 'hebdo') {
      return 'e_news_h';
    }
  };

  TagManager.dataLayer({
    dataLayer: {
      userBirthdate: birthDate,
      userCountry: user.country.alpha2,
      hashedEmailSha256: user.emailHash,
      hashedEmailMd5: user.emailMd5,
      userAge: user.age,
      userCategory: getMemberCategoryFromOrderCount(user.orderCount),
      userAppConnect: 'N',
      userID: user.id,
      userCrmOptins: crmOtpins(),
      userDuration: user.dayCountFromRegister,
      userGender: user.gender === 'male' ? 'H' : 'F',
      userLastOrder: userLastOrder,
      userLoginType: window.localStorage.getItem('loginType') || '',
      userPrivilege: user.isVip,
      userStatus: user.dayCountFromRegister / 30 > 12 ? 'AC' : 'NC',
      userTotalTransactions: user.orderCount,
      pageType: pageType,
      pageTypeB: pageTypeB,
      sessionMemberType: sessionMemberType,
    },
  });
};

export const gtmAddOrRemoveWishList = (tracking: any) => {
  TagManager.dataLayer({
    dataLayer: {
      event: tracking.event,
      pageType: window.location.pathname,
      pageTypeB: tracking.pageTypeB,
      productListType: tracking.page,
      products: [
        {
          id: tracking.product.id,
          price: tracking.product.sellPrice,
          productDeliveryDate: tracking.productDeliveryDate,
          promoMulti: tracking.promoMulti,
          promoCategory: tracking.promoCategory,
          promoTitle: tracking.product.title,
          promoId: tracking.saleId,
        },
      ],
    },
  });
};

export const setGtmPageData = (
  pathname: string,
  FFIdentifier: string,
  FFPath: string
) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'bzcPageView',
      currencyCode: 'EUR',
      bzcPageUrl: window.location.href,
      page: pathname,
      pageType: FFIdentifier,
      pageTypeB: FFPath,
    },
  });
};

let productVisibilityBuffer: {
  index: number;
  product: SearchProducts_products_edges_node;
}[] = [];
let productVisibilityIndex = 0;
const productVisibilityBufferSize = 4;

const setGtmProductVisibility = (
  product: SearchProducts_products_edges_node,
  page?: any,
  pageType?: { pageType: string; pageTypeB: string; rootId: string },
  action?: any,
  itemCategory?: string
) => {
  productVisibilityIndex += 1;
  productVisibilityBuffer.push({ index: productVisibilityIndex, product });

  if (productVisibilityBuffer.length >= productVisibilityBufferSize) {
    const event = action;
    TagManager.dataLayer({
      dataLayer: {
        ...event,
        ...pageType,
        products: productVisibilityBuffer.map((productWrap) => ({
          list: page,
          id: productWrap.product.id,
          name: productWrap.product.title,
          price: productWrap.product.sellPrice,
          promoId: pageType ? pageType.rootId : productWrap.product.saleId,
          brand: productWrap.product.brandDatas.name || '',
          currency: 'EUR',
          item_category: itemCategory,
          coupon: `${productWrap.product.discountPercentage}%`,
          position: productWrap.index,
        })),
      },
    });
    productVisibilityBuffer = [];
  }
};

export const SpyProductVisibility = ({
  product,
  page,
  itemCategory,
  infos,
  action,
}: {
  product: any;
  page: any;
  infos?: any;
  action?: any;
  itemCategory?: any;
}) => {
  const [active, setActive] = useState(true);
  const onChange = (visible: boolean) => {
    if (visible) {
      setActive(false);
      setGtmProductVisibility(product, page, infos, action, itemCategory);
    }
  };
  return (
    <VisibilitySensor active={active} onChange={onChange}>
      <div
        style={{
          height: 1,
          width: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
        }}
      />
    </VisibilitySensor>
  );
};

export default TagManager;
