import Cookie from "js-cookie";


type ConfigurationCookie = {
  path?: string;
  expires: number | Date;
  sameSite?: any;
  secure: boolean;
  value: any;
  name: string;
};

type ConfigurationRemoveCookie = {
  path?: string;
};

export const domain = window.location.hostname == "localhost" ? "localhost" : ".bazarchic.com";

export const setCookie = ({ path = "/", expires, secure, sameSite, value, name }: ConfigurationCookie) => {
  const configCookie = {
    path,
    domain,
    expires,
    secure,
    sameSite,
    // httpOnly: domain == 'localhost' ? false : ".bazarchic.com" ? true : false,
    value,
    name,
  }
  Cookie.set(name, value, configCookie);
}



export const getCookie = (value: string) => {
  return Cookie.get(value);
}

export const removeCookie = (name: string) => {
  const configCookie = {
    path: '/',
    domain,
  }
  Cookie.remove(name, configCookie);
}

export const filterAndRemoveCookies = () => {
  const objectCookie = {
    path: '/',
    domain: '.bazarchic.com',
    secure: true,
  };
  const getAllCookies = Cookie.get();
  const cookieFiltered = Object.keys(getAllCookies).filter(
    (obj: any) =>
      obj !==
      ('ssession' || 'site' || 'session_base' || 'privacyAccept')
  );
  const removeCookies = () => {
    cookieFiltered.map((c) => {
      Cookie.remove(c, objectCookie);
    });
  };
  removeCookies();
}

export const numberOfSession = () => {
  let count = 1
  const objectCookie = {
    path: '/',
    expires: 180,
    secure: true,
    domain: '.bazarchic.com',
  };
  const getNumberOfSession = Cookie.get('number_of_session');
  const parseIntNumberOfSession = parseInt(getNumberOfSession)
  const IncrementNumber = parseIntNumberOfSession + 1

  if (!getNumberOfSession) {
    Cookie.set('number_of_session', count.toString(), objectCookie)
  } else {
    Cookie.set('number_of_session', IncrementNumber.toString(), objectCookie)
  }
}

