import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';
import dayjs from 'dayjs';
import { useMediaQuery } from 'react-responsive';
import { theme } from 'Ui';
import { createUrlParams } from 'Utils/imageParams';
import CardSales from 'Components/CardSales';

type CarouselProps = {
  sales: any;
  isSearch?: boolean;
};

const CarouselContainer = styled.div<{ salesNumber?: any }>`
  display: flex;
  gap: 8px;
  justify-content: flex-start;
  position: relative;
  max-width: 197rem;
  /* min-height: 350px; */
  .swiper-container {
    //@ts-ignore
    margin-left: ${(props) => (props.salesNumber >= 4 ? 'auto' : '0')};
  }
  ${(props) =>
    props.salesNumber >= 5 &&
    css`
      .swiper-scrollbar {
        background: ${theme.v2.brand};
        width: 34rem !important;
        margin: 0 auto;
        .swiper-container-horizontal > .swiper-scrollbar {
          position: absolute;
          left: 1%;
          bottom: 3px;
          z-index: 50 !important;
          height: 4px;
          border-radius: 4px;
          background: #eae9f0;
        }
        .swiper-scrollbar-drag {
          height: 100%;
          width: ${props.salesNumber} / 34rem;

          position: relative;
          border-radius: 3px;
          background: ${theme.v2.brand.violet};
          left: 0;
          top: 0px;
        }
      }
    `}/* @media screen and (max-width: 48rem) {
    width: 24.5rem !important;
  } */
`;

const CarouselItem = styled.div`
  cursor: pointer;
  /* width: 32.1rem !important; */
  width: 134px !important;
  height: 134px !important;
  @media screen and (max-width: 48rem) {
    img {
      width: 134px !important;
      height: 134px !important;
      border-radius: 3px;
    }
  }
`;

const ArrowContainer = styled.div<{ sales: number }>`
  display: flex;
  position: absolute;
  z-index: 50;
  left: 128.4rem;
  top: 35%;
  width: 6rem;
  height: 6rem;
  padding: 14px;
  align-items: flex-start;
  cursor: pointer;
  gap: 1rem;
  border-radius: 2px;
  border: 1px solid ${theme.v2.brand.lightViolet};
  background: ${theme.light.colors.white};
  box-shadow: -6px 0px 13px 0px rgba(0, 0, 0, 0.49);
  @media screen and (min-width: 1920px) {
    /* right: 72rem; */
    left: 128.4rem;
    display: ${(props) => (props.sales === 5 ? 'none' : 'flex')};
  }
`;

const Cont = styled.div`
  position: relative;
  .swiper-slide {
    flex-shrink: 0;
    width: 100%;
  }

  .scrollbar-container {
    position: absolute;
    top: 35rem;
    right: 35%;
    height: 8px;
    width: 340px;
    background-color: black;
    border-radius: 4px;
  }

  .scrollbar-thumb {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: red;
    border-radius: 4px;
    cursor: pointer;
  }
`;

const ProgressContainer = styled.div<{ numberOfSales: number }>`
  width: 34rem;
  height: 4px;
  border-radius: 4px;
  background: #eae9f0;
  margin: 2.4rem 0 0;
  position: sticky;
  left: 48rem;
  display: block;
  display: ${(props) => (props.numberOfSales < 3 ? 'none' : 'block')};

  @media screen and (max-width: 48rem) {
    width: 15.6rem;
    height: 3px;
    position: sticky;
    left: 12.3rem;
    margin: 2.4rem 0 0;
    ${(props) =>
      props.numberOfSales <= 2 &&
      css`
        display: none;
      `}
  }
  @media screen and (min-width: 78.8rem) and (max-width: 10.24rem) {
    width: 15.6rem;
    height: 3px;
    position: sticky;
    left: 21rem;
    margin: 2.4rem 0 0;
    ${(props) =>
      props.numberOfSales <= 4 &&
      css`
        display: none;
      `}
  }
`;

const ProgressIndicator = styled.div<{ widthProgress: number }>`
  /* Version progressBar that filled completely
  height: 100%;
  background-color: ${theme.v2.brand.violet};
  transition: width 0.5s ease-in-out; */

  width: ${({ widthProgress }) => `${widthProgress}%`};
  height: 100%;
  background-color: ${theme.v2.brand.violet};
  position: absolute;
  top: 0;
  left: 0;
  /* transition: left 0.1s ease-in-out; */
`;

const CarouselWrapper = styled.div<{ translateX: number; transition: string }>`
  display: flex;
  gap: 8px;
  transition: ${(props) => props.transition};
  transform: translateX(${(props) => props.translateX}px);
`;

export const BestSalesCarousel = ({ sales, isSearch }: CarouselProps) => {
  const [isOnMobile, setIsOnMobile] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const isLargeDesktop = useMediaQuery({ query: '(min-width: 1890px)' });
  const isXlLargeDesktop = useMediaQuery({ query: '(min-width: 1920px)' });
  useEffect(() => {
    setIsOnMobile(isMobile);
  }, [isMobile]);
  const [index, setIndex] = useState(isXlLargeDesktop ? 0 : 4); // Start at index 4 because of Image
  const itemRef = useRef<HTMLDivElement>(null);
  const itemWidth = itemRef.current ? itemRef.current.clientWidth + 8 : 0;
  const [translateX, setTranslateX] = useState(0);
  const [transition, setTransition] = useState('transform 0.3s ease-in-out');
  const [touchStart, setTouchStart] = useState(0);
  const [progress, setProgress] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);

  const totalItems = sales.length;

  useEffect(() => {
    const itemWidth = itemRef.current ? itemRef.current.clientWidth + 8 : 0;
    const actualIndex = index - 4;
    setProgress(((actualIndex + 1) / totalItems) * 100);
    setTranslateX(-index * itemWidth);
  }, [index, totalItems]);

  useEffect(() => {
    const handleResize = () => {
      const itemWidth = itemRef.current ? itemRef.current.clientWidth + 8 : 0;
      setTranslateX(-index * itemWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [index]);

  const handleNext = () => {
    setIndex((prev) => prev + 1);
  };

  const handlePrev = () => {
    setIndex((prev) => prev - 1);
  };

  useEffect(() => {
    const itemWidth = itemRef.current ? itemRef.current.clientWidth + 8 : 0;

    if (index === totalItems + 4) {
      setTimeout(() => {
        setTransition('none');
        setIndex(4);
        setTranslateX(-4 * itemWidth);
      }, 500);
    } else if (index === -1) {
      setTimeout(() => {
        setTransition('none');
        setIndex(totalItems + 3);
        setTranslateX(-(totalItems + 3) * itemWidth);
      }, 500);
    } else {
      setTransition('transform 0.3s ease-in-out');
    }
  }, [index, totalItems]);

  // Swipe handling
  const handleTouchStart = (e: React.TouchEvent) => {
    setTouchStart(e.touches[0].clientX);
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    setTouchEnd(e.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (touchStart - touchEnd > 50) {
      handleNext();
    }

    if (touchStart - touchEnd < -50) {
      handlePrev();
    }
  };

  const myClonedImages = (sales: any) => {
    if (sales.length >= 4) {
      return [...sales.slice(-4), ...sales, ...sales.slice(0, 4)];
    } else {
      return sales;
    }
  };

  const GoToNextSlide = () => {
    return (
      <ArrowContainer
        onClick={handleNext}
        sales={totalItems}
        data-attribute={totalItems}
      >
        {/* <NextSlide width={32} height={32} /> */}
      </ArrowContainer>
    );
  };
  const imageSize = {
    carousel: {
      width: 321,
      height: 323,
    },
  };

  return (
    <div style={{ position: 'relative' }}>
      <Cont>
        <CarouselContainer salesNumber={sales && sales.length}>
          <CarouselWrapper
            translateX={translateX}
            transition={transition}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            {myClonedImages(sales)?.map((sale: any, index: any) => {
              const saleImage = `${sale.coverLogoUrl}?params=${createUrlParams(
                imageSize.carousel
              )}`;

              return (
                <CarouselItem
                  data-attribute={index}
                  ref={index === 4 ? itemRef : null}
                  key={index}
                >
                  <CardSales
                    image={saleImage}
                    isLastingSale={false}
                    to={`/ventes/cata/id/${sale.id}/` || ''}
                    // dateEnd={!isSearch && daysRemaining}
                    alt={sale.name}
                  />
                </CarouselItem>
              );
            })}
          </CarouselWrapper>
          {!isMobile && <GoToNextSlide />}
        </CarouselContainer>
      </Cont>
      <ProgressContainer numberOfSales={sales && sales.length}>
        <ProgressIndicator
          widthProgress={100 / totalItems}
          style={{
            left: `${
              (100 / totalItems) *
              Math.max(Math.min(index - 4, totalItems - 1), 0)
            }%`,
          }}
        />
      </ProgressContainer>
    </div>
  );
};
