import { useEffect, useState } from 'react';
import { Social } from '../Social';
import { isMobile, isTablet, isAndroid, isIOS } from 'react-device-detect';
import { LinkAppMobile } from './LinkAppMobile';
import googlePlayPath from 'Assets/images/googlePlay.svg';
import appStorePath from 'Assets/images/appStore.svg';
import styled from 'styled-components';

const SocialAppContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.9rem;
  padding-top: 0.7rem;
`;

export const SocialApp = () => {
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  const [isTabletDevice, setIsTabletDevice] = useState(false);
  const [isAndroidDevice, setIsAndroidDevice] = useState(false);
  const [isIOSDevice, setIsIOSDevice] = useState(false);

  useEffect(() => {
    setIsMobileDevice(isMobile);
    setIsTabletDevice(isTablet);
    setIsAndroidDevice(isAndroid);
    setIsIOSDevice(isIOS);
  }, []);

  const SocialAppGroup = () => (
    <SocialAppContainer>
      {(((isMobileDevice || isTabletDevice) && isIOSDevice) ||
        !isMobileDevice) && [
        <LinkAppMobile
          key="Android"
          img={appStorePath}
          url={isMobileDevice ? '/home/app/p/ios/' : '/home/mobilite/'}
          target={isMobileDevice ? '_self' : '_blank'}
          onClick={() => {
            localStorage.setItem('typeOfDeviceClicked', 'iOs');
          }}
        />,
      ]}
      {(((isMobileDevice || isTabletDevice) && isAndroidDevice) ||
        !isMobileDevice) && [
        <LinkAppMobile
          key="ios"
          img={googlePlayPath}
          url={isMobileDevice ? '/home/app/p/android/' : '/home/mobilite/'}
          target={isMobileDevice ? '_self' : '_blank'}
          onClick={() => {
            localStorage.setItem('typeOfDeviceClicked', 'Android');
          }}
        />,
      ]}
    </SocialAppContainer>
  );

  return (
    <Social title="Votre shopping en poche !" content={<SocialAppGroup />} />
  );
};
