import styled from 'styled-components';
import React from 'react';
import { ToastContainer, Slide, toast as toastFunction } from 'react-toastify';
import { IconClose, IconCheck, IconExclamationCircle } from 'Ui/Icons';
import { theme } from 'Ui';
import 'react-toastify/dist/ReactToastify.css';

const Message = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    margin-top: 0.1rem;
    @media screen and (max-width: 48rem) {
      width: 90%;
      padding: 0rem 1rem;
    }
  }
  svg {
    font-size: 2rem;
    margin-right: 0.5rem;
  }
`;

const StyledToastContainer = styled(ToastContainer)`
  position: fixed;
  top: 0 !important;
  left: 0 !important;
  margin-left: 0 !important;
  padding: 0 !important;
  width: 100% !important;
  z-index: 1000000000 !important;

  .close {
    position: absolute;
    top: 1rem;
    right: 0.5rem;
  }

  .Toastify__toast {
    font-size: 1.6rem;
    min-height: 5rem !important;
    text-align: center;
    margin-bottom: 0.5rem;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .Toastify__toast--error {
    background-color: ${theme.colors.error} !important;
  }
  .Toastify__toast--warning {
  }
  .Toastify__toast--success {
    background-color: ${theme.colors.success} !important;
  }
  .Toastify__toast-body {
  }
  .Toastify__progress-bar {
    background: rgba(0, 0, 0, 0.3) !important;
  }
`;

export default ({ autoClose = 5000 }) => {
  if (document && document.getElementsByClassName('Toastify').length)
    return null;
  return (
    <StyledToastContainer
      position="top-center"
      closeButton={<IconClose className="close" />}
      autoClose={autoClose}
      transition={Slide}
      draggable={false}
    />
  );
};

export const toast = {
  success: (text: string) => {
    toastFunction.success(
      <Message>
        <IconCheck />
        <span>{text}</span>
      </Message>
    );
  },
  error: (text: string) => {
    toastFunction.error(
      <Message data-test="graphql-errors">
        <IconExclamationCircle className="closeToast" />
        <span>{text}</span>
      </Message>
    );
  },
};
