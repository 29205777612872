import React from 'react';
import SearchAutoSuggest from 'Components/Header/SearchHeader';
// import SearchAutoSuggest from 'Components/SearchAutoSuggest';
import { ApolloProvider } from '@apollo/client';
import { PageContainer } from 'Ui';
import { init } from 'Libs/apollo';
import { GlobalStyle, AppStyled } from '../GlobalStyle';
import { BrowserRouter } from 'react-router-dom';
import AuthStore from 'Store/AuthStore';
import InitiStore from 'Store/InitiStore';

import UiStore from 'Store/UiStore';

export default () => {
  const client = init();
  return (
    <AppStyled>
      <BrowserRouter>
        <AuthStore.Provider initialData={client}>
          <UiStore.Provider>
            <ApolloProvider client={client}>
              <InitiStore />
              <PageContainer>
                <>
                  <GlobalStyle />
                  {/* <SearchAutoSuggestHeader  /> */}
                  {/* <SearchAutoSuggest placeholderCustom /> */}
                  <SearchAutoSuggest />
                </>
              </PageContainer>
            </ApolloProvider>
          </UiStore.Provider>
        </AuthStore.Provider>
      </BrowserRouter>
    </AppStyled>
  );
};
