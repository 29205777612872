import * as React from "react";
const SvgFacebook = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 32 32"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M23.377 2.205v4.447h-2.609a2.42 2.42 0 0 0-1.929.603 2.85 2.85 0 0 0-.503 1.819v3.185h4.873l-.652 4.984h-4.22V30H13.24V17.233H9v-4.975h4.24V8.585a6.55 6.55 0 0 1 1.733-4.852 6.24 6.24 0 0 1 4.612-1.725 27 27 0 0 1 3.792.198"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgFacebook;
