import React from 'react';
import styled from 'styled-components/macro';
import { formatPrice, getDiscoutPercent } from 'Utils/tools';
import { productInformation_productRecommended } from 'Pages/Product/__generated__/productInformation';
import { theme } from './theme';
import { ImageRetina } from 'Ui/ImageRetina';
import ProductCard from 'Components/ProductCard';
import { ImgEcoResponsability } from 'Ui/ImgEco';

type RecomandationProductProps = {
  product: productInformation_productRecommended;
};

const StyledRecommandationProductCard = styled.div`
  width: 100%;
  @media screen and (min-width: 320px) and (max-width: 500px) {
    width: 100%;
    margin: auto;
  }
  .productImage img {
    max-width: 100%;
    height: 260px;
    /* height: auto; */
    display: flex;
    flex-direction: column;
    -webkit-box-pack: justify;
    justify-content: space-between;
    object-fit: cover;
    border: 1px solid #ccc;
    @media screen and (max-width: 768px) {
      height: 263px;
    }
  }
`;

// const ProductImage = styled.img`
//   max-width: 100%;
//   height: 260px;
//   /* height: auto; */
//   display: flex;
//   flex-direction: column;
//   -webkit-box-pack: justify;
//   justify-content: space-between;
//   object-fit: cover;
//   border: 1px solid #ccc;
//   @media screen and (max-width: 768px) {
//     height: 263px;
//   }
// `;

const StyledRecommendationProductDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  font-size: 1.4rem;
`;

const ProductBrand = styled.h3`
  font-size: 1.4rem;
  margin: 1.2rem 0 0 0;
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  color: ${theme.light.colors.black};
  text-align: start;
`;

const ProductDescription = styled.div`
  margin: 1.2rem 0px;
  font-family: 'Montserrat';
  .line-clamp {
    display: block;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    position: relative;
    line-height: 1.2;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 !important;
    text-align: start;
  }

  .line-clamp:after {
    content: '...';
    text-align: right;
    bottom: 0;
    right: 0;
    width: 25%;
    display: block;
    position: absolute;
    height: calc(1em * 1.2);
    /* background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1) 75%
    ); */
  }

  @supports (-webkit-line-clamp: 1) {
    .line-clamp:after {
      display: none !important;
    }
  }
  .line-clamp-2 {
    -webkit-line-clamp: 2;
    height: calc(1em * 1.2 * 2);
  }
`;

const Price = styled.div`
  font-size: 1.4rem;
  color: #000;
  font-family: 'MontserratSemiBold';
  margin-right: 1rem;
`;

const StrikedPrice = styled.div`
  font-size: 1.4rem;
  font-weight: normal;
  margin-right: 1rem;
  color: #ccc;
  text-decoration: line-through;
`;

const PercentagePrice = styled.div`
  color: ${theme.light.colors.purple};
  font-size: 1.4rem;
  font-weight: normal;
`;

const ContentPrices = styled.div`
  font-family: 'Montserrat';
  display: flex;
  @media screen and (max-width: 480px) {
    /* margin: 1.5rem 0; */
    /* flex-direction: row; */
    /* align-items: start; */
  }
`;

export default ({ product }: RecomandationProductProps) => {
  // const paramsRetina = {
  //   dimensions: {
  //     width: 412,
  //     height: 520,
  //     crop: true,
  //   },
  // };

  // const params = {
  //   dimensions: {
  //     width: 206,
  //     height: 260,
  //     crop: true,
  //   },
  // };

  // const paramsUrl = JSON.stringify(params);
  // const paramsUrlRetina = JSON.stringify(paramsRetina);

  const price = product.sellPrice && product.sellPrice;
  const publicPrice = product.publicPrice && product.publicPrice;
  const image =
    (product.imagesDatas && product.imagesDatas[0].urlListPage) || undefined;

  const ecoResponsibleLabel = product && product.ecoResponsibleLabel;
  const hasEcoResponsibleLabel = ecoResponsibleLabel
    ? ecoResponsibleLabel.hasEcoResponsibleLabel
    : false;

  return (
    <StyledRecommandationProductCard>
      <div style={{ position: 'relative' }}>
        <ImageRetina
          alt={
            product?.brandDatas?.name
              ? `${product?.brandDatas?.name} - ${product.title}`
              : `${product.title}`
          }
          width={206}
          height={260}
          url={image}
          className="productImage"
          crop={false}
        />
        {hasEcoResponsibleLabel && (
          <ImgEcoResponsability
            width={4}
            height={4}
            position={2}
            right={4}
            rightMobile={14}
          />
        )}
      </div>
      {/* <ProductImage
        src={`${image}?params=${paramsUrl}`}
        data-srcSet={`${image}?params=${paramsUrlRetina}`}
      /> */}
      <StyledRecommendationProductDescription>
        <ProductBrand>{product?.brandDatas?.name.toUpperCase()}</ProductBrand>
        <ProductDescription>
          <h3 className="line-clamp line-clamp-2">{product.title}</h3>
        </ProductDescription>
        <ContentPrices>
          <Price>{formatPrice(price)}</Price>
          {publicPrice != 0 && (
            <StrikedPrice>{formatPrice(publicPrice)}</StrikedPrice>
          )}
          {publicPrice != 0 && price < publicPrice && (
            <PercentagePrice>
              -{getDiscoutPercent(price, publicPrice)}%
            </PercentagePrice>
          )}
        </ContentPrices>
      </StyledRecommendationProductDescription>
    </StyledRecommandationProductCard>
  );
};
