import { useApolloClient } from '@apollo/client';
import Cookies from 'js-cookie';
import { useState } from 'react';
import AuthStore from 'Store/AuthStore';
import { storageFactory } from '../../Libs/storageFactory';
import { REGISTER_MEMBER_FACEBOOK } from '../RegistrationForm/query';
import {
  registerMemberFacebook,
  registerMemberFacebookVariables,
} from '../RegistrationForm/__generated__/registerMemberFacebook';
import BtnFacebook from './BtnFacebook';

export const localStore = storageFactory(() => localStorage);

export default () => {
  const [loading, setLoading] = useState(false);
  const apolloClient = useApolloClient();
  const setAuth = AuthStore.useStoreActions((actions) => actions.setAuth);

  const redirect = () => {
    /** @todo once the app is fully React, change those ugly cookie to som url / router params */
    const url = Cookies.get('redirection_url');
    Cookies.remove('redirection_url');

    /** @todo once the app is fully React, change that to a router location push */
    window.location.href = url || '/';
  };

  const registerMemberWithFacebook = async (data: any) => {
    const accessToken: string = data.accessToken;
    setLoading(true);
    try {
      const response = await apolloClient.mutate<
        registerMemberFacebook,
        registerMemberFacebookVariables
      >({
        mutation: REGISTER_MEMBER_FACEBOOK,
        variables: {
          accessToken,
        },
      });

      if (response.data && response.data.registerMemberFacebook) {
        const data = response.data.registerMemberFacebook;
        if (data.member) setAuth(data);
      }

      redirect();
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <BtnFacebook
      onFacebookRegister={registerMemberWithFacebook}
      loading={loading}
    ></BtnFacebook>
  );
};
