import React, { useEffect } from 'react';
import { createModal } from 'react-modal-promise';
import { Modal } from 'Ui';
import styled from 'styled-components/macro';
import RegistrationForm from 'Components/RegistrationForm';
import Cookies from 'js-cookie';

import logo from 'Assets/images/BzcLogo1.svg';

type ModalProps = {
  open: boolean;
  close: (shouldGoOn: boolean) => void;
};

const RegistrationModalBody = styled.div`
  position: relative;
  img.pins {
    position: absolute;
    top: -5rem;
    left: -18rem;
    width: 15rem;
  }
  img.title {
    width: 30vh;
    display: block;
    margin: 0 auto;
    margin-bottom: 2rem;
  }
  p {
    text-align: center;
    margin-top: 2rem;
    font-family: 'Montserrat';
    font-size: 1.7rem;
  }
  span {
    font-family: 'MontserratSemiBold';
  }
`;

const RegistrationModal = ({ open, close }: ModalProps) => {
  useEffect(() => {
    Cookies.set('redirection_url', document.location.href, {
      domain: '.bazarchic.com',
      path: '/',
    });
  });
  return (
    <Modal isOpen={open} onClose={() => close(false)} size={510}>
      <RegistrationModalBody>
        {/* <img
          className="pins"
          src="https://cdn.bazarchic.com/front/images/pastille-10euro.png"
          alt="pastille 10 euro"
        /> */}
        <img className="title" src={logo} alt="BazarChic" />
        <RegistrationForm />
        <p>
          <span>10 €</span> OFFERTS avec le code <span>BIENVENUE</span>
        </p>
      </RegistrationModalBody>
    </Modal>
  );
};

export default createModal(RegistrationModal);
