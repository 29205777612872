// This file was generated - do not edit

export { default as IconAlmaLogo } from "./icons/AlmaLogo";
export { default as IconAlmaReassurance } from "./icons/AlmaReassurance";
export { default as IconApple } from "./icons/Apple";
export { default as IconArrow } from "./icons/Arrow";
export { default as IconArrowBack } from "./icons/ArrowBack";
export { default as IconArrowBottom } from "./icons/ArrowBottom";
export { default as IconBasket } from "./icons/Basket";
export { default as IconBasketFilled } from "./icons/BasketFilled";
export { default as IconBasketNew } from "./icons/BasketNew";
export { default as IconBcBackground } from "./icons/BcBackground";
export { default as IconBcBackgroundMobile } from "./icons/BcBackgroundMobile";
export { default as IconBcBackgroundPage } from "./icons/BcBackgroundPage";
export { default as IconBonAchat } from "./icons/BonAchat";
export { default as IconBzc } from "./icons/Bzc";
export { default as IconBzcLogo } from "./icons/BzcLogo";
export { default as IconChat } from "./icons/Chat";
export { default as IconChatbox } from "./icons/Chatbox";
export { default as IconCheck } from "./icons/Check";
export { default as IconCheckCircle } from "./icons/CheckCircle";
export { default as IconCheckMark } from "./icons/CheckMark";
export { default as IconChevronDown } from "./icons/ChevronDown";
export { default as IconChevronLeft } from "./icons/ChevronLeft";
export { default as IconChevronPaiement } from "./icons/ChevronPaiement";
export { default as IconChevronRight } from "./icons/ChevronRight";
export { default as IconCloche } from "./icons/Cloche";
export { default as IconClose } from "./icons/Close";
export { default as IconCloseCircle } from "./icons/CloseCircle";
export { default as IconCloseModal } from "./icons/CloseModal";
export { default as IconCoeur } from "./icons/Coeur";
export { default as IconCoeurMenu } from "./icons/CoeurMenu";
export { default as IconCreateurs } from "./icons/Createurs";
export { default as IconCrossClose } from "./icons/CrossClose";
export { default as IconDelivery } from "./icons/Delivery";
export { default as IconDownloadApp } from "./icons/DownloadApp";
export { default as IconEndSale } from "./icons/EndSale";
export { default as IconError } from "./icons/Error";
export { default as IconExclamationCircle } from "./icons/ExclamationCircle";
export { default as IconEye } from "./icons/Eye";
export { default as IconEyeClosed } from "./icons/EyeClosed";
export { default as IconFacebook } from "./icons/Facebook";
export { default as IconFaqs } from "./icons/Faqs";
export { default as IconFb } from "./icons/Fb";
export { default as IconFbook } from "./icons/Fbook";
export { default as IconFourteenDays } from "./icons/FourteenDays";
export { default as IconGgl } from "./icons/Ggl";
export { default as IconGoogle } from "./icons/Google";
export { default as IconGroup } from "./icons/Group";
export { default as IconHeadphones } from "./icons/Headphones";
export { default as IconHeart } from "./icons/Heart";
export { default as IconHeartHeader } from "./icons/HeartHeader";
export { default as IconHome } from "./icons/Home";
export { default as IconHomeFilled } from "./icons/HomeFilled";
export { default as IconHomeNew } from "./icons/HomeNew";
export { default as IconHotline } from "./icons/Hotline";
export { default as IconIcnEmail } from "./icons/IcnEmail";
export { default as IconIcnFacebook } from "./icons/IcnFacebook";
export { default as IconIcnPinterest } from "./icons/IcnPinterest";
export { default as IconIcnTwitter } from "./icons/IcnTwitter";
export { default as IconIcoAccount } from "./icons/IcoAccount";
export { default as IconIcoAchatExpress } from "./icons/IcoAchatExpress";
export { default as IconIcoAvisVerifie } from "./icons/IcoAvisVerifie";
export { default as IconIcoBack } from "./icons/IcoBack";
export { default as IconIcoBack14J } from "./icons/IcoBack14J";
export { default as IconIcoBasket } from "./icons/IcoBasket";
export { default as IconIcoBasketMultiSales } from "./icons/IcoBasketMultiSales";
export { default as IconIcoCb } from "./icons/IcoCb";
export { default as IconIcoCloche } from "./icons/IcoCloche";
export { default as IconIcoClose } from "./icons/IcoClose";
export { default as IconIcoCompetetivePrice } from "./icons/IcoCompetetivePrice";
export { default as IconIcoContact } from "./icons/IcoContact";
export { default as IconIcoCross } from "./icons/IcoCross";
export { default as IconIcoDelivery } from "./icons/IcoDelivery";
export { default as IconIcoEasyBack } from "./icons/IcoEasyBack";
export { default as IconIcoEndSale } from "./icons/IcoEndSale";
export { default as IconIcoEye } from "./icons/IcoEye";
export { default as IconIcoFacebook } from "./icons/IcoFacebook";
export { default as IconIcoFaq } from "./icons/IcoFaq";
export { default as IconIcoFiltre } from "./icons/IcoFiltre";
export { default as IconIcoFiltreNotification } from "./icons/IcoFiltreNotification";
export { default as IconIcoGuideTaille } from "./icons/IcoGuideTaille";
export { default as IconIcoHome } from "./icons/IcoHome";
export { default as IconIcoInstagram } from "./icons/IcoInstagram";
export { default as IconIcoLivraison } from "./icons/IcoLivraison";
export { default as IconIcoLocalisation } from "./icons/IcoLocalisation";
export { default as IconIcoMarquePremium } from "./icons/IcoMarquePremium";
export { default as IconIcoMenu } from "./icons/IcoMenu";
export { default as IconIcoNotification } from "./icons/IcoNotification";
export { default as IconIcoOrder } from "./icons/IcoOrder";
export { default as IconIcoPaiment3X4X } from "./icons/IcoPaiment3X4X";
export { default as IconIcoPinterest } from "./icons/IcoPinterest";
export { default as IconIcoSearch } from "./icons/IcoSearch";
export { default as IconIcoSponsorship } from "./icons/IcoSponsorship";
export { default as IconIcoSuccess } from "./icons/IcoSuccess";
export { default as IconIconTelFooter } from "./icons/IconTelFooter";
export { default as IconIconeAccount } from "./icons/IconeAccount";
export { default as IconIconeBack } from "./icons/IconeBack";
export { default as IconIconeBottom } from "./icons/IconeBottom";
export { default as IconIconeClose } from "./icons/IconeClose";
export { default as IconIconeEye } from "./icons/IconeEye";
export { default as IconIconeEyecross } from "./icons/IconeEyecross";
export { default as IconIconeFaq } from "./icons/IconeFaq";
export { default as IconIconeFinVentes } from "./icons/IconeFinVentes";
export { default as IconIconeGuideTaille } from "./icons/IconeGuideTaille";
export { default as IconIconeHome } from "./icons/IconeHome";
export { default as IconIconeInformations } from "./icons/IconeInformations";
export { default as IconIconeLivraison } from "./icons/IconeLivraison";
export { default as IconIconeLogout } from "./icons/IconeLogout";
export { default as IconIconeMenu } from "./icons/IconeMenu";
export { default as IconIconePanier } from "./icons/IconePanier";
export { default as IconIconePanierActif } from "./icons/IconePanierActif";
export { default as IconIconePictoApplication } from "./icons/IconePictoApplication";
export { default as IconIconeRight } from "./icons/IconeRight";
export { default as IconIconeSearch } from "./icons/IconeSearch";
export { default as IconIconeSearchBold } from "./icons/IconeSearchBold";
export { default as IconIconeSponsorship } from "./icons/IconeSponsorship";
export { default as IconIconpreference } from "./icons/Iconpreference";
export { default as IconIg } from "./icons/Ig";
export { default as IconIgColor } from "./icons/IgColor";
export { default as IconIgGray } from "./icons/IgGray";
export { default as IconIgGray1 } from "./icons/IgGray1";
export { default as IconInfo } from "./icons/Info";
export { default as IconInfoV2 } from "./icons/InfoV2";
export { default as IconInformationsIcone } from "./icons/InformationsIcone";
export { default as IconInfosOney } from "./icons/InfosOney";
export { default as IconInstagram } from "./icons/Instagram";
export { default as IconLinkedin } from "./icons/Linkedin";
export { default as IconLock } from "./icons/Lock";
export { default as IconLogoMobile } from "./icons/LogoMobile";
export { default as IconLogout } from "./icons/Logout";
export { default as IconLoop } from "./icons/Loop";
export { default as IconLoopSearch } from "./icons/LoopSearch";
export { default as IconMailFooter } from "./icons/MailFooter";
export { default as IconMailMenu } from "./icons/MailMenu";
export { default as IconMenu } from "./icons/Menu";
export { default as IconMinus } from "./icons/Minus";
export { default as IconMyFilleuls } from "./icons/MyFilleuls";
export { default as IconMyInfos } from "./icons/MyInfos";
export { default as IconMyOrders } from "./icons/MyOrders";
export { default as IconMyPref } from "./icons/MyPref";
export { default as IconMyReturn } from "./icons/MyReturn";
export { default as IconNewCloche } from "./icons/NewCloche";
export { default as IconOneyLogo } from "./icons/OneyLogo";
export { default as IconOrder } from "./icons/Order";
export { default as IconOrder2 } from "./icons/Order2";
export { default as IconPaiement } from "./icons/Paiement";
export { default as IconParams } from "./icons/Params";
export { default as IconPersonna } from "./icons/Personna";
export { default as IconPictoCb } from "./icons/PictoCb";
export { default as IconPictoMobile } from "./icons/PictoMobile";
export { default as IconPinterest } from "./icons/Pinterest";
export { default as IconPlaceholder } from "./icons/Placeholder";
export { default as IconPlus } from "./icons/Plus";
export { default as IconPoubelle } from "./icons/Poubelle";
export { default as IconPoubelleOuverte } from "./icons/PoubelleOuverte";
export { default as IconPower } from "./icons/Power";
export { default as IconPrivilege } from "./icons/Privilege";
export { default as IconQuestion } from "./icons/Question";
export { default as IconQuestionMark } from "./icons/QuestionMark";
export { default as IconReassurance } from "./icons/Reassurance";
export { default as IconRetoursretour } from "./icons/Retoursretour";
export { default as IconReturn } from "./icons/Return";
export { default as IconSearch } from "./icons/Search";
export { default as IconSecurePayment } from "./icons/SecurePayment";
export { default as IconServiceClient } from "./icons/ServiceClient";
export { default as IconSizeGuide } from "./icons/SizeGuide";
export { default as IconThreeCol } from "./icons/ThreeCol";
export { default as IconTo } from "./icons/To";
export { default as IconTrash } from "./icons/Trash";
export { default as IconTwitter } from "./icons/Twitter";
export { default as IconTwoCol } from "./icons/TwoCol";
export { default as IconUser } from "./icons/User";
export { default as IconUserFilled } from "./icons/UserFilled";
export { default as IconUsers } from "./icons/Users";
export { default as IconUsersFilled } from "./icons/UsersFilled";
export { default as IconUsersSponsorship } from "./icons/UsersSponsorship";
export { default as IconValidCheckAvailable } from "./icons/ValidCheckAvailable";
export { default as IconWrong } from "./icons/Wrong";
export { default as IconYoutube } from "./icons/Youtube";