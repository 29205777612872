import * as React from "react";
const SvgFbook = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 25 25"
    {...props}
  >
    <path
      fill="#CCC"
      fillRule="evenodd"
      d="M17.964 1.643v3.402l-.057-.003-1.912.001q-.24.001-.48.016c-.239.015-.472.06-.695.145-.36.139-.612.38-.746.735a2.5 2.5 0 0 0-.138.87q-.003 1.226 0 2.45c0 .077-.015.077.078.077h3.766c.034-.003.042.009.037.043q-.04.283-.076.567l-.065.491-.076.572-.065.492-.077.571-.065.492q-.034.252-.067.504c-.008.062-.005.063-.07.063h-3.232c-.1 0-.089-.012-.089.088v9.615c0 .066 0 .066-.066.066H9.93c-.026.001-.04-.007-.037-.034v-9.668c0-.067 0-.067-.069-.067H6.553l-.053.001V9.337l.07-.001h3.25c.09 0 .074-.003.074-.073V6.561q0-.219.008-.437c.01-.28.045-.556.097-.831a5 5 0 0 1 .504-1.465c.528-.975 1.337-1.635 2.399-2.01.32-.114.65-.192.988-.243.295-.044.591-.078.89-.075q.803.01 1.607.027.172.005.343.018.31.019.62.042.177.014.352.03z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgFbook;
