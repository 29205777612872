import { Button, Input, theme } from 'Ui';
import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import useForm from 'react-hook-form';
import styled from 'styled-components';
import { placeHolder } from 'Components/modals/LoginRegister/FogotPassword/ResetPasswordInstructions';
import { emailRegex } from 'Utils/login';
export type FormData = {
  email: string;
};

type ForgotFormProps = {
  onSubmitForgotPassword: (emailFromModal: string) => void;
  emailFromModal: any;
  close?: (shouldGoOn: boolean) => void;
  loading: boolean;
  type: string;
};

const MandatoryField = styled.div`
  font-size: 1rem;
  font-family: 'MontserratMedium' !important;
  line-height: 1.4rem;
  color: ${theme.v2.text.lightGray};
  text-align: center;
  padding: 1.6rem 0 0.8rem 0;
`;
export const ResetPasswordForm = ({
  onSubmitForgotPassword,
  loading = false,
}: ForgotFormProps) => {
  const { register, setError, errors, formState, getValues, watch } =
    useForm<FormData>({
      mode: 'onChange',
      defaultValues: {
        email: '',
      },
    });

  const emailFromBuglogin: any = sessionStorage.getItem('bugLogin');

  const emailParsed = JSON.parse(emailFromBuglogin);

  const values = getValues();

  const validating = (field: (typeof formState.touched)[0]) => {
    if (!field) return 'default';
    const typesErors = errors as { [index: string]: any };
    return typesErors[field]
      ? 'error'
      : formState.touched.includes(field)
      ? 'success'
      : 'default';
  };

  return (
    <>
      <form
        onSubmit={(e: any) => {
          e.preventDefault();
          onSubmitForgotPassword && onSubmitForgotPassword(values.email);
        }}
        noValidate
      >
        <Input
          block
          type="email"
          name="email"
          className="fontMontserrat"
          placeholder={placeHolder}
          kind={validating('email')}
          required
          value={watch('email')}
          ref={register({
            required: 'Champ obligatoire',
            pattern: {
              value: emailRegex,
              message: "L'adresse e-mail n'est pas valide",
            },
          })}
          helperText={errors.email && errors.email.message}
          autoComplete="off"
        />
        <MandatoryField>
          <sup>*</sup>Champs Obligatoire
        </MandatoryField>
        <Button
          style={{
            width: isMobile ? '100%' : '22rem',
            display: 'flex',
            margin: isMobile ? ' 0rem 0rem 1.5rem' : '0rem auto 1.5rem',
          }}
          type="submit"
          loading={loading}
        >
          Valider
        </Button>
      </form>
      {/* {resetInstruction && ResetPasswordIntruction(emailSend ? emailSend : '')} */}
    </>
  );
};
