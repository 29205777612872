import * as React from "react";
const SvgIcoSearch = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="ico-search_svg__Calque_1"
    x={0}
    y={0}
    viewBox="0 0 60 60"
    width="1em"
    height="1em"
    {...props}
  >
    <style>
      {
        ".ico-search_svg__st0{fill:none;stroke:#2f2f2f;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}"
      }
    </style>
    <path
      d="M49.9 25.7c0 6.7-2.7 12.8-7.1 17.2S32.3 50 25.6 50C12.2 50 1.3 39.1 1.3 25.7S12.2 1.4 25.6 1.4s24.3 10.9 24.3 24.3M58.3 59 42.7 43.4"
      className="ico-search_svg__st0"
    />
  </svg>
);
export default SvgIcoSearch;
