import { IconSearch } from 'Ui/Icons';
import React from 'react';
import styled from 'styled-components';
interface Props {
  placeholder?: string;
  className?: string;
  isLogin?: boolean;
}

const Wrapper = styled.div<Props>`
  width: 100%;
  height: 4.4rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  svg {
    width: 1.7rem;
    height: 1.7rem;
    margin-right: 1rem;
  }

  border-radius: 3rem;
  border: 1px solid #d8d8d8;
  background: #f9f9f9;
  padding: 0 4px 0 15px;
`;

const Placeholder = styled.div`
  width: 100%;
  overflow: hidden;
  font-size: 1.4rem;
  color: #4f4f4f;
  top: 1.45rem;
  line-height: 1;
`;

const SearchInput = ({ placeholder = '' }: Props) => {
  return (
    <>
      <Wrapper>
        <Placeholder>{placeholder}</Placeholder>
        <IconSearch width="20" height="21" />
      </Wrapper>
    </>
  );
};
export default SearchInput;
