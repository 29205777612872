import { AppStyled, GlobalStyle } from '../GlobalStyle';
import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { init } from 'Libs/apollo';
import { initGtm } from 'Libs/Gtm/common';
import NotFound from 'Pages/NotFound';
import AuthStore from 'Store/AuthStore';
import UiStore from 'Store/UiStore';
import InitiStore from 'Store/InitiStore';
import { BrowserRouter } from 'react-router-dom';
import ModalContainer from 'react-modal-promise';

initGtm();

const App = () => {
  const client = init();
  return (
    <AppStyled>
      <BrowserRouter>
        <AuthStore.Provider initialData={client}>
          <UiStore.Provider>
            <ApolloProvider client={client}>
              <InitiStore />
              <>
                <GlobalStyle />
                <NotFound />
                <ModalContainer />
              </>
            </ApolloProvider>
          </UiStore.Provider>
        </AuthStore.Provider>
      </BrowserRouter>
    </AppStyled>
  );
};

export default App;
