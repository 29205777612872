import * as React from "react";
const SvgIcoAchatExpress = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    viewBox="0 0 60 60"
    width="1em"
    height="1em"
    {...props}
  >
    <style>
      {
        ".ico-achat-express_svg__st0{stroke-linecap:round}.ico-achat-express_svg__st0,.ico-achat-express_svg__st1{fill:none;stroke:#2f2f2f;stroke-width:2;stroke-miterlimit:10}"
      }
    </style>
    <g id="ico-achat-express_svg__Calque_2">
      <path
        d="M15.9 24v-5.8H58v26.4s.6 6.8-9.5 6.6c-10.1-.1-23.8 0-23.8 0s-8.7 0-8.8-9.5"
        className="ico-achat-express_svg__st0"
      />
      <path
        d="M.9 28.7h17.5M.9 37.8h17.5M5.3 33.2h13.1"
        className="ico-achat-express_svg__st1"
      />
      <path
        d="M24.4 24.7c-.2-1.1-.3-2.3-.3-3.5 0-8.7 6-15.7 13.3-15.7s13.3 7 13.3 15.7c0 1.1-.1 2.1-.3 3.2"
        className="ico-achat-express_svg__st0"
      />
      <defs>
        <path
          id="ico-achat-express_svg__SVGID_1_"
          d="M-151 434.5H40v-70.3h-191z"
        />
      </defs>
      <clipPath id="ico-achat-express_svg__SVGID_00000083070591610281002250000011658758444751707009_">
        <use
          xlinkHref="#ico-achat-express_svg__SVGID_1_"
          style={{
            overflow: "visible",
          }}
        />
      </clipPath>
      <g
        style={{
          clipPath:
            "url(#ico-achat-express_svg__SVGID_00000083070591610281002250000011658758444751707009_)",
        }}
      >
        <defs>
          <path
            id="ico-achat-express_svg__SVGID_00000008107612541250063260000015140946623630915736_"
            d="M-6 400.2h36v25H-6z"
          />
        </defs>
        <clipPath id="ico-achat-express_svg__SVGID_00000031207579563938300070000017477222232373689506_">
          <use
            xlinkHref="#ico-achat-express_svg__SVGID_00000008107612541250063260000015140946623630915736_"
            style={{
              overflow: "visible",
            }}
          />
        </clipPath>
        <g
          style={{
            clipPath:
              "url(#ico-achat-express_svg__SVGID_00000031207579563938300070000017477222232373689506_)",
          }}
        >
          <defs>
            <path
              id="ico-achat-express_svg__SVGID_00000183937580248131819650000013013312190456072094_"
              d="M0 401.2h29v24H0z"
            />
          </defs>
          <clipPath id="ico-achat-express_svg__SVGID_00000088122913216629548980000005717877450273757332_">
            <use
              xlinkHref="#ico-achat-express_svg__SVGID_00000183937580248131819650000013013312190456072094_"
              style={{
                overflow: "visible",
              }}
            />
          </clipPath>
          <path
            d="M11.7 410.6s-1.1-8.8 6.3-8.8 6.4 8.8 6.4 8.8"
            style={{
              clipPath:
                "url(#ico-achat-express_svg__SVGID_00000088122913216629548980000005717877450273757332_)",
              fill: "none",
              stroke: "#fff",
              strokeWidth: 1.1,
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeMiterlimit: 10,
            }}
          />
          <path
            d="M7.6 410.7c0-2.1-.1-2.8-.1-2.8H28v12.2s.5 3.9-4.6 3.8-11.5 0-11.5 0c-2.4 0-4.3-2-4.2-4.4v-.2.3"
            style={{
              clipPath:
                "url(#ico-achat-express_svg__SVGID_00000088122913216629548980000005717877450273757332_)",
              fill: "none",
              stroke: "#fff",
              strokeWidth: 1.1,
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeMiterlimit: 10,
            }}
          />
          <path
            d="M2.4 415.2h6.8M.4 413h8.5M.4 417.4h8.5"
            style={{
              clipPath:
                "url(#ico-achat-express_svg__SVGID_00000088122913216629548980000005717877450273757332_)",
              fill: "none",
              stroke: "#fff",
              strokeWidth: 1.1,
              strokeMiterlimit: 10,
            }}
          />
        </g>
      </g>
    </g>
  </svg>
);
export default SvgIcoAchatExpress;
