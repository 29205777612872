import gql from "graphql-tag";

import { InfoProduct } from "Fragments/Product";
import { InfoSale } from "Fragments/Sale";

export const SALE_PRODUCTS_LIST = gql`
  query SaleProductsList(
    $saleId: ID!
    $sort: SortEnum
    $facetFilters: [FacetInput]
    $filters: FiltersInput
    $pagination: PaginationInput
  ) {
    products: productsBySaleId(
      saleId: $saleId
      sort: $sort
      facetFilters: $facetFilters
      filters: $filters
      pagination: $pagination

    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          ...InfoProduct
          hasStock
        }
      }
    }
  }
  ${InfoProduct}
`;

export const SALE = gql`
  query sale($id: ID!) {
    sale: saleById(id: $id) {
      rootId
      name
      id
      ...InfoSale
      maximumRootDiscountPercent
      parent {
        name
        id
        parent {
          name
          id
        }
      }
    }
  }
  ${InfoSale}
`;

export const ROOT_SALE = gql`
  query rootSale($id: ID!) {
    sale: saleById(id: $id) {
      id
      name
      coverUrl
      logoUrl
      ...InfoSale
      children {
        id
        name
        children {
          id
          name
        }
      }
    }
  }
  ${InfoSale}
`;


export const INFROMATION_DATALAYER = gql`
  query informationDatalayer($id: ID!) {
    sale: saleById(id: $id) {
      promoCategory
      productDeliveryDate
      promoMulti
      saleArea
    }
  }
`;

export const SET_TRACK_PAGE_VIEW = gql`
  mutation setTrackPageView($infos: [PageViewInput!]!) {
    trackPageView(infos: $infos)
  }
`;

export const SET_TRACKVIEW_INFORMATION = gql`
  mutation setTrackViewIformation(
    $infos: [PageInformationInput!]!
  ) {
    trackPageInformation(infos: $infos)
  }
`;

export const MENU = gql`
  query menu($id: ID!) {
    menuSale: saleById(id: $id) {
      menuChildren {
        id
        name
        menuChildren {
          id
          name
        }
      }
    }
  }
`;

