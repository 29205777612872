import { useState } from 'react';
import styled from 'styled-components';
import { theme } from 'Ui';

const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;

const SwitchBtn = styled.div`
  position: relative;
  width: 38px !important;
  height: 18px !important;
  background: ${theme.light.colors.grayUltraLight};
  border-radius: 32px;
  /* padding: 4px; */
  transition: 300ms all;

  &:before {
    transition: 300ms all;
    content: '';
    position: absolute;
    width: 1.6rem !important;
    height: 1.6rem !important;
    border-radius: 35px;
    top: 50%;
    left: 1px;
    background: white;
    transform: translate(0, -50%);
  }
  @media screen and (max-width: 48rem) {
    width: 38px !important;
    height: 22px !important;
    &:before {
      transition: 300ms all;
      content: '';
      position: absolute;
      width: 2rem !important;
      height: 2rem !important;
      border-radius: 35px;
      top: 50%;
      left: 1px;
      background: white;
      transform: translate(0, -50%);
    }
  }
`;

const Input = styled.input`
  opacity: 0;
  position: absolute;

  &:checked + ${SwitchBtn} {
    background: ${theme.light.colors.purple};

    &:before {
      transform: translate(20px, -50%);
    }
    @media screen and (max-width: 48rem) {
      &:before {
        transform: translate(16px, -50%);
      }
    }
  }
`;

const Switch = ({ isOn, handleToggle }: any) => {
  return (
    <>
      <Label>
        <Input checked={isOn} type="checkbox" onChange={handleToggle} />
        <SwitchBtn />
      </Label>
    </>
  );
};

export default Switch;
