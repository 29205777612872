import styled, { css } from 'styled-components/macro';

export const StyledContainerTitleTunnel = styled.div`
  display: flex;
  width: 95%;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 1rem 0rem 1.6rem;
  background: rgba(228, 215, 198, 0.2);
  border-radius: 8px;
  height: 4rem;
  filter: alpha(opacity=20);
  -moz-opacity: 0.2;
  -khtml-opacity: 0.2;
  @media screen and (min-width: 1240px) {
    width: 100%;
  }

  margin-bottom: 1.8rem;
`;

export const TitleTunnel = styled.div`
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 2.4rem;
`;

export const SubTitleTunnel = styled.div`
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 1, 2rem; ;
`;
