import { ApolloClient } from '@apollo/client';
import { optinMember, optinMemberVariables } from 'Components/FooterV2/__generated__/optinMember';
import { REGISTER_SUSPECT } from "Components/FooterV2/query";

export const registerSuspect = async (
  email: string,
  application: any,
  module: any,
  subscriptionURI: any,
  apolloClient: ApolloClient<Object>
) => {
  try {
    const { data } = await apolloClient.mutate({
      mutation: REGISTER_SUSPECT,
      variables: {
        suspect: {
          email: email
        },
        environment: {
          application: application,
          module: module
        },
        subscriptionURI: subscriptionURI
      },
    });
    return data;
  } catch (e) {
    throw e;
  }
};