import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { Action, Thunk, action, createContextStore, thunk } from 'easy-peasy';
import Cookies from 'js-cookie';
import {
  login,
  login_createTokenFromSessionId_member,
} from './__generated__/login';

import { removeCookie, setCookie } from 'Utils/cookie';
import { createSession } from 'Utils/webview/utilsSession';
import { TOKEN_FROM_SESSIONID } from './query';
import { allowedTypes } from 'Utils/memberTypes';

type AuthParam = {
  jwt: string;
  member: login_createTokenFromSessionId_member | any;
  sessionId: string | null;
  sessionMemberType?: string;
  isCompletedProfile?: boolean;
  phoneNumber?: string | null;
};

type AuthModel = {
  apolloClient: ApolloClient<object> | undefined;

  isLoggedIn: boolean;
  sessionMemberType: string;
  isCompletedProfile: boolean;
  member: login_createTokenFromSessionId_member | null;
  loading: boolean;
  preferenceJwt: string;
  emailSuspect: string;

  setIsCompletedProfile: Action<AuthModel, boolean>;
  setPrefrenceJwt: Action<AuthModel, string>;
  setEmailSuspect: Action<AuthModel, string>;
  setMember: Action<AuthModel, login_createTokenFromSessionId_member | null>;
  setIsLoggedIn: Action<AuthModel, boolean>;
  setSessionMemberType: Action<AuthModel, string>;
  setLoading: Action<AuthModel, boolean>;

  setAuth: Action<AuthModel, AuthParam>;

  initAuth: Thunk<
    AuthModel,
    void,
    { apolloClient: ApolloClient<NormalizedCacheObject> }
  >;
};

const authModel = (
  apolloClient: ApolloClient<object> | undefined
): AuthModel => ({
  apolloClient,
  isLoggedIn: false,
  member: null,
  loading: false,
  sessionMemberType: null,
  preferenceJwt: null,
  emailSuspect: null,
  isCompletedProfile: false,

  // actions
  setMember: action((state, payload) => {
    state.member = payload;
  }),
  setSessionMemberType: action((state, payload) => {
    state.sessionMemberType = payload;
  }),
  setIsCompletedProfile: action((state, payload) => {
    state.isCompletedProfile = payload;
  }),
  setIsLoggedIn: action((state, payload) => {
    state.isLoggedIn = payload;
  }),
  setPrefrenceJwt: action((state, payload) => {
    state.preferenceJwt = payload;
  }),
  setEmailSuspect: action((state, payload) => {
    state.emailSuspect = payload;
  }),
  setLoading: action((state, payload) => {
    state.loading = payload;
  }),

  /** Sets authentification if obtained by other mean than login */
  setAuth: action((state, payload) => {
    state.isLoggedIn = allowedTypes.includes(payload.sessionMemberType)
      ? true
      : false;
    state.sessionMemberType = payload.sessionMemberType;
    state.member = payload.member;
    state.isCompletedProfile = payload.isCompletedProfile;
    sessionStorage.setItem('access_token', payload.jwt);
    const expirationDate = new Date();
    expirationDate.setMonth(expirationDate.getMonth() + 6);
    const loginCookieObj = {
      path: '/',
      expires: expirationDate,
      secure: true,
      sameSite: 'Lax',
      value: payload.sessionId || '',
      name: 'ssession',
    };
    setCookie(loginCookieObj);
  }),

  // thunks
  initAuth: thunk(async (actions, payload, { getState }) => {
    const search = window.location.search;
    const webviewParams = new URLSearchParams(search).get('webview');
    const idSessionParams = new URLSearchParams(search).get('idSession');
    // For webview
    if (!!idSessionParams && !!webviewParams) {
      createSession(idSessionParams);
    }
    // get sessionId cookie - old authentification from main php site
    // For now, we need to follow this logic, so we do a change from
    // session cookie to JWT at every application start
    const sessionId = Cookies.get('ssession');
    const apolloClient = getState().apolloClient;
    if (!apolloClient) return;

    // delete old jwt
    if (!sessionId && sessionStorage.getItem('access_token')) {
      sessionStorage.removeItem('access_token');
    }

    let member: login_createTokenFromSessionId_member | null = null;
    let jwt = '';
    let sessionMemberType;
    let isCompletedProfile
    // If sessionId is there let's get the JWT now, and get the user
    if (sessionId) {
      actions.setLoading(true);
      try {
        const result = await apolloClient.query<login>({
          query: TOKEN_FROM_SESSIONID,
          variables: { sessionId },
        });

        jwt =
          (result.data &&
            result.data.createTokenFromSessionId &&
            result.data.createTokenFromSessionId.jwt) ||
          '';

        member =
          (result.data &&
            result.data.createTokenFromSessionId &&
            result.data.createTokenFromSessionId.member) ||
          null;

        sessionMemberType =
          result && result.data.createTokenFromSessionId.sessionMemberType;
        isCompletedProfile =
          result && result.data.createTokenFromSessionId.isCompletedProfile;
        localStorage.setItem('isLoggedIn', 'true');
      } catch (e: any) {
        const failedToFetch = e.message?.includes('Failed to fetch');
        if (!failedToFetch) {
          removeCookie('ssession');
        }
        console.log({ e });
      }

      actions.setLoading(false);

      sessionStorage.setItem('access_token', jwt);
    }

    if (sessionMemberType) {
      actions.setSessionMemberType(sessionMemberType);
    }
    if (isCompletedProfile) {
      actions.setIsCompletedProfile(isCompletedProfile);
    }

    // If the jwt is here set it in local storage for subsequent requests
    if (jwt) {
      // we are officially logged in
      actions.setIsLoggedIn(
        allowedTypes.includes(sessionMemberType) ? true : false
      );
    }
    // If we do have the user (member), let's dispatch it in our store
    if (member) {
      actions.setMember(member);
      // if (!!Cookies.get("_marketing")) {
      //   setGtmUserData(member);
      // }
    }

    // listen to local storage events in case our token get busted out
    window &&
      window.addEventListener('storage', () => {
        if (!sessionStorage.getItem('access_token')) {
          actions.setMember(null);
          actions.setIsLoggedIn(false);
        }
      });
  }),
});

export default createContextStore(authModel, { name: 'authState' });
