export default {
  white: '#fff',
  gray: '#969696',
  grayLighter: '#C8C8C8',
  grayLight: '#F2F2F3',
  grayDark: '#4A4A4A',
  grayUltraDark: '#2F2F2F',
  grayUltraLight: '#DEDEDE',
  black: '#000',
  purple: '#6C4DFF',
  purpleLight: '#907AFC',
  background: 'white',
  success: '#66cc66',
  error: '#C64235',
  default: '#C8C8C8',
  primary: '#C8C8C8',
  button: {
    backgroundColor: {
      purple: '#6C4DFF',
      default: '#6C4DFF',
      primary: '#6C4DFF',
      black: '#000',
      success: '#66cc66',
      error: '#ccc',
    },
    borderColor: {
      purple: '#6C4DFF',
      default: '#6C4DFF',
      primary: '#6C4DFF',
      black: '#000',
      success: '#66cc66',
      error: '#ccc',
    },
    color: {
      purple: '#6C4DFF',
      default: '#6C4DFF',
      primary: '#6C4DFF',
      black: '#000',
      success: '#66cc66',
      error: '#C64235',
    },
  },
  select: {
    borderColor: {
      default: '#F2F2F3',
    },
    backgroundColor: {
      default: '#F2F2F3',
    },
  },
};
