import React from 'react';
import styled, { css } from 'styled-components/macro';
import posed from 'react-pose';

import { theme } from './theme';

interface StyleProps {
  kind?: 'default' | 'purple' | 'error';
  selected: boolean;
  hollow?: boolean;
  top?: number;
  left?: number;
  marginLeft?: number;
  radioWidth?: number;
  radioHeight?: number;
  radioWidthChecked?: number;
  radioHeightChecked?: number;
  isVouchers?: boolean;
}

interface StylePropsRouned extends Omit<StyleProps, 'selected'> {
  kind?: 'default' | 'purple' | 'error';
  hollow?: boolean;
}

interface RadioGroup extends Omit<StyleProps, 'selected'> {
  options: { value: string; label: string }[];
  onChange: (value: any) => void;
  placeholder?: string;
  value: any;
  selected?: boolean;
  show?: boolean;
}

const SelectStyled = styled.div`
  position: relative;
  font-family: 'Montserrat';
`;

const CheckRadio = styled.div<StyleProps>`
  width: ${(props) => props.radioWidth && `${props.radioWidth}rem`};
  height: ${(props) => props.radioHeight && `${props.radioHeight}rem`};
  position: relative;
  border: 2px solid ${theme.light.colors.grayUltraLight};
  background-color: white;
  border-radius: 100%;
  margin-right: 1.1rem;
  cursor: pointer;
  /* ${(props) =>
    props.selected &&
    css`
      border-color: ${theme.light.colors[props.kind || 'default']};
    `}; */
`;

const Content = styled.div<{ show?: boolean; reverse?: boolean }>`
  .radioGroup {
    display: flex;
    align-items: center;
  }
  .radioGroup:nth-child(1n) {
    margin-bottom: 7px;
  }
  .radioGroup:last-child {
    margin-bottom: 0px;
  }
  ${(props) =>
    props.reverse &&
    css`
      .radioGroup {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        /* flex: 1 0; */
        align-items: center;
      }
      .radioGroup:nth-child(1n) {
        margin-bottom: 7px;
      }
      .radioGroup:last-child {
        margin-bottom: 0px;
      }
    `}
`;

const Option = styled.div`
  color: ${theme.v2.text.darkGray};
  font-size: 1.4rem;
  font-family: 'Montserrat' !important;
`;

const SelectedRounded = styled.div<StylePropsRouned>`
  position: absolute;
  transform-origin: center;
  top: ${(props) => props.top && `${props.top}px`};
  left: ${(props) => props.left && `${props.left}px`};
  margin-left: ${(props) => props.left && `${props.marginLeft}px`};
  border-radius: 100%;
  width: ${(props) =>
    props.radioWidthChecked && `${props.radioWidthChecked}rem`};
  height: ${(props) =>
    props.radioHeightChecked && `${props.radioHeightChecked}rem`};
  background: ${(props) => theme.light.colors[props.kind || 'default']};
  @media screen and (max-width: 968px) {
    margin-left: 0px;
    margin-left: ${(props) => props.marginLeft && `0px`};
  }
`;
const SelectedRoundedAnimated = posed.div({
  selected: {
    alignSelf: 'center !important',
    scale: 1,
    transition: { ease: 'easeInOut', duration: 200 },
  },
  unselected: {
    scale: 0,
    transition: { ease: 'easeInOut', duration: 200 },
  },
});

export const RadioGroup = ({
  options,
  onChange,
  top = 2,
  left = 2,
  marginLeft = 0,
  value,
  selected = true,
  kind,
  show,
  hollow,
  radioWidth = 2.5,
  radioHeight = 2.5,
  radioWidthChecked = 1.7,
  radioHeightChecked = 1.7,
  isVouchers = false,
}: RadioGroup) => {
  const onSelect = (value: any) => {
    onChange(value);
  };
  return (
    <SelectStyled>
      <Content className="inline-radio-button">
        {options.map((option, index) => {
          const selected = option.value === value;
          return (
            <div
              className="radioGroup"
              key={option.value}
              onClick={() => onSelect(option.value)}
            >
              <CheckRadio
                radioWidth={radioWidth}
                radioHeight={radioHeight}
                selected={selected}
                kind={kind}
                hollow={hollow}
              >
                <SelectedRoundedAnimated
                  pose={selected ? 'selected' : 'unselected'}
                >
                  <SelectedRounded
                    kind={kind}
                    top={top}
                    left={left}
                    marginLeft={marginLeft}
                    radioHeightChecked={radioHeightChecked}
                    radioWidthChecked={radioWidthChecked}
                  />
                </SelectedRoundedAnimated>
              </CheckRadio>
              <Option className="fontMontserrat" key={option.label}>
                {option.label}
              </Option>
            </div>
          );
        })}
      </Content>
    </SelectStyled>
  );
};
