import styled from 'styled-components';
import logoBzcPath from 'Assets/images/BzcLogo1.svg';
import { useEffect, useState } from 'react';
import { theme } from 'Ui';
import { LinkToLoginOrRegister } from './LinkToLoginOrRegister/LinkToLoginOrRegister';
import UiStore from 'Store/UiStore';
import { ConnectingWith } from './LoginWithSocial';

type LoginFormProps = {
  title: string;
  switchTo: () => void;
  textTo: string;
  linkTo: string;
  isLoginMethod?: boolean;
  form?: any;
  showLogo?: boolean;
  showReassurance: boolean;
  register?: string;
  pageCurrent?: string;
  onOpenCookies?: () => void;
};
const TitleLoginRegister = styled.div`
  color: ${theme && theme.v2.black};
  font-family: 'MontserratMedium' !important;
  font-size: 2rem;
  font-style: normal;
  line-height: normal;
  margin-top: 1rem;
  @media screen and (min-width: ${theme.breakpoints.medium}px) {
    font-size: 2.4rem;
  }
`;

const LoginRegisterContainer = styled.div`
  max-width: 49rem;
  margin: auto;
`;

const LogoStyled = styled.img`
  height: 2.2rem;
  width: 20.6rem;
`;

const LogoContainer = styled.div<{ showLogo: boolean }>`
  text-align: center;
  margin-top: 3rem;
  ${(props) => (props.showLogo ? 'display: block' : 'display: none')};
`;

const SubtitleRegister = styled.div`
  font-family: 'MontserratMedium' !important;
  font-size: 1.4rem;
  font-style: normal;
  line-height: 22px;
  color: ${theme.v2.text.lightGray};
  margin-top: 0.4rem;
`;

export const LoginFormV2 = ({
  title,
  switchTo,
  textTo,
  linkTo,
  isLoginMethod = false,
  showReassurance,
  form,
  showLogo = false,
  register = '',
  onOpenCookies,
  pageCurrent,
}: LoginFormProps) => {
  const cookieMarketingStore = UiStore.useStoreState(
    (state) => state.cookieMarketing
  );
  const [showSocial, setShowSocial] = useState(false);
  useEffect(() => {
    setShowSocial(cookieMarketingStore);
  }, [cookieMarketingStore]);

  return (
    <LoginRegisterContainer>
      {showLogo && (
        <LogoContainer showLogo={showLogo}>
          <a href="/">
            <LogoStyled src={logoBzcPath} alt="BazarChic" />
          </a>
        </LogoContainer>
      )}
      <TitleLoginRegister>{title}</TitleLoginRegister>
      {register && <SubtitleRegister>{register}</SubtitleRegister>}
      <LinkToLoginOrRegister
        switchTo={switchTo}
        textTo={textTo}
        linkTo={linkTo}
      />
      <ConnectingWith onOpenCookies={() => onOpenCookies && onOpenCookies()} />

      <div style={{ marginTop: '16px' }}>{form}</div>
    </LoginRegisterContainer>
  );
};
