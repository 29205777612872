import logoBzc from 'Assets/images/BzcLogo1.svg';
import { theme } from 'Ui';
import styled from 'styled-components/macro';
import AnonymousHeader from '../AnonymousHeader';
import HeaderBasket from '../HeaderBasket';

import BannerApp from 'Components/BannerApp';
import { storageFactory } from 'Libs/storageFactory';
import AuthStore from 'Store/AuthStore';
import { isAndroid, isIOS } from 'react-device-detect';

import UiStore from 'Store/UiStore';

const DesktopHeaderHeight = 6.5;

const HeaderStyled = styled.div`
  background-color: white;
  z-index: 10001;
  position: relative;
  padding-top: ${DesktopHeaderHeight}rem;
  margin-bottom: 1.2rem;
`;

const LogoStyled = styled.img`
  width: 31rem;
  height: 100%;
`;
const MobileHeaderHeight = 4.8;

const TopBar = styled.div`
  display: flex;
  align-items: center;
  height: ${MobileHeaderHeight}rem;
  justify-content: space-between;
  padding: 0 1.5rem;
  border-bottom: 1px solid #eee;
  background-color: #fff;
  font-size: 2.5rem;
  img {
    width: 19rem;
  }
`;

const FixBar = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 1000;
  .addShadow {
    box-shadow: 0px 1px 9px 0px rgba(0, 0, 0, 0.1);
  }
`;
const Basket = styled.div`
  @media screen and (max-width: ${theme.breakpoints.small}px) {
    margin-right: 1rem;
    svg {
      height: 2.6rem;
    }
    svg path {
      // stroke: ${theme.light.colors.purple};
      stroke-width: 4px;
    }
  }
`;

export default () => {
  const cookieMobileAppPopup = UiStore.useStoreState(
    (state) => state.cookieMobileApp
  );

  return (
    <>
      <>
        <FixBar>
          {cookieMobileAppPopup && (isAndroid || isIOS) && (
            <BannerApp android={isAndroid} />
          )}
          <TopBar>
            <div style={{ flex: 1, display: 'flex', marginLeft: '1.8rem' }}>
              <a href="/">
                <LogoStyled src={logoBzc} />
              </a>
            </div>
            <Basket>
              <HeaderBasket isBasket={true} />
            </Basket>
          </TopBar>
        </FixBar>
      </>
    </>
  );
};
