import gql from "graphql-tag";
import { GtmInfo } from "Fragments/GtmInfo";

export const CREATE_PASSWORD_FROM_RESET_TOKEN = gql`
  mutation createPasswordFromResetToken(
    $memberId: ID!
    $token: String!
    $newPassword: String!
  ) {
    createPasswordFromResetToken(
      memberId: $memberId
      token: $token
      newPassword: $newPassword
    ) {
      member {
        id
        isVip
        email
        firstName
        lastName
        phoneNumber
        ...GtmInfo
      }
      sessionId
      jwt
    }
  }
  ${GtmInfo}
`;

export const CHECK_RESET_PASSWORD_DATA = gql`
  query checkResetPasswordDatas($memberId: ID!, $token: String!) {
    checkResetPasswordDatas(memberId: $memberId, token: $token)
  }
`;

export const BACKGROUND_IMAGES = gql`
  query pageDatas {
    pageDatas {
      loginBackgroundImages {
        urlBackgroundDesktop1x
        urlBackgroundDesktop2x
        urlBackgroundMobile1x
      }
    }
  }
`;
