import AuthStore from 'Store/AuthStore';
import styled from 'styled-components';
import { theme } from 'Ui';
import showLoginModal from 'Components/modals/LoginRegister';
import { ModalTitleClient } from 'Utils/modalTitleProps';

const ContactContainer = styled.div`
  display: flex;
  max-width: 22rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  margin-right: 2.4rem;
  padding-bottom: 2rem;
  @media screen and (max-width: 48rem) {
    width: calc(96.5% - 2rem);
    gap: 0;
    padding-bottom: 0;
  }
`;

const TitleContact = styled.div`
  color: ${theme.light.colors.black};
  font-family: 'granville-bold' !important;
  font-size: 19px;
  line-height: normal;
  @media screen and (max-width: 48rem) {
    font-size: 1.6rem;
  }
`;

const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  a {
    color: ${theme.light.colors.black};
    font-family: 'Montserrat' !important;
    font-size: 1.4rem;
    font-style: normal;
    line-height: 18px; /* 128.571% */

    :hover {
      color: ${theme.light.colors.purple};
    }
    @media screen and (max-width: 48rem) {
      font-size: 1.2rem;
    }
  }
`;

const Link = styled.a`
  padding-top: 1.1rem;
  cursor: pointer;
`;

type ContactProps = {
  title: string;
  links: any;
};

export const ContactColumn = ({ title, links }: ContactProps) => {
  const isLoggedIn = AuthStore.useStoreState((state) => state.isLoggedIn);
  return (
    <ContactContainer>
      <TitleContact>{title}</TitleContact>
      <LinksContainer>
        {links.map(
          (
            link: {
              linkUrl: string;
              linkName: string;
              linkTarget: string;
              isLoggedIn: boolean;
            },
            index: any
          ) =>
            !link.isLoggedIn ? (
              <Link key={index} href={link.linkUrl} target={link.linkTarget}>
                {link.linkName}
              </Link>
            ) : isLoggedIn ? (
              <Link key={index} href={link.linkUrl} target={link.linkTarget}>
                {link.linkName}
              </Link>
            ) : (
              <Link
                key={index}
                onClick={() => {
                  const loginModalProps = {
                    linkRedirect: link.linkUrl,
                    title: {
                      login: ModalTitleClient.login,
                      register: ModalTitleClient.register,
                    },
                    isRegister: true,
                  };
                  showLoginModal(loginModalProps);
                }}
                target={link.linkTarget}
              >
                {link.linkName}
              </Link>
            )
        )}
      </LinksContainer>
    </ContactContainer>
  );
};
