import React from "react";
import styled from "styled-components/macro";

type SpinnerProps = {
  size?: number;
};

export const Spinner = ({ size = 10, color = "rgba(0,0,0,0.5)" }) => (
  <StyledSpinner viewBox="0 0 50 50" size={size} color={color}>
    <circle
      className="path"
      cx="25"
      cy="25"
      r="20"
      fill="none"
      strokeWidth="5"
    />
  </StyledSpinner>
);

const StyledSpinner = styled.svg<SpinnerProps>`
  width: ${(props) => props.size}px;
  animation: rotate 1s linear infinite;

  & .path {
    stroke: ${(props) => props.color};
    animation: dash 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;
