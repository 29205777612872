import styled, { createGlobalStyle } from 'styled-components/macro';
import fonts from './Fonts/';

export const GlobalStyle = createGlobalStyle`

@font-face {
  font-family: "granville-Regular";
  src: url(${fonts.granvileRegular}) format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'granville-bold';
  src: url(${fonts.granvileBold}) format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url(${fonts.MontserratRegular}) format('opentype');
  /* font-weight: 400; */
  font-style: normal;
}

@font-face {
  font-family: 'MontserratLight';
  src: url(${fonts.MontserratLight}) format('opentype');
  /* font-weight: 400; */
  font-style: normal;
}

@font-face {
  font-family: 'MontserratMedium';
  src: url(${fonts.MontserratMedium}) format('opentype');
  /* font-weight: 400; */
  font-style: normal;
}

@font-face {
  font-family: 'MontserratSemiBold';
  src: url(${fonts.MontserratSemiBold}) format('opentype');
  /* font-weight: 400; */
  font-style: normal;
}

@font-face {
  font-family: 'MontserratBold';
  src: url(${fonts.MontserratBold}) format('opentype');
  /* font-weight: 400; */
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url(${fonts.InterRegular})
    format('opentype');
  font-style: normal;
}
@font-face {
  font-family: 'InterMedium';
  src: url(${fonts.InterMedium})
    format('opentype');
  font-style: normal;
}
@font-face {
  font-family: 'InterSemiBold';
  src: url(${fonts.InterSemiBold})
    format('opentype');
  font-style: normal;
}
@font-face {
  font-family: 'InterBold';
  src: url(${fonts.InterBold})
    format('opentype');
  font-style: normal;
}

@font-face {
  font-family: 'Albra';
  src: url(${fonts.AlbraRegular})
    format('opentype');
  font-style: normal;
}
@font-face {
  font-family: 'AlbraMedium';
  src: url(${fonts.AlbraMedium})
    format('opentype');
  font-style: normal;
}
@font-face {
  font-family: 'AlbraSemiBold';
  src: url(${fonts.AlbraSemiBold})
    format('opentype');
  font-style: normal;
}



* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
  background-color: #fff;
  min-height: 200px;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
html {
        font-size: 62.5%;

}
body {
  width : 100vw;
  overflow-x : hidden;
  overflow-y : auto;
}

  /* @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap"); */
  /* @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;600;700;800&display=swap"); */
  a {
    color: inherit;
    text-decoration: inherit;
  }
  button {
    outline: none;
  }
  * {
    box-sizing: border-box;
    font-family: inherit;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  input,textarea {
    /* Exception for input areas */
    -webkit-touch-callout: default !important;
    -webkit-user-select: text !important;
    -khtml-user-select: text !important;
    -moz-user-select: text !important;
  }




  .accordion-wrapper .accordion-item h3.accordion-title {
    font-size: 1.75rem;
    margin: 0;
  }
  
  .accordion-wrapper .accordion-item h3.accordion-title button {
    position: relative;
    display: flex;
    align-items: center;
    background: #c4c4c4;
    border-radius: 1rem;
    border: none;
    font-size: 1.2rem;
    width: 100%;
    text-align: left;
    color: #ffffff;
    font-weight: bold;
    margin-top: 0.5rem;
    padding: 1rem;
  }
  
  .accordion-wrapper .accordion-item h3.accordion-title button.active {
    background-color: #dfbd85;
    color: #ffffff;
    border: #dfbd85 solid 1px;
  }
  
  .accordion-wrapper .accordion-item h3.accordion-title button:hover {
    background-color: #dfbd85;
  }
  
  .accordion-wrapper
    .accordion-item
    h3.accordion-title
    button
    span.title-wrapper {
    display: block;
    position: relative;
    width: 100%;
  }
  
  .accordion-wrapper .accordion-item h3.accordion-title button span.icon-wrapper {
    width: 10%;
    display: flex;
    justify-content: center;
  }
  
  .accordion-wrapper
    .accordion-item
    h3.accordion-title
    button
    span.icon-wrapper
    span.minus {
    content: url('../src/assets/line.svg');
    width: 24px;
    height: 24px;
  }
  
  .accordion-wrapper
    .accordion-item
    h3.accordion-title
    button
    span.icon-wrapper
    span.plus {
    content: url('../src/assets/plus.svg');
    width: 24px;
    height: 24px;
    transform: rotate(90deg);
    transition-timing-function: ease-in;
    transition: all 1s;
  }
  
  .accordion-wrapper .accordion-item .accordion-panel .panel-close {
    background: #e0e0e0;
    box-sizing: border-box;
    padding: 0.5rem 1rem 0 1rem;
    opacity: 0;
    width: 100%;
    height: 0;
    overflow: hidden;
    transition-timing-function: ease-out;
    transition: all 1s;
  }
  
  .accordion-wrapper .accordion-item .accordion-panel .panel-open {
    overflow: hidden;
    background: #e0e0e0;
    padding: 1rem;
    margin-bottom: 1rem;
    box-sizing: border-box;
    border-radius: 1rem;
    opacity: 1;
    width: 100%;
    height: auto;
    transition-timing-function: ease-in;
    transition: all 1s;
  }
  
  .accordion-wrapper .accordion-item .accordion-panel p {
    margin: 0;
  }

  .scrollTop {
    transition: max-height 0.25s ease-in;
  }
  
`;

export const AppStyled = styled.div`
  /* @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap");
  @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;800&display=swap");
  font-family: "MontSerrat", sans-serif; */
`;
