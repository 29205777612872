import { SearchCard } from 'Components/SearchCard/Sale';
import {
  SearchSales_sales,
  SearchSales_sales_edges,
} from './__generated__/SearchSales';

type SearchSalesProps = {
  sales: SearchSales_sales | null;
};

export const SearchSale = ({ sales }: SearchSalesProps) => {
  const get4Sales = sales?.edges?.slice(0, 4);
  return (
    <>
      {get4Sales &&
        get4Sales &&
        get4Sales?.map((sale: SearchSales_sales_edges) => {
          const saleItem = sale.node;
          return (
            <div style={{ marginBottom: '2.2rem' }}>
              <SearchCard sale={saleItem!!} />
            </div>
          );
        })}
    </>
  );
};
